define("workflows-web/workflows/library/details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    requestService: Ember.inject.service('request'),
    model: function model(params) {
      return this.get('requestService').fetchWorkflowExample(params.id);
    },
    actions: {
      didTransition: function didTransition() {
        return this.get('requestService').incrementWorkflowExampleViewCount(this.currentModel.id);
      }
    }
  });
});