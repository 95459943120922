define("workflows-web/components/form-field-input/toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    field: null,
    classNames: ['hf-toggle'],
    actions: {
      onChange: function onChange() {
        this.toggleProperty('field.value');
        if (this.onChange) {
          this.onChange(this.get('field.value'));
        }
      }
    }
  });
});