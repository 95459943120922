define("workflows-web/helpers/get-prefix-color", ["exports", "workflows-web/workflows/details/edit/utilities"], function (_exports, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    var prefix = params[0];
    return (0, _utilities.getPrefixColor)(prefix);
  });
});