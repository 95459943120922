define("workflows-web/utilities/transform-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generatePayload = generatePayload;
  _exports.transformData = transformData;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function transformData(field) {
    var fieldValue = field.value;
    field.actualValue = fieldValue;
    if (_typeof(fieldValue) === 'object' && fieldValue.value) {
      fieldValue = fieldValue.value;
    }
    return fieldValue;
  }
  function generatePayload(field) {
    var payload = {};
    var choices = field.choices.findBy('groupName', field.groupName);
    if (choices) {
      var selectedOption = choices.options.findBy('label', field.value);
      if (Ember.isPresent(selectedOption)) {
        payload[field.key] = {
          'id': selectedOption.id,
          'value': field.value
        };
      } else {
        payload[field.key] = field.value;
      }
    } else {
      if (field.actualValue) {
        payload[field.key] = field.actualValue;
      } else {
        payload[field.key] = field.value;
      }
    }
    return payload;
  }
});