define("workflows-web/components/pop-over/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "U8VnbMJ1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,10],null,[[\"trigger\",\"content\",\"isOpen\",\"closePopOver\",\"openPopOver\"],[[30,[36,7],[\"pop-over/trigger\"],[[\"isOpen\",\"stopClickPropagation\",\"openOnHover\",\"isTooltip\",\"isNewTooltip\",\"isAdjustingPopOver\",\"exemptedSelector\",\"togglePopOver\",\"openPopOver\",\"closePopOver\"],[[35,1],[35,6],[35,9],[35,4],[35,3],[35,2],[35,8],[30,[36,0],[[32,0],\"togglePopOver\"],null],[30,[36,0],[[32,0],\"openPopOver\"],null],[30,[36,0],[[32,0],\"closePopOver\"],null]]]],[30,[36,7],[\"pop-over/content\"],[[\"isOpen\",\"stopClickPropagation\",\"isMini\",\"isTooltip\",\"isNewTooltip\",\"isAdjustingPopOver\",\"togglePopOver\",\"closePopOver\"],[[35,1],[35,6],[35,5],[35,4],[35,3],[35,2],[30,[36,0],[[32,0],\"togglePopOver\"],null],[30,[36,0],[[32,0],\"closePopOver\"],null]]]],[35,1],[30,[36,0],[[32,0],\"closePopOver\"],null],[30,[36,0],[[32,0],\"openPopOver\"],null]]]]]],[2,\"\\n\"],[6,[37,13],[[30,[36,12],[[35,11],[35,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"hf-confirmation-overlay hf-mod-pop-over translucent hf-js-confirmation-overlay\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"isOpen\",\"isAdjustingPopOver\",\"isNewTooltip\",\"isTooltip\",\"isMini\",\"stopClickPropagation\",\"component\",\"exemptedSelector\",\"openOnHover\",\"hash\",\"canShowPopOverOverlay\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/components/pop-over/template.hbs"
    }
  });
});