define("workflows-web/components/mini-toast-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0DKt1XRf",
    "block": "{\"symbols\":[\"&attrs\",\"@canShowToastMessage\",\"@message\"],\"statements\":[[11,\"div\"],[17,1],[16,0,[31,[\"hf-scale-out hf-mini-toast-message hf-u-push-right hf-u-vertically-centered-container \",[32,0,[\"toastMessageClassName\"]]]]],[4,[38,0],null,[[\"animationDelay\",\"timeToDisplay\",\"remove\",\"canShow\",\"isClosing\"],[250,[32,0,[\"timeToDisplay\"]],[32,0,[\"remove\"]],[32,2],[32,0,[\"isClosing\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-mini-toast-message_text\"],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"div\"],[24,0,\"hf-u-push-right\"],[4,[38,1],[\"click\",[32,0,[\"preRemove\"]]],null],[12],[2,\"\\n    \"],[1,[30,[36,2],[\"close-icon\"],[[\"class\"],[\"hf-u-cursor-pointer hf-u-icon-left-margin\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"toast-message-ui\",\"on\",\"inline-svg\"]}",
    "meta": {
      "moduleName": "workflows-web/components/mini-toast-message/template.hbs"
    }
  });
});