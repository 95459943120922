define("workflows-web/workflows/mixins/okta/triggers", ["exports", "workflows-web/workflows/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    intlService: Ember.inject.service('intl'),
    getOktaTriggers: function getOktaTriggers() {
      var _this = this;
      var triggerClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var oktaTriggerChoices = Ember.A();
      var userActivated = triggerClass.create({
        stepType: 'user_activated',
        eventType: 'trigger',
        application: 'okta',
        color: '#09491F',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      var userDeactivated = triggerClass.create({
        stepType: 'user_deactivated',
        eventType: 'trigger',
        application: 'okta',
        color: '#091949',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      var userProfileUpdated = triggerClass.create({
        stepType: 'user_profile_updated',
        eventType: 'trigger',
        application: 'okta',
        color: '#490946',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      var userAccountAutoLocked = triggerClass.create({
        stepType: 'user_account_auto_locked',
        eventType: 'trigger',
        application: 'okta',
        color: '#49090F',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      var userAddedToGroup = triggerClass.create({
        stepType: 'user_added_to_group',
        eventType: 'trigger',
        application: 'okta',
        color: '#493209',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      oktaTriggerChoices.addObjects([userActivated, userDeactivated, userProfileUpdated, userAccountAutoLocked, userAddedToGroup]);
      oktaTriggerChoices.forEach(function (trigger) {
        trigger.displayName = _this.intlService.findTranslationByKey(trigger.stepType);
      });
      return oktaTriggerChoices;
    }
  });
});