define("workflows-web/workflows/dashboard/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    router: Ember.inject.service(),
    requestService: Ember.inject.service('request'),
    accountInfoService: Ember.inject.service('account-info'),
    beforeModel: function beforeModel() {
      if (!this.accountInfoService.isWorkflowExecutionDashboardEnabled) {
        this.router.transitionTo('workflows.list');
      }
    },
    model: function model() {
      if (!this.accountInfoService.isUserSelfServedStateUnknown) {
        return this.requestService.fetchDashboardMetrics();
      }
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});