define("workflows-web/components/form-minimizable-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0uxuzeUq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"header\"],[16,0,[31,[\"hf-form-minimizable-section_header \",[30,[36,5],[[35,4],\"hf-is-minimized\"],null]]]],[4,[38,2],[[32,0],\"toggleShowContent\"],null],[12],[2,\"\\n  \"],[1,[30,[36,8],[\"down-caret\"],[[\"class\"],[[30,[36,7],[\"hf-form-minimizable-section_header_caret\",[30,[36,6],[[35,4],\"hf-mod-rotate\"],null]],null]]]]],[1,[30,[36,3],[[35,9]],null]],[2,\"\\n\"],[6,[37,6],[[30,[36,11],[[35,10],[35,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[24,0,\"hf-u-push-right hf-label\"],[16,\"data-test-id\",[30,[36,1],[\"section-action-\",[35,0]],null]],[4,[38,2],[[32,0],\"handleSecondaryActionClick\"],[[\"bubbles\"],[false]]],[12],[2,\" \"],[1,[30,[36,3],[[35,0]],null]],[2,\" \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,6],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"secondaryActionKey\",\"concat\",\"action\",\"t\",\"showContent\",\"unless\",\"if\",\"concat-strings\",\"inline-svg\",\"sectionLabelKey\",\"canShowSecondaryAction\",\"and\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-minimizable-section/template.hbs"
    }
  });
});