define("workflows-web/helpers/linkify-string", ["exports", "workflows-web/utilities/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.linkifyString = linkifyString;
  function linkifyString(params) {
    return (0, _string.getSafeHtml)(linkifyStr(params[0]));
  }
  var _default = _exports.default = Ember.Helper.helper(linkifyString);
});