define("workflows-web/components/color-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DLiERqfd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[14,0,\"hf-js-color-picker\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"hf-color-picker_color-code\"],[14,\"data-test-id\",\"color-picker-color-code\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"color\"]}",
    "meta": {
      "moduleName": "workflows-web/components/color-picker/template.hbs"
    }
  });
});