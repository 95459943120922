define("workflows-web/form-field-models/remote-choice", ["exports", "workflows-web/form-field-models/base-field"], function (_exports, _baseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseField.default.extend({
    choiceLabelKey: 'name',
    choiceValueKey: null,
    choices: null,
    isRemoteChoice: true,
    isAddNew: false,
    lookupUrl: '',
    searchParam: '',
    autoFocus: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('choices', []);
      if (!this.get('value')) {
        this.set('value', null);
      }
    },
    reset: function reset() {
      this._super();
      this.set('errors', Ember.A());
      var initialValue = this.get('_initialValue');
      this.set('value', initialValue);
    },
    validate: function validate() {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          var value = _this.get('value');
          if (value) {
            var choiceValueKey = _this.get('choiceValueKey');
            _this.set('_cleanValue', choiceValueKey ? value[choiceValueKey] : value);
          }
          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });
});