define("workflows-web/components/form-input/date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Y/VlpDnx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-position-relative\"],[12],[2,\"\\n  \"],[1,[30,[36,12],null,[[\"minDate\",\"class\",\"renderInPlace\",\"isAbsolutelyPositioned\",\"data-test-id\",\"value\",\"placeholder\",\"onClose\"],[[35,11],[30,[36,10],[[30,[36,9],[\"hf-date-input\",\"hf-form-input\",[35,8],[30,[36,6],[[35,7],\"hf-is-highlighted\"],null]],null],\" \",[30,[36,6],[[35,5],\"hf-is-error\"],null],\" \"],null],[35,4],true,[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"onClose\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[19,\"components/form-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"action\",\"placeholder\",\"value\",\"inputDataTestId\",\"renderInPlace\",\"hasErrors\",\"if\",\"isHighlighted\",\"inputClass\",\"concat-strings\",\"concat\",\"minDate\",\"date-picker\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-input/date/template.hbs"
    }
  });
});