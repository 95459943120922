define("workflows-web/workflows/details/edit/actions/details/primary-field-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+SaLbbLG",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"previewText\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"hf-form-field_subtext hf-form-triggers-subtext\"],[12],[2,\"\\n    \"],[1,[35,0,[\"previewText\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"h4\"],[14,0,\"hf-u-paragraph\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0,[\"helperText\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[35,0,[\"helperText\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    Choose a field below that holds the value for \"],[1,[35,0,[\"label\"]]],[2,\" from the Event that we \\n    just received. We will use the corresponding value for this field as the value for \\n    \"],[1,[35,0,[\"label\"]]],[2,\".\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"br\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"span\"],[12],[1,[35,0,[\"label\"]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"field\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/primary-field-label/template.hbs"
    }
  });
});