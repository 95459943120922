define("workflows-web/workflows/history/workflow-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bKMapuD2",
    "block": "{\"symbols\":[],\"statements\":[[1,[35,0,[\"name\"]]]],\"hasEval\":false,\"upvars\":[\"value\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/history/workflow-name/template.hbs"
    }
  });
});