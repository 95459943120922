define("workflows-web/components/form-field-input/category-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yqh/dD6j",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"isFormField\",\"selectedCategories\",\"totalCategoryOptions\",\"class\",\"dataTestId\",\"onCategoriesSelection\"],[true,[35,2,[\"value\"]],[35,2,[\"options\"]],\"hf-notification_category-drodown\",[35,1],[30,[36,0],[[32,0],\"onCategoriesSelection\"],null]]]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]]],\"hasEval\":true,\"upvars\":[\"action\",\"fieldDataTestId\",\"field\",\"tickets/category-dropdown\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/category-dropdown/template.hbs"
    }
  });
});