define("workflows-web/components/react-components/workflow-toggle-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xP5L481c",
    "block": "{\"symbols\":[\"@invalidStepsCount\",\"@activeWorkflowsLimit\",\"@isActiveWorkflowsLimitReached\",\"@activationErrorMessage\",\"@onChange\",\"@isDisabled\",\"@isChecked\"],\"statements\":[[11,\"div\"],[4,[38,0],null,[[\"component\",\"isChecked\",\"isDisabled\",\"onChange\",\"activationErrorMessage\",\"isActiveWorkflowsLimitReached\",\"activeWorkflowsLimit\",\"invalidStepsCount\"],[[32,0,[\"ReactComponent\"]],[32,7],[32,6],[32,5],[32,4],[32,3],[32,2],[32,1]]]],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"react-render\"]}",
    "meta": {
      "moduleName": "workflows-web/components/react-components/workflow-toggle-wrapper/template.hbs"
    }
  });
});