define("workflows-web/utilities/editor", ["exports", "workflows-web/config/environment", "workflows-web/utilities/text-area"], function (_exports, _environment, _textArea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getEditorInstanceById = getEditorInstanceById;
  _exports.getEditorTextContent = getEditorTextContent;
  _exports.insertHtmlAtCaretPosition = insertHtmlAtCaretPosition;
  _exports.isContentEmpty = isContentEmpty;
  function isContentEmpty(editorInstance) {
    try {
      var snapshot = editorInstance.getSnapshot();
      // temporarily create a dom and assign the editor's html content to it. From that we can get the plain text content.
      var dom = document.createElement('DIV');
      dom.innerHTML = snapshot;
      var plainText = dom.textContent || dom.innerText;
      return Ember.isEmpty(editorInstance.getData()) || Ember.isEmpty(plainText.trim());
    } catch (exception) {
      Ember.Logger.log('Error while verifying content is empty or not in RTE');
    }
  }
  function getEditorTextContent(editorInstance) {
    if (editorInstance) {
      return editorInstance.element.getText();
    }
  }
  function getEditorInstanceById(instanceId) {
    return CKEDITOR.instances[instanceId];
  }
  function insertHtmlAtCaretPosition(newHtml, elementId) {
    if (_environment.default.environment === 'test') {
      (0, _textArea.insertTextAtCaretPosition)(newHtml, elementId);
    } else {
      var instanceId = elementId;
      var editorInstance = getEditorInstanceById(instanceId);
      if (editorInstance) {
        editorInstance.insertHtml(newHtml);
      }
    }
  }
});