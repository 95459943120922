define("workflows-web/forms/field-choices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LcnS/6Et",
    "block": "{\"symbols\":[\"fieldType\"],\"statements\":[[10,\"div\"],[14,0,\"hf-card hf-form-details_body_container hf-mod-field-choices\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-card_header hf-form-field-choices-container_header\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"form-fields\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-form-field-choices-container_body\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"fieldTypeOptions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[16,1,[32,1,[\"type\"]]],[24,0,\"form-field_choice draggable\"],[24,\"draggable\",\"true\"],[24,\"role\",\"button\"],[4,[38,0],[\"dragstart\",[32,0,[\"dragHasStarted\"]]],null],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"icon\"]]],[[\"class\"],[\"hf-u-icon-right-margin hf-mod-extra\"]]]],[2,\"\\n        \"],[1,[32,1,[\"label\"]]],[2,\"\\n        \"],[10,\"div\"],[14,0,\"hf-u-push-right\"],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"hf-workflows_actions-container_add-button\"],[4,[38,0],[\"click\",[30,[36,2],[[32,0,[\"addField\"]],[32,1]],null]],null],[12],[2,\"+\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"inline-svg\",\"fn\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "workflows-web/forms/field-choices/template.hbs"
    }
  });
});