define("workflows-web/workflows/list/history/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    actions: {
      goToWorkflowExecutionHistory: function goToWorkflowExecutionHistory(workflowId) {
        this.router.transitionTo('history', {
          queryParams: {
            workflowId: workflowId
          }
        });
      }
    }
  });
});