define("workflows-web/workflows/history/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.fetchData.perform();
    }
  });
});