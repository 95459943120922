define("workflows-web/forms/details/edit/form-model", ["exports", "workflows-web/form-models/base-form", "workflows-web/utilities/object"], function (_exports, _baseForm, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseForm.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var rawFields = this.get('rawFields');
      var basicFieldKeys = this.get('basicFieldKeys');
      this.set('dataSource', (0, _object.camelizeObject)(this.get('dataSource')));
      this.set('basicFieldKeys', basicFieldKeys);
      this.set('fields', this._createFieldModels(rawFields));
      this.set('errors', Ember.A());
    },
    _copyFromDataSource: function _copyFromDataSource() {
      var _this = this;
      this._super.apply(this, arguments);
      var fields = this.get('fields');
      if (fields.isAny('isArray')) {
        fields.filterBy('isArray').forEach(function (field) {
          _this.get("model.".concat(field.key)).createChildFields();
        });
      }
    },
    submit: function submit() {
      var _this2 = this;
      var fields = this.get('fields');
      var dataSource = this.get('dataSource');
      this.set('errors', Ember.A());
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.validate().then(function () {
          _this2._copyToDataSource(fields, dataSource);
          resolve(dataSource);
        }).catch(function () {
          _this2.scrollToError();
          reject();
        });
      });
    },
    generatePayload: function generatePayload() {
      var formModel = this.get('model');
      var basicFieldKeys = this.get('basicFieldKeys');
      var payload = {};
      basicFieldKeys.forEach(function (key) {
        if (formModel[key].get('isDirty') || formModel[key].includeAlwaysInPayload) {
          var field = formModel[key];
          if (field.isArray) {
            if (field.key === 'matchAll') {
              payload.matchAll = formModel.matchAll.value.map(function (val) {
                return {
                  field: val.field,
                  predicate: val.predicate,
                  value: val.value
                };
              });
            } else {
              payload[key] = [];
              field.childFields.forEach(function (childField) {
                if (childField.childFields) {
                  var childPayload = {};
                  childField.childFields.forEach(function (_field) {
                    childPayload[_field.key] = _.isEmpty(_field.value) ? _field.defaultValue : _field.value;
                  });
                  payload[key].pushObject(childPayload);
                }
              });
            }
          } else if (field.isObject) {
            payload[key] = {};
            field.childFields.forEach(function (childField) {
              payload[key][childField.key] = childField.value;
            });
          } else {
            var value = formModel[key].value;
            if (Ember.isPresent(value)) {
              if (value && field.isChoice && field.choiceValueKey) {
                payload[key] = value[field.choiceValueKey];
              } else {
                payload[key] = value;
              }
            }
          }
        }
      });
      return payload;
    }
  });
});