define("workflows-web/services/toast-message", ["exports", "workflows-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    autoHide: true,
    message: null,
    hidePreviousMessage: true,
    messageType: false,
    isMultiLine: false,
    showMessage: function showMessage(message) {
      var _this = this;
      this.set('autoHide', !(message.type === 'failure' || _environment.default.environment === 'test' || message.disableAutoHide));
      this.set('messageType', message.type);
      this.set('isMultiLine', message.isMultiLine || false);
      if (Ember.isPresent(this.get('message'))) {
        // if there's a message already being displayed
        this.set('hidePreviousMessage', true); // setting hidePreviousMessage to true for closing animation of previous message
        Ember.run.later(function () {
          _this.hideMessage(); // hides the previous message
          Ember.run.next(function () {
            _this.set('message', message); // after hiding the previous message show the new message
            _this.set('hidePreviousMessage', false);
          });
        }, 500);
      } else {
        this.set('hidePreviousMessage', false);
        this.set('message', message);
        this.set('messageComponent', message.messageComponent);
      }
    },
    hideMessage: function hideMessage() {
      this.set('message', null);
    }
  });
});