define("workflows-web/form-field-models/dynamic-field", ["exports", "workflows-web/form-field-models/base-field"], function (_exports, _baseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseField.default.extend({
    isDynamicField: true,
    validate: function validate() {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          var value = _this.get('value');
          _this.set('_cleanValue', value);
          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });
});