define("workflows-web/email-verification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YpQQHnMW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"login-content hf-u-viewport-centered\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-content_branding\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/svgs/hf-logo.svg\"],[14,0,\"login-content_branding_img\"],[15,\"alt\",[30,[36,4],[\"happyfox\"],null]],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"login-content_company-name font-semi-bold\"],[12],[1,[30,[36,4],[\"email-verification\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,3],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"login-content_validation-loader\"],[12],[2,\"\\n      \"],[19,\"templates/loading\",[]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"login-content_success-message\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"login-content_error mod-align-left hf-mod-block\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[1,[34,0]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"error\",\"successMessage\",\"isValidToken\",\"if\",\"t\",\"isValidatingToken\"]}",
    "meta": {
      "moduleName": "workflows-web/email-verification/template.hbs"
    }
  });
});