define("workflows-web/mixins/table", ["exports", "jquery", "ember-light-table"], function (_exports, _jquery, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    localStorageService: Ember.inject.service('local-storage'),
    isAnyRowSelected: Ember.computed.notEmpty('table.selectedRows'),
    selectedIds: Ember.computed.mapBy('table.selectedRows', 'id'),
    tableModelKey: 'model',
    isSortableTable: false,
    // The case in which the table's rows can be dragged manually and sorted
    isLocalSort: false,
    canPersistSort: true,
    bulkSelectionColumn: Ember.Object.create({
      label: '',
      cellComponent: 'table-bulk-selection-cell',
      cellClassNames: 'hf-u-visible-overflow',
      width: '40px',
      sortable: false,
      droppable: false
    }),
    tableSettingsColumn: null,
    dragHandleColumn: Ember.Object.create({
      cellComponent: 'sortable-data-table/draggable-row/dragger',
      cellClassNames: 'hf-u-cursor-drag js-dragHandle dragHandle',
      width: '30px',
      sortable: false
    }),
    // Local storage keys
    sortOrderKey: Ember.computed('tableId', {
      get: function get() {
        var tableId = this.get('tableId');
        return "hf-staff-".concat(tableId, "-sort-order");
      }
    }),
    sortFieldKey: Ember.computed('tableId', {
      get: function get() {
        var tableId = this.get('tableId');
        return "hf-staff-".concat(tableId, "-sort-field");
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.fetchPersistedSort();
      var tableSettingsColumn = Ember.Object.create({
        width: '37px',
        sortable: false,
        component: 'table-settings',
        classNames: 'hf-mod-table-settings',
        droppable: false
      });
      this.setProperties({
        tableSettingsColumn: tableSettingsColumn
      });
      if (this.tableModelKey && this.get(this.get('tableModelKey'))) {
        this.createTableInstance();
      }
    },
    tableProperties: Ember.computed('isLocalSort', 'canPersistSort', {
      get: function get() {
        return {
          isLocalSort: this.get('isLocalSort'),
          canPersistSort: this.get('canPersistSort')
        };
      }
    }),
    formatColumns: function formatColumns(columns) {
      var result = [];
      if (this.get('canBulkSelect')) {
        result.addObject(this.get('bulkSelectionColumn'));
      }
      if (this.get('canChooseColumns')) {
        result.addObject(this.get('tableSettingsColumn'));
      }
      // TODO: Handle the case in which table settings is needed without leftFixedColumns
      if (Ember.isPresent(this.get('leftFixedColumns'))) {
        // To take up the space of the table settings column, we can shift the left fixed columns to the left
        var leftFixedColumns = this.get('leftFixedColumns').slice();
        var firstColumn = leftFixedColumns.get('firstObject');
        this.get('tableSettingsColumn').setProperties({
          // To make sure the space below the table settings is filled
          cellComponent: firstColumn.cellComponent,
          cellClassNames: firstColumn.cellClassNames
        });
        leftFixedColumns.removeObject(firstColumn);
        result.addObjects(leftFixedColumns);
      }
      // TODO: Rename this boolean
      if (this.get('isSortableTable')) {
        columns.unshift(this.get('dragHandleColumn'));
      }
      result.addObjects(columns);
      if (this.get('rightFixedColumns')) {
        result.addObjects(this.get('rightFixedColumns'));
      }
      result = result.map(function (column) {
        column = Ember.Object.create(column);
        var _column = column,
          label = _column.label;
        if (Ember.isPresent(label)) {
          column.label = label;
        }
        if (!column.component) {
          column.component = 'data-table-new/column'; // For sorting
        }
        column.droppable = false;
        if (!column.minResizeWidth) {
          column.minResizeWidth = 100;
        }
        if (column.isHtmlContent) {
          column.cellComponent = 'data-table-new/html-cell';
        }
        // Ember Light Table only needs valuePath in a column, but we include id as well for certain use cases ('priority' vs 'priority.name' for eg) and regard that as the primary way to identify a column
        if (Ember.isBlank(column.id)) {
          column.id = column.valuePath;
        }
        return column;
      });
      return result;
    },
    fetchPersistedSort: function fetchPersistedSort() {
      var localStorageService = this.get('localStorageService');
      var sortField = localStorageService.getItem(this.get('sortFieldKey'));
      var sortOrder = localStorageService.getItem(this.get('sortOrderKey'));
      if (_jquery.default.isNumeric(sortField)) {
        sortField = Number(sortField);
      }
      if (sortField && sortOrder) {
        // To be able to set default sortField, sortOrder in the controller. If check is not present and localStorage has empty values, these properties will be set to empty values.
        this.setProperties({
          sortField: sortField,
          sortOrder: sortOrder
        });
      }
    },
    createTableInstance: function createTableInstance(isSwitchingViews) {
      var sortField = this.get('sortField');
      var sortOrder = this.get('sortOrder');
      var columns = _.cloneDeep(this.get('columns'));
      var data = this.get(this.get('tableModelKey'));
      var sortedColumn;
      columns = this.formatColumns(columns);
      if (sortField) {
        sortedColumn = columns.findBy('id', sortField);
        if (sortedColumn) {
          sortedColumn.setProperties({
            sorted: true,
            ascending: sortOrder === 'asc'
          });
        }
      }
      var table = _emberLightTable.default.create({
        columns: columns,
        rows: data,
        enableSync: true
      });
      var tablePreferences = this.get('tablePreferences');
      table.setProperties({
        columnsMeta: columns,
        preferences: tablePreferences,
        tableProperties: this.get('tableProperties')
      });
      // All possible columns for a table, used only in dynamic tables
      if (this.getAllColumns) {
        table.set('availableColumns', this.getAllColumns());
      }
      if (sortField && this.get('isLocalSort')) {
        table.sort(sortedColumn);
      }
      this.set('table', table);
      // Changing table view from custom to default or vice versa will rerender the table, thus closing the pop-over.
      if (isSwitchingViews && tablePreferences.get('selectedView') !== 'default') {
        Ember.run.next(function () {
          (0, _jquery.default)('.hf-js-open-table-settings').click(); // Instantiating table again will close the pop over, hence we are forcibly opening it
        });
      }
      return table;
    },
    // Recommended way to use table. Using the table object will necessitate calling createTableInstance in number of use cases
    // TODO: Should support tableModelKey
    computedTable: Ember.computed('columns', 'model', {
      get: function get() {
        if (this.get('columns') && this.get('model')) {
          return this.createTableInstance();
        }
      }
    }),
    actions: {
      clearSelection: function clearSelection() {
        this.get('table.selectedRows').setEach('selected', false);
      },
      // Only for remote sort
      changeSort: function changeSort(_ref) {
        var field = _ref.field,
          order = _ref.order,
          searchText = _ref.searchText,
          offset = _ref.offset;
        this.setProperties({
          sortOrder: order,
          sortField: field,
          searchText: searchText
        });
        if (Ember.isPresent(offset)) {
          this.set('offset', offset);
        }
        this.fetchData.perform();
      },
      persistSort: function persistSort(_ref2) {
        var field = _ref2.field,
          order = _ref2.order;
        this.setProperties({
          sortOrder: order,
          sortField: field
        });
        var localStorageService = this.get('localStorageService');
        localStorageService.setItem(this.get('sortOrderKey'), order);
        localStorageService.setItem(this.get('sortFieldKey'), field);
      }
    }
  });
});