define("workflows-web/form-field-models/local-multi-choice-dropdown-field", ["exports", "workflows-web/form-field-models/base-field"], function (_exports, _baseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseField.default.extend({
    // default is 'name'
    choiceLabelKey: 'name',
    choiceValueKey: null,
    isLocalMultiChoiceDropdown: true,
    choices: null,
    // value will be passed as it is from datasource
    init: function init() {
      var selectedChoices = Ember.A();
      var fieldValue = this.get('value');
      if (Ember.isPresent(fieldValue)) {
        var choices = this.get('choices');
        selectedChoices = this.findChoices(choices, fieldValue);
      }
      this.setProperties({
        _initialValue: selectedChoices,
        value: selectedChoices
      });
    },
    findChoices: function findChoices(choices, selectedChoices) {
      var _this = this;
      return choices.filter(function (choice) {
        return selectedChoices.any(function (selectedChoice) {
          var choiceValueKey = _this.get('choiceValueKey');
          if (choiceValueKey) {
            return selectedChoice.toString() === choice.get ? choice.get(choiceValueKey).toString() : choice[choiceValueKey].toString();
          } else {
            return _.isEqual(choice, selectedChoice);
          }
        });
      });
    },
    isDirty: Ember.computed('value.[]', '_initialValue.[]', {
      get: function get() {
        var choiceLabelKey = this.get('choiceLabelKey');
        // _.isEqual([1, 2, 3], [3, 2, 1]) returns false as ordering is different. So using sortBy
        return !_.isEqual(this.get('_initialValue').sortBy(choiceLabelKey), this.get('value').sortBy(choiceLabelKey));
      }
    }),
    updateInitialValue: function updateInitialValue() {
      this.set('_initialValue', this.get('value').slice());
    },
    reset: function reset() {
      this._super();
      this.set('value', this.get('_initialValue').slice());
    },
    validate: function validate() {
      var _this2 = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2._super().then(function () {
          var value = _this2.get('value');
          if (value) {
            var choiceValueKey = _this2.get('choiceValueKey');
            _this2.set('_cleanValue', choiceValueKey ? value.mapBy(choiceValueKey) : value);
          }
          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });
});