define("workflows-web/components/confirmation-dialog/component", ["exports", "jquery", "workflows-web/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    confirmationDialogClass: null,
    confirmationDialogTrigger: null,
    primaryActionText: 'ok',
    secondaryActionText: 'cancel',
    message: null,
    stopPropagation: false,
    showButtons: true,
    /* Rendering the contents of this component to the container 'ember-testing' when the tests run and body of html otherwise using Ember wormhole by setting this destination property */
    destination: Ember.computed({
      get: function get() {
        if (_environment.default.environment !== 'test') {
          return 'body';
        } else {
          return 'ember-testing';
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      var confirmationDialog = (0, _jquery.default)('.hf-js-confirmation-dialog');
      var confirmationDialogCaret = (0, _jquery.default)('.hf-confirmation-dialog-caret');
      var $this = this.$();
      var $trigger = this.get('confirmationDialogTrigger');
      var $triggerElement = (0, _jquery.default)(".hf-js-".concat($trigger));
      var windowHeight = (0, _jquery.default)(window).outerHeight();
      $this.attr('tabindex', 0);
      $this.focus();
      /* calculations are done with outerHeight() and outerWidth() as these functions take into account the border and padding which the
          element has while calculating dimensions */
      if ($triggerElement.offset().top + $triggerElement.outerHeight() + confirmationDialogCaret.outerHeight() + confirmationDialog.outerHeight() < windowHeight) {
        confirmationDialog.css('top', $triggerElement.offset().top + $triggerElement.outerHeight() + confirmationDialogCaret.outerHeight() - 1);
        // confirmation dialog caret is positioned at the bottom center of the trigger
        confirmationDialogCaret.css('top', $triggerElement.offset().top + $triggerElement.outerHeight());
      } else {
        var bottom = windowHeight - $triggerElement.offset().top + confirmationDialogCaret.outerHeight();
        confirmationDialog.css('bottom', bottom < 0 ? 0 : bottom);
        // confirmation dialog caret is positioned at the top center of the trigger
        confirmationDialogCaret.css('bottom', windowHeight - $triggerElement.offset().top + 1);
        confirmationDialogCaret.css('-webkit-transform', 'rotate(180deg)');
        confirmationDialogCaret.css('transform', 'rotate(180deg)');
      }
      if ($triggerElement.offset().left + confirmationDialog.outerWidth() > (0, _jquery.default)(window).outerWidth()) {
        /* confirmation dialog positioned at the far right if trigger is at the far right  */
        confirmationDialog.css('right', 0);
        confirmationDialog.css('min-width', '300px');
      } else {
        if ($triggerElement.width() < confirmationDialog.width()) {
          var left = $triggerElement.offset().left - confirmationDialog.outerWidth() / 2;
          confirmationDialog.css('left', left < 0 ? 0 : left);
        } else {
          /* In the case of ticket subject, the ticket subject input box's width is greater than the confrimation dialog's width */
          confirmationDialog.css('left', $triggerElement.offset().left + $triggerElement.outerWidth() / 2 - confirmationDialog.outerWidth() / 2);
        }
      }
      confirmationDialogCaret.css('left', $triggerElement.offset().left + $triggerElement.outerWidth() / 2 - confirmationDialogCaret.outerWidth() / 2);
      if (this.get('stopPropagation')) {
        Ember.run.scheduleOnce('afterRender', function () {
          (0, _jquery.default)('.hf-js-confirmation-overlay, .hf-js-confirmation-dialog').on('click', function (event) {
            // do not stop event propagation if clicked on buttons in confirmation dialog
            if (event.target.className.indexOf('hf-js-confirmation-button') === -1) {
              event.stopPropagation();
            }
          });
        });
      }
    },
    /* Enter key : keycode = 13, Esc Key : KeyCode = 27 */keyDown: function keyDown(e) {
      if (e.which === 27) {
        this.secondaryAction();
      }
      if (e.which === 13) {
        this.primaryAction();
      }
    },
    actions: {
      primaryAction: function primaryAction() {
        var primaryActionParams = this.primaryActionParams;
        if (primaryActionParams) {
          this.primaryAction(primaryActionParams);
        } else {
          this.primaryAction();
        }
      },
      secondaryAction: function secondaryAction() {
        this.secondaryAction();
      }
    }
  });
});