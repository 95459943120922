define("workflows-web/components/form-input/text/component", ["exports", "workflows-web/components/form-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CHARACTER_LIMIT = 100;
  var _default = _exports.default = _base.default.extend({
    characterLimit: CHARACTER_LIMIT,
    placeholder: null,
    autoFocus: false,
    isHighlighted: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('autoFocus')) {
        var inputElement = this.$('input').eq(0);
        // Needed for the value to be set first and then focus so the cursor is at the end of the text.
        Ember.run.later(function () {
          inputElement.focus();
          var _inputElement$val = inputElement.val(),
            length = _inputElement$val.length;
          inputElement[0].setSelectionRange(length, length);
        });
      }
    },
    validate: function validate() {
      this.initializeErrors();
      this.checkIfMandatory();
      this.checkForCharacterLimit();
    },
    actions: {
      onFocusOut: function onFocusOut() {
        this.validate();
        this.set('isHighlighted', false);
        if (!this.get('errors.length') && Ember.isPresent(this.get('onFocusOut'))) {
          this.onFocusOut();
        }
      },
      onEnter: function onEnter() {
        if (this.onEnter) {
          this.onEnter();
        }
      }
    }
  });
});