define("workflows-web/workflows/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "g6TaV4JW",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,10],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"react-components/left-nav-wrapper\",[],[[\"@allWorkflowsCount\",\"@activeWorkflowsCount\",\"@inactiveWorkflowsCount\",\"@goToWorkflowsList\",\"@goToWorkflowsHistoryList\",\"@currentRouteName\",\"@selectedWorkflow\",\"@selectedRunHistory\",\"@goToRoute\",\"@accountInfoService\",\"@currentRoute\"],[[34,0],[34,1],[34,2],[30,[36,3],[[32,0],\"goToWorkflowsList\"],null],[30,[36,3],[[32,0],\"goToWorkflowsHistoryList\"],null],[34,4],[34,5,[\"status\"]],[34,6,[\"status\"]],[30,[36,3],[[32,0],\"goToRoute\"],null],[34,7],[34,8]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"section\"],[14,0,\"hf-content\"],[12],[2,\"\\n  \"],[1,[30,[36,12],[[30,[36,11],null,[[\"class\"],[\"hf-outlet-container\"]]]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"allWorkflowsCount\",\"activeWorkflowsCount\",\"inactiveWorkflowsCount\",\"action\",\"currentRouteName\",\"workflowsListController\",\"workflowsHistoryListController\",\"accountInfoService\",\"currentRoute\",\"hideLeftNav\",\"unless\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/template.hbs"
    }
  });
});