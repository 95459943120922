define("workflows-web/workflows/history/completed-at/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    valueModified: Ember.computed('value', {
      get: function get() {
        var value = this.value;
        if (value) {
          return this.value.replace('Z', '').replace('T', ' ');
        } else {
          return '~';
        }
      }
    })
  });
});