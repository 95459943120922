define("workflows-web/services/request", ["exports", "fetch", "workflows-web/utilities/request", "workflows-web/utilities/object", "workflows-web/utilities/url", "workflows-web/workflows/constants"], function (_exports, _fetch, _request, _object, _url, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = Ember.Service.extend({
    csrfToken: '',
    forbiddenError: null,
    router: Ember.inject.service(),
    accountInfoService: Ember.inject.service('account-info'),
    appsToNotModifyPayloadKeys: Ember.computed('accountInfoService.applicationMetaInfo.[]', {
      get: function get() {
        var _this$accountInfoServ;
        var appsToNotModifyPayloadKeys = _constants.APPS_TO_NOT_MODIFY_PAYLOAD_KEYS;
        (_this$accountInfoServ = this.accountInfoService.applicationMetaInfo) === null || _this$accountInfoServ === void 0 || _this$accountInfoServ.forEach(function (app) {
          var metadata = app.settings.metadata;
          if (metadata && 'modifyPayloadKeys' in metadata && metadata.modifyPayloadKeys) {
            appsToNotModifyPayloadKeys.addObject(app.name);
          }
        });
        return appsToNotModifyPayloadKeys;
      }
    }),
    loggerService: Ember.inject.service('logger'),
    sendRequest: function sendRequest(url) {
      var method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GET';
      var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var namespace = '/api/1.0';
      url = "".concat(namespace, "/").concat(url);
      var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      };
      headers['X-CSRFTOKEN'] = this.csrfToken;
      var requestBody = {
        method: method,
        headers: headers
      };
      if (data) {
        if (!options.doNotModifyPayloadKeys) {
          data = (0, _object.underscoreObject)(data);
        }
        requestBody.body = JSON.stringify(data);
      }
      return (0, _fetch.default)(url, requestBody).then(Ember.run.bind(this, this.checkStatus));
    },
    checkStatus: function checkStatus(response) {
      var _this = this;
      var loggerService = Ember.get(this, 'loggerService');
      this.forbiddenError = null;
      if (_typeof(response) === 'object') {
        var status = response.status;
        if ((0, _request.isSuccess)(status)) {
          if (response.headers.get('Content-Type') === 'application/json') {
            if (status === 204) {
              // HTTP Status 204 No Content. Empty string will be return and trying parse it as json results in adapter error.
              return {};
            } else {
              return response.json().then(function (responseObject) {
                return responseObject;
              });
            }
          } else {
            return response.text();
          }
        } else {
          var responsePromise = response.headers.get('Content-Type') === 'application/json' ? response.json() : response.text();
          return responsePromise.then(function (payload) {
            if (status === 403 && (payload === null || payload === void 0 ? void 0 : payload.code) === 'suspended_account' && (_this.accountInfoService.hasSelfServedAccountExpired || _this.accountInfoService.isUserSelfServedStateUnknown)) {
              _this.router.transitionTo('workflows.dashboard');
            } else if ((status === 403 || status === 401) && _this.router.currentRouteName !== 'login') {
              // set user to null else the user will be redirected to the workflows page which may result in infinite loop
              _this.accountInfoService.setAccountInfo({
                user: null
              });
              _this.router.transitionTo('login', {
                queryParams: {
                  'return_to': window.location.href,
                  'error': JSON.stringify(payload)
                }
              });
              _this.forbiddenError = payload === null || payload === void 0 ? void 0 : payload.reason;
            } else if (Ember.isArray(payload)) {
              return Ember.RSVP.Promise.reject((0, _object.camelizeObject)(payload));
            } else {
              var adapterError = Ember.Object.create(payload);
              adapterError.set('status', status);
              return Ember.RSVP.Promise.reject((0, _object.camelizeObject)(adapterError));
            }
          });
        }
      } else {
        loggerService.error('Error in processing a request, check data.', response);
      }
    },
    get: function get(url, data) {
      var extraInformation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      if (!Ember.isEmpty(data)) {
        url = "".concat(url, "?").concat((0, _url.getQueryString)((0, _object.underscoreObject)(data)));
      }
      return this.sendRequest(url, 'get', null, extraInformation);
    },
    put: function put(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var extraInformation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.sendRequest(url, 'put', data, extraInformation);
    },
    post: function post(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var extraInformation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.sendRequest(url, 'post', data, extraInformation);
    },
    patch: function patch(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var extraInformation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.sendRequest(url, 'PATCH', data, extraInformation);
    },
    delete: function _delete(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var extraInformation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.sendRequest(url, 'delete', data, extraInformation);
    },
    fetchPaginatedRecords: function fetchPaginatedRecords(url) {
      var _this2 = this;
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        offset: 0,
        limit: 100
      };
      var fetchedRecords = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Ember.A();
      return this.get(url, params).then(function (records) {
        fetchedRecords.addObjects(records.results);
        if (records.meta.next) {
          params.offset = records.meta.offset + records.meta.limit;
          return _this2.fetchPaginatedRecords(url, params, fetchedRecords);
        } else {
          return (0, _object.camelizeObject)(fetchedRecords);
        }
      });
    },
    fetchAccountInfo: function fetchAccountInfo() {
      return this.get('account/').then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchTextTransformTypes: function fetchTextTransformTypes() {
      return this.get('entity-autocomplete/workflows/text-transform-types/').then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchIntegrationMetaInfo: function fetchIntegrationMetaInfo() {
      return this.get('integrations-meta-info/').then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchApplicationMetaInfo: function fetchApplicationMetaInfo() {
      return this.fetchPaginatedRecords('application-meta-info/');
    },
    createWorkflow: function createWorkflow(data) {
      var url = 'workflows/';
      var method = 'POST';
      return this.sendRequest(url, method, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchAllWorkflows: function fetchAllWorkflows(params) {
      var url = "workflows/";
      return this.get(url, params).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchWorkflow: function fetchWorkflow(id) {
      var _this3 = this;
      var url = "workflows/".concat(id, "/");
      return this.get(url).then(function (response) {
        var steps = response.steps;
        delete response.steps;
        var workflow = (0, _object.camelizeObject)(response);
        steps = steps.map(function (action) {
          // do not modify the keys of data for apps like salesforce
          if (_this3.appsToNotModifyPayloadKeys.includes(action.application) && action.eventType === 'action') {
            return (0, _object.camelizeObject)(action, ['data']);
          }
          return (0, _object.camelizeObject)(action);
        });
        workflow.set('steps', steps);
        return workflow;
      }).catch(function () {
        return {
          invalidWorkflow: true
        };
      });
    },
    patchWorkflow: function patchWorkflow(id, data) {
      var _this4 = this;
      var url = "workflows/".concat(id, "/");
      return this.patch(url, data).then(function (response) {
        var steps = response.steps;
        delete response.steps;
        var workflow = (0, _object.camelizeObject)(response);
        if (steps.any(function (action) {
          return _this4.appsToNotModifyPayloadKeys.includes(action.application);
        })) {
          workflow.set('steps', (0, _object.camelizeObject)(steps, ['data']));
        } else {
          workflow.set('steps', (0, _object.camelizeObject)(steps));
        }
        return workflow;
      });
    },
    deleteWorkflow: function deleteWorkflow(id) {
      var url = "workflows/".concat(id, "/");
      return this.delete(url);
    },
    fetchWorkflowTestData: function fetchWorkflowTestData(id) {
      var url = "workflows/".concat(id, "/test-data/");
      return this.get(url);
    },
    saveWorkflowAction: function saveWorkflowAction(workflow, action, data) {
      var _this5 = this;
      var options = Ember.Object.create();
      if (this.appsToNotModifyPayloadKeys.includes(action.application) && action.eventType === 'action') {
        options.doNotModifyPayloadKeys = true;
      }
      var url = Ember.isPresent(action.stepId) ? "workflows/".concat(workflow.workflowId, "/steps/").concat(action.stepId, "/") : "workflows/".concat(workflow.workflowId, "/steps/");
      var method = Ember.isPresent(action.stepId) ? 'PATCH' : 'POST';
      return this.sendRequest(url, method, data, options).then(function (response) {
        if (_this5.appsToNotModifyPayloadKeys.includes(response.application)) {
          return (0, _object.camelizeObject)(response, ['data']);
        } else {
          return (0, _object.camelizeObject)(response);
        }
      });
    },
    deleteWorkflowAction: function deleteWorkflowAction(workflowId, stepId) {
      var url = "workflows/".concat(workflowId, "/steps/").concat(stepId, "/");
      return this.sendRequest(url, 'DELETE').then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    testWorkflowAction: function testWorkflowAction(workflow, action, data) {
      var options = Ember.Object.create();
      if (this.appsToNotModifyPayloadKeys.includes(action.application) && action.eventType === 'action') {
        options.doNotModifyPayloadKeys = true;
      }
      if ('groups' in data) {
        delete data.groups;
      }
      var url = "workflows/".concat(workflow.workflowId, "/steps/").concat(action.stepId, "/test/");
      return this.post(url, data, options);
    },
    fetchTriggerData: function fetchTriggerData(url) {
      return this.sendRequest(url, 'GET', null).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    saveTriggerData: function saveTriggerData(url, data) {
      return this.patch(url, data, {
        doNotModifyPayloadKeys: false
      }).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    recordTriggerData: function recordTriggerData(url, data) {
      return this.post(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    reorderSteps: function reorderSteps(workflowId, data) {
      var _this6 = this;
      var url = "workflows/".concat(workflowId, "/actions/reorder/");
      return this.sendRequest(url, 'POST', data).then(function (response) {
        var steps = response.steps;
        delete response.steps;
        var workflow = (0, _object.camelizeObject)(response);
        steps = steps.map(function (action) {
          // do not modify the keys of data for apps like salesforce
          if (_this6.appsToNotModifyPayloadKeys.includes(action.application) && action.eventType === 'action') {
            return (0, _object.camelizeObject)(action, ['data']);
          }
          return (0, _object.camelizeObject)(action);
        });
        workflow.set('steps', steps);
        return workflow;
      });
    },
    validateAIDescription: function validateAIDescription(data) {
      var url = 'workflows/actions/validate-build-workflow/';
      return this.post(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    createAIDescription: function createAIDescription(data) {
      var url = 'workflows/actions/build-workflow/';
      return this.post(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    cloneWorkflow: function cloneWorkflow(workflowId) {
      var url = "workflows/".concat(workflowId, "/actions/clone");
      return this.get(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    runWorkflow: function runWorkflow(webhookUrl) {
      var method = 'POST';
      return (0, _fetch.default)("".concat(webhookUrl), {
        method: method
      }).then(Ember.run.bind(this, this.checkStatus));
    },
    logout: function logout() {
      return this.post('logout/').then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    autoCompleteSearch: function autoCompleteSearch(searchParam, term, url) {
      var additionalParams = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var params = {};
      params[searchParam] = term;
      var paramsString = (0, _url.getQueryString)(params);
      if (url.indexOf('?') >= 0) {
        url = "".concat(url, "&").concat(paramsString);
      } else {
        url = "".concat(url, "?").concat(paramsString);
      }
      if (Ember.isPresent(additionalParams)) {
        var additionalParamsString = (0, _url.getQueryString)(additionalParams);
        url = "".concat(url, "&").concat(additionalParamsString);
      }
      return this.sendRequest(url, 'GET', null).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    entityAutocomplete: function entityAutocomplete(application, entity) {
      var term = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      var additionalParams = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var completeUrl = arguments.length > 4 ? arguments[4] : undefined;
      var url = completeUrl ? completeUrl : "entity-autocomplete/".concat(application, "/").concat(entity, "/");
      if (Ember.isPresent(additionalParams)) {
        var additionalParamsString = (0, _url.getQueryString)(additionalParams);
        url = "".concat(url, "?").concat(additionalParamsString);
      }
      if (Ember.isPresent(term)) {
        var params = {};
        params['text'] = term;
        var paramsString = (0, _url.getQueryString)(params);
        if (url.indexOf('?') >= 0) {
          url = "".concat(url, "&").concat(paramsString);
        } else {
          url = "".concat(url, "?").concat(paramsString);
        }
      }
      return this.get(url).then(function (response) {
        if (application !== 'jira') {
          return (0, _object.camelizeObject)(response);
        }
        return response;
      });
    },
    generateAIDescription: function generateAIDescription(workflowId) {
      var url = "workflows/".concat(workflowId, "/actions/generate-description/");
      return this.get(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    feedbackAIDescription: function feedbackAIDescription(payload) {
      var url = "record-feedback/";
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    staffLogin: function staffLogin(email, password, returnTo, keepMeLoggedIn) {
      var url = 'login/';
      var payload = {
        email: email,
        password: password,
        keepMeLoggedIn: keepMeLoggedIn
      };
      if (returnTo) {
        payload.returnTo = returnTo;
      }
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    verifyOTP: function verifyOTP(otp, userAccountId, rememberDevice, returnTo) {
      var url = 'verify-otp/';
      var payload = {
        otp: otp,
        userAccountId: userAccountId,
        rememberDevice: rememberDevice
      };
      if (returnTo) {
        payload.returnTo = returnTo;
      }
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    resendOTP: function resendOTP(userAccountId) {
      var url = 'resend-otp/';
      var payload = {
        userAccountId: userAccountId
      };
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    validateRegistarionToken: function validateRegistarionToken(token) {
      var url = 'validate-registration-token/';
      var payload = {
        token: token
      };
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    resendRegistrationToken: function resendRegistrationToken(token) {
      var url = 'resend-registration-token/';
      var payload = {
        token: token
      };
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    validateEmailVerificationToken: function validateEmailVerificationToken(token) {
      var url = 'validate-email-verification-token/';
      var payload = {
        token: token
      };
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    registerUser: function registerUser(payload) {
      var url = 'finish-registration/';
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    validateForgotPasswordToken: function validateForgotPasswordToken(token) {
      var url = 'validate-forgot-password-token/';
      var payload = {
        token: token
      };
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    sendEmailForForgotPassword: function sendEmailForForgotPassword(payload) {
      var url = 'forgot-password/';
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    resetPassword: function resetPassword(payload) {
      var url = 'reset-password/';
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchForms: function fetchForms() {
      var url = 'workflows/forms/';
      return this.fetchPaginatedRecords(url);
    },
    fetchWorkflowsHistory: function fetchWorkflowsHistory(params) {
      var paramsString = (0, _url.getQueryString)((0, _object.underscoreObject)(params));
      var url = "workflows/history/?".concat(paramsString);
      return this.get(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchWorkflowExecution: function fetchWorkflowExecution(url) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var paramsString = (0, _url.getQueryString)((0, _object.underscoreObject)(params));
      url = "".concat(url, "?").concat(paramsString);
      return this.get(url);
    },
    getWorkflowsCount: function getWorkflowsCount() {
      var url = "workflows/count/";
      return this.get(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchActionDetailsChoices: function fetchActionDetailsChoices(workflowId, stepId, includeCurrentActionTestData, groupId) {
      var recommendedFieldsParam = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      var params = _objectSpread({
        stepId: stepId,
        includeCurrentActionTestData: includeCurrentActionTestData
      }, recommendedFieldsParam);
      if (groupId) {
        params.groupId = groupId;
      }
      var paramsString = (0, _url.getQueryString)((0, _object.underscoreObject)(params));
      var url = "workflows/".concat(workflowId, "/test-data/?").concat(paramsString);
      return this.get(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchWorkflowExamples: function fetchWorkflowExamples() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var url = 'workflow-examples/';
      var paramsString = (0, _url.getQueryString)((0, _object.underscoreObject)(params));
      url = "".concat(url, "?").concat(paramsString);
      return this.get(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchWorkflowExample: function fetchWorkflowExample(id) {
      var url = "workflow-examples/".concat(id, "/");
      return this.get(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    incrementWorkflowExampleViewCount: function incrementWorkflowExampleViewCount(id) {
      var url = "workflow-examples/".concat(id, "/increment-view-count/");
      return this.post(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    filterWorkflowExamples: function filterWorkflowExamples(params) {
      var url = 'workflow-examples/search/';
      return this.post(url, params).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchDashboardMetrics: function fetchDashboardMetrics() {
      var url = this.accountInfoService.isUserSelfServed ? 'dashboard/details/' : 'dashboard/metrics/';
      return this.get(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    updateDashboardSettings: function updateDashboardSettings(data) {
      var url = 'account/dashboard/settings/';
      return this.patch(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchProfile: function fetchProfile() {
      var url = "profile/";
      return this.get(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchAppsInfo: function fetchAppsInfo() {
      var url = "integrations/";
      return this.get(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    updateProfile: function updateProfile(data) {
      var url = 'profile/';
      return this.post(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    changeEmail: function changeEmail(data) {
      var url = 'change-email/';
      return this.post(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    changePassword: function changePassword(data) {
      var url = 'change-password/';
      return this.post(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    forceResetPassword: function forceResetPassword(data) {
      var url = 'force-reset-password/';
      return this.post(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    reinvitePendingAgent: function reinvitePendingAgent(agentId) {
      var url = "agents/".concat(agentId, "/actions/reinvite/");
      return this.post(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    unlockAgent: function unlockAgent(agentId) {
      var url = "agents/".concat(agentId, "/actions/unlock/");
      return this.post(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    saveAppSettings: function saveAppSettings(_ref) {
      var application = _ref.application,
        data = _ref.data;
      var url = "integrations/".concat(application, "/");
      return this.patch(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    unlinkGoogleAccount: function unlinkGoogleAccount(data) {
      var url = 'integrations/google-sheets/actions/unlink-accounts/';
      return this.post(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    unlinkBambooHRAccount: function unlinkBambooHRAccount(data) {
      var url = "integrations/bamboohr/".concat(data === null || data === void 0 ? void 0 : data.id, "/unlink/");
      return this.delete(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    exportWorkflowsHistory: function exportWorkflowsHistory(data) {
      var url = 'workflows/history/actions/export/';
      return this.post(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    unlinkAzureAdTenant: function unlinkAzureAdTenant(data) {
      var url = "integrations/azure-active-directory/".concat(data.appId, "/").concat(data.tenantId, "/unlink/");
      return this.delete(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    getCSRFToken: function getCSRFToken() {
      var _this7 = this;
      return this.get('csrf-token/').then(function (response) {
        var camelizedResponse = (0, _object.camelizeObject)(response);
        if (camelizedResponse !== null && camelizedResponse !== void 0 && camelizedResponse.csrfToken) {
          _this7.set('csrfToken', camelizedResponse.csrfToken);
        }
        return camelizedResponse;
      });
    },
    validateSSORedirection: function validateSSORedirection(appName, data) {
      var url = "".concat(appName, "/sso/");
      return this.post(url, data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    signupUser: function signupUser(payload) {
      var url = 'signup/';
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    validateSignupToken: function validateSignupToken(payload) {
      var url = 'validate-user-registration/';
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    resendSignupToken: function resendSignupToken(payload) {
      var url = 'resend-user-registration/';
      return this.post(url, payload).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchInstalledIntegrations: function fetchInstalledIntegrations() {
      var url = 'fetch-available-integrations/';
      return this.get(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchBillingLink: function fetchBillingLink() {
      var url = 'billing/login/';
      return this.post(url).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    fetchCustomActions: function fetchCustomActions() {
      return this.get('integrations/workflow-steps/meta-info/').then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    }
  });
});