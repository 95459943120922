define("workflows-web/components/form-field-input/phone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6v+pfPl3",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"class\",\"dropdownClass\",\"field\"],[\"hf-form-field_value hf-mod-dropdown\",\"hf-mod-push-dropdown-up\",[35,0,[\"typeField\"]]]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"hf-form-field-input-container\"],[12],[2,\"\\n  \"],[1,[30,[36,8],null,[[\"type\",\"value\",\"focus-out\",\"class\",\"classNames\",\"disabled\",\"placeholder\",\"data-test-id\"],[\"text\",[35,0,[\"value\"]],[30,[36,7],[[32,0],\"onFocusOut\"],null],[30,[36,6],[\"hf-form-field-input hf-text-input\",\" \",[30,[36,5],[[35,4],\"hf-is-error\"],null],\" \"],null],[35,3],[35,0,[\"isDisabled\"]],[35,0,[\"placeholder\"]],[35,2]]]]],[2,\"\\n  \"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"field\",\"form-field-input/choice\",\"fieldDataTestId\",\"inputClass\",\"hasErrors\",\"if\",\"concat\",\"action\",\"input\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/phone/template.hbs"
    }
  });
});