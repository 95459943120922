define("workflows-web/utilities/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.caseInsensitiveSort = caseInsensitiveSort;
  _exports.searchByKey = searchByKey;
  _exports.sortArrayOfObjects = sortArrayOfObjects;
  function caseInsensitiveSort(list) {
    // REFER: https://stackoverflow.com/questions/8996963/how-to-perform-case-insensitive-sorting-in-javascript
    return list.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  }
  function searchByKey(list, keys, searchText) {
    /* list: Array of Ember objects
    key: key in the object you want to perform search*/
    if (typeof keys === 'string') {
      keys = [keys];
    }
    searchText = searchText.toLocaleLowerCase();
    return list.filter(function (item) {
      return keys.any(function (key) {
        var value = item.get(key);
        value = value ? value.toLocaleLowerCase() : null;
        return value ? value.includes(searchText) : false;
      });
    });
  }
  function sortArrayOfObjects(array, sortParams) {
    var sortFields = sortParams.mapBy('field');
    var sortOrders = sortParams.mapBy('order');
    sortOrders = sortOrders.map(function (order) {
      return order ? order : 'asc'; // setting the default as ascending.
    });
    var nonEmptyItems = array.filter(function (item) {
      return Ember.isPresent(item.get(sortFields[0]));
    });
    var emptyItems = array.filter(function (item) {
      return Ember.isEmpty(item.get(sortFields[0]));
    });
    var sortFunctions = sortFields.map(function (key) {
      // Necessary because lodash orderBy does not do item.get()
      return function (item) {
        var value = item.get(key);
        if (typeof value === 'string') {
          value = value.toLowerCase();
        }
        return value;
      };
    });
    var sortedItems = _.orderBy(nonEmptyItems, sortFunctions, sortOrders);
    sortedItems.addObjects(emptyItems);
    return sortedItems;
  }
});