define("workflows-web/components/form-input/base", ["exports", "workflows-web/mixins/validation"], function (_exports, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_validation.default, {
    isMandatory: false,
    isDisabled: false,
    value: null,
    errors: null,
    delayedErrors: null,
    inputClass: '',
    inputDataTestId: '',
    errorDataTestId: '',
    hasErrors: Ember.computed('errors', {
      get: function get() {
        return !Ember.isEmpty(this.get('errors'));
      }
    }),
    delayErrors: Ember.observer('errors', function () {
      Ember.run.later(this, function () {
        if (!this.isDestroyed) {
          this.set('delayedErrors', this.get('errors'));
        }
      }, 100);
    }),
    initializeErrors: function initializeErrors() {
      this.set('errors', Ember.A());
    },
    addError: function addError(errorMessage) {
      this.get('errors').push(errorMessage);
    }
  });
});