define("workflows-web/mixins/filters/add-filter-condition", ["exports", "workflows-web/utilities/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    isEdit: false,
    classNames: ['hf-add-filter-condition'],
    'data-test-id': 'add-filter-condition',
    fieldsArray: null,
    selectedField: null,
    selectedPredicate: null,
    selectedValue: null,
    selectedFromDate: null,
    selectedToDate: null,
    filterConditionBuffer: null,
    inProgress: false,
    isPrimaryActionDisabled: Ember.computed('filterConditionBuffer.{field,predicate,value.[],fromDate,toDate,unit}', 'showValueList', {
      get: function get() {
        var filterConditionBuffer = this.get('filterConditionBuffer');
        var fieldBuffer = this.get('filterConditionBuffer').get('field');
        var predicateBuffer = this.get('filterConditionBuffer').get('predicate');
        var valueBuffer = this.get('filterConditionBuffer').get('value');
        var fromDateBuffer = this.get('filterConditionBuffer').get('fromDate');
        var toDateBuffer = this.get('filterConditionBuffer').get('toDate');
        if (!this.get('showValueList')) {
          if (!this.get('showPredicateList')) {
            return !(filterConditionBuffer.hasBufferedChanges && Ember.isPresent(fieldBuffer));
          } else {
            return !(filterConditionBuffer.hasBufferedChanges && Ember.isPresent(fieldBuffer) && Ember.isPresent(predicateBuffer));
          }
        } else {
          return !(filterConditionBuffer.hasBufferedChanges && Ember.isPresent(fieldBuffer) && Ember.isPresent(predicateBuffer) && (Ember.isPresent(valueBuffer) || Ember.isPresent(fromDateBuffer) || Ember.isPresent(toDateBuffer)));
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.initializeAddFilterBuffer();
    },
    showPredicateList: Ember.computed('filterConditionBuffer.{field,predicate}', {
      get: function get() {
        var fieldBuffer = this.get('filterConditionBuffer.field');
        var predicateBuffer = this.get('filterConditionBuffer.predicate');
        return Ember.isPresent(fieldBuffer) && Ember.isPresent(predicateBuffer);
      }
    }),
    showValueList: Ember.computed('filterConditionBuffer.predicate', {
      get: function get() {
        var predicateBuffer = this.get('filterConditionBuffer.predicate');
        return Ember.isPresent(predicateBuffer) && predicateBuffer.value !== 'not_set' && predicateBuffer.value !== 'set';
      }
    }),
    optionValuePath: Ember.computed('filterConditionBuffer.field', {
      get: function get() {
        var key = this.get('filterConditionBuffer.field').get('key');
        // Custom field field keys are in numbers.OptionValuePath is value for custom fields and name for other fields
        if (isNaN(Number(key))) {
          return 'name';
        } else {
          return 'value';
        }
      }
    }),
    valueType: Ember.computed.reads('filterConditionBuffer.field.type'),
    valueOptions: Ember.computed('filterConditionBuffer.field', {
      get: function get() {
        var key = this.get('filterConditionBuffer.field.key');
        if (this.get('isViewFilterCondition') && key === 'assignee_id') {
          var intlService = this.get('intlService');
          return Ember.A([{
            'name': intlService.findTranslationByKey('me')
          }]).addObjects(this.get('filterConditionBuffer.field.options'));
        } else {
          return this.get('filterConditionBuffer.field.options');
        }
      },
      set: function set(key, val) {
        return val;
      }
    }),
    lookUpUrl: Ember.computed('filterConditionBuffer.field', {
      get: function get() {
        var field = this.get('filterConditionBuffer.field');
        var key = this.get('filterConditionBuffer.field').get('key');
        // Custom field field keys are in numbers.OptionValuePath is value for custom fields and name for other fields
        if (isNaN(Number(key))) {
          return field.lookUpUrl;
        } else {
          return "".concat(field.lookUpUrl, "?field=").concat(key);
        }
      }
    }),
    minDate: Ember.computed('filterConditionBuffer.fromDate', {
      get: function get() {
        return (0, _date.createDate)(this.get('filterConditionBuffer.fromDate'));
      }
    })
  });
});