define("workflows-web/signup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QnO4I2zu",
    "block": "{\"symbols\":[],\"statements\":[[8,\"react-components/signup-wrapper\",[],[[\"@signupUser\"],[[30,[36,0],[[32,0],\"signupUser\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "workflows-web/signup/template.hbs"
    }
  });
});