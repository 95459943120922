define("workflows-web/utilities/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.focusFirstInput = focusFirstInput;
  function focusFirstInput(container) {
    // focusing the first element once all inputs are rendered
    Ember.run.next(this, function () {
      container.find('.ember-power-select-trigger, input').eq(0).focus();
    });
  }
});