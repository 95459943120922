define("workflows-web/components/form-field-input/remote-multi-choice-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EvheH1nR",
    "block": "{\"symbols\":[\"value\",\"select\",\"&default\"],\"statements\":[[6,[37,10],null,[[\"class\",\"autoFocus\",\"renderInPlace\",\"selected\",\"noMatchesMessage\",\"url\",\"searchParam\",\"characterLimit\",\"isAddSearchHelpOption\",\"isAddNew\",\"onChange\",\"onClose\",\"disabled\",\"data-test-id\",\"dropdownClass\",\"optionValuePath\"],[[30,[36,9],[[35,8],[35,7],[30,[36,2],[[35,6],\"hf-is-error\"],null],[30,[36,2],[[35,0,[\"isHighlighted\"]],\"hf-is-highlighted\"],null],[30,[36,2],[[35,0,[\"isDisabled\"]],\"hf-is-disabled\"],null]],null],[35,0,[\"autoFocus\"]],true,[35,0,[\"value\"]],\"no-results-found\",[35,0,[\"lookupUrl\"]],[35,0,[\"searchParam\"]],[35,0,[\"characterLimit\"]],[35,0,[\"isAddSearchHelpOption\"]],[35,0,[\"isAddNew\"]],[30,[36,5],[[32,0],\"valueChanged\"],null],[30,[36,5],[[32,0],\"onClose\"],null],[35,0,[\"isDisabled\"]],[35,4],[35,3],[35,0,[\"optionValuePath\"]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,3,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0,[\"choiceLabelKey\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,1],[35,0,[\"choiceLabelKey\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"field\",\"get\",\"if\",\"dropdownClass\",\"fieldDataTestId\",\"action\",\"hasErrors\",\"jsTestClass\",\"selectClass\",\"concat-strings\",\"auto-complete/remote/multiple\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/remote-multi-choice-dropdown/template.hbs"
    }
  });
});