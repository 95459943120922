define("workflows-web/components/form-field-input/toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UMKnOHCN",
    "block": "{\"symbols\":[],\"statements\":[[11,\"label\"],[16,0,[31,[\"hf-toggle_button \",[30,[36,1],[[35,0,[\"value\"]],\"hf-is-checked\"],null],\" \",[30,[36,1],[[35,0,[\"isDisabled\"]],\"hf-u-disabled\"],null]]]],[16,\"data-test-id\",[34,2]],[4,[38,3],[[32,0],\"onChange\"],null],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"field\",\"if\",\"fieldDataTestId\",\"action\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/toggle/template.hbs"
    }
  });
});