define("workflows-web/components/data-table-new/html-cell/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['hf-u-text-overflow'],
    click: function click(event) {
      // Prevent row action when user clicks on a link
      if ((0, _jquery.default)(event.target).closest('a').length) {
        event.stopPropagation();
      }
    }
  });
});