define("workflows-web/components/filter-condition/component", ["exports", "workflows-web/utilities/date", "workflows-web/mixins/filters/filter-condition", "workflows-web/workflows/details/edit/utilities"], function (_exports, _date, _filterCondition, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _exports.default = Ember.Component.extend(_filterCondition.default, {
    isMatchAny: false,
    // For bordered css
    init: function init() {
      this._super.apply(this, arguments);
    },
    fieldFormatted: Ember.computed('field.name', 'condition.field', {
      get: function get() {
        var _this$field;
        var conditionField = this.condition.field;
        var prefix = this.prefix;
        if (conditionField.key === 'custom_fields' || (_this$field = this.field) !== null && _this$field !== void 0 && _this$field.isCustomField) {
          return conditionField.name;
        } else {
          var field = this.field;
          var fieldFormattedValue;
          if (field !== null && field !== void 0 && field.displayValueKey) {
            var choices = this.field.choices;
            if (choices) {
              var fieldValue = field[field.displayValueKey];
              var _fieldValue$match = fieldValue.match(/[^{{\}}]+(?=})/g),
                _fieldValue$match2 = _slicedToArray(_fieldValue$match, 1),
                key = _fieldValue$match2[0];
              var _key$split = key.split('__'),
                _key$split2 = _slicedToArray(_key$split, 1),
                stepId = _key$split2[0];
              var choice = choices.findBy('stepId', stepId);
              if (choice) {
                var selectedOption = choice.options.findBy('value', fieldValue);
                if (selectedOption) {
                  this.set('selectedOption', selectedOption);
                  var tag = (0, _utilities.createTag)(choice.prefix, selectedOption.label, fieldValue);
                  fieldFormattedValue = tag;
                }
              }
            }
          } else if (field) {
            fieldFormattedValue = field.name;
          } else if (prefix) {
            var _this$condition;
            var _fieldValue = (_this$condition = this.condition) === null || _this$condition === void 0 ? void 0 : _this$condition.field;
            var _fieldValue$match3 = _fieldValue.match(/[^{{\}}]+(?=})/g),
              _fieldValue$match4 = _slicedToArray(_fieldValue$match3, 1),
              _key = _fieldValue$match4[0];
            var _tag = (0, _utilities.createTagForInvalidStep)(_key, {
              prefix: prefix
            });
            fieldFormattedValue = _tag;
          }
          return fieldFormattedValue;
        }
      }
    }),
    field: Ember.computed('condition.field', {
      get: function get() {
        var fieldsArray = this.get('fieldsArray');
        var field = this.get('condition.field');
        var fieldKey;
        if (Ember.typeOf(field) === 'instance') {
          fieldKey = field.key;
        } else if (Ember.typeOf(field) === 'string' || Ember.typeOf(field) === 'number') {
          fieldKey = field;
        }
        if (fieldsArray) {
          if (fieldsArray.firstObject.stepId) {
            return [].concat.apply([], fieldsArray.mapBy('options')).findBy('key', fieldKey);
          }
          return fieldsArray.findBy('key', fieldKey) || fieldsArray.findBy('key', fieldKey.toString());
        }
      },
      set: function set(key, value) {
        return value;
      }
    }),
    predicate: Ember.computed('condition.{field,preidcate,value}', {
      get: function get() {
        var field = this.get('condition.field');
        var predicate = this.get('condition.predicate');
        var fieldValue = this.get('condition.value');
        var type = this.get('field.type');
        var key = this.get('field.key');
        var intlService = this.get('intlService');
        if (!Ember.isBlank(predicate)) {
          if (!Ember.isBlank(type)) {
            var value;
            if (predicate === 'range') {
              /*
               'match_all': [{ 'field': '18', 'predicate': 'range', 'value': { 'gte': '2016-09-12' } }]
                To display this condition in UI we have to take the value of value key from the json
                and use its key,in this instance the key being gte,to get predicates like on or after,on or before in
                case of date type fields and less than,less than or equal to,greater than,greater than or equal to in the case of
                number type fields
              */
              if (key === 'due_date') {
                value = intlService.findTranslationByKey("filters.".concat(predicate));
                return this.setPredicate(predicate, value);
              } else {
                if (type === 'date') {
                  predicate = Object.keys(fieldValue).get('firstObject');
                  value = intlService.findTranslationByKey("filters.date.".concat(predicate));
                  return this.setPredicate(predicate, value);
                } else if (type === 'timestamp') {
                  predicate = Object.keys(fieldValue);
                  if (predicate.length > 1) {
                    // timestamp fields with between predicate
                    var timestampValues = [];
                    for (var _key2 in fieldValue) {
                      if (fieldValue.hasOwnProperty(_key2)) {
                        timestampValues.push((0, _date.getDateFromTimestamp)(fieldValue[_key2]));
                      }
                    }
                    if (timestampValues[0] === timestampValues[1]) {
                      // timestamp fields with same values in from and to date fields
                      predicate = 'on';
                      value = intlService.findTranslationByKey('filters.date.on');
                    } else {
                      predicate = 'between';
                      value = intlService.findTranslationByKey('filters.date.between');
                    }
                  } else {
                    // timestamp fields with either on,before,on or before,after,on or after predicate
                    predicate = Object.keys(fieldValue).get('firstObject');
                    value = intlService.findTranslationByKey("filters.date.".concat(predicate));
                  }
                  return this.setPredicate(predicate, value);
                } else {
                  var predicateValue = Ember.isArray(fieldValue) ? fieldValue[0] : fieldValue;
                  predicate = Object.keys(predicateValue).get('firstObject');
                  value = intlService.findTranslationByKey("filters.".concat(predicate));
                  return this.setPredicate(predicate, value);
                }
              }
            } else {
              if (field === 'tags' || type === 'text') {
                value = intlService.findTranslationByKey("filters.tags.".concat(predicate));
                return this.setPredicate(predicate, value);
              } else if (field === 'assets') {
                value = intlService.findTranslationByKey("filters.assets.".concat(predicate));
                return this.setPredicate(predicate, value);
              } else if (field === 'agent_scripts') {
                value = intlService.findTranslationByKey("filters.agent-scripts.".concat(predicate));
                return this.setPredicate(predicate, value);
              } else {
                if (type === 'date') {
                  value = intlService.findTranslationByKey("filters.date.".concat(predicate));
                  return this.setPredicate(predicate, value);
                } else {
                  value = intlService.findTranslationByKey("filters.".concat(predicate));
                  return this.setPredicate(predicate, value);
                }
              }
            }
          } else {
            var _value = intlService.findTranslationByKey("filters.".concat(predicate));
            return this.setPredicate(predicate, _value);
          }
        }
      },
      set: function set(key, value) {
        return value;
      }
    }),
    value: Ember.computed('condition.{field,predicate,value}', {
      // jscs:disable
      /* This computed property returns an object with value label pairs wherein the label is used for
          displaying the condition and value is passed to the add filter condition component
      */
      // jscs:enable
      get: function get() {
        var value = this.get('condition.value');
        var type = this.get('field.type');
        var intlService = this.get('intlService');
        var key = this.get('field.key');
        var isCustomField = this.get('field.isCustomField');
        if (key === 'assignee_id' && type === 'local-single-autocomplete' && Ember.isBlank(value)) {
          return {
            value: value,
            label: this.get('intlService').findTranslationByKey('automate.unassigned')
          };
        }
        if (!Ember.isBlank(value)) {
          if (key === 'contact_group_id' || key === 'contact_name' || key === 'contact_email') {
            return {
              value: value,
              label: value.label
            };
          } else if (type === 'text' || type === 'remote-single-autocomplete' || type === 'number' || type === 'timeWithUnits') {
            return {
              value: value,
              label: value
            };
          } else if (type === 'local-single-autocomplete') {
            if (key === 'due_date') {
              if ((0, _date.isDate)(value)) {
                this.set('customFromDate', value);
                return {
                  value: {
                    label: 'on'
                  },
                  label: intlService.formatDate((0, _date.createDate)(this.get('customFromDate')), {
                    format: 'default'
                  })
                };
              } else if (Ember.typeOf(value) === 'object') {
                this.set('customFromDate', value.gte);
                this.set('customToDate', value.lte);
                var dateValues = [];
                for (var _key3 in value) {
                  if (value.hasOwnProperty(_key3)) {
                    dateValues.push(value[_key3]);
                  }
                }
                dateValues = dateValues.map(function (dateValue) {
                  return intlService.formatDate((0, _date.createDate)(dateValue), {
                    format: 'default'
                  });
                });
                return {
                  value: {
                    label: 'between'
                  },
                  label: dateValues.join(" <span class=\"hf-filter-condition_conjunction\">".concat(intlService.findTranslationByKey('and'), "</span> "))
                };
              } else {
                return {
                  value: {
                    label: intlService.findTranslationByKey(value),
                    value: value
                  },
                  label: intlService.findTranslationByKey(value)
                };
              }
            } else {
              value = typeof value === 'boolean' || typeof value === 'number' ? value : value.toString();
              var valueOption = this.get('field').options.findBy('value', value);
              return {
                value: valueOption,
                label: valueOption.label
              };
            }
          } else if (type === 'boolean') {
            var _valueOption = this.get('field').options.findBy('value', value);
            return {
              value: _valueOption,
              label: _valueOption.label
            };
          } else if (type === 'local-multiple-autocomplete') {
            var valueOptions = this.get('field').options.filter(function (option) {
              var optionIds = value;
              var optionValue = isNaN(Number(option.value)) ? option.value : Number(option.value);
              return optionIds.includes(optionValue);
            });
            return {
              value: valueOptions,
              label: valueOptions.mapBy('label').join(" <span class=\"hf-filter-condition_conjunction\">".concat(intlService.findTranslationByKey('or'), "</span>"))
            };
          } else if (type === 'remote-multiple-autocomplete') {
            return {
              value: value,
              label: value.join(" <span class=\"hf-filter-condition_conjunction\">".concat(intlService.findTranslationByKey('or'), "</span> "))
            };
          } else if (type === 'date') {
            var label;
            if (value === 'today') {
              label = this.get('intlService').findTranslationByKey('automate.condition-values.today');
            } else {
              label = this.get('intlService').findTranslationByKeyWithParameters('automate.condition-values.days-{n}', {
                n: value
              });
            }
            return {
              value: value,
              label: label
            };
          } else if (key === 'created_at' || key === 'last_staff_reply_at' || key === 'last_contact_reply_at' || key === 'last_updated_at' || key === 'last_modified_at' || key === 'unresponded_since') {
            var timestampValues = [];
            if (Object.keys(value).length > 1) {
              // timestamp fields with between predicate
              this.set('customFromDate', (0, _date.getDateFromTimestamp)(value.gte));
              this.set('customToDate', (0, _date.getDateFromTimestamp)(value.lte));
              for (var _key4 in value) {
                if (value.hasOwnProperty(_key4)) {
                  timestampValues.push((0, _date.getDateFromTimestamp)(value[_key4]));
                }
              }
            } else {
              // timestamp fields with either on,before,on or before,after,on or after predicate
              for (var _key5 in value) {
                if (value.hasOwnProperty(_key5)) {
                  value = value[_key5];
                }
              }
              this.set('customFromDate', (0, _date.getDateFromTimestamp)(value));
              timestampValues.push((0, _date.getDateFromTimestamp)(value));
            }
            var _label;
            if (timestampValues[0] === timestampValues[1]) {
              _label = intlService.formatDate((0, _date.createDate)(timestampValues[0]), {
                format: 'default'
              });
            } else {
              timestampValues = timestampValues.map(function (timestampValue) {
                return intlService.formatDate((0, _date.createDate)(timestampValue), {
                  format: 'default'
                });
              });
              _label = timestampValues.join(" <span class=\"hf-filter-condition_conjunction\">".concat(intlService.findTranslationByKey('and'), "</span> "));
            }
            return {
              value: null,
              label: _label
            };
          } else if (type === 'dynamic') {
            if (key === 'custom_fields' || isCustomField) {
              if (this.condition.field.type === 'multiple_option' || this.condition.field.type === 'dropdown') {
                return {
                  value: value.join(),
                  label: value.join(" <span class=\"hf-filter-condition_conjunction\">".concat(intlService.findTranslationByKey('or'), "</span> "))
                };
              } else {
                if (Ember.typeOf(value) === 'instance') {
                  return {
                    value: value,
                    label: value[Object.keys(value)[0]]
                  };
                } else {
                  return {
                    value: value,
                    label: value
                  };
                }
              }
            } else {
              if (this.field.allowMultipleValues) {
                if (Ember.typeOf(value) === 'instance' || Ember.typeOf(value) === 'object') {
                  value = value[Object.keys(value)[0]];
                }
                value = Ember.isArray(value) ? value : value.split();
                var formattedLabel = this.getFormattedLabel(value);
                return {
                  value: value,
                  label: (0, _utilities.appendTags)(formattedLabel)
                };
              } else {
                if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                  return {
                    value: value[Object.keys(value)[0]],
                    label: value[Object.keys(value)[0]]
                  };
                } else {
                  var _formattedLabel = this.getFormattedLabel([value]);
                  return {
                    value: value,
                    label: (0, _utilities.appendTags)(_formattedLabel)
                  };
                }
              }
            }
          }
        }
      },
      set: function set(key, value) {
        return value;
      }
    }),
    getFormattedLabel: function getFormattedLabel(conditions) {
      var _this = this;
      var formattedLabel = [];
      conditions.forEach(function (value, index) {
        var _this$actionChoices;
        var condition = Ember.typeOf(value) === 'instance' || Ember.typeOf(value) === 'object' ? value[Object.keys(value)[0]] : value;
        var choices = _this.field.choices;
        if (!choices && ((_this$actionChoices = _this.actionChoices) === null || _this$actionChoices === void 0 ? void 0 : _this$actionChoices.length) > 0) {
          choices = _this.actionChoices;
        }
        if (index !== 0) {
          var spanTag = document.createElement('span');
          spanTag.className = 'hf-filter-condition_conjunction';
          spanTag.innerText = " ".concat(_this.get('intlService').findTranslationByKey('or'));
          formattedLabel.push(spanTag);
        }
        var regExp = /\{\{.+?\}\}/g;
        var conditionString;
        var conditionArray = [];
        var stringIndex = 0;
        // split the string into array of strings if the value is from previous step or if its a user defined string
        while ((conditionString = regExp.exec(condition)) !== null) {
          // if no user defined string is present, push the previous step string
          if (stringIndex === conditionString.index) {
            conditionArray.push(conditionString[0]);
          } else {
            // if there is a user defined string before previous step value, push that first
            conditionArray.push(condition.substring(stringIndex, conditionString.index));
            stringIndex += conditionString.index - stringIndex;
            conditionArray.push(conditionString[0]);
          }
          stringIndex += conditionString[0].length;
        }
        if (stringIndex !== condition.length) {
          conditionArray.push(condition.substring(stringIndex, condition.length));
        }
        conditionArray.forEach(function (conditionStr) {
          var _ref = (conditionStr === null || conditionStr === void 0 ? void 0 : conditionStr.match(/[^{{\}}]+(?=})/g)) || [],
            _ref2 = _slicedToArray(_ref, 1),
            step = _ref2[0];
          var _ref3 = (step === null || step === void 0 ? void 0 : step.split('__')) || [],
            _ref4 = _slicedToArray(_ref3, 1),
            key = _ref4[0];
          if (choices && key) {
            var choice = choices.findBy('stepId', key);
            if (choice) {
              var selectedOption = choice.options.findBy('value', "".concat(conditionStr));
              if (selectedOption) {
                // create chip if value is present in options
                var tag = (0, _utilities.createTag)(choice.prefix, selectedOption.label, conditionStr);
                formattedLabel.push(tag);
              } else {
                formattedLabel.push(conditionStr);
              }
            } else {
              formattedLabel.push(conditionStr);
            }
          } else {
            formattedLabel.push(conditionStr);
          }
        });
      });
      return formattedLabel;
    },
    actions: {
      updateCondition: function updateCondition(field, predicate, value, unit) {
        this.get('condition').setProperties({
          field: field,
          predicate: predicate,
          value: value,
          unit: unit
        });
        if (this.updateCondition) {
          this.updateCondition(this.get('condition'));
        }
      }
    }
  });
});