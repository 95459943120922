define("workflows-web/workflows/apps/details/partials/ms-teams/setup-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "l6AqkCsU",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"app-description.ms-teams.basic-info\"],null]],[2,\" \\n\"],[10,\"div\"],[14,0,\"hf-apps-details_right_sub-heading\"],[12],[1,[30,[36,0],[\"apps.how-to-setup\"],null]],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"hf-app-description_how-to-setup\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"hf-app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.ms-teams.how-to-setup.connect-account\"],null]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"hf-app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.ms-teams.how-to-setup.login-teams\"],null]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"hf-app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.ms-teams.how-to-setup.install-bot-to-teams\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/apps/details/partials/ms-teams/setup-info/template.hbs"
    }
  });
});