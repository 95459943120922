define("workflows-web/components/form-field-input/dynamic/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      var value = this.get('field.value');
      if (value) {
        if (Ember.isArray(value)) {
          value = value.join();
        } else {
          value = value.toString();
        }
        this.set('field.value', value);
      }
    },
    actions: {
      onValueChange: function onValueChange(updatedValue, selectedOption) {
        this.set('field.value', updatedValue);
        if (this.get('onValueChange')) {
          this.get('onValueChange')(updatedValue, selectedOption);
        }
      }
    }
  });
});