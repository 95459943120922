define("workflows-web/workflows/mixins/happyfox-service-desk/actions", ["exports", "workflows-web/workflows/details/edit/utilities", "workflows-web/constants/editor"], function (_exports, _utilities, _editor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    requestService: Ember.inject.service('request'),
    intlService: Ember.inject.service('intl'),
    accountInfoService: Ember.inject.service('account-info'),
    getServiceDeskActions: function getServiceDeskActions() {
      var _this = this;
      var actionClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var workflowActionChoices = Ember.A();
      var createServiceRequest = actionClass.create({
        stepType: 'create_service_request',
        application: 'happyfox-service-desk',
        component: 'workflows/details/edit/actions/details/happyfox-service-desk/create-update-incident',
        color: '#4c29e7',
        rawFields: Ember.A([Ember.Object.create({
          label: 'Team',
          key: 'team',
          isDynamicField: true,
          isMandatory: true,
          entity: 'teams',
          groupName: 'Service Desk Teams',
          step: 1
        }), Ember.Object.create({
          label: 'Contact Id',
          key: 'contactId',
          isDynamicField: true,
          step: 2
        }), Ember.Object.create({
          label: 'Contact Name',
          key: 'contactName',
          isDynamicField: true,
          isMandatory: true,
          step: 2
        }), Ember.Object.create({
          label: 'Contact Email',
          key: 'contactEmail',
          isDynamicField: true,
          step: 2
        }), Ember.Object.create({
          label: 'Contact Phone',
          key: 'contactPhone',
          isDynamicField: true,
          step: 2
        }), Ember.Object.create({
          key: 'contactCustomFields',
          isArray: true,
          transformDataSource: _utilities.transformCustomFieldsWithType,
          transformValue: _utilities.generateCustomFieldsPayloadWithType,
          step: 2,
          childFieldMetaInfo: Ember.Object.create({
            key: 'contactCustomField',
            isObject: true,
            primaryObjectKey: 'id',
            secondaryObjectKey: 'value',
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              label: 'Field',
              key: 'id',
              isRemoteChoice: true,
              entity: 'contact-custom-fields',
              lookupUrl: 'entity-autocomplete/happyfox-service-desk/contact-custom-fields/',
              searchParam: 'text',
              isMandatory: true,
              choiceLabelKey: 'name',
              choices: []
            }), Ember.Object.create({
              key: 'value',
              isDynamicField: true,
              isMandatory: true
            })])
          })
        }), Ember.Object.create({
          label: 'Status',
          key: 'status',
          isDynamicField: true,
          entity: 'service-request-status',
          groupName: 'Service Request Status',
          step: 2
        }), Ember.Object.create({
          label: 'Priority',
          key: 'priority',
          isDynamicField: true,
          entity: 'priorities',
          groupName: 'Service Desk Priority',
          step: 2
        }), Ember.Object.create({
          label: 'Impact',
          key: 'impact',
          isDynamicField: true,
          entity: 'impacts',
          groupName: 'Service Desk Impact',
          step: 2
        }), Ember.Object.create({
          label: 'Assignment Group',
          key: 'assignmentGroup',
          isDynamicField: true,
          entity: 'assignment-groups',
          groupName: 'Service Desk Assignment Groups',
          step: 2
        }), Ember.Object.create({
          label: 'Assignee',
          key: 'assignee',
          isDynamicField: true,
          entity: 'staff',
          groupName: 'Service Desk Assignee',
          step: 2
        }), Ember.Object.create({
          label: 'Catalog Items',
          key: 'catalogItems',
          labelHelpText: 'Separate multiple items with comma',
          isDynamicField: true,
          transformValue: _utilities.commaSeperatedStringsToListPayload,
          transformDataSource: _utilities.listToStrings,
          entity: 'catalog-items',
          groupName: 'Catalog Items',
          isMandatory: true,
          step: 2
        }), Ember.Object.create({
          key: 'customFields',
          isArray: true,
          transformDataSource: _utilities.transformCustomFieldsWithType,
          transformValue: _utilities.generateCustomFieldsPayloadWithType,
          step: 2,
          childFieldMetaInfo: Ember.Object.create({
            key: 'customField',
            isObject: true,
            primaryObjectKey: 'id',
            secondaryObjectKey: 'value',
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              label: 'Field',
              key: 'id',
              isRemoteChoice: true,
              entity: 'service-request-custom-fields',
              lookupUrl: 'entity-autocomplete/happyfox-service-desk/service-request-custom-fields/',
              searchParam: 'text',
              isMandatory: true,
              choiceLabelKey: 'name',
              choices: []
            }), Ember.Object.create({
              key: 'value',
              isDynamicField: true,
              isMandatory: true
            })])
          })
        }), Ember.Object.create({
          label: 'Due By',
          key: 'dueBy',
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextPartial: 'workflows/details/edit/actions/details/label-help-text/due-date',
          isDynamicField: true,
          labelHelpText: true,
          step: 2
        }), Ember.Object.create({
          label: 'Subject',
          key: 'subject',
          isDynamicField: true,
          isMandatory: true,
          step: 2
        }), Ember.Object.create({
          label: 'Description',
          key: 'description',
          isEditor: true,
          editorId: 'description',
          editorConfig: _editor.LITE_EDITOR_CONFIG,
          isMandatory: true,
          step: 2
        })])
      });
      var createIncident = actionClass.create({
        stepType: 'create_incident',
        application: 'happyfox-service-desk',
        component: 'workflows/details/edit/actions/details/happyfox-service-desk/create-update-incident',
        color: '#4c29e7',
        rawFields: Ember.A([Ember.Object.create({
          label: 'Contact ID',
          key: 'contactId',
          isDynamicField: true,
          step: 2
        }), Ember.Object.create({
          label: 'Contact Name',
          key: 'contactName',
          isDynamicField: true,
          isMandatory: true,
          step: 2
        }), Ember.Object.create({
          label: 'Contact Email',
          key: 'contactEmail',
          isDynamicField: true,
          step: 2
        }), Ember.Object.create({
          label: 'Contact Phone',
          key: 'contactPhone',
          isDynamicField: true,
          step: 2
        }), Ember.Object.create({
          key: 'contactCustomFields',
          isArray: true,
          transformDataSource: _utilities.transformCustomFieldsWithType,
          transformValue: _utilities.generateCustomFieldsPayloadWithType,
          step: 2,
          childFieldMetaInfo: Ember.Object.create({
            key: 'contactCustomField',
            isObject: true,
            primaryObjectKey: 'id',
            secondaryObjectKey: 'value',
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              label: 'Field',
              key: 'id',
              isRemoteChoice: true,
              entity: 'contact-custom-fields',
              lookupUrl: 'entity-autocomplete/happyfox-service-desk/contact-custom-fields/',
              searchParam: 'text',
              isMandatory: true,
              choiceLabelKey: 'name',
              choices: []
            }), Ember.Object.create({
              key: 'value',
              isDynamicField: true,
              isMandatory: true
            })])
          })
        }), Ember.Object.create({
          label: 'Team',
          key: 'team',
          isDynamicField: true,
          isMandatory: true,
          entity: 'teams',
          groupName: 'Service Desk Teams',
          step: 1
        }), Ember.Object.create({
          label: 'Assignment Group',
          key: 'assignmentGroup',
          isDynamicField: true,
          entity: 'assignment-groups',
          groupName: 'Service Desk Assignment Groups',
          step: 2
        }), Ember.Object.create({
          label: 'Status',
          key: 'status',
          isDynamicField: true,
          entity: 'incident-status',
          groupName: 'Incident Status',
          step: 2
        }), Ember.Object.create({
          label: 'Priority',
          key: 'priority',
          isDynamicField: true,
          entity: 'priorities',
          groupName: 'Service Desk Priority',
          step: 2
        }), Ember.Object.create({
          label: 'Resolution',
          key: 'resolution',
          isDynamicField: true,
          step: 2
        }), Ember.Object.create({
          label: 'Impact',
          key: 'impact',
          isDynamicField: true,
          entity: 'impacts',
          groupName: 'Service Desk Impact',
          step: 2
        }), Ember.Object.create({
          label: 'Urgency',
          key: 'urgency',
          isDynamicField: true,
          entity: 'urgencies',
          groupName: 'Service Desk Urgency',
          step: 2
        }), Ember.Object.create({
          label: 'Assignee',
          key: 'assignee',
          isDynamicField: true,
          entity: 'staff',
          groupName: 'Service Desk Assignee',
          step: 2
        }), Ember.Object.create({
          label: 'Due By',
          key: 'dueBy',
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextPartial: 'workflows/details/edit/actions/details/label-help-text/due-date',
          isDynamicField: true,
          labelHelpText: true,
          step: 2
        }), Ember.Object.create({
          label: 'Subject',
          key: 'subject',
          isDynamicField: true,
          isMandatory: true,
          step: 2
        }), Ember.Object.create({
          label: 'Description',
          key: 'description',
          isEditor: true,
          editorId: 'description',
          editorConfig: _editor.LITE_EDITOR_CONFIG,
          isMandatory: true,
          step: 2
        }), Ember.Object.create({
          key: 'customFields',
          isArray: true,
          transformDataSource: _utilities.transformCustomFieldsWithType,
          transformValue: _utilities.generateCustomFieldsPayloadWithType,
          step: 2,
          childFieldMetaInfo: Ember.Object.create({
            key: 'customField',
            isObject: true,
            primaryObjectKey: 'id',
            secondaryObjectKey: 'value',
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              label: 'Field',
              key: 'id',
              isRemoteChoice: true,
              entity: 'custom-fields',
              lookupUrl: 'entity-autocomplete/happyfox-service-desk/incident-custom-fields/',
              searchParam: 'text',
              isMandatory: true,
              choiceLabelKey: 'name',
              choices: []
            }), Ember.Object.create({
              key: 'value',
              isDynamicField: true,
              isMandatory: true
            })])
          })
        })])
      });
      var updateIncident = actionClass.create({
        stepType: 'update_incident',
        application: 'happyfox-service-desk',
        component: 'workflows/details/edit/actions/details/happyfox-service-desk/create-update-incident',
        color: '#4c29e7',
        rawFields: Ember.A([Ember.Object.create({
          label: 'Ticket ID',
          key: 'ticketId',
          isDynamicField: true,
          isMandatory: true,
          step: 1
        }), Ember.Object.create({
          label: 'Team',
          key: 'team',
          isDynamicField: true,
          entity: 'teams',
          groupName: 'Service Desk Teams',
          step: 2
        }), Ember.Object.create({
          label: 'Status',
          key: 'status',
          isDynamicField: true,
          entity: 'incident-status',
          groupName: 'Incident Status',
          step: 2
        }), Ember.Object.create({
          label: 'Priority',
          key: 'priority',
          isDynamicField: true,
          entity: 'priorities',
          groupName: 'Service Desk Priority',
          step: 2
        }), Ember.Object.create({
          label: 'Impact',
          key: 'impact',
          isDynamicField: true,
          entity: 'impacts',
          groupName: 'Service Desk Impact',
          step: 2
        }), Ember.Object.create({
          label: 'Urgency',
          key: 'urgency',
          isDynamicField: true,
          entity: 'urgencies',
          groupName: 'Service Desk Urgency',
          step: 2
        }), Ember.Object.create({
          label: 'Assignee',
          key: 'assignee',
          isDynamicField: true,
          entity: 'staff',
          groupName: 'Service Desk Assignee',
          step: 2
        }), Ember.Object.create({
          label: 'Subject',
          key: 'subject',
          isDynamicField: true,
          step: 2
        }), Ember.Object.create({
          key: 'customFields',
          isArray: true,
          transformDataSource: _utilities.transformCustomFieldsWithType,
          transformValue: _utilities.generateCustomFieldsPayloadWithType,
          step: 2,
          childFieldMetaInfo: Ember.Object.create({
            key: 'customField',
            isObject: true,
            primaryObjectKey: 'id',
            secondaryObjectKey: 'value',
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              label: 'Field',
              key: 'id',
              isRemoteChoice: true,
              entity: 'custom-fields',
              lookupUrl: 'entity-autocomplete/happyfox-service-desk/incident-custom-fields/',
              searchParam: 'text',
              isMandatory: true,
              choiceLabelKey: 'name',
              choices: []
            }), Ember.Object.create({
              key: 'value',
              isDynamicField: true,
              isMandatory: true
            })])
          })
        }), Ember.Object.create({
          label: 'Tags',
          key: 'tags',
          labelHelpText: 'Separate multiple tags with comma',
          isDynamicField: true,
          transformValue: _utilities.generateTagsPayload,
          transformDataSource: _utilities.modifyTags,
          step: 2
        })])
      });
      var updateServiceRequest = actionClass.create({
        stepType: 'update_service_request',
        application: 'happyfox-service-desk',
        rawFields: Ember.A([])
      });
      var updateServiceRequestCustomFields = actionClass.create({
        stepType: 'update_service_request_custom_fields',
        application: 'happyfox-service-desk',
        rawFields: Ember.A([])
      });
      workflowActionChoices.addObjects([createServiceRequest, createIncident, updateIncident, updateServiceRequest, updateServiceRequestCustomFields]);
      workflowActionChoices.forEach(function (action) {
        action.displayName = _this.intlService.findTranslationByKey(action.stepType);
        action.eventType = 'action';
      });
      return workflowActionChoices;
    }
  });
});