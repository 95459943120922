define("workflows-web/workflows/details/edit/actions/details/extract-values-from-text/component", ["exports", "workflows-web/workflows/details/edit/actions/details/mixin/primary-field-empty-check"], function (_exports, _primaryFieldEmptyCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_primaryFieldEmptyCheck.default, {
    actions: {
      onChange: function onChange(field) {
        field.set('isEditMode', false);
      },
      cancelEdit: function cancelEdit(field) {
        field.set('isEditMode', false);
      },
      addChildField: function addChildField(field) {
        var _this = this;
        field.addNewChildField(null);
        field.set('isEditMode', true);
        field.childFieldMetaInfo.childFields.forEach(function (field) {
          if (field.isDynamicField) {
            _this.get('form').setActionDetailsChoices(field);
          }
        });
      },
      removeChildField: function removeChildField(field, childField) {
        field.removeChildField(childField);
      },
      editField: function editField(field) {
        field.set('isEditMode', true);
      },
      onFieldChange: function onFieldChange(updatedValue, selectedOption) {
        if (this.form.isDirty) {
          if (selectedOption) {
            this.form.model.pattern.set('value', selectedOption.fieldValue);
          } else {
            this.form.model.pattern.set('value', '');
          }
        }
      }
    }
  });
});