define("workflows-web/workflows/mixins/asana/actions", ["exports", "workflows-web/workflows/details/edit/utilities"], function (_exports, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    requestService: Ember.inject.service('request'),
    intlService: Ember.inject.service('intl'),
    accountInfoService: Ember.inject.service('account-info'),
    getAsanaActions: function getAsanaActions() {
      var _this = this;
      var actionClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var workflowActionChoices = Ember.A();
      var createWorkItem = actionClass.create({
        stepType: 'create_task',
        application: 'asana',
        color: '#034032',
        component: 'workflows/details/edit/actions/details/asana/create-update-work-item',
        rawFields: [Ember.Object.create({
          label: 'Workspace',
          key: 'workspace',
          entity: 'workspace',
          groupName: 'Asana Workspace',
          isMandatory: true,
          step: 1,
          isPrimary: true,
          isRemoteChoice: true,
          lookupUrl: "entity-autocomplete/asana/workspaces/",
          searchParam: "text",
          choiceLabelKey: "name",
          choices: []
        }), Ember.Object.create({
          label: 'Project',
          key: 'project',
          entity: 'projects',
          groupName: 'Asana Projects',
          isMandatory: false,
          step: 2,
          isDynamicField: true
        }), Ember.Object.create({
          label: 'Section',
          key: 'section',
          entity: 'sections',
          groupName: 'Asana Sections',
          isMandatory: false,
          step: 2,
          isDynamicField: true
        }), Ember.Object.create({
          label: 'Task name',
          key: 'name',
          isDynamicField: true,
          isMandatory: true,
          step: 2,
          isPrimary: true
        }), Ember.Object.create({
          label: 'Approval Status',
          key: 'approvalStatus',
          entity: 'approval-status',
          groupName: 'Approval status',
          isDynamicField: true,
          isMandatory: false,
          step: 2,
          isPrimary: true
        }), Ember.Object.create({
          label: 'Completed',
          key: 'completed',
          isChoice: true,
          isMandatory: false,
          choiceLabelKey: 'label',
          choiceValueKey: 'value',
          value: false,
          step: 2,
          choices: Ember.A([{
            'value': true,
            'label': 'Yes'
          }, {
            'value': false,
            'label': 'No'
          }])
        }), Ember.Object.create({
          label: 'Due Date',
          key: 'dueDate',
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextPartial: 'workflows/details/edit/actions/details/label-help-text/due-date',
          isDynamicField: true,
          labelHelpText: true
        }), Ember.Object.create({
          step: 2,
          label: 'Description',
          key: 'notes',
          isDynamicField: true
        }), Ember.Object.create({
          label: 'Liked',
          key: 'liked',
          isChoice: true,
          isMandatory: false,
          choiceLabelKey: 'label',
          choiceValueKey: 'value',
          value: false,
          step: 2,
          choices: Ember.A([{
            'value': true,
            'label': 'Yes'
          }, {
            'value': false,
            'label': 'No'
          }])
        }), Ember.Object.create({
          label: 'Assignee',
          key: 'assignee',
          entity: 'users',
          groupName: 'Asana Users',
          isDynamicField: true,
          isMandatory: false,
          step: 2,
          isPrimary: true
        }), Ember.Object.create({
          key: 'customFields',
          isArray: true,
          step: 2,
          transformDataSource: _utilities.transformCustomFieldsWithType,
          transformValue: _utilities.generateCustomFieldsPayloadWithType,
          childFieldMetaInfo: Ember.Object.create({
            key: 'customField',
            isObject: true,
            primaryObjectKey: 'id',
            secondaryObjectKey: 'value',
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              label: 'Field',
              key: 'id',
              isRemoteChoice: true,
              entity: 'custom-fields',
              lookupUrl: 'entity-autocomplete/asana/custom-fields/',
              searchParam: 'text',
              isMandatory: true,
              choiceLabelKey: 'name',
              choices: []
            }), Ember.Object.create({
              key: 'value',
              isDynamicField: true,
              isMandatory: true
            })])
          })
        }), Ember.Object.create({
          label: 'Parent Task ID',
          key: 'parent',
          isDynamicField: true,
          isMandatory: false,
          step: 2,
          isPrimary: true
        }), Ember.Object.create({
          label: 'Followers',
          key: 'followers',
          entity: 'users',
          groupName: 'Asana Users',
          isDynamicField: true,
          step: 2,
          isMandatory: false,
          isPrimary: true,
          labelHelpText: "Separate multiple followers with comma",
          transformValue: _utilities.commaSeperatedStringsToListPayloadAlways,
          transformDataSource: _utilities.listToStrings
        }), Ember.Object.create({
          label: 'Start Date',
          key: 'startDate',
          step: 2,
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextPartial: 'workflows/details/edit/actions/details/label-help-text/due-date',
          isDynamicField: true,
          labelHelpText: true
        }), Ember.Object.create({
          label: 'Tags',
          key: 'tags',
          entity: 'tags',
          groupName: 'Asana Tags',
          labelHelpText: 'Separate multiple tags with comma',
          isDynamicField: true,
          transformValue: _utilities.commaSeperatedStringsToListPayloadAlways,
          transformDataSource: _utilities.listToStrings,
          step: 2
        }), Ember.Object.create({
          label: 'Attachments',
          key: 'attachments',
          isDynamicField: true,
          labelHelpText: 'Separate multiple attachments with comma',
          transformValue: _utilities.generateAttachmentsPayload,
          step: 2
        })]
      });
      var updateWorkItem = actionClass.create({
        stepType: 'update_task',
        application: 'asana',
        color: '#034032',
        component: 'workflows/details/edit/actions/details/asana/create-update-work-item',
        rawFields: [Ember.Object.create({
          label: 'Task',
          key: 'taskId',
          isDynamicField: true,
          isMandatory: true,
          step: 1
        }), Ember.Object.create({
          label: 'Task name',
          key: 'name',
          isDynamicField: true,
          isMandatory: false,
          step: 2
        }), Ember.Object.create({
          label: 'Approval Status',
          key: 'approvalStatus',
          entity: 'approval-status',
          groupName: 'Approval status',
          isDynamicField: true,
          isMandatory: false,
          step: 2,
          isPrimary: true
        }), Ember.Object.create({
          label: 'Completed',
          key: 'completed',
          isChoice: true,
          isMandatory: false,
          choiceLabelKey: 'label',
          choiceValueKey: 'value',
          step: 2,
          choices: Ember.A([{
            'value': true,
            'label': 'Yes'
          }, {
            'value': false,
            'label': 'No'
          }])
        }), Ember.Object.create({
          label: 'Due Date',
          key: 'dueDate',
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextPartial: 'workflows/details/edit/actions/details/label-help-text/due-date',
          isDynamicField: true,
          labelHelpText: true
        }), Ember.Object.create({
          step: 2,
          label: 'Description',
          key: 'htmlNotes',
          isDynamicField: true
        }), Ember.Object.create({
          label: 'Liked',
          key: 'liked',
          isChoice: true,
          isMandatory: false,
          choiceLabelKey: 'label',
          choiceValueKey: 'value',
          step: 2,
          choices: Ember.A([{
            'value': true,
            'label': 'Yes'
          }, {
            'value': false,
            'label': 'No'
          }])
        }), Ember.Object.create({
          label: 'Assignee',
          key: 'assignee',
          entity: 'users',
          groupName: 'Asana Users',
          isDynamicField: true,
          isMandatory: false,
          step: 2,
          isPrimary: true
        }), Ember.Object.create({
          key: 'customFields',
          isArray: true,
          step: 2,
          transformDataSource: _utilities.transformCustomFieldsWithType,
          transformValue: _utilities.generateCustomFieldsPayloadWithType,
          childFieldMetaInfo: Ember.Object.create({
            key: 'customField',
            isObject: true,
            primaryObjectKey: 'id',
            secondaryObjectKey: 'value',
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              label: 'Field',
              key: 'id',
              isRemoteChoice: true,
              entity: 'custom-fields',
              lookupUrl: 'entity-autocomplete/asana/custom-fields/',
              searchParam: 'text',
              isMandatory: true,
              choiceLabelKey: 'name',
              choices: []
            }), Ember.Object.create({
              key: 'value',
              isDynamicField: true,
              isMandatory: true
            })])
          })
        }), Ember.Object.create({
          label: 'Parent Task ID',
          key: 'parent',
          isDynamicField: true,
          isMandatory: false,
          step: 2,
          isPrimary: true
        }), Ember.Object.create({
          label: 'Followers',
          key: 'followers',
          entity: 'users',
          groupName: 'Asana Users',
          isDynamicField: true,
          step: 2,
          isMandatory: false,
          isPrimary: true,
          labelHelpText: "Separate multiple followers with comma",
          transformValue: _utilities.commaSeperatedStringsToListPayloadAlways,
          transformDataSource: _utilities.listToStrings
        }), Ember.Object.create({
          label: 'Start Date',
          key: 'startDate',
          step: 2,
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextPartial: 'workflows/details/edit/actions/details/label-help-text/due-date',
          isDynamicField: true,
          labelHelpText: true
        }), Ember.Object.create({
          label: 'Tags',
          key: 'tags',
          entity: 'tags',
          groupName: 'Asana Tags',
          labelHelpText: 'Separate multiple tags with comma',
          isDynamicField: true,
          transformValue: _utilities.commaSeperatedStringsToListPayloadAlways,
          transformDataSource: _utilities.listToStrings,
          step: 2
        }), Ember.Object.create({
          label: 'Attachments',
          key: 'attachments',
          isDynamicField: true,
          labelHelpText: 'Separate multiple attachments with comma',
          transformValue: _utilities.generateAttachmentsPayload,
          step: 2
        })]
      });
      var getTask = actionClass.create({
        stepType: 'get_task',
        application: 'asana',
        color: '#045032',
        rawFields: [Ember.Object.create({
          label: 'Task Id',
          key: 'taskId',
          isDynamicField: true,
          isMandatory: true,
          labelPartial: "workflows/details/edit/actions/details/primary-field-label"
        })]
      });
      workflowActionChoices.addObjects([createWorkItem, updateWorkItem, getTask]);
      workflowActionChoices.forEach(function (action) {
        action.displayName = _this.intlService.findTranslationByKey(action.stepType);
        action.eventType = 'action';
      });
      return workflowActionChoices;
    }
  });
});