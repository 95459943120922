define("workflows-web/components/data-table-new/relative-time-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Uv7nZVcL",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,2],[[30,[36,1],[[35,0],\"hf-tooltipped hf-tooltipped-left\"],null]],null]],[15,\"aria-label\",[30,[36,3],[[35,0]],[[\"format\"],[\"absolute\"]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-u-text-overflow\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[35,0],[30,[36,4],[[35,0]],null],\"~\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"if\",\"concat-strings\",\"format-date\",\"format-relative\"]}",
    "meta": {
      "moduleName": "workflows-web/components/data-table-new/relative-time-cell/template.hbs"
    }
  });
});