define("workflows-web/manage/agents/list/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "c6wBnegw",
    "block": "{\"symbols\":[],\"statements\":[[8,\"slide-in\",[],[[\"@isOpen\",\"@canHide\",\"@onClose\"],[true,false,[30,[36,2],[[32,0,[\"closeNewAgentForm\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"header\"],[14,0,\"hf-entity-header hf-u-vertically-centered-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-entity-header_title hf-u-vertically-centered-container\"],[12],[1,[30,[36,0],[\"invite-agent\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"canShowToastMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"mini-toast-message\",[[24,0,\"new-agent-toast-message\"]],[[\"@message\",\"@type\",\"@canShow\",\"@removeToastMessage\"],[[30,[36,0],[\"manage.new-agent-activation-email-sent\"],null],[30,[36,1],[[32,0,[\"isSaveSuccess\"]],\"success\",\"failure\"],null],[32,0,[\"canShowToastMessage\"]],[32,0,[\"removeToastMessage\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"manage/agents/form\",[],[[\"@canShowEditForm\",\"@onSave\"],[true,[32,0,[\"onSave\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"fn\"]}",
    "meta": {
      "moduleName": "workflows-web/manage/agents/list/new/template.hbs"
    }
  });
});