define("workflows-web/components/inline-attachment/component", ["exports", "jquery", "workflows-web/config/environment", "workflows-web/utilities/editor"], function (_exports, _jquery, _environment, _editor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DUMMY_ATTACHMENT_PATH = '/assets/images/dummy-attachment.png';
  var INLINE_ATTACHMENT_PLACEHOLDER_PATH = '/assets/svgs/inline-attachment-placeholder.svg';
  var _default = _exports.default = Ember.Component.extend({
    inProgress: false,
    url: '',
    dummyAttachmentPath: DUMMY_ATTACHMENT_PATH,
    filesTooLargeMessage: '',
    triggerModifierClass: '',
    isDisabled: false,
    uploadUrl: null,
    appendHostInAttachmentURL: true,
    isSaveDisabled: Ember.computed('url', 'files', {
      get: function get() {
        return Ember.isBlank(this.get('url')) && Ember.isBlank(this.get('files'));
      }
    }),
    insertInlineAttachments: function insertInlineAttachments(inlineAttachmentUrls, editorId) {
      if (editorId) {
        var editorInstance = (0, _editor.getEditorInstanceById)(editorId);
        if (editorInstance) {
          var editorContent = editorInstance.getData();
          var bookmarks = editorInstance.getSelection().createBookmarks2();
          editorContent = this.replacePlaceholder(editorContent, inlineAttachmentUrls);
          editorInstance.setData(editorContent);
          editorInstance.getSelection().selectBookmarks(bookmarks);
        }
      }
    },
    closePopOver: function closePopOver(popOver) {
      this.set('url', '');
      this.set('files', '');
      this.set('saveInProgress', false);
      popOver.closePopOver();
    },
    insertImage: function insertImage(url, editorId, popOver) {
      if (editorId && url) {
        var placeholder = "<img src=\"".concat(url, "\"> ");
        (0, _editor.insertHtmlAtCaretPosition)(placeholder, editorId);
        if (!Ember.isBlank(popOver)) {
          this.closePopOver(popOver);
        }
      }
    },
    replacePlaceholder: function replacePlaceholder(editorContent, urls) {
      if (editorContent) {
        urls.forEach(function (url) {
          editorContent = editorContent.replace(INLINE_ATTACHMENT_PLACEHOLDER_PATH, url);
        });
        return editorContent;
      }
    },
    removePlaceHolder: function removePlaceHolder(editorContent) {
      var placeholder = "<img alt=\"\" src=\"".concat(INLINE_ATTACHMENT_PLACEHOLDER_PATH, "\" />");
      if (editorContent) {
        editorContent = editorContent.split(placeholder).join('');
        return editorContent;
      }
    },
    actions: {
      insertAttachment: function insertAttachment(popOver) {
        var url;
        var editorId = _environment.default.environment !== 'test' ? this.get('editorId') : '';
        if (!Ember.isBlank(this.get('url'))) {
          url = this.get('url');
          this.insertImage(url, editorId, popOver);
        }
      },
      closePopOver: function closePopOver(popOver) {
        this.closePopOver(popOver);
      },
      setPopOverWidth: function setPopOverWidth() {
        var _this = this;
        Ember.run.next(function () {
          if (_this.get('isEditorMaximized')) {
            _this.$('.hf-pop-over').width((0, _jquery.default)('.cke_inner.cke_maximized').width() - _this.$('.hf-inline-attachment-trigger.hf-editor-maximized').offset().left - 20);
          } else {
            _this.$('.hf-pop-over').width((0, _jquery.default)('.cke_editor_kbArticleContents').width());
          }
        });
      }
    }
  });
});