define("workflows-web/components/form-field-input/multi-choice-dropdown/option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6RuPZPoj",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"type\",\"class\",\"checked\",\"data-test-id\"],[\"checkbox\",\"hf-mod-no-outline\",[35,0,[\"isSelected\"]],\"entity-dropdown-option-check-box\"]]]],[2,\"\\n\"],[1,[30,[36,3],[[35,0],[35,2]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"option\",\"input\",\"choiceLabelKey\",\"get\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/multi-choice-dropdown/option/template.hbs"
    }
  });
});