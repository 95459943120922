define("workflows-web/components/text-editor/component", ["exports", "jquery", "workflows-web/config/environment", "workflows-web/constants/editor", "workflows-web/utilities/editor"], function (_exports, _jquery, _environment, _editor, _editor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    loggerService: Ember.inject.service('logger'),
    config: null,
    editor: null,
    editorId: '',
    value: '',
    disableRichText: false,
    maxLength: null,
    background: null,
    startupFocus: false,
    uploadInProgress: false,
    disableInlineAttachment: false,
    disableToolbarOptions: false,
    uploadUrl: '',
    appendHostInAttachmentURL: true,
    isAdditionalCKEditorInstanceRemoved: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('config', _editor.LITE_EDITOR_CONFIG);
    },
    didInsertElement: function didInsertElement() {
      try {
        var editorId = this.get('editorId');
        if (_environment.default.environment !== 'test' && !this.get('disableRichText')) {
          if (window.CKEDITOR !== undefined) {
            this.initCkeditor(editorId);
          } else {
            // In case of visiting the form with CKEditor instance directly from URL,
            // there might be a delay in loading CKEDitor and hence this check.
            this.retryEditorInit(editorId);
          }
        }
      } catch (exception) {
        this.get('loggerService').log('CKEditor error while inserting the component', exception);
      }
    },
    input: function input() {
      if (_environment.default.environment !== 'test' && !this.get('disableRichText')) {
        Ember.run.debounce(this, this.setValue, 200);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var editor = this.get('editor');
      if (editor) {
        editor.destroy();
      }
    },
    removeAdditionalEditorInstance: function removeAdditionalEditorInstance() {
      var _this = this;
      var currentRetryCount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var maxRetryCount = 5;
      var isLoaded = this.get('isAdditionalCKEditorInstanceRemoved');
      if (CKEDITOR.instances.editor1) {
        CKEDITOR.instances.editor1.removeAllListeners();
        this.set('isAdditionalCKEditorInstanceRemoved', true);
      } else if (!isLoaded && currentRetryCount < maxRetryCount) {
        Ember.run.later(this, function () {
          _this.removeAdditionalEditorInstance(currentRetryCount + 1);
        }, 500);
      }
    },
    onInstanceReady: function onInstanceReady(event) {
      try {
        /*
        This method is needed as an extra ckeditor instance is added sometimes.
        We will periodically check if the instance is added and remove the listeners
        */
        this.removeAdditionalEditorInstance();
        this.get('editor').setData(this.get('value'));
        this.setBackground(this.get('editorId'));
        this.get('editor').container.$.setAttribute('data-test-id', 'hf-editor-field');
        this.get('editor').editable().$.setAttribute('data-test-id', 'hf-editor-field-editable-area');
        if (this.get('startupFocus')) {
          Ember.run.next(function () {
            var editor = event.editor;
            var range = editor.createRange();
            if (range) {
              range.moveToElementEditEnd(editor.editable());
              range.select();
              range.scrollIntoView();
            }
          });
        }
      } catch (exception) {
        this.get('loggerService').log('CKEditor error in startup focus', exception);
      }
    },
    setEditorContent: Ember.observer('value', function () {
      try {
        var editor = this.get('editor');
        var htmlValue = this.get('value');
        if (htmlValue) {
          // Blockquote addon is not including the inline styles by default and hence the below code.
          htmlValue = htmlValue.replace('<blockquote>', '<blockquote style="font-style:italic;font-family:Georgia,Times,Times New Roman,serif;padding:2px 8px 2px 20px;display:block;margin-top:10px;margin-bottom:10px;border-left:3px solid #ccc;">');
        }
        if (!this.get('disableRichText') && editor && htmlValue !== editor.getData()) {
          editor.setData(htmlValue === null ? '' : htmlValue);
          this.setBackground();
        }
      } catch (exception) {
        this.get('loggerService').error('CKEditor error while setting the html content', exception);
      }
    }),
    initCodeHighlighting: function initCodeHighlighting() {
      var _this2 = this;
      if (window.hljs !== undefined) {
        window.hljs.initHighlighting();
      } else {
        Ember.run.later(function () {
          _this2.initCodeHighlighting();
        }, 500);
      }
    },
    retryEditorInit: function retryEditorInit(editorId) {
      var _this3 = this;
      if (window.CKEDITOR !== undefined) {
        this.initCkeditor(editorId);
      } else {
        Ember.run.later(function () {
          _this3.retryEditorInit(editorId);
        }, 500);
      }
    },
    initCkeditor: function initCkeditor(editorId) {
      var _this4 = this;
      var config = this.get('config');
      this.initCodeHighlighting();
      if (config.enterTag === 'BR') {
        config.enterMode = CKEDITOR.ENTER_BR;
      } else {
        config.enterMode = CKEDITOR.ENTER_P;
      }
      config.startupFocus = this.get('startupFocus');
      if (!config.allowClasses) {
        // This will strip out class attributes in editor content
        config.allowedContent = {
          $1: {
            attributes: true,
            styles: true
          }
        };
      } else {
        config.allowedContent = true;
      }
      /* eslint-disable camelcase */
      // Below configuration is mandatory for inclding the embed plugin
      config.embed_provider = '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}';
      /* eslint-enable camelcase */
      var editorHeight = this.get('height');
      if (Ember.isPresent(editorHeight)) {
        config.height = editorHeight;
      }
      this.set('editor', CKEDITOR.replace(editorId, config));
      var editor = this.get('editor');
      editor.on('change', function () {
        Ember.run.debounce(_this4, _this4.setValue, 200);
        if (_this4.onChange) {
          _this4.onChange(editor);
        }
      });
      editor.on('blur', function () {
        if (_this4.onFocusOut) {
          _this4.onFocusOut();
        }
      });
      editor.on('instanceReady', function (event) {
        _this4.onInstanceReady(event);
      });
      editor.on('maximize', function (event) {
        _this4.set('isEditorMaximized', event.editor.getCommand('maximize').state === 1);
      });
      editor.on('mode', function (event) {
        _this4.set('disableToolbarOptions', event.editor.mode === 'markdown' || event.editor.mode === 'source');
        _this4.setBackground(_this4.get('editorId'));
      });
    },
    isEditorContentEmpty: function isEditorContentEmpty() {
      if (!this.get('disableRichText')) {
        return (0, _editor2.isContentEmpty)(this.get('editor'));
      }
    },
    setValue: function setValue() {
      if (!this.get('isDestroyed')) {
        this.set('value', this.get('editor').getData());
      }
    },
    setBackground: function setBackground(editorId) {
      var background = this.get('background');
      if (background) {
        (0, _jquery.default)("#cke_".concat(editorId, " .cke_editable")).css('background', background);
      }
    }
  });
});