define("workflows-web/components/form-field-input/check-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "w3ZvHlKw",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"type\",\"checked\",\"class\",\"classNames\",\"disabled\",\"data-test-id\"],[\"checkbox\",[35,1,[\"value\"]],\"hf-form-field-input hf-check-box-input\",[35,2],[35,1,[\"isDisabled\"]],[35,0]]]]],[2,\"\\n\"],[1,[35,1,[\"label\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fieldDataTestId\",\"field\",\"inputClass\",\"input\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/check-box/template.hbs"
    }
  });
});