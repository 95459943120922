define("workflows-web/components/modal-dialog", ["exports", "jquery", "ember-modal-dialog/components/modal-dialog"], function (_exports, _jquery, _modalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalDialog.default.extend({
    translucentOverlay: true,
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        containerClassNames: ['modal'],
        overlayClassNames: ['modal-overlay']
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      var $modalOverlay = (0, _jquery.default)('.modal-overlay');
      $modalOverlay.addClass('hf-slide-in-up');
      (0, _jquery.default)('body').on('keydown.ember-modal-dialog', function (e) {
        if (e.keyCode === 27) {
          // Escape
          /* eslint-disble ember/closure-actions */
          _this.get('close')();
          /* eslint-enable ember/closure-actions */
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)('body').off('keydown.ember-modal-dialog');
    }
  });
});