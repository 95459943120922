define("workflows-web/workflows/details/edit/actions/details/send-email/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    requestService: Ember.inject.service('request'),
    routerService: Ember.inject.service('router'),
    accountInfoService: Ember.inject.service('account-info'),
    didInsertElement: function didInsertElement() {
      var form = this.form;
      var emailActionsChoices = form.model.choices.value;
      var actionDetailsChoices = form.actionDetailsChoices;
      if (Ember.isPresent(emailActionsChoices)) {
        actionDetailsChoices.unshiftObject({
          groupName: 'Email Actions',
          options: Ember.A()
        });
        var choices = actionDetailsChoices.findBy('groupName', 'Email Actions');
        emailActionsChoices.forEach(function (choice) {
          choices.options.addObject(Ember.Object.create({
            id: choice.id,
            label: choice.name,
            value: "&lt;&lt;".concat(choice.name, "&gt;&gt;&nbsp;")
          }));
        });
        var editorField = form.model.message;
        var subjectField = form.model.subject;
        form.setActionDetailsChoices(editorField);
        form.setActionDetailsChoices(subjectField);
      }
    },
    actions: {
      onNameChange: function onNameChange(field, choiceName) {
        var form = this.form;
        var actionDetailsChoices = form.actionDetailsChoices;
        var emailActionsChoices = actionDetailsChoices.findBy('groupName', 'Email Actions');
        if (Ember.isPresent(emailActionsChoices)) {
          var options = emailActionsChoices.options;
          var option = options.findBy('id', field.value.id);
          if (option) {
            option.set('label', choiceName);
            option.set('value', "&lt;&lt;".concat(choiceName, "&gt;&gt;&nbsp;"));
          } else {
            options.addObject(Ember.Object.create({
              id: field.value.id,
              label: choiceName,
              value: "&lt;&lt;".concat(choiceName, "&gt;&gt;&nbsp;")
            }));
          }
        } else {
          actionDetailsChoices.unshiftObject({
            groupName: 'Email Actions',
            options: Ember.A([Ember.Object.create({
              id: field.value.id,
              label: choiceName,
              value: "&lt;&lt;".concat(choiceName, "&gt;&gt;&nbsp;")
            })])
          });
        }
        var editorField = form.model.message;
        var subjectField = form.model.subject;
        form.setActionDetailsChoices(editorField);
        form.setActionDetailsChoices(subjectField);
      },
      addChildField: function addChildField(field) {
        var _this = this;
        field.addNewChildField(null);
        field.childFieldMetaInfo.childFields.forEach(function (field) {
          if (field.key === 'id') {
            field.set('value', (_this.form.model.choices.childFields.mapBy('value').filter(function (val) {
              return val;
            }).mapBy('id').lastObject || 0) + 1);
          }
          if (field.isDynamicField) {
            _this.get('form').setActionDetailsChoices(field);
          }
        });
      },
      removeChildField: function removeChildField(field, childField) {
        field.removeChildField(childField);
      },
      onFormChange: function onFormChange(value) {
        if (value.isNewForm) {
          var url = this.routerService.urlFor('forms.new');
          window.open(url);
        }
      }
    }
  });
});