define("workflows-web/components/form-field-input/editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "13QjvEhK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[34,0]],[12],[2,\"\\n  \"],[1,[30,[36,10],null,[[\"editorId\",\"attachmentsMaxSize\",\"filesTooLargeMessage\",\"modifierClass\",\"isDisabled\",\"isEditorMaximized\",\"uploadUrl\",\"appendHostInAttachmentURL\"],[[35,9],[35,8],[30,[36,7],[\"ticket-details.add-update.attachments-larger-than-{maxSize}-validation-message\"],[[\"maxSize\"],[[35,6]]]],[30,[36,5],[[30,[36,4],[[35,1,[\"isEditorMaximized\"]],\"hf-is-editor-maximized\"],null],[35,3]],null],[35,2],[35,1,[\"isEditorMaximized\"]],[35,1,[\"uploadUrl\"]],[35,1,[\"appendHostInAttachmentURL\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,16],null,[[\"editorId\",\"value\",\"height\",\"editorInputClass\",\"onFocusOut\",\"data-test-id\",\"config\",\"isEditorMaximized\",\"disableInlineAttachment\",\"disableToolbarOptions\",\"uploadUrl\",\"appendHostInAttachmentURL\",\"class\"],[[35,9],[35,1,[\"value\"]],[35,1,[\"height\"]],\"hf-form-field-input hf-editor-input\",[30,[36,15],[[32,0],\"onHtmlFocusOut\"],null],[35,14],[35,13],[35,1,[\"isEditorMaximized\"]],[35,1,[\"disableEditorInlineAttachment\"]],[35,2],[35,1,[\"uploadUrl\"]],[35,1,[\"appendHostInAttachmentURL\"]],[30,[36,12],[[30,[36,4],[[35,11],\"hf-is-error\"],null],\" \"],null]]]]],[2,\"\\n\"],[1,[30,[36,17],null,[[\"field\",\"isEditorFieldPreview\"],[[35,1],true]]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"inlineImageAttachmentContainerClass\",\"field\",\"disableToolbarOptions\",\"inlineImagesModifierClass\",\"if\",\"concat-strings\",\"attachmentsMaxSizeInMB\",\"t\",\"attachmentsMaxSize\",\"editorId\",\"inline-attachment\",\"hasErrors\",\"concat\",\"editorConfig\",\"inputDataTestId\",\"action\",\"text-editor\",\"field-preview\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/editor/template.hbs"
    }
  });
});