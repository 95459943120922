define("workflows-web/workflows/apps/details/okta/component", ["exports", "workflows-web/workflows/apps/details/manage-app-form/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    getRawFieldsInfo: function getRawFieldsInfo() {
      var intlService = this.get('intlService');
      return [Ember.Object.create({
        label: intlService.findTranslationByKey('apps.form-fields.account-url'),
        key: 'accountUrl',
        isText: true
      }), Ember.Object.create({
        label: intlService.findTranslationByKey('apps.form-fields.api-token'),
        key: 'apiToken',
        isPassword: true
      }), Ember.Object.create({
        label: intlService.findTranslationByKey('apps.form-fields.enable-webhook'),
        key: 'isTriggersEnabled',
        isToggle: true,
        showHelperText: true,
        enableHelperText: intlService.findTranslationByKey('apps.form-fields.okta.enable-helper-text'),
        disableHelperText: intlService.findTranslationByKey('apps.form-fields.okta.disable-helper-text')
      })];
    }
  });
});