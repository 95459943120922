define("workflows-web/utilities/form-models", ["exports", "workflows-web/form-field-models/text-field", "workflows-web/form-field-models/textarea-field", "workflows-web/form-field-models/number-field", "workflows-web/form-field-models/email-field", "workflows-web/form-field-models/date-field", "workflows-web/form-field-models/dynamic-date-field", "workflows-web/form-field-models/choice-field", "workflows-web/form-field-models/remote-choice", "workflows-web/form-field-models/multi-choice-field", "workflows-web/form-field-models/check-box-field", "workflows-web/form-field-models/password-field", "workflows-web/form-field-models/editor-field", "workflows-web/form-field-models/phone-field", "workflows-web/form-field-models/toggle-field", "workflows-web/form-field-models/object-field", "workflows-web/form-field-models/array-field", "workflows-web/form-field-models/condition-field", "workflows-web/form-field-models/local-multi-choice-dropdown-field", "workflows-web/form-field-models/remote-multi-choice-dropdown-field", "workflows-web/form-field-models/date-time-field", "workflows-web/form-field-models/time-field", "workflows-web/form-field-models/color-field", "workflows-web/form-field-models/files-field", "workflows-web/form-field-models/slug-field", "workflows-web/form-field-models/code-editor-field", "workflows-web/form-field-models/dynamic-field"], function (_exports, _textField, _textareaField, _numberField, _emailField, _dateField, _dynamicDateField, _choiceField, _remoteChoice, _multiChoiceField, _checkBoxField, _passwordField, _editorField, _phoneField, _toggleField, _objectField, _arrayField, _conditionField, _localMultiChoiceDropdownField, _remoteMultiChoiceDropdownField, _dateTimeField, _timeField, _colorField, _filesField, _slugField, _codeEditorField, _dynamicField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createField = createField;
  _exports.createObjectInContainer = createObjectInContainer;
  // 'Ember.getOwner(this)' returns a container mixin
  // 'ownerInjection()' on conatiner mixin returns a object which can be passed as parameter for manual object instantiation.
  // Object created in such a way is same(or similar) to an object instatiated via a container.
  // Services can be injected only into objects instantiated via a container. We are using intl service.
  function createObjectInContainer(containerItem, objectClass) {
    var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var ownerInjection = Ember.getOwner(containerItem).ownerInjection();
    return objectClass.create(ownerInjection, data);
  }
  function getFieldClass(rawField) {
    var fieldClassDefinition = null;
    if (rawField.get('isObject')) {
      fieldClassDefinition = _objectField.default;
    } else if (rawField.get('isText')) {
      fieldClassDefinition = _textField.default;
    } else if (rawField.get('isTextarea')) {
      fieldClassDefinition = _textareaField.default;
    } else if (rawField.get('isNumber')) {
      fieldClassDefinition = _numberField.default;
    } else if (rawField.get('isEmail')) {
      fieldClassDefinition = _emailField.default;
    } else if (rawField.get('isDate')) {
      fieldClassDefinition = _dateField.default;
      if (rawField.get('isDynamicDate')) {
        fieldClassDefinition = _dynamicDateField.default;
      }
    } else if (rawField.get('isChoice')) {
      fieldClassDefinition = _choiceField.default;
    } else if (rawField.get('isRemoteChoice')) {
      fieldClassDefinition = _remoteChoice.default;
    } else if (rawField.get('isMultiChoice')) {
      fieldClassDefinition = _multiChoiceField.default;
    } else if (rawField.get('isCheckBox')) {
      fieldClassDefinition = _checkBoxField.default;
    } else if (rawField.get('isPassword')) {
      fieldClassDefinition = _passwordField.default;
    } else if (rawField.get('isEditor')) {
      fieldClassDefinition = _editorField.default;
    } else if (rawField.get('isPhone')) {
      fieldClassDefinition = _phoneField.default;
    } else if (rawField.get('isToggle')) {
      fieldClassDefinition = _toggleField.default;
    } else if (rawField.get('isArray')) {
      fieldClassDefinition = _arrayField.default;
    } else if (rawField.get('isCondition')) {
      fieldClassDefinition = _conditionField.default;
    } else if (rawField.get('isLocalMultiChoiceDropdown')) {
      fieldClassDefinition = _localMultiChoiceDropdownField.default;
    } else if (rawField.get('isRemoteMultiChoiceDropdown')) {
      fieldClassDefinition = _remoteMultiChoiceDropdownField.default;
    } else if (rawField.get('isColor')) {
      fieldClassDefinition = _colorField.default;
    } else if (rawField.get('isDateTime')) {
      fieldClassDefinition = _dateTimeField.default;
    } else if (rawField.get('isTime')) {
      fieldClassDefinition = _timeField.default;
    } else if (rawField.get('isFiles')) {
      fieldClassDefinition = _filesField.default;
    } else if (rawField.get('isSlug')) {
      fieldClassDefinition = _slugField.default;
    } else if (rawField.get('isCodeEditor')) {
      fieldClassDefinition = _codeEditorField.default;
    } else if (rawField.get('isDynamicField')) {
      fieldClassDefinition = _dynamicField.default;
    }
    return fieldClassDefinition;
  }
  function createField(containerItem, rawField) {
    var fieldClassDefinition = rawField.fieldClass || getFieldClass(rawField);
    // If the field is of object type, create its child fields
    if (rawField.get('isObject')) {
      var childFields = Ember.A();
      rawField.get('childFieldsMetaInfo').forEach(function (child) {
        if (rawField.get('value')) {
          var value = rawField.get("value.".concat(child.key));
          child.set('value', value);
        }
        var field = createField(containerItem, child);
        childFields.pushObject(field);
        child.set('value', null);
      });
      rawField.set('childFields', childFields);
    } else if (rawField.get('isArray')) {
      // If the field of type array, set the child class definition on the field,
      // so that array field can create its children using it. Even for add/remove child
      rawField.childClassDefinition = getFieldClass(rawField.get('childFieldMetaInfo'));
    } else if (rawField.get('isChoice') && rawField.get('choiceValueKey') && Ember.isPresent(rawField.get('value'))) {
      var choices = rawField.get('choices');
      var selectedChoice = choices.findBy(rawField.get('choiceValueKey'), rawField.get('value'));
      if (selectedChoice) {
        rawField.set('value', selectedChoice);
      }
    }
    return createObjectInContainer(containerItem, fieldClassDefinition, rawField);
  }
});