define("workflows-web/workflows/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    router: Ember.inject.service(),
    accountInfoService: Ember.inject.service('account-info'),
    beforeModel: function beforeModel() {
      if (this.accountInfoService.isUserSelfServed || this.accountInfoService.isUserSelfServedStateUnknown) {
        this.router.transitionTo('workflows.dashboard');
      }
    },
    redirect: function redirect() {
      this.router.transitionTo('workflows.list');
    }
  });
});