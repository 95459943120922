define("workflows-web/form-field-models/multi-choice-field", ["exports", "workflows-web/form-field-models/base-field"], function (_exports, _baseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseField.default.extend({
    isMultiChoice: true,
    options: null,
    value: null,
    apiValueKey: null,
    valueProperty: null,
    labelProperty: null,
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('value')) {
        this.set('value', []);
      }
      this.updateInitialValue();
    },
    isDirty: Ember.computed('value.[]', '_initialValue.[]', {
      get: function get() {
        var valueProperty = this.get('valueProperty');
        // _.isEqual([1, 2, 3], [3, 2, 1]) returns false as ordering is different. So using sortBy
        var isDirty = !_.isEqual(this.get('_initialValue').sortBy(valueProperty), this.get('value').sortBy(valueProperty));
        return isDirty;
      }
    }),
    updateInitialValue: function updateInitialValue() {
      this.set('_initialValue', this.get('value').slice());
    },
    reset: function reset() {
      this._super();
      this.set('value', this.get('_initialValue').slice());
    },
    validate: function validate() {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          var apiValueKey = _this.get('apiValueKey');
          var value = _this.get('value');
          // TODO : Remove this apiValueKey property hack while refactoring form fields.
          _this.set('_cleanValue', apiValueKey ? value.mapBy(apiValueKey) : value);
          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });
});