define("workflows-web/components/text-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "h61IGlZL",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"id\",\"maxlength\",\"class\",\"data-test-id\",\"autofocus\",\"value\"],[[35,4],[35,3],[35,2],[35,1],true,[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"editorDataTestId\",\"editorInputClass\",\"maxLength\",\"editorId\",\"textarea\"]}",
    "meta": {
      "moduleName": "workflows-web/components/text-editor/template.hbs"
    }
  });
});