define("workflows-web/workflows/library/details/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    workflowExample: Ember.computed.reads('model'),
    media: Ember.computed('workflowExample.media', {
      get: function get() {
        return _.orderBy(this.workflowExample.media, ['order'], ['asc']);
      }
    }),
    router: Ember.inject.service(),
    actions: {
      transitionToWorkflowsLibraryList: function transitionToWorkflowsLibraryList() {
        this.router.transitionTo('workflows.library.list');
      }
    }
  });
});