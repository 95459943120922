define("workflows-web/workflows/details/edit/actions/details/jira/set-assignee/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OMV+VM/D",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-vertical-flex-container hf-workflows_form-group hf-mod-last\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,3],[\"step-{index}\"],[[\"index\"],[\"1\"]]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"field\",\"onFocusOut\",\"onValueChange\"],[[35,4,[\"model\",\"issueid\"]],[30,[36,9],[[35,8]],null],[30,[36,7],[[32,0],\"onIssueIdChange\"],null]]]]],[2,\"  \\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[35,8,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[19,\"templates/loading\",[]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[30,[36,2],[\"hf-u-vertical-flex-container hf-workflows_form-group hf-mod-last\",[30,[36,1],[[35,0],\"hf-u-disabled\"],null]],null]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,3],[\"step-{index}\"],[[\"index\"],[\"2\"]]]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"field\"],[[35,4,[\"model\",\"assignee\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"isPrimaryFieldEmpty\",\"if\",\"concat-strings\",\"t\",\"form\",\"form-field\",\"editIssueMeta\",\"action\",\"onIssueIdChange\",\"perform\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/jira/set-assignee/template.hbs"
    }
  });
});