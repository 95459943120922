define("workflows-web/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9erm8742",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,10],[[35,4,[\"accountInfo\",\"user\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"react-components/module-switcher-wrapper\",[],[[\"@goToModule\",\"@goToCreateWorkflow\",\"@currentRouteName\",\"@userInfo\",\"@isUserSelfServed\",\"@logout\",\"@goToRoute\",\"@hasSelfServedAccountExpired\",\"@hasBillingPermission\",\"@isUserSelfServedStateUnknown\",\"@redirectToBilling\"],[[30,[36,1],[[32,0],\"goToModule\"],null],[30,[36,1],[[32,0],\"goToCreateWorkflow\"],null],[34,2],[34,4,[\"accountInfo\",\"user\"]],[34,5],[30,[36,1],[[32,0],\"goToLogout\"],null],[30,[36,1],[[32,0],\"goToRoute\"],null],[34,6],[34,7],[34,8],[30,[36,1],[[32,0],\"redirectToBilling\"],null]]],null],[2,\"\\n\"],[6,[37,10],[[35,9,[\"message\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[34,3]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,10],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"react-components/left-nav-wrapper\",[],[[\"@showMainSecondaryNav\",\"@goToRoute\",\"@currentRouteName\"],[[34,0],[30,[36,1],[[32,0],\"goToRoute\"],null],[34,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"section\"],[15,0,[30,[36,11],[\"hf-content\",[30,[36,10],[[35,4,[\"accountInfo\",\"user\"]],\"hf-content-left-spacing\"],null],[30,[36,10],[[35,0],\"hf-content-left-nav-spacing\"],null]],null]],[12],[2,\"\\n  \"],[1,[30,[36,13],[[30,[36,12],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"showMainSecondaryNav\",\"action\",\"currentRouteName\",\"toast-message\",\"accountInfoService\",\"isUserSelfServed\",\"hasSelfServedAccountExpired\",\"hasBillingPermission\",\"isUserSelfServedStateUnknown\",\"toastMessageService\",\"if\",\"concat-strings\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "workflows-web/application/template.hbs"
    }
  });
});