define("workflows-web/utilities/object", ["exports", "workflows-web/workflows/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.camelizeObject = camelizeObject;
  _exports.cloneArray = cloneArray;
  _exports.flattenObject = flattenObject;
  _exports.getKeysFromObject = getKeysFromObject;
  _exports.hasProperty = hasProperty;
  _exports.hashToArray = hashToArray;
  _exports.isArrayOrObject = isArrayOrObject;
  _exports.isPromise = isPromise;
  _exports.underscoreObject = underscoreObject;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function isArrayOrObject(object) {
    return _typeof(object) === 'object';
  }
  function isPromise(object) {
    return !!object && (_typeof(object) === 'object' || typeof object === 'function') && typeof object.then === 'function';
  }
  function recursivelyConvert(object, type) {
    var excludeKeys = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var convertedObject, actualKey, convertedKey, value;
    if (Ember.typeOf(excludeKeys) !== 'array') {
      excludeKeys = []; // this should always be an array
    }
    if (Ember.typeOf(object) === 'array' && Ember.isPresent(object)) {
      if (type === 'camelize') {
        convertedObject = Ember.A();
      } else {
        convertedObject = [];
      }
      for (actualKey in object) {
        value = object[actualKey];
        if (object.includes(value)) {
          if (isArrayOrObject(value) && !_.isEmpty(value)) {
            if (!excludeKeys.includes(actualKey)) {
              value = recursivelyConvert(value, type, excludeKeys);
            }
          }
          if (type === 'camelize') {
            convertedObject.addObject(value);
          } else {
            convertedObject.push(value);
          }
        }
      }
    } else if (_typeof(object) === 'object' && !_.isEmpty(object) && !Ember.String.isHTMLSafe(object)) {
      if (type === 'camelize') {
        convertedObject = Ember.Object.create();
      } else {
        convertedObject = {};
      }
      for (actualKey in object) {
        if (Object.prototype.hasOwnProperty.call(object, actualKey)) {
          if (type === 'camelize') {
            if (excludeKeys.includes(actualKey)) {
              convertedKey = actualKey;
            } else {
              convertedKey = actualKey.camelize();
            }
          } else {
            if (actualKey.indexOf('-') === -1) {
              convertedKey = actualKey.underscore();
            } else {
              convertedKey = actualKey;
            }
          }
          value = object[actualKey];
          if (!_.isEmpty(value) && isArrayOrObject(value)) {
            if (actualKey === 'data' && type === 'camelize' && 'application' in object && _constants.APPS_TO_NOT_MODIFY_PAYLOAD_KEYS.includes(object.application) && object.event_type === 'action') {
              value = Ember.Object.create(value);
            } else if (actualKey === 'parentAction' && type === 'underscore') {
              continue;
            } else if (!excludeKeys.includes(actualKey)) {
              value = recursivelyConvert(value, type, excludeKeys);
            } else {
              value = Ember.Object.create(value);
            }
          }
          if (type === 'camelize') {
            convertedObject.set(convertedKey, value);
          } else {
            convertedObject[convertedKey] = value;
          }
        }
      }
    } else {
      return object;
    }
    return convertedObject;
  }
  function camelizeObject(object) {
    var excludeKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return recursivelyConvert(object, 'camelize', excludeKeys);
  }
  function underscoreObject(object) {
    return recursivelyConvert(object, 'underscore');
  }
  function hasProperty(object, property) {
    return Ember.Object.create(object).hasOwnProperty(property);
  }
  function getKeysFromObject(object) {
    var keys = Object.keys(object);
    return keys.removeObject('toString');
  }
  function cloneArray(array) {
    if (Ember.isArray(array)) {
      return array.map(function (object) {
        if (isArrayOrObject(object)) {
          var newObject = Object.assign(Ember.Object.create(), object);
          return newObject;
        } else {
          return object;
        }
      });
    }
  }
  function flattenObject(object) {
    var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var parent = arguments.length > 2 ? arguments[2] : undefined;
    var result = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    object = JSON.parse(JSON.stringify(object));
    for (var key in object) {
      var propName = parent ? "".concat(parent, "__").concat(key) : key;
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        if (_typeof(object[key]) == 'object') {
          flattenObject(object[key], prefix, propName, result);
        } else {
          result[propName] = object[key];
        }
      }
    }
    if (Ember.isPresent(prefix)) {
      var modifiedResult = {};
      for (var _key in result) {
        modifiedResult["".concat(prefix, "__").concat(_key)] = result[_key];
      }
      return modifiedResult;
    }
    return result;
  }
  function hashToArray(data) {
    var content = Ember.A();
    if (data) {
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          var value = data[key];
          if (Ember.isArray(value)) {
            value = JSON.stringify(value);
          }
          content.pushObject(Ember.Object.create({
            label: "".concat(key),
            value: value
          }));
        }
      }
    }
    return content;
  }
});