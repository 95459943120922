define("workflows-web/initializers/modify-light-table-cell", ["exports", "ember-light-table/components/cells/base", "workflows-web/utilities/string"], function (_exports, _base, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'modify-light-table-cell',
    initialize: function initialize() {
      _base.default.reopen({
        attributeBindings: ['data-test-id'],
        'data-test-id': Ember.computed('column.cellComponent', 'value', function () {
          if (this.get('column.cellComponent') === 'workflows/history/workflow-name') {
            return "hf-table-column-workflow-name-".concat((0, _string.convertValueToTestID)(this.get('value.name')));
          }
          return null;
        })
      });
    }
  };
});