define("workflows-web/workflows/apps/details/zendesk/component", ["exports", "workflows-web/workflows/apps/details/manage-app-form/component", "workflows-web/utilities/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    generateTokenInProgress: false,
    getRawFieldsInfo: function getRawFieldsInfo() {
      var intlService = this.get('intlService');
      return [Ember.Object.create({
        label: intlService.findTranslationByKey('apps.form-fields.account-url'),
        key: 'accountUrl',
        isText: true
      }), Ember.Object.create({
        label: intlService.findTranslationByKey('apps.form-fields.email-address'),
        key: 'emailAddress',
        isEmail: true
      }), Ember.Object.create({
        label: intlService.findTranslationByKey('apps.form-fields.api-token'),
        key: 'apiToken',
        isPassword: true
      })];
    },
    actions: {
      generateToken: function generateToken() {
        var _this = this;
        this.set('generateTokenInProgress', true);
        this.requestService.post('integrations/zendesk/actions/generate-token/').then(function () {
          _this.requestService.get('integrations/zendesk').then(function (app) {
            return _this.set('app', (0, _object.camelizeObject)(app));
          });
        }).finally(function () {
          _this.set('generateTokenInProgress', false);
        });
      }
    }
  });
});