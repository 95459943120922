define("workflows-web/workflows/details/edit/actions/details/update-available-time/component", ["exports", "workflows-web/workflows/details/edit/actions/details/mixin/primary-field-empty-check"], function (_exports, _primaryFieldEmptyCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_primaryFieldEmptyCheck.default, {
    actions: {
      editField: function editField() {
        this.toggleProperty('isEditMode');
      },
      onChange: function onChange(field, selected) {
        if (selected.value === 'reduce') {
          field.set('dependantFieldLabel', 'Reduce Available Time');
        } else if (selected.value === 'add') {
          field.set('dependantFieldLabel', 'Add Available Time');
        }
        this.set('isEditMode', false);
      }
    }
  });
});