define("workflows-web/components/pop-over/trigger/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['hf-popover-trigger'],
    classNameBindings: ['isOpen:hf-is-open', 'isDisabled:hf-is-disabled'],
    attributeBindings: ['tabindex'],
    canCloseOtherPopOvers: true,
    stopClickPropagation: false,
    isAdjustingPopOver: false,
    isNewTooltip: false,
    tabindex: Ember.computed('canFocus', 'isDisabled', {
      get: function get() {
        return this.get('canFocus') && !this.get('isDisabled') ? '0' : null; // Returning null will not render the property from attribute bindings.
      }
    }),
    isOpen: false,
    isDisabled: false,
    canFocus: false,
    isClickInProgess: false,
    openOnHover: false,
    exemptedSelector: null,
    mouseEnter: function mouseEnter() {
      if (!this.get('isAdjustingPopOver') && (this.get('isTooltip') || this.get('isNewTooltip'))) {
        this.get('openPopOver')();
      }
    },
    mouseLeave: function mouseLeave(event) {
      if (this.get('exemptedSelector')) {
        if ((0, _jquery.default)(event.relatedTarget).attr('data-pop-over-selector') == this.get('exemptedSelector')) {
          return false;
        }
      }
      if (!this.get('isAdjustingPopOver') && (this.get('isTooltip') || this.get('isNewTooltip') && this.closeOnMouseLeave)) {
        this.get('closePopOver')();
      }
    },
    click: function click() {
      var _this = this;
      if (this.get('openOnHover')) {
        return false;
      }
      // To prevent geeky users from opening the popover by inspecting and removing the hf-is-disabled class for the trigger
      this.set('isClickInProgress', true);
      if (!this.get('isDisabled')) {
        Ember.run.next(function () {
          _this.get('togglePopOver')();
        });
      }
      if (this.get('stopClickPropagation')) {
        return false;
      }
    },
    focusIn: function focusIn() {
      var _this2 = this;
      if (!this.get('isOpen') && this.get('canCloseOtherPopOvers')) {
        // This check to prevent closing the popover when focus is coming from popover content (Shift + Tab)
        (0, _jquery.default)(document).click(); // To close all currently open pop-overs
      }
      Ember.run.later(function () {
        // To wait for click event to fire, to check whether the focusIn was caused by a direct mouse click
        if (!_this2.isDestroyed) {
          if (!_this2.get('isClickInProgress')) {
            _this2.click();
          }
          _this2.set('isClickInProgress', false);
        }
      }, 300);
    }
  });
});