define("workflows-web/utilities/url", ["exports", "workflows-web/utilities/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getHostFromURL = getHostFromURL;
  _exports.getQueryString = getQueryString;
  _exports.makeURL = makeURL;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /*
   * Function to join two strings into an url in given order.
   * Prevents consecutive slashes while joining
   */
  function makeURL(str1, str2) {
    if (!Ember.isBlank(str1) && !Ember.isBlank(str2)) {
      var len1 = str1.length;
      var len2 = str2.length;
      if (str1.charAt(len1 - 1) === '/' && str2.charAt(0) === '/') {
        // str1 has trailing slash and str2 has leading slash
        // "http://localhost:4200/" + "/staff" = http://localhost:4200/staff"
        return str1.concat(str2.substring(1, len2));
      } else if (str1.charAt(len1 - 1) !== '/' && str2.charAt(0) !== '/') {
        // str1 has no trailing slash and str2 has no leading slash
        // "http://localhost:4200" + "staff" = http://localhost:4200/staff"
        return [str1, str2].join('/');
      } else {
        // str1 has trailing slash and str2 has no leading slash
        // "http://localhost:4200/" + "staff" = "http://localhost:4200/staff"
        // str1 has no trailing slash and str2 has leading slash
        // "http://localhost:4200" + "/staff" = "http://localhost:4200/staff"
        return str1.concat(str2);
      }
    }
  }
  function getHostFromURL(url) {
    var _url$split = url.split('://'),
      _url$split2 = _slicedToArray(_url$split, 2),
      protocol = _url$split2[0],
      urlWithoutProtocol = _url$split2[1];
    var _urlWithoutProtocol$s = urlWithoutProtocol.split('/'),
      _urlWithoutProtocol$s2 = _slicedToArray(_urlWithoutProtocol$s, 1),
      domain = _urlWithoutProtocol$s2[0];
    return "".concat(protocol, "://").concat(domain);
  }
  function getQueryString(data) {
    var queryString;
    var keys = (0, _object.getKeysFromObject)(data);
    var queryStringArray = [];
    keys.forEach(function (key) {
      var value = data[key];
      if (_typeof(value) === 'object') {
        value = JSON.stringify(value);
      }
      queryStringArray.push("".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(value)));
    });
    queryString = queryStringArray.join('&');
    return queryString;
  }
});