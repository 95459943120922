define("workflows-web/workflows/details/edit/actions/details/azure-active-directory/get-or-delete-user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SApHFN12",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,2,[\"model\",\"tenant\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"hf-u-vertical-flex-container hf-workflows_form-group\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,0],[\"step-{index}\"],[[\"index\"],[\"1\"]]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"field\",\"actionOnOpen\",\"onSearch\",\"onValueChange\"],[[35,2,[\"model\",\"tenant\"]],[35,1],[35,1],[30,[36,4],[[32,0],\"onTenantFieldChange\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,5],[[35,2,[\"model\",\"tenant\",\"value\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-u-vertical-flex-container hf-workflows_form-group hf-mod-last\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,0],[\"step-{index}\"],[[\"index\"],[\"2\"]]]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"field\",\"actionOnOpen\",\"onSearch\"],[[35,2,[\"model\",\"user\"]],[35,1],[35,1]]]]],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"field\"],[[35,2,[\"model\",\"additionalFilters\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"fetchAzureActiveDirectoryAutocompleteResults\",\"form\",\"form-field\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/azure-active-directory/get-or-delete-user/template.hbs"
    }
  });
});