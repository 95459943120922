define("workflows-web/utilities/scroll", ["exports", "jquery", "workflows-web/config/environment", "workflows-web/constants", "workflows-web/utilities/run-loop"], function (_exports, _jquery, _environment, _constants, _runLoop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scrollToElement = scrollToElement;
  _exports.scrollToTop = scrollToTop;
  _exports.scrollToView = scrollToView;
  function scrollToElement(containerSelectorString, elementSelectorString) {
    var scrollDuration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _constants.SCROLL_DURATION;
    var $element = (0, _jquery.default)(elementSelectorString);
    if ($element.length) {
      var $scrollableContainer = (0, _jquery.default)(containerSelectorString);
      var customFieldOffsetTop = $element.offset().top;
      var scrollableContainerOffsetTop = $scrollableContainer.offset().top;
      var scrollableContainerScrollTop = $scrollableContainer.scrollTop();
      $scrollableContainer.animate({
        scrollTop: customFieldOffsetTop - 2 * scrollableContainerOffsetTop + scrollableContainerScrollTop
      }, scrollDuration);
      (0, _runLoop.runLater)(this, function () {
        // addBack('input') will add the input with its parent element into selection, which is needed for focus as find returns descendants
        // https://api.jquery.com/find/
        $element.find('.ember-power-select-trigger, input').addBack('input').eq(0).focus();
        $element.addClass('hf-is-highlighted');
      }, 300);
      if (_environment.default.environment !== 'test') {
        Ember.run.later(this, function () {
          $element.removeClass('hf-is-highlighted');
        }, 3000);
      }
    } else {
      Ember.Logger.error('scrollToElement: No element present for passed selector string: ', elementSelectorString);
    }
  }
  function scrollToTop(elementSelectorString) {
    var element = (0, _jquery.default)(elementSelectorString);
    if (Ember.isPresent(element)) {
      element.scrollTop(0);
    }
  }
  function scrollToView(element, container) {
    var offset = (0, _jquery.default)(element).offset();
    var containerElement = (0, _jquery.default)(container);
    if (offset.top < 0 && Ember.isPresent(containerElement)) {
      containerElement.animate({
        scrollTop: offset.top
      }, 1000);
    }
  }
});