define("workflows-web/components/pop-over/content/component", ["exports", "jquery", "workflows-web/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: ['isOpen:hf-slide-in-up', 'isMini:hf-mini-pop-over:hf-pop-over', 'isTooltip:hf-tooltip:hf-pop-over', 'isNewTooltip:hf-new-tooltip:hf-pop-over', 'positionOnTop:hf-mod-top'],
    isOpen: false,
    uniqueIdentifier: null,
    clickElement: null,
    keydownElement: null,
    attributeBindings: ['style'],
    canCloseOnOutsideClick: true,
    stopClickPropagation: false,
    reduceLeftOffsetBy: 0,
    disableAutoTopPositioning: false,
    disableAutoLeftPositioning: false,
    popOverMaxHeight: 450,
    scrollContainer: '',
    isAdjustingPopOver: false,
    isNewTooltip: true,
    closeDataTestId: 'pop-over-close',
    didInsertElement: function didInsertElement() {
      var uniqueIdentifier = this.get('elementId');
      this.set('clickElement', "click.'".concat(uniqueIdentifier));
      this.set('keydownElement', "keydown.'".concat(uniqueIdentifier));
      this.adjustPopover();
      (0, _jquery.default)(document).on(this.get('clickElement'), Ember.run.bind(this, this.closeOnOutsideClick));
      (0, _jquery.default)(document).on(this.get('keydownElement'), Ember.run.bind(this, this.closeOnEscape));
    },
    closeOnOutsideClick: function closeOnOutsideClick(event) {
      if (this && this.get('isOpen') && this.get('canCloseOnOutsideClick')) {
        if (!(0, _jquery.default)(event.target).closest(this.$()).length) {
          this.get('closePopOver')();
        }
      }
    },
    closeOnEscape: function closeOnEscape(e) {
      if (e.keyCode === 27) {
        if (this && this.get('isOpen') && !(0, _jquery.default)(e.target).is(':input')) {
          this.get('closePopOver')();
        }
      }
    },
    click: function click(event) {
      var target = event.target;
      if ((0, _jquery.default)(target).closest((0, _jquery.default)('[data-pop-over="close"]'), this.$()).length || (0, _jquery.default)(target).data('pop-over') === 'close') {
        this.get('closePopOver')();
      }
      if (this.get('stopClickPropagation')) {
        return event.stopPropagation();
      }
    },
    adjustPopover: function adjustPopover() {
      var scrollContainer = this.get('scrollContainer');
      var $scrollContainer = (0, _jquery.default)(scrollContainer);
      var $popover = this.$();
      var $container = $scrollContainer.length > 0 ? $scrollContainer : (0, _jquery.default)(window);
      var containerHeight = $container.height();
      var containerWidth = $container.width();
      var topBarHeight = scrollContainer ? 0 : (0, _jquery.default)('.hf-top-bar').height();
      var $trigger = this.$().siblings('.hf-popover-trigger');
      var topOffset = $trigger.length > 0 ? $trigger.offset().top + $trigger.height() / 2 : this.$().offset().top;
      var leftOffset = $popover.offset().left;
      if ($scrollContainer.length > 0) {
        topOffset = topOffset - $container.offset().top;
        leftOffset = leftOffset - $container.offset().left;
      }
      var popoverHeight = $popover.height();
      var popoverWidth = $popover.width();
      if (_environment.default.environment !== 'test') {
        if (!this.get('disableAutoTopPositioning')) {
          if (containerHeight - topOffset < ($scrollContainer.length > 0 ? popoverHeight + 30 : popoverHeight)) {
            // 30 added as Buffer to prevent cases where popover gets hidden inside the scrollContainer
            this.setIsAdjustingPopOver(true);
            if (topOffset - topBarHeight > popoverHeight) {
              // subtracting top bar height as it's z-index is higher than the popover. When the popover tries to render on the top due to lack of window space at the bottom it sometimes goes underneath the top bar
              $popover.css('top', 'inherit');
              $popover.css('bottom', '100%');
              $popover.addClass('hf-mod-position-top');
            } else {
              var popOverMaxHeight = this.get('popOverMaxHeight');
              // adjustedPopoverHeight -> popover height shouldn't be greater than popover max height
              var adjustedPopoverHeight = popoverHeight > popOverMaxHeight ? popOverMaxHeight : popoverHeight;
              var availableHeightForPopover = containerHeight - topBarHeight;
              var modifiedPopoverHeight = availableHeightForPopover <= adjustedPopoverHeight ? availableHeightForPopover - 10 : adjustedPopoverHeight;
              $popover.height(modifiedPopoverHeight);
              if (modifiedPopoverHeight !== popoverHeight) {
                $popover.css('overflow-y', 'auto');
              }
            }
            this.setIsAdjustingPopOver(false);
          }
        }
        if (!this.get('disableAutoLeftPositioning')) {
          if (containerWidth > popoverWidth && containerWidth - leftOffset < popoverWidth) {
            this.setIsAdjustingPopOver(true);
            var newLeft = containerWidth - (popoverWidth + leftOffset);
            $popover.css('left', newLeft - this.get('reduceLeftOffsetBy'));
            this.setIsAdjustingPopOver(false);
          }
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(document).off(this.get('clickElement'));
      (0, _jquery.default)(document).off(this.get('keydownElement'));
    },
    setIsAdjustingPopOver: function setIsAdjustingPopOver(value) {
      var _this = this;
      if (this.get('isNewTooltip')) {
        if (value) {
          this.set('isAdjustingPopOver', true);
        } else {
          // If there is adjustment to pop over, change in position is taking time
          Ember.run.later(function () {
            _this.set('isAdjustingPopOver', false);
          }, 100);
        }
      }
    }
  });
});