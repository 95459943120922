define("workflows-web/workflows/list/history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "R/gUcYwy",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[4,[38,1],[[32,0],\"goToWorkflowExecutionHistory\",[35,0]],null],[12],[2,\"View History\"],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"action\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/list/history/template.hbs"
    }
  });
});