define("workflows-web/mixins/filters/filter-condition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    isMatchAny: false,
    classNameBindings: ['isMatchAny:mod-match-any'],
    fieldsArray: null,
    condition: null,
    customFromDate: null,
    customToDate: null,
    readOnly: false,
    isValueLoading: false,
    addFilterConditionComponent: null,
    field: Ember.computed('condition.field', {
      get: function get() {
        return this.get('fieldsArray').findBy('key', this.get('condition.field'));
      },
      set: function set(key, value) {
        return value;
      }
    }),
    setPredicate: function setPredicate(predicate, value) {
      return {
        value: predicate,
        label: value
      };
    },
    actions: {
      removeFilterCondition: function removeFilterCondition() {
        this.removeFilterCondition(this.get('condition'));
      }
    }
  });
});