define("workflows-web/components/focus-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.TextField.extend({
    classNames: ['hf-form-input'],
    didInsertElement: function didInsertElement() {
      var _this = this;
      var $this = this.$();
      Ember.run.later(function () {
        if (!_this.isDestroyed) {
          $this.focus();
          var _$this$val = $this.val(),
            length = _$this$val.length;
          $this[0].setSelectionRange(length, length);
        }
      });
    }
  });
});