define("workflows-web/workflows/details/edit/actions/details/check-conditions/group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    canShowGroupInfo: false,
    canEditGroup: Ember.computed('group', 'canShowGroupInfo', {
      get: function get() {
        return !this.group.isDefault && this.canShowGroupInfo;
      }
    }),
    actions: {
      toggleCanShowGroupInfo: function toggleCanShowGroupInfo() {
        if (!this.$().find(document.activeElement).length) {
          this.toggleProperty('canShowGroupInfo');
        }
      },
      editGroup: function editGroup(group) {
        this.editGroup(group);
      }
    }
  });
});