define("workflows-web/components/form-minimizable-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['hf-form-minimizable-section'],
    showContent: true,
    canShowSecondaryAction: false,
    sectionLabelKey: null,
    actions: {
      toggleShowContent: function toggleShowContent() {
        this.toggleProperty('showContent');
      },
      handleSecondaryActionClick: function handleSecondaryActionClick() {
        if (this.onSecondaryActionClick) {
          this.onSecondaryActionClick();
        }
      }
    }
  });
});