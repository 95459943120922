define("workflows-web/components/auto-complete/email/component", ["exports", "workflows-web/components/auto-complete/remote/multiple/component", "workflows-web/autocomplete-url-constants", "workflows-web/utilities/email-validation", "workflows-web/utilities/text-width"], function (_exports, _component, _autocompleteUrlConstants, _emailValidation, _textWidth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layoutName: 'components/auto-complete/remote/multiple',
    invalidEmails: null,
    url: _autocompleteUrlConstants.EMAIL_AUTOCOMPLETE_URL,
    searchParam: 'text',
    noMatchesMessage: 'no-matches-message',
    isHighlighted: false,
    hasErrors: Ember.computed('invalidEmails.[]', {
      get: function get() {
        return !Ember.isBlank(this.get('invalidEmails'));
      },
      set: function set(key, value) {
        return value;
      }
    }),
    keyUp: function keyUp() {
      var searchText = this.$('input').val();
      if (Ember.isBlank(searchText)) {
        if (this.get('hasErrors')) {
          this.set('hasErrors', null);
        }
      } else {
        var emailArray = searchText.split(',');
        if (emailArray.every(function (email) {
          return (0, _emailValidation.validateEmail)(email.trim());
        })) {
          if (this.get('hasErrors')) {
            this.set('hasErrors', false);
          }
        }
      }
    },
    createCustomChoice: function createCustomChoice(select) {
      var _this = this;
      if (Ember.isPresent(select.searchText)) {
        this.set('invalidEmails', Ember.A());
        var invalidEmails = this.get('invalidEmails');
        var emailArray = select.searchText.split(',');
        emailArray.forEach(function (email, index) {
          if ((0, _emailValidation.validateEmail)(email.trim())) {
            if (index < emailArray.length - 1) {
              _this.get('selected').pushObject(email);
            } else {
              select.actions.choose(email);
              // clearing the power-select input value which doesn't happen by default if we don't use select.actions.choose to set selected
            }
          } else {
            invalidEmails.pushObject(email);
          }
        });
        if (this.get('hasErrors')) {
          Ember.run.next(function () {
            var input = _this.$('.ember-power-select-trigger input');
            input.focus();
            input.val(invalidEmails.join());
            /* power-select-multiple-input has a default width of 25px which progressively increases as we type. So,we are
                              setting the width manually */
            input.css('width', (0, _textWidth.getWidth)(invalidEmails.join()));
          });
        }
      }
    },
    actions: {
      onKeyDown: function onKeyDown(select, e) {
        if ((e.keyCode === 13 || e.keyCode === 188) && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          this.createCustomChoice(select.searchText);
        } else if (e.keyCode === 188 && !select.isOpen || e.keyCode === 188 && !Ember.isBlank(select.searchText.split(',')) || Ember.isPresent(this.get('characterLimit')) && select.searchText.length >= this.get('characterLimit')) {
          e.preventDefault();
        }
      },
      onClose: function onClose(select) {
        this.set('isHighlighted', false);
        if (this.get('isAddNew') && !select.highlighted) {
          // Allowing creation of new emails only when isAddNew is true and there are no suggestions for the typed text
          this.createCustomChoice(select);
        }
      }
    }
  });
});