define("workflows-web/workflows/mixins/hubspot/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    requestService: Ember.inject.service('request'),
    intlService: Ember.inject.service('intl'),
    accountInfoService: Ember.inject.service('account-info'),
    getHubspotActions: function getHubspotActions() {
      var _this = this;
      var actionClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var workflowActionChoices = Ember.A();
      var createTicket = actionClass.create({
        stepType: 'create_ticket',
        application: 'hubspot',
        rawFields: []
      });
      var updateTicket = actionClass.create({
        stepType: 'update_ticket',
        application: 'hubspot',
        rawFields: []
      });
      var getContact = actionClass.create({
        stepType: 'get_contact',
        application: 'hubspot',
        rawFields: []
      });
      var getCompany = actionClass.create({
        stepType: 'get_company',
        application: 'hubspot',
        rawFields: []
      });
      var getDeal = actionClass.create({
        stepType: 'get_deal',
        application: 'hubspot',
        rawFields: []
      });
      var getTicket = actionClass.create({
        stepType: 'get_ticket',
        application: 'hubspot',
        rawFields: []
      });
      var getCustomObject = actionClass.create({
        stepType: 'get_custom_object',
        application: 'hubspot',
        rawFields: []
      });
      var createAssociations = actionClass.create({
        stepType: 'create_associations',
        application: 'hubspot',
        rawFields: []
      });
      var removeAssociations = actionClass.create({
        stepType: 'remove_associations',
        application: 'hubspot',
        rawFields: []
      });
      workflowActionChoices.addObjects([createTicket, updateTicket, getContact, getCompany, getDeal, getTicket, getCustomObject, createAssociations, removeAssociations]);
      workflowActionChoices.forEach(function (action) {
        action.displayName = _this.intlService.findTranslationByKey(action.stepType);
        action.eventType = 'action';
      });
      return workflowActionChoices;
    }
  });
});