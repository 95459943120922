define("workflows-web/workflows/apps/details/app-description/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "iCjqtTwR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-app-description\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[30,[36,7],[\"hf-app-description_text\",[30,[36,4],[[35,0],\"hf-mod-full-text\"],null]],null]],[12],[2,\"\\n    \"],[19,[35,8],[]],[2,\"\\n\"],[6,[37,4],[[35,9]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"a\"],[15,6,[34,5]],[14,\"target\",\"_blank\"],[14,0,\"hf-button hf-app-details_left_how-to-setup-button hf-mod-detailed-guide\"],[14,\"rel\",\"noopener\"],[12],[2,\"\\n          \"],[1,[30,[36,3],[\"apps.detailed-guide\"],null]],[2,\"\\n          \"],[1,[30,[36,6],[\"pop-out\"],[[\"class\"],[\"hf-u-icon-left-margin\"]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,4],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"hf-app-description_toggle\"],[4,[38,2],[[32,0],\"toggleDescriptionText\"],null],[12],[2,\"\\n      \"],[1,[30,[36,4],[[35,0],[30,[36,3],[\"view-less\"],null],[30,[36,3],[\"view-more\"],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,10]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"hf-app-description_fade\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"isShowingFullText\",\"unless\",\"action\",\"t\",\"if\",\"kbUrl\",\"inline-svg\",\"concat-strings\",\"descriptionPartial\",\"isSetUpInfo\",\"canTruncateText\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/apps/details/app-description/template.hbs"
    }
  });
});