define("workflows-web/initializers/injectors/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.create({
    name: 'inject-router',
    initialize: function initialize(application) {
      application.inject('adapter', 'router', 'router:main');
      application.inject('service', 'router', 'router:main');
      application.inject('component', 'router', 'router:main');
      application.inject('controller', 'router', 'router:main');
    }
  });
});