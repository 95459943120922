define("workflows-web/forms/field-details/component", ["exports", "workflows-web/forms/details/edit/form-model", "workflows-web/utilities/form-models", "workflows-web/utilities/object"], function (_exports, _formModel, _formModels, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);
      this.setCacheValues();
    },
    setCacheValues: function setCacheValues() {
      var sortedFields = this.fields.sortBy('order');
      var sortedFieldsClone = _.cloneDeep(sortedFields);
      var _activeFieldCache = this.activeField;
      this.setProperties({
        sortedFields: sortedFields,
        sortedFieldsClone: sortedFieldsClone,
        _activeFieldCache: _activeFieldCache
      });
    },
    didInsertElement: function didInsertElement() {
      this.renderForm();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this._activeFieldCache !== this.activeField) {
        this.renderForm();
        this.setCacheValues();
      }
    },
    fetchFilterFields: function fetchFilterFields() {
      var _this = this;
      var fieldsArray = Ember.A();
      var fields = this.dependsOnChoices;
      fields.forEach(function (field) {
        var identifier = field.id ? field.id : field.uuid;
        var newField = Ember.Object.create();
        newField.setProperties({
          type: 'local-multiple-autocomplete',
          predicates: ['in'],
          name: field.name,
          key: identifier,
          options: _this.getDependsOnOptionChoices(identifier).map(function (choice) {
            return {
              'label': choice.text,
              'value': choice.id ? choice.id : choice.text
            };
          })
        });
        fieldsArray.addObject(newField);
      });
      this.set('conditionFieldsArray', fieldsArray);
      return fieldsArray;
    },
    dependsOnChoices: Ember.computed('fields.[]', {
      get: function get() {
        var _this2 = this;
        var dependsOnChoices = Ember.A([Ember.Object.create({
          value: null,
          label: 'No Parent Field'
        })]);
        this.fields.forEach(function (field) {
          var identifier = field.id ? 'id' : 'uuid';
          if (field[identifier] !== _this2.activeField[identifier] && field.type === 'dropdown') {
            dependsOnChoices.addObject(Ember.Object.create({
              'id': field[identifier],
              'name': field.name,
              'type': field.type
            }));
          }
        });
        return dependsOnChoices;
      }
    }),
    getDependsOnOptionChoices: function getDependsOnOptionChoices(dependsOnValue) {
      var dependsOnOptionChoices = Ember.A();
      if (dependsOnValue) {
        var field = this.fields.findBy('id', dependsOnValue) || this.fields.findBy('uuid', dependsOnValue);
        if (field.options) {
          var options = field.options;
          dependsOnOptionChoices.addObjects(options);
        } else {
          var _options = field.settings.options;
          dependsOnOptionChoices.addObjects(_options);
        }
      }
      return dependsOnOptionChoices;
    },
    constructFormDetailsRawFields: function constructFormDetailsRawFields() {
      var rawFields = Ember.A();
      var formNameField = Ember.Object.create({
        key: 'name',
        isText: true,
        isMandatory: true,
        label: 'Name'
      });
      var primaryButtonLabelField = Ember.Object.create({
        key: 'primaryButtonLabel',
        isText: true,
        isMandatory: true,
        label: 'Primary Button Label'
      });
      var primaryButtonColorField = Ember.Object.create({
        key: 'primaryButtonColor',
        isColor: true,
        isMandatory: true,
        label: 'Primary Button Color',
        defaultValue: '#08c'
      });
      rawFields.addObjects([formNameField, primaryButtonLabelField, primaryButtonColorField]);
      return rawFields;
    },
    constructFieldDetailsRawFields: function constructFieldDetailsRawFields(dataSource) {
      var rawFields = Ember.A();
      var fieldNameField = Ember.Object.create({
        key: 'name',
        isText: true,
        isMandatory: true,
        label: 'Label'
      });
      var requiredField = Ember.Object.create({
        key: 'required',
        isToggle: true,
        isMandatory: false,
        label: 'Required',
        defaultValue: false
      });
      var helpTextField = Ember.Object.create({
        key: 'helpText',
        isText: true,
        label: 'Label Help Text'
      });
      var matchAllField = Ember.Object.create({
        key: 'matchAll',
        isArray: true,
        isMandatory: false,
        alwaysSendInPayload: true,
        childFieldMetaInfo: Ember.Object.create({
          isCondition: true,
          conditionFields: Ember.A(),
          conditionComponent: 'filter-condition',
          addConditionComponent: 'add-filter-condition'
        })
      });
      var optionsField = Ember.Object.create({
        key: 'options',
        isArray: true,
        isMandatory: true,
        childFieldMetaInfo: Ember.Object.create({
          key: 'option',
          isObject: true,
          childFieldsMetaInfo: Ember.A([Ember.Object.create({
            key: 'id',
            isText: true,
            defaultValue: null
          }), Ember.Object.create({
            key: 'text',
            isText: true,
            isMandatory: true
          })])
        })
      });
      rawFields.addObjects([fieldNameField, requiredField, matchAllField, helpTextField]);
      if (dataSource.type === 'dropdown' || dataSource.type === 'multiple_option') {
        rawFields.addObjects([optionsField]);
      }
      return rawFields;
    },
    renderForm: function renderForm() {
      var rawFields;
      var dataSource;
      var activeField = this.activeField,
        form = this.form;
      if (_.isEmpty(activeField)) {
        dataSource = form.toJSON();
        rawFields = this.constructFormDetailsRawFields();
      } else {
        dataSource = (0, _object.camelizeObject)(Ember.Object.create(_objectSpread({}, activeField)));
        rawFields = this.constructFieldDetailsRawFields(dataSource);
      }
      var basicFieldKeys = rawFields.mapBy('key');
      var uiForm = (0, _formModels.createObjectInContainer)(this, _formModel.default, {
        rawFields: rawFields,
        basicFieldKeys: basicFieldKeys,
        dataSource: dataSource
      });
      this.set('uiForm', uiForm);
      if (uiForm.model.matchAll) {
        var fieldsArray = this.fetchFilterFields();
        uiForm.model.matchAll.childFieldMetaInfo.set('conditionFields', fieldsArray);
      }
      uiForm._copyFromDataSource();
    },
    updatePayload: function updatePayload() {
      var uiForm = this.uiForm,
        activeField = this.activeField,
        sortedFields = this.sortedFields,
        updateFields = this.updateFields,
        updateForm = this.updateForm,
        form = this.form,
        sortedFieldsClone = this.sortedFieldsClone;
      if (uiForm && uiForm.isDirty) {
        var payload = uiForm.generatePayload();
        if (Ember.isPresent(activeField)) {
          if (!_.isEmpty(payload)) {
            var _activeField = sortedFields.findBy('order', activeField.order);
            var _activeFieldIndex = sortedFields.indexOf(_activeField);
            for (var key in payload) {
              if (payload.hasOwnProperty(key)) {
                Ember.set(activeField, key, payload[key]);
              }
            }
            sortedFields.removeObject(_activeField);
            sortedFields.replace(_activeFieldIndex, 0, [activeField]);
            updateFields(sortedFields);
          }
        } else {
          updateForm(payload);
        }
      } else {
        if (Ember.isPresent(activeField)) {
          if (!_.isEqual(sortedFieldsClone, sortedFields)) {
            updateFields(sortedFieldsClone);
          }
        } else {
          if (form.hasDirtyAttributes) {
            form.rollbackAttributes();
            updateForm(form);
          }
        }
      }
    },
    actions: {
      addChildField: function addChildField(field) {
        field.addNewChildField(null);
        this.send('onValueChange');
      },
      removeChildField: function removeChildField(field, childField) {
        field.removeChildField(childField);
        this.send('onValueChange');
      },
      onValueChange: function onValueChange() {
        this.updatePayload();
      },
      removeAllCondition: function removeAllCondition(conditionField) {
        this.uiForm.model.matchAll.removeChildField(conditionField);
        this.updatePayload();
      },
      addAllCondition: function addAllCondition(condition) {
        this.uiForm.model.matchAll.addNewChildField(condition);
        this.updatePayload();
      },
      updateCondition: function updateCondition() {
        this.updatePayload();
      }
    }
  });
});