define("workflows-web/workflows/details/edit/actions/details/check-conditions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2cf07uj3",
    "block": "{\"symbols\":[\"@form\",\"@action\"],\"statements\":[[8,\"add-check-conditions\",[],[[\"@form\",\"@actionDetailsChoices\",\"@currentlySelectedAction\"],[[32,1],[32,1,[\"actionDetailsChoices\"]],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"form-field\",[],[[\"@field\",\"@class\"],[[32,1,[\"model\",\"name\"]],\"hf-mod-create-workflow-input\"]],null],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"hf-check-conditions_message\"],[12],[1,[30,[36,0],[\"check-conditions-message\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/check-conditions/template.hbs"
    }
  });
});