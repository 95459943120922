define("workflows-web/components/form-field-input/condition/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "P6z0PPfd",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,6],[[35,4,[\"conditionComponent\"]]],[[\"addFilterConditionComponent\",\"fieldsArray\",\"removeFilterCondition\",\"condition\",\"updateCondition\",\"isMatchAny\",\"extra\",\"actionChoices\",\"prefix\"],[[35,4,[\"addConditionComponent\"]],[35,4,[\"conditionFields\"]],[30,[36,5],[[32,0],\"removeCondition\"],null],[35,4,[\"value\"]],[30,[36,5],[[32,0],\"updateCondition\"],null],[35,4,[\"isMatchAny\"]],[30,[36,3],[[35,2]],null],[35,1],[35,0]]]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"prefix\",\"actionChoices\",\"extra\",\"readonly\",\"field\",\"action\",\"component\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/condition/template.hbs"
    }
  });
});