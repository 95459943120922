define("workflows-web/components/form-field-input/mixins/autofocus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    autoFocus: function autoFocus() {
      var inputElement = this.$('input, textarea, .ember-basic-dropdown-trigger').eq(0);
      // Needed for the value to be set first and then focus so the cursor is at the end of the text.
      Ember.run.later(function () {
        inputElement.focus();
        var _inputElement$val = inputElement.val(),
          length = _inputElement$val.length;
        if (length) {
          inputElement[0].setSelectionRange(length, length);
        }
      }, 300); // Delay fixes the datepicker position when it is autofocused on render.
    }
  });
});