define("workflows-web/form-field-models/slug-field", ["exports", "workflows-web/form-field-models/text-field", "workflows-web/constants"], function (_exports, _textField, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _textField.default.extend({
    isSlug: true,
    isText: false,
    testRegex: _constants.SLUG_REGEX,
    replaceRegex: _constants.SLUG_REPLACE_REGEX,
    setSlugValue: function setSlugValue(value) {
      this.set('value', this.convertValue(value));
    },
    convertValue: function convertValue(value) {
      return value.toLowerCase().replace(this.get('replaceRegex'), '-').replace(/-$/g, '');
    },
    validate: function validate() {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          var value = _this.get('value');
          if (Ember.isPresent(value)) {
            if (_this.get('testRegex') && !_this.get('testRegex').test(value)) {
              _this.addError(_this.get('intlService').findTranslationByKey('validation.invalid-slug-message'));
              reject();
            } else {
              _this.set('_cleanValue', value);
              resolve();
            }
          }
        }).catch(function () {
          reject();
        });
      });
    }
  });
});