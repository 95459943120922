define("workflows-web/helpers/v1-link", ["exports", "workflows-web/config/environment", "workflows-web/utilities/url"], function (_exports, _environment, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getV1URL = getV1URL;
  function getV1URL(host, params) {
    return _environment.default.isRemote ? (0, _url.makeURL)(host, "/staff/".concat(params)) : "/staff/".concat(params);
  }
  var _default = _exports.default = Ember.Helper.extend({
    hostService: Ember.inject.service('host'),
    compute: function compute(params) {
      return getV1URL(this.get('hostService.host'), params);
    }
  });
});