define("workflows-web/workflows/mixins/happyfox-service-desk/triggers", ["exports", "workflows-web/workflows/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    intlService: Ember.inject.service('intl'),
    getServiceDeskTriggers: function getServiceDeskTriggers() {
      var _this = this;
      var triggerClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var happyfoxServiceDeskTriggerChoices = Ember.A();
      var incidentCreated = triggerClass.create({
        stepType: 'incident_created',
        eventType: 'trigger',
        application: 'happyfox-service-desk',
        color: '#102aad',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      var incidentUpdated = triggerClass.create({
        stepType: 'incident_updated',
        eventType: 'trigger',
        application: 'happyfox-service-desk',
        color: '#570faf',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      var serviceRequestCreated = triggerClass.create({
        stepType: 'service_request_created',
        eventType: 'trigger',
        application: 'happyfox-service-desk',
        color: '#102aad',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      var serviceRequestUpdated = triggerClass.create({
        stepType: 'service_request_updated',
        eventType: 'trigger',
        application: 'happyfox-service-desk',
        color: '#570faf',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      happyfoxServiceDeskTriggerChoices.addObjects([incidentCreated, incidentUpdated, serviceRequestCreated, serviceRequestUpdated]);
      happyfoxServiceDeskTriggerChoices.forEach(function (trigger) {
        trigger.displayName = _this.intlService.findTranslationByKey(trigger.stepType);
      });
      return happyfoxServiceDeskTriggerChoices;
    }
  });
});