define("workflows-web/components/code-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        mode: this.get('mode') || 'htmlmixed',
        readOnly: this.get('readOnly') || false,
        keyMap: ['sublime'],
        theme: ['solarized']
      });
    },
    actions: {
      onCodeChange: function onCodeChange(updatedCode) {
        this.set('code', updatedCode);
        if (this.get('onCodeChange')) {
          this.get('onCodeChange')(updatedCode);
        }
      }
    }
  });
});