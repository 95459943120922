define("workflows-web/components/form-field-input/local-multi-choice-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GAmR/MIh",
    "block": "{\"symbols\":[\"value\",\"select\",\"&default\"],\"statements\":[[6,[37,11],null,[[\"options\",\"selected\",\"class\",\"placeholder\",\"onChange\",\"onClose\",\"renderInPlace\",\"disabled\",\"searchField\",\"choiceLabelKey\",\"choiceValueKey\",\"isAddNew\",\"data-test-id\",\"dropdownClass\",\"selectedItemComponent\"],[[35,0,[\"choices\"]],[35,0,[\"value\"]],[30,[36,10],[[35,9],[35,8],[30,[36,2],[[35,7],\"hf-is-error\"],null],[30,[36,2],[[35,0,[\"isHighlighted\"]],\"hf-is-highlighted\"],null],[30,[36,2],[[35,0,[\"isDisabled\"]],\"hf-is-disabled\"],null]],null],[35,0,[\"placeholder\"]],[30,[36,6],[[32,0],\"valueChanged\"],null],[30,[36,6],[[32,0],\"onClose\"],null],true,[35,0,[\"isDisabled\"]],[35,0,[\"choiceLabelKey\"]],[35,0,[\"choiceLabelKey\"]],[35,0,[\"choiceValueKey\"]],[35,0,[\"isAddNew\"]],[35,5],[35,4],[35,3]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,3,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[30,[36,1],[[32,1],[35,0,[\"choiceLabelKey\"]]],null],[30,[36,1],[[32,1],[35,0,[\"choiceLabelKey\"]]],null],[32,1]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"field\",\"get\",\"if\",\"selectedItemComponent\",\"dropdownClass\",\"fieldDataTestId\",\"action\",\"hasErrors\",\"jsTestClass\",\"selectClass\",\"concat-strings\",\"auto-complete/local/multiple\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/local-multi-choice-dropdown/template.hbs"
    }
  });
});