define("workflows-web/application/staff-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    userName: Ember.computed('user.{firstName,lastName,email}', {
      get: function get() {
        var user = this.get('user');
        var firstName = user.firstName,
          lastName = user.lastName;
        if (Ember.isBlank(firstName) && Ember.isBlank(lastName)) {
          return user.email;
        } else if (Ember.isBlank(lastName)) {
          return user.firstName;
        }
        return "".concat(user.firstName, " ").concat(user.lastName);
      }
    }),
    actions: {
      logout: function logout() {
        this.get('logout')();
      }
    }
  });
});