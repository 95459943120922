define("workflows-web/workflows/apps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KueczFmf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"hf-apps-layout\"],[12],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"react-components/apps-navbar-wrapper\",[],[[\"@currentRoute\",\"@goToSelectedAppCategory\",\"@appsInfo\"],[[34,2],[30,[36,5],[[32,0],\"goToSelectedAppCategory\"],null],[34,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,7],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"react-components/apps-wrapper\",[],[[\"@currentRoute\",\"@appsInfo\",\"@appType\",\"@goToRoute\"],[[34,2],[34,3],[34,4],[30,[36,5],[[32,0],\"goToRoute\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,[[\"class\"],[\"hf-outlet-container\"]]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"currentRoute\",\"appsInfo\",\"app_type\",\"action\",\"isUserSelfServed\",\"if\",\"showAppsTile\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/apps/template.hbs"
    }
  });
});