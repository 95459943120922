define("workflows-web/workflows/apps/details/custom-app/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OBcsXCH7",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"react-components/oauth-wrapper\",[],[[\"@app\",\"@unlinkApp\"],[[34,0],[30,[36,1],[[32,0],\"unlinkApp\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[19,\"workflows/apps/details/partials/base-form\",[]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"app\",\"action\",\"showOauthComponent\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/apps/details/custom-app/template.hbs"
    }
  });
});