define("workflows-web/form-field-models/editor-field", ["exports", "workflows-web/form-field-models/base-field", "workflows-web/utilities/editor"], function (_exports, _baseField, _editor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseField.default.extend({
    isEditor: true,
    height: null,
    isUploadInProgress: false,
    isEditorMaximized: false,
    insertContent: function insertContent(content) {
      var _this = this;
      var editorId = this.get('editorId');
      if (editorId) {
        (0, _editor.insertHtmlAtCaretPosition)(content, editorId);
        // Editor two way binding of value happens after 200ms
        Ember.run.later(function () {
          _this.validate();
        }, 300);
      }
    },
    validate: function validate() {
      var _this2 = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2._super().then(function () {
          _this2.set('_cleanValue', _this2.get('actualValue') || _this2.get('value'));
          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });
});