define("workflows-web/workflows/details/edit/actions/details/get-asset-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NFgMBc4Z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-vertical-flex-container hf-workflows_form-group\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,0],[\"step-{index}\"],[[\"index\"],[\"1\"]]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[10,\"h4\"],[14,0,\"hf-u-paragraph\"],[12],[2,\"\\n      Choose a field below that holds the value for the below fields from the Event that we \\n      just received. We will use the corresponding value for this field as the value for \\n      them. Please fill atleast one of them to fetch asset information.\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"field\"],[[35,1,[\"model\",\"assetId\"]]]]]],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"field\"],[[35,1,[\"model\",\"displayId\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"form\",\"form-field\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/get-asset-info/template.hbs"
    }
  });
});