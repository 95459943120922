define("workflows-web/manage/agents/agent-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IT2w/S2p",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-vertically-centered-container\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[32,0,[\"agentName\"]]],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"agent\",\"locked\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"lock\"],[[\"class\"],[\"hf-u-icon-left-margin\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/manage/agents/agent-name/template.hbs"
    }
  });
});