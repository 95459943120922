define("workflows-web/components/insert-tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bpKozGJm",
    "block": "{\"symbols\":[\"popOver\"],\"statements\":[[6,[37,9],null,[[\"class\"],[\"hf-insert-merge-fields\"]],[[\"default\"],[{\"statements\":[[6,[37,7],[[32,1,[\"trigger\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-mini-pop-over_item hf-u-link-color\"],[15,\"data-test-id\",[34,8]],[12],[1,[30,[36,0],[\"insert-tags\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[32,1,[\"isOpen\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,7],[[32,1,[\"content\"]]],[[\"class\"],[[30,[36,6],[\"hf-insert-merge-fields-popover\",[30,[36,5],[[35,4],\"hf-mod-push-up\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"header\"],[14,0,\"hf-pop-over_header\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"insert-tags\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"section\"],[14,0,\"hf-dynamic-field\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"hf-mod-dropdown\"],[12],[2,\"\\n          \"],[1,[30,[36,3],null,[[\"choices\",\"closeDropdown\"],[[35,2],[30,[36,1],[[32,0],\"closeDropdown\",[32,1]],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"choices\",\"dynamic-field/dropdown-component\",\"showPopOverOnTopOfTrigger\",\"if\",\"concat-strings\",\"component\",\"dataTestId\",\"pop-over\"]}",
    "meta": {
      "moduleName": "workflows-web/components/insert-tags/template.hbs"
    }
  });
});