define("workflows-web/templates/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wkU/6PTE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-hv-centered\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"ball-triangle-loader\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inline-svg\"]}",
    "meta": {
      "moduleName": "workflows-web/templates/loading/template.hbs"
    }
  });
});