define("workflows-web/workflows/details/edit/actions/details/label-help-text/due-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kSU7MHcA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0,[\"isDueByEnabled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"hf-u-paragraph\"],[12],[2,\"Follwing datetime formats will be supported:\"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"hf-u-paragraph\"],[12],[2,\"\\n            YYYY-MM-DDTHH:MM:SS, YYYY-MM-DDTHH:MM:SSZ, MM-DD-YYYYTHH:MM:SS, MM-DD-YYYYTHH:MM:SSZ, DD-MM-YYYYTHH:MM:SS, DD-MM-YYYYTHH:MM:SSZ\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"hf-u-paragraph\"],[12],[2,\"Relative days are also supported. For example, if 5 is given as input, then it will be considered as exactly 5 days from now.\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"hf-u-paragraph\"],[12],[2,\"Follwing date formats will be supported:\"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"hf-u-paragraph\"],[12],[2,\"\\n            YYYY-MM-DD, MM-DD-YYYY, DD-MM-YYYY, 1 Jan 2023\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"hf-u-paragraph\"],[12],[2,\"Relative days are also supported. For example, if 5 is given as input, then it will be considered as 5 days from today.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"field\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/label-help-text/due-date/template.hbs"
    }
  });
});