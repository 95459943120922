define("workflows-web/workflows/apps/details/manage-app-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "u0Al8Xxm",
    "block": "{\"symbols\":[],\"statements\":[[19,\"workflows/apps/details/partials/base-form\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[]}",
    "meta": {
      "moduleName": "workflows-web/workflows/apps/details/manage-app-form/template.hbs"
    }
  });
});