define("workflows-web/components/form-field/component", ["exports", "workflows-web/utilities/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['hf-form-field'],
    dropdownClass: 'hf-mod-push-dropdown-up',
    classNameBindings: ['isHorizontal:hf-u-vertically-centered-container', 'field.isPhone:hf-mod-phone', 'field.isEditor:hf-mod-editor'],
    attributeBindings: ['dataTestId:data-test-id'],
    field: null,
    editorId: 'editorLight',
    // Only for html editor input
    isHorizontal: false,
    selectClass: '',
    enableEditorAttachments: true,
    enableEditorDropZone: true,
    testIdPrefix: 'form-field-',
    inputClass: '',
    labelClass: '',
    labelPartial: '',
    autoFocus: false,
    usePopOverNewTooltipForHelpText: false,
    // jsTestClass is needed for acceptance tests of dropdowns(power select)
    jsTestClass: null,
    hideFieldLabel: Ember.computed('field.{label,labelPartial,isCheckBox}', {
      get: function get() {
        return Ember.isBlank(this.get('field.label')) && Ember.isBlank(this.get('field.labelPartial')) || this.get('field.isCheckBox');
      }
    }),
    dataTestId: Ember.computed('field.key', {
      get: function get() {
        var _this$field;
        return (_this$field = this.field) !== null && _this$field !== void 0 && _this$field.key ? "hf-form-field-".concat((0, _string.convertValueToTestID)(this.field.key)) : 'hf-form-field';
      }
    }),
    onFieldChange: Ember.observer('field.value', function () {
      if (this.onValueChange) {
        this.onValueChange(this.get('field.value'));
      }
    }),
    actions: {
      choiceChanged: function choiceChanged(newChoice, oldChoice) {
        if (this.onChoiceChange) {
          this.onChoiceChange(newChoice, oldChoice, this.get('field'));
        }
      },
      onEnter: function onEnter() {
        if (this.onEnter) {
          this.onEnter();
        }
      },
      onChange: function onChange(value) {
        if (this.onChange) {
          this.onChange(value);
        }
      },
      updateCondition: function updateCondition(condition) {
        if (this.updateCondition) {
          this.updateCondition(condition);
        }
      },
      removeCondition: function removeCondition(condition) {
        if (this.removeCondition) {
          this.removeCondition(condition);
        }
      },
      toggleRelativeDate: function toggleRelativeDate() {
        var field = this.get('field');
        field.toggleProperty('isRelative');
        field.set('value', null);
      },
      clearField: function clearField() {
        if (this.clearField) {
          this.clearField();
        }
      },
      onOpen: function onOpen() {
        if (this.onOpen) {
          this.onOpen();
        }
      },
      onClose: function onClose() {
        if (this.onClose) {
          this.onClose();
        }
      }
    }
  });
});