define("workflows-web/helpers/v1-contact-group-url", ["exports", "workflows-web/helpers/v1-link", "workflows-web/url-constants"], function (_exports, _v1Link, _urlConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    hostService: Ember.inject.service('host'),
    compute: function compute(params) {
      return (0, _v1Link.getV1URL)(this.get('hostService.host'), "".concat(_urlConstants.CONTACT_GROUP_URL).concat(params));
    }
  });
});