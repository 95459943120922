define("workflows-web/workflows/details/edit/actions/details/code-editor-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    codeMirror: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var codeMirrorInstance = this.get('codeMirror')._instances[this.$().find('textarea').attr('id')];
      this.set('codeMirrorInstance', codeMirrorInstance);
    },
    field: Ember.computed('form.model', 'key', {
      get: function get() {
        return this.form.model.get(this.key);
      }
    }),
    fieldChoices: Ember.computed.reads('form.model.url.choices'),
    actions: {
      insertTags: function insertTags(value, choiceValueProperty) {
        var codeMirrorInstance = this.codeMirrorInstance;
        codeMirrorInstance.replaceSelection(value[choiceValueProperty], 'end');
      }
    }
  });
});