define("workflows-web/helpers/format-relative-time", ["exports", "ember-intl/helpers/format-relative", "workflows-web/constants", "workflows-web/utilities/time"], function (_exports, _formatRelative, _constants, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _formatRelative.default.extend({
    intlService: Ember.inject.service('intl'),
    compute: function compute(value) {
      var modifiedHash = {
        interval: _constants.FORMAT_RELATIVE_RECOMPUTE_INTERVAL
      };
      this._super(value, modifiedHash); // To compute it for every interval period
      return (0, _time.getCustomRelativeTime)(value[0], this.get('intlService'));
    }
  });
});