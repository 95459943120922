define("workflows-web/components/form-input/toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gV4dNUxA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[15,0,[31,[\"hf-toggle_button \",[30,[36,1],[[35,2],\"hf-is-checked\"],null],\" \",[30,[36,1],[[35,0],\"hf-u-disabled\"],null]]]],[15,\"data-test-id\",[34,3]],[12],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"type\",\"class\",\"checked\",\"change\"],[\"checkbox\",\"hf-toggle_checkbox\",[35,2],[30,[36,4],[[32,0],\"onChange\"],[[\"value\"],[\"target.checked\"]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isDisabled\",\"if\",\"isChecked\",\"testId\",\"action\",\"input\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-input/toggle/template.hbs"
    }
  });
});