define("workflows-web/workflows/apps/details/route", ["exports", "workflows-web/utilities/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var _default = _exports.default = Ember.Route.extend({
    requestService: Ember.inject.service('request'),
    toastMessageService: Ember.inject.service('toast-message'),
    accountInfoService: Ember.inject.service('account-info'),
    appsInfo: Ember.computed.reads('model'),
    model: function model(params) {
      var _appsInfo$apps;
      var appsInfo = this.modelFor('workflows.apps');
      var appUnavailableInPlan = appsInfo === null || appsInfo === void 0 || (_appsInfo$apps = appsInfo.apps) === null || _appsInfo$apps === void 0 ? void 0 : _appsInfo$apps.find(function (app) {
        return app.name === params.name && !app.isInstalled && (!app.availableInPlan || !app.availableToInstall);
      });
      // For self served users, if app is not available in plan or not available to install, do not invoke the details API call, it would return 403
      if (this.accountInfoService.isUserSelfServed && appUnavailableInPlan) {
        return Ember.Object.create(_objectSpread(_objectSpread({}, appUnavailableInPlan), {}, {
          application: params.name
        }));
      } else {
        var url = "integrations/".concat(params.name, "/");
        return this.requestService.get(url).then(function (app) {
          return (0, _object.camelizeObject)(app);
        });
      }
    },
    afterModel: function afterModel(model, transition) {
      if (transition.to && transition.to.queryParams) {
        var queryParams = transition.to.queryParams;
        if (queryParams.error) {
          model.error = queryParams.error;
        }
        if (queryParams.success && queryParams.success === 'true') {
          this.toastMessageService.showMessage({
            type: 'success',
            text: 'apps.save-settings-success-message'
          });
        }
      }
      transition.pageTitleInfo = {
        name: model.application
      };
    }
  });
});