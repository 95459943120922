define("workflows-web/components/data-table-new/time-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oULUldvn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-tooltipped hf-tooltipped-left\"],[15,\"aria-label\",[30,[36,1],[[35,0]],[[\"format\"],[\"absolute\"]]]],[12],[2,\"\\n  \"],[1,[30,[36,1],[[35,0]],[[\"format\",\"isTimeInput\"],[\"default\",true]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"format-date\"]}",
    "meta": {
      "moduleName": "workflows-web/components/data-table-new/time-cell/template.hbs"
    }
  });
});