define("workflows-web/workflows/library/list/controller", ["exports", "workflows-web/mixins/table"], function (_exports, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WORKFLOW_LIBRARY_LIST_PAGE_COLUMNS = [{
    label: 'Name',
    valuePath: 'name',
    sortable: true,
    ascending: true,
    cellClassNames: 'hf-mod-can-wrap',
    width: '350px'
  }, {
    label: 'Benefits',
    valuePath: 'benefits',
    cellClassNames: 'hf-mod-can-wrap',
    width: '250px'
  }, {
    label: 'Products',
    valuePath: 'products',
    cellClassNames: 'hf-mod-can-wrap'
  }, {
    label: 'Domains',
    valuePath: 'domains',
    cellClassNames: 'hf-mod-can-wrap'
  }];
  var _default = _exports.default = Ember.Controller.extend(_table.default, {
    router: Ember.inject.service(),
    workflowsLibraryController: Ember.inject.controller('workflows.library'),
    tableModelKey: 'workflowLibrary',
    tableHeight: '80vh',
    tableId: 'workflows-library',
    columns: Ember.computed('', {
      get: function get() {
        return WORKFLOW_LIBRARY_LIST_PAGE_COLUMNS.slice();
      }
    }),
    pageInfo: null,
    isPaginationNeeded: Ember.computed.gt('pageInfo.count', 0),
    isLoading: Ember.computed.reads('workflowsLibraryController.isLoading'),
    actions: {
      goToWorkflowLibraryDetails: function goToWorkflowLibraryDetails(workflowLibrary) {
        this.transitionToRoute('workflows.library.details', workflowLibrary);
      },
      changePage: function changePage(offset) {
        this.workflowsLibraryController.send('changeListPage', offset);
      }
    }
  });
});