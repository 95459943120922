define("workflows-web/workflows/details/edit/actions/details/request-approval/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TsRnkSBV",
    "block": "{\"symbols\":[\"@form\"],\"statements\":[[10,\"div\"],[14,0,\"hf-workflows_form-component\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"canShowPlatformsField\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"field\",\"onChange\"],[[32,1,[\"model\",\"platforms\"]],[30,[36,0],[[32,0],[32,0,[\"onPlatFormChange\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,1],null,[[\"field\"],[[32,1,[\"model\",\"approvers\"]]]]]],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"field\"],[[32,1,[\"model\",\"approvalRequiredFrom\"]]]]]],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"field\"],[[32,1,[\"model\",\"subject\"]]]]]],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"form\",\"key\"],[[32,1],\"message\"]]]],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"field\"],[[32,1,[\"model\",\"attachments\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"form-field\",\"if\",\"workflows/details/edit/actions/details/message-field\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/request-approval/template.hbs"
    }
  });
});