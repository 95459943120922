define("workflows-web/workflows/mixins/azure-active-directory/actions", ["exports", "workflows-web/utilities/transform-data"], function (_exports, _transformData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    requestService: Ember.inject.service("request"),
    intlService: Ember.inject.service("intl"),
    formsService: Ember.inject.service("forms"),
    getAzureActiveDirectoryActions: function getAzureActiveDirectoryActions() {
      var _this = this;
      var actionClass = Ember.Object.extend({
        name: "",
        apiUrl: "",
        rawFields: null
      });
      var workflowActionChoices = Ember.A();
      var getUserFields = actionClass.create({
        stepType: "get_user_info",
        application: "azure-active-directory",
        color: "#0062AD",
        component: "workflows/details/edit/actions/details/azure-active-directory/get-or-delete-user",
        rawFields: [Ember.Object.create({
          label: "Tenant",
          key: "tenant",
          isDynamicField: true,
          isMandatory: true,
          step: 1,
          isPrimary: true,
          groupName: "AzureAD Tenants",
          entity: "tenants",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "User",
          key: "user",
          isDynamicField: true,
          isMandatory: true,
          step: 2,
          groupName: "AzureAD Users",
          entity: "users",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "Additional Filters",
          key: "additionalFilters",
          isDynamicField: true,
          isMandatory: false,
          step: 2,
          labelHelpText: true,
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextPartial: 'workflows/details/edit/actions/details/azure-active-directory/label-help-text/additional-filters'
        })]
      });
      var createUserFields = actionClass.create({
        stepType: "get_or_create_user",
        application: "azure-active-directory",
        color: "#0078D4",
        component: "workflows/details/edit/actions/details/azure-active-directory/create-or-update-user",
        rawFields: [Ember.Object.create({
          label: "Tenant",
          key: "tenant",
          isDynamicField: true,
          isMandatory: true,
          step: 1,
          isPrimary: true,
          groupName: "AzureAD Tenants",
          entity: "tenants",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "User Principal Name",
          key: "userPrincipalName",
          isDynamicField: true,
          isMandatory: true,
          step: 2
        }), Ember.Object.create({
          label: "Email",
          key: "mail",
          isDynamicField: true,
          isMandatory: true,
          step: 2
        }), Ember.Object.create({
          label: "Display Name",
          key: "displayName",
          isDynamicField: true,
          isMandatory: true,
          step: 2
        }), Ember.Object.create({
          label: "Mail Nick Name",
          key: "mailNickName",
          isDynamicField: true,
          isMandatory: true,
          step: 2
        }), Ember.Object.create({
          label: "Account Enabled",
          key: "accountEnabled",
          isToggle: true,
          isMandatory: true,
          defaultValue: true,
          includeAlwaysInPayload: true
        })]
      });
      var addMemberToGroupFields = actionClass.create({
        stepType: "add_member_to_group",
        application: "azure-active-directory",
        color: "#0062AD",
        component: "workflows/details/edit/actions/details/azure-active-directory/add-or-remove-member-to-group",
        rawFields: [Ember.Object.create({
          label: "Tenant",
          key: "tenant",
          isDynamicField: true,
          isMandatory: true,
          step: 1,
          isPrimary: true,
          groupName: "AzureAD Tenants",
          entity: "tenants",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "Group",
          key: "group",
          isDynamicField: true,
          isMandatory: true,
          step: 2,
          groupName: "AzureAD Groups",
          entity: "groups",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "User",
          key: "user",
          isDynamicField: true,
          isMandatory: true,
          step: 3,
          groupName: "AzureAD Users",
          entity: "users",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "Additional Filters",
          key: "additionalFilters",
          isDynamicField: true,
          isMandatory: false,
          step: 3,
          labelHelpText: true,
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextPartial: 'workflows/details/edit/actions/details/azure-active-directory/label-help-text/additional-filters'
        })]
      });
      var removeMemberFromGroupFields = actionClass.create({
        stepType: "remove_member_from_group",
        application: "azure-active-directory",
        color: "#0062AD",
        component: "workflows/details/edit/actions/details/azure-active-directory/add-or-remove-member-to-group",
        rawFields: [Ember.Object.create({
          label: "Tenant",
          key: "tenant",
          isDynamicField: true,
          isMandatory: true,
          step: 1,
          isPrimary: true,
          groupName: "AzureAD Tenants",
          entity: "tenants",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "Group",
          key: "group",
          isDynamicField: true,
          isMandatory: true,
          step: 2,
          groupName: "AzureAD Groups",
          entity: "groups",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "User",
          key: "user",
          isDynamicField: true,
          isMandatory: true,
          step: 3,
          groupName: "AzureAD Users",
          entity: "users",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "Additional Filters",
          key: "additionalFilters",
          isDynamicField: true,
          isMandatory: false,
          step: 3,
          labelHelpText: true,
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextPartial: 'workflows/details/edit/actions/details/azure-active-directory/label-help-text/additional-filters'
        })]
      });
      var deleteUserFields = actionClass.create({
        stepType: "delete_user",
        application: "azure-active-directory",
        color: "#0062AD",
        component: "workflows/details/edit/actions/details/azure-active-directory/get-or-delete-user",
        rawFields: [Ember.Object.create({
          label: "Tenant",
          key: "tenant",
          isDynamicField: true,
          isMandatory: true,
          step: 1,
          isPrimary: true,
          groupName: "AzureAD Tenants",
          entity: "tenants",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "User",
          key: "user",
          isDynamicField: true,
          isMandatory: true,
          step: 2,
          groupName: "AzureAD Users",
          entity: "users",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "Additional Filters",
          key: "additionalFilters",
          isDynamicField: true,
          isMandatory: false,
          step: 2,
          labelHelpText: true,
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextPartial: "workflows/details/edit/actions/details/azure-active-directory/label-help-text/additional-filters"
        })]
      });
      var disableUserFields = actionClass.create({
        stepType: "disable_user",
        application: "azure-active-directory",
        color: "#1172BD",
        component: "workflows/details/edit/actions/details/azure-active-directory/get-or-delete-user",
        rawFields: [Ember.Object.create({
          label: "Tenant",
          key: "tenant",
          isDynamicField: true,
          isMandatory: true,
          step: 1,
          isPrimary: true,
          groupName: "AzureAD Tenants",
          entity: "tenants",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "User",
          key: "user",
          isDynamicField: true,
          isMandatory: true,
          step: 2,
          groupName: "AzureAD Users",
          entity: "users",
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: "Additional Filters",
          key: "additionalFilters",
          isDynamicField: true,
          isMandatory: false,
          step: 2,
          labelHelpText: true,
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextPartial: "workflows/details/edit/actions/details/azure-active-directory/label-help-text/additional-filters"
        })]
      });
      workflowActionChoices.addObjects([getUserFields, createUserFields, addMemberToGroupFields, removeMemberFromGroupFields, deleteUserFields, disableUserFields]);
      workflowActionChoices.forEach(function (action) {
        action.displayName = _this.intlService.findTranslationByKey(action.stepType);
        action.eventType = "action";
      });
      return workflowActionChoices;
    }
  });
});