define("workflows-web/initializers/injectors/logger-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.create({
    name: 'inject-logger-service',
    initialize: function initialize(application) {
      application.inject('controller', 'loggerService', 'service:logger');
      application.inject('component', 'loggerService', 'service:logger');
    }
  });
});