define("workflows-web/components/empty-state-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isLeftMarginRequired: false,
    emptyStateMessage: null,
    emptyStateHeader: null,
    showEmptyStateActionButton: false,
    emptyStateActionButtonText: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('dummyArray', [1, 2, 3, 4]);
    },
    actions: {
      onClick: function onClick() {
        if (this.emptyStateAction) {
          this.emptyStateAction();
        }
      }
    }
  });
});