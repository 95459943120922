define("workflows-web/workflows/dashboard-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Qextsf58",
    "block": "{\"symbols\":[],\"statements\":[[19,\"templates/loading\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[]}",
    "meta": {
      "moduleName": "workflows-web/workflows/dashboard-loading/template.hbs"
    }
  });
});