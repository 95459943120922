define("workflows-web/workflows/mixins/azure-devops/actions", ["exports", "workflows-web/workflows/details/edit/utilities"], function (_exports, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    requestService: Ember.inject.service("request"),
    intlService: Ember.inject.service("intl"),
    accountInfoService: Ember.inject.service("account-info"),
    getAzureDevopsActions: function getAzureDevopsActions() {
      var _this = this;
      var actionClass = Ember.Object.extend({
        name: "",
        apiUrl: "",
        rawFields: null
      });
      var workflowActionChoices = Ember.A();
      var createWorkItem = actionClass.create({
        stepType: "create_work_item",
        application: "azure-devops",
        color: "#581ea5",
        component: "workflows/details/edit/actions/details/azure-devops/create-update-work-item",
        rawFields: [Ember.Object.create({
          label: "Project",
          key: "project",
          entity: "projects",
          groupName: "Azure Devops Projects",
          isDynamicField: true,
          isMandatory: true,
          step: 1,
          isPrimary: true
        }), Ember.Object.create({
          label: "Work Item Type",
          key: "workItemType",
          entity: "work-item-types",
          groupName: "Azure Devops Work Item Types",
          isDynamicField: true,
          isMandatory: true,
          step: 2
        }), Ember.Object.create({
          key: "extraFields",
          isArray: true,
          step: 3,
          childFieldMetaInfo: Ember.Object.create({
            key: "extraCreateWorkItemFields",
            isObject: true,
            primaryObjectKey: "key",
            secondaryObjectKey: "value",
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              isDynamicField: true,
              isMandatory: true,
              step: 3
            }), Ember.Object.create({
              isDynamicField: true,
              isMandatory: true,
              step: 3
            })])
          })
        }), Ember.Object.create({
          label: "Attachments",
          key: "attachments",
          isDynamicField: true,
          transformValue: _utilities.generateAttachmentsPayload,
          step: 4
        })]
      });
      workflowActionChoices.addObjects([createWorkItem]);
      workflowActionChoices.forEach(function (action) {
        action.displayName = _this.intlService.findTranslationByKey(action.stepType);
        action.eventType = "action";
      });
      return workflowActionChoices;
    }
  });
});