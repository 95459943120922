define("workflows-web/components/data-table-new/absolute-time-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fMMHdfF2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-u-text-overflow\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[35,0],[30,[36,1],[[35,0]],[[\"format\"],[\"absolute\"]]],\"~\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"format-date\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/components/data-table-new/absolute-time-cell/template.hbs"
    }
  });
});