define("workflows-web/components/copy-to-clipboard", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    value: '',
    isCopied: false,
    attributeBindings: ['aria-label'],
    // TODO: Move this to template
    'aria-label': Ember.computed('isCopied', {
      get: function get() {
        if (this.get('isCopied')) {
          return 'Copied';
        }
        return 'Copy to clipboard';
      }
    }),
    click: function click(event) {
      var value = this.get('value');
      event.preventDefault();
      event.stopPropagation();
      if (value) {
        this.set('isCopied', this.copyText(value));
      }
    },
    // Creates a fake textarea element, sets its value, selects it and executes copy operation
    copyText: function copyText(value) {
      var succeeded;
      var self = this;
      var fakeElement = (0, _jquery.default)('<textarea>');
      fakeElement.css({
        position: 'absolute',
        left: '-9999px'
      }).val(value);
      (0, _jquery.default)('body').append(fakeElement);
      fakeElement[0].select();
      try {
        succeeded = document.execCommand('copy');
      } catch (err) {
        self.get('loggerService').error('Error while copying text, check data.', err);
        succeeded = false;
      }
      fakeElement.remove();
      return succeeded;
    },
    mouseLeave: function mouseLeave() {
      if (this.get('isCopied')) {
        this.toggleProperty('isCopied');
      }
    }
  });
});