define("workflows-web/workflows/details/edit/actions/group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isCheckConditionGroup: Ember.computed.equal('parentAction.stepType', 'check_conditions'),
    orderedGroupSteps: Ember.computed('group.steps.[]', {
      // steps need to be sorted by order since we rely on order from BE
      get: function get() {
        var _this$group$steps;
        return (_this$group$steps = this.group.steps) === null || _this$group$steps === void 0 ? void 0 : _this$group$steps.sortBy('order');
      }
    }),
    actions: {
      addAction: function addAction(group) {
        var parentAction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        var newlyAddedAction = this.addAction(group, parentAction);
        if (group.steps) {
          group.steps.addObject(newlyAddedAction);
        } else {
          group.set('steps', Ember.A([newlyAddedAction]));
        }
      },
      onGroupClick: function onGroupClick(group) {
        this.onGroupClick(group.parentAction);
      }
    }
  });
});