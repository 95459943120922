define("workflows-web/workflows/apps/details/partials/salesforce/setup-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JjSfSEkB",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"app-description.salesforce.basic-info\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"hf-apps-details_right_sub-heading\"],[12],[1,[30,[36,0],[\"app-description.salesforce.pre-requisites.heading\"],null]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"hf-app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.salesforce.pre-requisites.content\"],null]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"hf-apps-details_right_sub-heading\"],[12],[1,[30,[36,0],[\"apps.how-to-setup\"],null]],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"hf-app-description_how-to-setup\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"hf-app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.salesforce.how-to-setup.create-connected-app\"],null]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"hf-app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.salesforce.how-to-setup.use-oauth\"],null]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"hf-app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.salesforce.how-to-setup.ensure-appropriate-permissions\"],null]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"hf-app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.salesforce.how-to-setup.find-consumer-key-consumer-secret\"],null]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"hf-app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.salesforce.how-to-setup.enter-credentials\"],null]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"hf-app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.salesforce.how-to-setup.save-and-activate\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/apps/details/partials/salesforce/setup-info/template.hbs"
    }
  });
});