define("workflows-web/workflows/details/edit/actions/salesforce/form-utilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateSalesforceObjectPayload = generateSalesforceObjectPayload;
  _exports.transformExtraFields = transformExtraFields;
  _exports.transformSalesforceObjectResults = transformSalesforceObjectResults;
  function transformExtraFields(field) {
    var modifiedValue = field.value.map(function (val) {
      return {
        key: {
          label: val.label,
          key: val.key
        },
        value: val.value
      };
    });
    return modifiedValue;
  }
  function generateSalesforceObjectPayload(field) {
    var payload = {};
    var sfObjects = field.choices.findBy('groupName', field.groupName);
    if (sfObjects) {
      var selectedSfObject = sfObjects.options.findBy('label', field.value);
      if (Ember.isPresent(selectedSfObject)) {
        payload.salesforceObject = selectedSfObject.name;
      } else {
        payload.salesforceObject = field.value;
      }
    } else {
      payload.salesforceObject = field.value;
    }
    return payload;
  }
  function transformSalesforceObjectResults(results) {
    return results.map(function (result) {
      return {
        'value': result['id'],
        'label': result['name']
      };
    });
  }
});