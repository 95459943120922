define("workflows-web/login/route", ["exports", "workflows-web/utilities/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ssoErrors = ['USER_ACCOUNT_NOT_FOUND', 'ACCOUNT_NOT_FOUND', 'INVALID_TOKEN'];
  var _default = _exports.default = Ember.Route.extend({
    accountInfoService: Ember.inject.service('accountInfo'),
    loginErrors: null,
    router: Ember.inject.service('router'),
    beforeModel: function beforeModel(transition) {
      this.transition = transition;
      if (Ember.isPresent(this.get('accountInfoService.accountInfo.user'))) {
        this.router.transitionTo('workflows.list', {
          queryParams: {
            status: 'all'
          }
        });
      }
    },
    setupController: function setupController(controller) {
      this._super(controller);
      if (Ember.isPresent(this.transition.to.queryParams)) {
        if (Ember.isPresent(this.transition.to.queryParams.error)) {
          var _errors$nonFieldError;
          var errors = (0, _object.camelizeObject)(JSON.parse(this.transition.to.queryParams.error));
          if ((errors === null || errors === void 0 || (_errors$nonFieldError = errors.nonFieldErrors) === null || _errors$nonFieldError === void 0 ? void 0 : _errors$nonFieldError.length) > 0) {
            var ssoError = errors.nonFieldErrors.find(function (error) {
              return error.code && ssoErrors.includes(error.code.toUpperCase());
            });
            if (ssoError) {
              // TODO: display error message once design is updated
              controller.ssoError = ssoError.code.toUpperCase();
            }
          }
        } else if (Ember.isPresent(this.transition.to.queryParams.signupSuccess) && Ember.isPresent(this.transition.to.queryParams.email)) {
          controller.signupSuccess = this.transition.to.queryParams.signupSuccess;
          controller.email = this.transition.to.queryParams.email;
          controller.state = this.transition.to.queryParams.state;
        }
      }
    }
  });
});