define("workflows-web/workflows/library/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    router: Ember.inject.service(),
    accountInfoService: Ember.inject.service('account-info'),
    beforeModel: function beforeModel() {
      if (!this.accountInfoService.isWorflowLibraryEnabled) {
        this.router.transitionTo('workflows.list');
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.fetchData.perform();
    }
  });
});