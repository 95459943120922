define("workflows-web/helpers/angle-bracket-strings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function (param) {
    return "<".concat(param, ">");
  });
});