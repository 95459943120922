define("workflows-web/components/dynamic-field/input-component/component", ["exports", "workflows-web/workflows/details/edit/utilities"], function (_exports, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _exports.default = Ember.Component.extend({
    codeMirror: Ember.inject.service(),
    uniqueIdentifier: null,
    clickElement: null,
    keydownElement: null,
    selectedTag: null,
    showPlaceholder: Ember.computed('value', {
      get: function get() {
        return Ember.isBlank(this.value);
      },
      set: function set(key, val) {
        return val;
      }
    }),
    placeholderText: Ember.computed('field.label', {
      get: function get() {
        var field = this.field;
        if (field.placeholderText) {
          return field.placeholderText;
        } else {
          if (field && field.label) {
            return "Select ".concat(field.label);
          }
        }
      }
    }),
    fieldValue: Ember.computed('value', {
      get: function get() {
        var value = this.value;
        if (Ember.isPresent(value)) {
          if (Ember.isArray(value)) {
            var isArrayOfObject = value.some(function (val) {
              return Ember.typeOf(val) === 'instance' || Ember.typeOf(val) === 'object';
            });
            if (isArrayOfObject) {
              if (typeof value[0][Object.keys(value[0])] === 'string') {
                return value[0][Object.keys(value[0])];
              } else {
                return JSON.stringify(value);
              }
            }
            return value.join(', ');
          } else if (typeof value !== 'string') {
            return JSON.stringify(value);
          }
        } else if (Ember.isArray(value)) {
          return value.join(', ');
        }
        return value;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        mode: this.get('mode') || 'htmlmixed',
        readOnly: this.get('readOnly') || false,
        keyMap: ['sublime'],
        theme: ['solarized']
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('setInputComponentReference')(this);
      var codeMirrorInstance = this.get('codeMirror')._instances[this.$().find('textarea').attr('id')];
      this.set('codeMirrorInstance', codeMirrorInstance);
      var value = this.value;
      if (Ember.isPresent(value)) {
        this.markText();
      }
    },
    focusIn: function focusIn() {
      this.set('showPlaceholder', false);
    },
    focusOut: function focusOut() {
      if (Ember.isBlank(this.fieldValue)) {
        this.set('showPlaceholder', true);
      }
      this.onFocusOut(this.fieldValue, this.selectedOption);
    },
    markText: function markText() {
      var _this = this;
      var codeMirrorInstance = this.get('codeMirrorInstance');
      var value = this.get('fieldValue');
      var positions = (0, _utilities.getTagPositions)(value);
      positions.forEach(function (pos) {
        var _this$actionChoices;
        var _pos$tag$split = pos.tag.split('__'),
          _pos$tag$split2 = _slicedToArray(_pos$tag$split, 1),
          key = _pos$tag$split2[0];
        var choices = _this.choices;
        if (!choices && ((_this$actionChoices = _this.actionChoices) === null || _this$actionChoices === void 0 ? void 0 : _this$actionChoices.length) > 0) {
          choices = _this.actionChoices;
        }
        var tag;
        if (choices) {
          var choice = choices.findBy('stepId', key);
          if (choice) {
            var selectedOption = choice.options.findBy('value', "{{".concat(pos.tag, "}}"));
            if (selectedOption) {
              _this.set('selectedOption', selectedOption);
              tag = (0, _utilities.createTag)(choice.prefix, selectedOption.label);
            }
          } else {
            // create tag for merge field even if key is not present in choices
            tag = (0, _utilities.createTagForInvalidStep)(pos.tag, _this.field);
          }
        } else {
          // create tag for merge field even if test data is unavailable
          tag = (0, _utilities.createTagForInvalidStep)(pos.tag, _this.field);
        }
        if (tag) {
          codeMirrorInstance.markText({
            line: pos.line,
            ch: pos.start
          }, {
            line: pos.line,
            ch: pos.stop
          }, {
            replacedWith: tag,
            handleMouseEvents: true
          });
        }
      });
      if (value === '') {
        this.set('selectedOption', null);
      }
    },
    updateField: function updateField(data) {
      var codemirror = this.get('codeMirrorInstance');
      var selectedTag = this.get('selectedTag');
      if (Ember.isPresent(selectedTag)) {
        codemirror.replaceRange(data, {
          line: selectedTag.line,
          ch: selectedTag.start
        }, {
          line: selectedTag.line,
          ch: selectedTag.stop
        });
        this.set('selectedTag', null);
      } else {
        codemirror.replaceSelection(data, 'end');
      }
    },
    actions: {
      toggleDropdown: function toggleDropdown() {
        this.get('toggleDropdown')();
      },
      onValueChange: function onValueChange(updatedValue) {
        this.set('value', updatedValue);
        this.markText();
        if (this.get('onValueChange')) {
          this.get('onValueChange')(updatedValue, this.selectedOption);
        }
        var codemirror = this.get('codeMirrorInstance');
        codemirror.refresh();
      }
    }
  });
});