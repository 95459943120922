define("workflows-web/manage/agents/form/form-model", ["exports", "workflows-web/form-models/base-form"], function (_exports, _baseForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseForm.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var dataSource = this.dataSource,
        rawFields = this.rawFields;
      this.setProperties({
        dataSource: dataSource,
        fields: this._createFieldModels(rawFields),
        errors: Ember.A()
      });
      if (!_.isEmpty(dataSource)) {
        this._copyFromDataSource();
      }
    },
    submit: function submit() {
      var _this = this;
      var fields = this.get('fields');
      var dataSource = this.get('dataSource');
      this.set('errors', Ember.A());
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.validate().then(function () {
          _this._copyToDataSource(fields, dataSource);
          resolve();
        }).catch(function () {
          _this.scrollToError();
          reject();
        });
      });
    },
    generatePayload: function generatePayload() {
      var formModel = this.get('model');
      var basicFieldKeys = this.get('basicFieldKeys');
      var payload = Ember.Object.create();
      basicFieldKeys.forEach(function (key) {
        if (formModel[key].get('isDirty') || formModel[key].includeAlwaysInPayload) {
          payload.set(key, formModel.get(key).getCleanValue());
        }
      });
      return payload;
    }
  });
});