define("workflows-web/workflows/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Je61tIoY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"hf-list-body hf-mod-workflows-list-body\"],[12],[2,\"\\n    \"],[8,\"react-components/workflow-list-wrapper\",[],[[\"@classname\",\"@cellEmptyValue\",\"@rowAction\",\"@changeSort\",\"@canPersistSort\",\"@persistSort\",\"@data\",\"@isLoading\",\"@sortOrder\",\"@sortField\",\"@emptyStateActionButtonText\",\"@emptyStateAction\",\"@emptyStateSvg\",\"@emptyStateHeader\",\"@changePage\",\"@pageInfo\",\"@filter\",\"@isPaginationNeeded\",\"@goToCreateWorkflow\",\"@validateDescription\",\"@createDescription\",\"@redirectToEditWorkflow\",\"@searchText\",\"@isAiWorkflowBuilderEnabled\"],[\"hf-workflow-list-table\",\"~\",[30,[36,0],[[32,0],\"goToWorkflowDetails\"],null],[30,[36,0],[[32,0],\"changeSort\"],null],[32,0,[\"canPersistSort\"]],[30,[36,0],[[32,0],\"persistSort\"],null],[32,0,[\"workflows\"]],[34,1,[\"isRunning\"]],[32,0,[\"sortOrder\"]],[32,0,[\"sortField\"]],\"Create Workflow\",[30,[36,0],[[32,0],\"createWorkflow\"],null],\"/assets/svgs/react/folder.svg\",\"Start Creating Workflows\",[30,[36,0],[[32,0],\"changePage\"],null],[34,2],[32,0,[\"status\"]],[32,0,[\"isPaginationNeeded\"]],[30,[36,0],[[32,0],\"createWorkflow\"],null],[30,[36,0],[[32,0],\"validateDescription\"],null],[30,[36,0],[[32,0],\"createDescription\"],null],[30,[36,0],[[32,0],\"redirectToEditWorkflow\"],null],[32,0,[\"searchText\"]],[32,0,[\"accountInfoService\",\"isAiWorkflowBuilderEnabled\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"fetchData\",\"pageInfo\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/list/template.hbs"
    }
  });
});