define("workflows-web/workflows/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    requestService: Ember.inject.service('request'),
    accountInfoService: Ember.inject.service('account-info'),
    model: function model() {
      if (!this.accountInfoService.hasSelfServedAccountExpired && !this.accountInfoService.isUserSelfServedStateUnknown) {
        return this.requestService.getWorkflowsCount();
      }
    }
  });
});