define("workflows-web/workflows/details/edit/actions/details/update-contact/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      onChange: function onChange(field) {
        field.set('isEditMode', false);
        var fieldModel = field.model;
        var entity = fieldModel[field.primaryObjectKey].value;
        var entityOptions = entity.options;
        var entityValueFieldChoices = fieldModel[field.secondaryObjectKey].choices;
        if (Ember.isPresent(entityOptions)) {
          entityValueFieldChoices.unshiftObject({
            groupName: "".concat(entity.name, " Choices"),
            options: entityOptions.map(function (option) {
              option.label = option.value;
              return option;
            })
          });
        }
      },
      addChildField: function addChildField(field) {
        var _this = this;
        field.addNewChildField(null);
        field.set('isEditMode', true);
        field.childFieldMetaInfo.childFields.forEach(function (field) {
          if (field.isDynamicField) {
            _this.get('form').setActionDetailsChoices(field);
          }
        });
      },
      removeChildField: function removeChildField(field, childField) {
        field.removeChildField(childField);
      },
      editField: function editField(field) {
        field.toggleProperty('isEditMode');
      }
    }
  });
});