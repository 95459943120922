define("workflows-web/workflows/apps/details/form-model", ["exports", "workflows-web/form-models/base-form"], function (_exports, _baseForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseForm.default.extend({
    dataService: Ember.inject.service('data'),
    basicFieldKeys: null,
    init: function init() {
      this._super.apply(this, arguments);
      var app = this.get('app');
      var rawFields = this.get('rawFields');
      var basicFieldKeys = this.get('basicFieldKeys');
      var dataSource = this.get('dataSource') || app.get('dynamicAttributes');
      this.set('dataSource', dataSource);
      this.set('basicFieldKeys', basicFieldKeys);
      this.set('fields', this._createFieldModels(rawFields));
      this.set('errors', Ember.A());
      if (!_.isEmpty(this.get('dataSource')) && (app.get('isInstalled') || this.get('copyFromDataSource'))) {
        this._copyFromDataSource();
      }
    },
    submit: function submit() {
      var _this = this;
      var fields = this.get('fields');
      var dataSource = this.get('dataSource');
      this.set('errors', Ember.A());
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.validate().then(function () {
          _this._copyToDataSource(fields, dataSource);
          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });
});