define("workflows-web/workflows/library/details/card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isItemsArray: Ember.computed('items', {
      get: function get() {
        return Ember.isArray(this.items);
      }
    })
  });
});