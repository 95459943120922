define("workflows-web/helpers/build-dependant-links", ["exports", "workflows-web/utilities/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _exports.default = Ember.Helper.extend({
    hostService: Ember.inject.service('host'),
    compute: function compute(params) {
      var _this = this;
      var _params = _slicedToArray(params, 4),
        dependants = _params[0],
        url = _params[1],
        appendId = _params[2],
        dataTestIdKey = _params[3];
      return dependants.map(function (dependant) {
        var dependantUrl = url;
        if (appendId) {
          dependantUrl = (0, _url.makeURL)(dependantUrl, dependant.id.toString());
        }
        var host = _this.get('hostService.host');
        host = (0, _url.makeURL)(host, 'staff');
        dependantUrl = (0, _url.makeURL)(host, dependantUrl);
        return "<a target=\"_blank\" href=\"".concat(dependantUrl, "\" class=\"hf-u-link-color\" data-test-id=\"dependant-link-").concat(dataTestIdKey, "-").concat(dependant.id, "\">").concat(dependant.name, "</a>");
      });
    }
  });
});