define("workflows-web/helpers/format-date-time", ["exports", "workflows-web/utilities/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDateTime = formatDateTime;
  function formatDateTime(params) {
    return (0, _date.getFormattedDateTime)(params[0]);
  }
  var _default = _exports.default = Ember.Helper.helper(formatDateTime);
});