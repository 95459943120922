define("workflows-web/components/pop-over/component", ["exports", "jquery", "workflows-web/utilities/run-loop"], function (_exports, _jquery, _runLoop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['hf-pop-over-container'],
    isMini: false,
    isOpen: false,
    openOnHover: false,
    onHoverDelay: 0,
    actionOnClose: null,
    actionOnOpen: null,
    hoverInProgress: false,
    stopClickPropagation: false,
    isAdjustingPopOver: false,
    isNewTooltip: false,
    canShowPopOverOverlay: false,
    mouseEnter: function mouseEnter() {
      var _this = this;
      if (!this.get('isDestroyed')) {
        if (this.get('openOnHover')) {
          if (this.get('onHoverDelay')) {
            this.set('hoverInProgress', true);
            (0, _runLoop.runLater)(this, function () {
              if (_this.get('hoverInProgress')) {
                _this.send('openPopOver');
                _this.set('hoverInProgress', false);
              }
            }, this.get('onHoverDelay'));
          } else {
            this.send('openPopOver');
          }
        }
      }
    },
    mouseLeave: function mouseLeave(event) {
      if (this.get('exemptedSelector')) {
        if ((0, _jquery.default)(event.relatedTarget).attr('data-pop-over-selector') == this.get('exemptedSelector')) {
          return false;
        }
      }
      if (this.get('openOnHover')) {
        this.set('hoverInProgress', false);
        this.send('closePopOver');
      }
    },
    actions: {
      togglePopOver: function togglePopOver() {
        if (!this.get('isDestroyed')) {
          if (this.get('isOpen')) {
            this.send('closePopOver');
          } else {
            this.send('openPopOver');
          }
        }
      },
      openPopOver: function openPopOver() {
        this.set('isOpen', true);
        if (this.get('actionOnOpen')) {
          this.get('actionOnOpen')();
        }
      },
      closePopOver: function closePopOver(data) {
        var _this2 = this;
        if (this.get('actionOnClose')) {
          this.get('actionOnClose')(data);
        }
        var $popOver = this.get('isMini') ? this.$('.hf-mini-pop-over') : this.$('.hf-pop-over');
        $popOver.addClass('hf-slide-out-down');
        Ember.run.later(function () {
          if (!_this2.isDestroyed) {
            _this2.set('isOpen', false);
          }
        }, 200);
      }
    }
  });
});