define("workflows-web/workflows/mixins/bamboohr/triggers", ["exports", "workflows-web/workflows/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    intlService: Ember.inject.service('intl'),
    getBambooHRTriggers: function getBambooHRTriggers() {
      var _this = this;
      var triggerClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var bamboohrTriggerChoices = Ember.A();
      var newTimeOffRequestCreated = triggerClass.create({
        stepType: 'new_time_off_request_created',
        eventType: 'trigger',
        application: 'bamboohr',
        color: '#012c2b',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: false
      });
      var timeOffRequestStatusUpdate = triggerClass.create({
        stepType: 'time_off_request_status_update',
        eventType: 'trigger',
        application: 'bamboohr',
        color: '#494925',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: false
      });
      var newEmployeeStarted = triggerClass.create({
        stepType: 'new_employee_started',
        eventType: 'trigger',
        application: 'bamboohr',
        color: '#390738',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: false
      });
      bamboohrTriggerChoices.addObjects([newTimeOffRequestCreated, timeOffRequestStatusUpdate, newEmployeeStarted]);
      bamboohrTriggerChoices.forEach(function (trigger) {
        trigger.displayName = _this.intlService.findTranslationByKey(trigger.stepType);
      });
      return bamboohrTriggerChoices;
    }
  });
});