define("workflows-web/email-verification/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    requestService: Ember.inject.service('request'),
    token: null,
    successMessage: null,
    error: null,
    isValidatingToken: null,
    isValidToken: false,
    validateToken: function validateToken() {
      var _this = this;
      var token = this.get('token');
      this.setProperties({
        isValidToken: false,
        isValidatingToken: true,
        email: null,
        error: null
      });
      return this.get('requestService').validateEmailVerificationToken(token).then(function (response) {
        _this.setProperties({
          'isValidToken': true,
          'successMessage': response.result[0]
        });
      }).catch(function (errors) {
        _this.setProperties({
          error: errors.result[0],
          isValidToken: false
        });
      }).finally(function () {
        _this.set('isValidatingToken', false);
      });
    }
  });
});