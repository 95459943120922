define("workflows-web/components/auto-complete/local/multiple/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var _default = _exports.default = Ember.Component.extend({
    closeDropdownOnMouseLeave: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    mouseLeave: function mouseLeave() {
      if (this.get('closeDropdownOnMouseLeave')) {
        var select = this.get('select');
        if (Ember.isPresent(select)) {
          this.get('select').actions.close();
          this.$('input').focus();
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      if (this.get('autofocus')) {
        Ember.run.next(function () {
          _this.$('input').focus();
        });
      }
      if (Ember.isBlank(this.get('selected'))) {
        this.set('selected', Ember.A());
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this2 = this;
      if (this.get('autofocus')) {
        Ember.run.next(function () {
          _this2.$('input').focus();
        });
      }
    },
    createCustomChoice: function createCustomChoice(select) {
      var _this3 = this;
      if (Ember.isPresent(select.searchText)) {
        var searchTextArray = select.searchText.split(',');
        searchTextArray = searchTextArray.filter(function (searchText) {
          return !Ember.isBlank(searchText.trim());
        });
        searchTextArray.forEach(function (searchText, index) {
          var selectedArray = _this3.get('selected');
          var searchTextObject = Ember.Object.create(_defineProperty(_defineProperty({}, _this3.get('choiceValueKey'), searchText), _this3.get('choiceLabelKey'), searchText));
          if (!selectedArray.includes(searchText)) {
            if (index < searchTextArray.length - 1) {
              _this3.get('selected').pushObject(searchTextObject);
            } else {
              // clearing the power-select input value which doesn't happen by default if we don't use select.actions.choose to set selected
              select.actions.choose(searchTextObject);
            }
          } else {
            Ember.run.later(function () {
              _this3.$('input').val('');
            }, 100);
          }
        });
      }
    },
    actions: {
      onChange: function onChange(result, select) {
        if (this.get('onChange')) {
          this.get('onChange')(result);
        }
        this.set('selected', result);
        select.actions.search();
        this.$('input').val('');
        this.$('input').focus();
      },
      onFocus: function onFocus(select) {
        this.set('select', select);
      },
      onClose: function onClose(select) {
        if (this.get('isAddNew') && !select.highlighted) {
          // Allowing creation of custom choices only when isAddNew is true and there are no suggestions for the typed text
          this.createCustomChoice(select);
        }
        if (this.get('onClose')) {
          this.get('onClose')();
        }
      },
      onKeyDown: function onKeyDown(select, e) {
        if (this.get('isAddNew') && (e.keyCode === 13 || e.keyCode === 188) && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          this.createCustomChoice(select);
        } else if (e.keyCode === 188 && !select.isOpen || e.keyCode === 188 && !Ember.isBlank(select.searchText.split(',')) || Ember.isPresent(this.get('characterLimit')) && select.searchText.length >= this.get('characterLimit') && !(e.keyCode === 8 || e.keyCode === 46)) {
          // 8 for backspace and 46 for delete. Should allow backspace and delete when text length is greater than or equal to character limit
          e.preventDefault();
        }
      }
    }
  });
});