define("workflows-web/workflows/details/edit/actions/details/salesforce/create-update-entity/component", ["exports", "workflows-web/workflows/details/edit/actions/details/mixin/primary-field-empty-check"], function (_exports, _primaryFieldEmptyCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_primaryFieldEmptyCheck.default, {
    action: null,
    accountInfoService: Ember.inject.service('account-info'),
    isCreate: Ember.computed('action.stepType', {
      get: function get() {
        return this.get('action.stepType').indexOf('create_') !== -1;
      }
    }),
    isUpdate: Ember.computed('action.stepType', {
      get: function get() {
        return this.get('action.stepType').indexOf('update_') !== -1;
      }
    }),
    actions: {
      onChange: function onChange(field) {
        field.set('isEditMode', false);
        var fieldModel = field.model;
        var entity = fieldModel[field.primaryObjectKey].value;
        var entityOptions = entity.options;
        var entityValueFieldChoices = fieldModel[field.secondaryObjectKey].choices;
        if (Ember.isPresent(entityOptions)) {
          entityValueFieldChoices.unshiftObject({
            groupName: "".concat(entity.label, " Choices"),
            options: entityOptions.map(function (option) {
              option.label = option.value;
              return option;
            })
          });
        }
      },
      addChildField: function addChildField(field) {
        var _this = this;
        field.addNewChildField(null);
        field.set('isEditMode', true);
        field.childFieldMetaInfo.childFields.forEach(function (field) {
          if (field.isDynamicField) {
            _this.get('form').setActionDetailsChoices(field);
          }
        });
      },
      removeChildField: function removeChildField(field, childField) {
        field.removeChildField(childField);
      },
      editField: function editField(field) {
        field.toggleProperty('isEditMode');
      },
      updateFieldLookupUrl: function updateFieldLookupUrl(value) {
        if (value) {
          var extraFields = this.form.fields.findBy('key', 'extraFields');
          var autocompleteField = extraFields.childFieldMetaInfo.childFieldsMetaInfo.findBy('key', 'key');
          var url = autocompleteField.lookupUrl;
          if (!url.includes('?object=')) {
            if (url.indexOf('?') >= 0) {
              autocompleteField.lookupUrl = "".concat(url, "&object=").concat(value);
            } else {
              autocompleteField.lookupUrl = "".concat(url, "?object=").concat(value);
            }
          }
        }
      }
    }
  });
});