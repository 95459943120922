define("workflows-web/components/pop-over/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vryJJaCl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7],[35,6]],null],[35,5]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"hf-mini-pop-over_close-icon hf-js-pop-over-close\"],[16,\"data-test-id\",[34,0]],[4,[38,1],[[32,0],[32,0,[\"closePopOver\"]]],[[\"bubbles\"],[false]]],[12],[1,[30,[36,2],[\"close-details\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"hf-pop-over_close-icon\"],[16,\"data-test-id\",[34,0]],[4,[38,1],[[32,0],[32,0,[\"togglePopOver\"]]],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[1,[30,[36,2],[\"close-icon\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"closeDataTestId\",\"action\",\"inline-svg\",\"canCloseOnOutsideClick\",\"unless\",\"isNewTooltip\",\"isTooltip\",\"isMini\",\"or\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/components/pop-over/content/template.hbs"
    }
  });
});