define("workflows-web/components/form-input/textarea/component", ["exports", "workflows-web/components/form-input/text/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    isResizable: true
  });
});