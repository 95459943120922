define("workflows-web/services/forms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    accountInfoService: Ember.inject.service('account-info'),
    forms: null,
    formChoices: Ember.computed('forms.[]', 'accountInfoService.isManageFormsEnabled', {
      get: function get() {
        var formChoices = Ember.A();
        this.forms.forEach(function (form) {
          formChoices.addObject(Ember.Object.create({
            label: form.name,
            value: form.id
          }));
        });
        if (this.accountInfoService.isManageFormsEnabled) formChoices.unshiftObject(Ember.Object.create({
          isNewForm: true
        }));
        return formChoices;
      }
    }),
    setForms: function setForms(forms) {
      this.set('forms', forms);
    }
  });
});