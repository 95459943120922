define("workflows-web/workflows/apps/details/partials/jira/setup-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CYaKjqYs",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"app-description.jira.basic-info\"],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/apps/details/partials/jira/setup-info/template.hbs"
    }
  });
});