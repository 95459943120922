define("workflows-web/workflows/history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7o4yQw2x",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],[[30,[36,3],null,[[\"class\"],[[30,[36,2],[\"hf-outlet-container\",[30,[36,1],[[35,0],\"hf-mod-details\"],null]],null]]]]],null]]],\"hasEval\":false,\"upvars\":[\"isDetails\",\"if\",\"concat-strings\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/history/template.hbs"
    }
  });
});