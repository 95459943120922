define("workflows-web/helpers/linkify-html", ["exports", "workflows-web/utilities/string", "workflows-web/utilities/html"], function (_exports, _string, _html) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compute = compute;
  _exports.default = void 0;
  function compute(params) {
    return (0, _string.getSafeHtml)((0, _html.getLinkifiedHtml)(params[0].toString()));
  }
  var _default = _exports.default = Ember.Helper.helper(compute);
});