define("workflows-web/components/auto-complete/remote/single/component", ["exports", "workflows-web/utilities/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    requestService: Ember.inject.service('request'),
    selected: null,
    url: null,
    options: null,
    allowClear: false,
    searchParam: null,
    placeholder: null,
    searchPlaceholder: null,
    dropdownClass: 'hf-mod-push-dropdown-up',
    isAddNew: false,
    autofocus: false,
    isAddSearchHelpOption: false,
    searchText: null,
    initiallyOpened: false,
    /* In the case of add related tickets item will be the current ticket and change contact will be the current raised by.This entity is needed
          to get the id of current ticket in the case of related ticket or current raised by in the case of change contact to exclude them
             from autocomplete suggestion results */
    item: null,
    itemsToBeExcluded: null,
    additionalParams: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('options', []);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      if (Ember.isBlank(this.get('itemsToBeExcluded'))) {
        this.set('itemsToBeExcluded', Ember.A());
      }
      if (this.get('autofocus')) {
        Ember.run.next(function () {
          _this.$('input').focus();
        });
      }
    },
    actions: {
      onOpen: function onOpen(select) {
        if (this.get('onOpen')) {
          this.get('onOpen')(select);
        }
      },
      onClose: function onClose() {
        if (this.get('onClose')) {
          this.get('onClose')();
        }
      },
      onChange: function onChange(result) {
        this.set('selected', result);
        if (this.get('onChange')) {
          this.get('onChange')(result);
        } else {
          this.set('selected', result);
        }
      },
      search: function search(term) {
        var _this2 = this;
        var url = this.get('url');
        this.set('searchText', term);
        var searchParam = this.get('searchParam');
        var additionalParams = this.get('additionalParams');
        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this2, _this2._performSearch, term, resolve, reject, url, searchParam, additionalParams, 600);
        });
        return promise;
      },
      createCustomChoice: function createCustomChoice(select, e) {
        var _this3 = this;
        if (select.isOpen) {
          if (e.keyCode === 27) {
            e.stopPropagation();
          }
        }
        if (e.keyCode === 13 && this.get('onEnter') && !(select.isOpen && select.highlighted)) {
          this.get('onEnter')(this.get('searchText'));
        }
        var searchText = select.searchText;
        if (this.get('isAddNew') && e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(searchText)) {
          Ember.run.next(function () {
            select.actions.choose(searchText);
            if (_this3.get('createCustomChoice')) {
              _this3.get('createCustomChoice')(searchText);
            }
          });
        }
      }
    },
    _performSearch: function _performSearch(term, resolve, reject, url, searchParam, additionalParams) {
      var _this4 = this;
      if (Ember.isBlank(term)) {
        return resolve([]);
      }
      this.get('requestService').autoCompleteSearch(searchParam, term, url, additionalParams).then(function (response) {
        var results = null;
        if (response.results) {
          results = response.results;
        } else {
          results = response;
        }
        if (Ember.isBlank(results)) {
          if (_this4.get('onEmptyResults')) {
            _this4.get('onEmptyResults')(term);
          }
        }
        if (_this4.get('isAddSearchHelpOption')) {
          if (Ember.isPresent(results)) {
            results.unshiftObject(Ember.Object.create({
              isSearchHelpOption: true
            }));
            _this4.set('searchText', term);
          }
        }
        if (Ember.isPresent(_this4.get('itemsToBeExcluded'))) {
          results = results.reject(function (result) {
            var itemsToBeExcluded = _this4.get('itemsToBeExcluded');
            return itemsToBeExcluded.includes(result.id.toString());
          });
        }
        resolve(results.map(_object.camelizeObject));
      }, reject);
    }
  });
});