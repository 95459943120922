define("workflows-web/utilities/text-width", ["exports", "workflows-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getWidth = getWidth;
  function getWidth(text) {
    var canvas = document.createElement('canvas');
    var canvasContext = canvas.getContext('2d');
    canvasContext.font = "".concat(_constants.BASE_FONT_SIZE, " ").concat(_constants.BASE_FONT_FAMILY);
    var _canvasContext$measur = canvasContext.measureText(text),
      width = _canvasContext$measur.width;
    return width;
  }
});