define("workflows-web/workflows/details/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('workflows.details');
    },
    afterModel: function afterModel(model, transition) {
      this.set('transition', transition);
      transition.pageTitleInfo = {
        name: model.name
      };
    }
  });
});