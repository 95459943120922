define("workflows-web/workflows/mixins/workflows/triggers", ["exports", "workflows-web/workflows/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    intlService: Ember.inject.service('intl'),
    getWorkflowTriggers: function getWorkflowTriggers() {
      var _this = this;
      var triggerClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var workflowTriggerChoices = Ember.A();
      var webhookTriggerFields = triggerClass.create({
        stepType: 'webhook',
        eventType: 'trigger',
        application: 'workflows',
        color: '#bf350c',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      workflowTriggerChoices.addObjects([webhookTriggerFields]);
      workflowTriggerChoices.forEach(function (trigger) {
        trigger.displayName = _this.intlService.findTranslationByKey(trigger.stepType);
      });
      return workflowTriggerChoices;
    }
  });
});