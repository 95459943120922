define("workflows-web/workflows/details/edit/actions/details/mixin/primary-field-empty-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    isPrimaryFieldEmpty: Ember.computed('form.isDirty', {
      get: function get() {
        var form = this.form;
        var isPrimaryFieldEmpty = false;
        if (form.isMultiplePrimaryKeysPresent) {
          var primaryFields = Ember.A();
          form.primaryFieldKeys.forEach(function (key) {
            primaryFields.addObject(form.model[key]);
          });
          isPrimaryFieldEmpty = !primaryFields.any(function (field) {
            return Ember.isPresent(field.value);
          });
        } else {
          var primaryField = form.model[form.primaryFieldKey];
          isPrimaryFieldEmpty = Ember.isBlank(primaryField.value);
        }
        return isPrimaryFieldEmpty;
      }
    })
  });
});