define("workflows-web/components/form/non-field-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZrVR19YP",
    "block": "{\"symbols\":[\"error\",\"@errors\"],\"statements\":[[6,[37,3],[[30,[36,2],[[32,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"ul\"],[14,0,\"hf-form-content_non-field-errors hf-js-form-content_non-field-errors\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"is-present\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form/non-field-errors/template.hbs"
    }
  });
});