define("workflows-web/workflows/history/list/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      this.set('transition', transition);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      var workflowId = this.transition.to.queryParams.workflowId;
      controller.fetchData.perform(workflowId);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          'workflowId': null,
          'status': ''
        });
        controller.set('eventDataFilterField.value', null);
        controller.eventDataFilterField.childFields.forEach(function (field) {
          field.set('value', null);
        });
      }
    }
  });
});