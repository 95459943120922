define("workflows-web/helpers/field-to-string", ["exports", "workflows-web/utilities/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    var choice = params[0];
    var choiceValue = choice[params[1]];
    return (0, _string.convertArrayToString)(choiceValue);
  });
});