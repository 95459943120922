define("workflows-web/form-field-models/files-field", ["exports", "workflows-web/form-field-models/base-field"], function (_exports, _baseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseField.default.extend({
    init: function init() {
      if (!this.get('value')) {
        this.set('value', Ember.A());
      }
      this._super.apply(this, arguments);
      this.set('_initialValue', this.get('value').slice());
    },
    updateInitialValue: function updateInitialValue() {
      this.set('_initialValue', this.get('value').slice());
    },
    reset: function reset() {
      this._super();
      this.set('value', this.get('_initialValue').slice());
    },
    isFiles: true,
    value: null,
    isDirty: Ember.computed('value.[]', '_initialValue', {
      get: function get() {
        return !_.isEqual(this.get('_initialValue'), this.get('value'));
      }
    }),
    validate: function validate() {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          var value = _this.get('value');
          _this.set('_cleanValue', Ember.isPresent(value) ? value : Ember.A());
          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });
});