define("workflows-web/workflows/details/edit/actions/shopify/form-utilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transformLineItemFields = transformLineItemFields;
  function transformLineItemFields(field) {
    var modifiedValue = field.value.map(function (val) {
      return {
        variantId: {
          title: val.title,
          id: val.variantId
        },
        quantity: val.quantity
      };
    });
    return modifiedValue;
  }
});