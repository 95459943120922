define("workflows-web/workflows/mixins/happyfox/contact-filter-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    requestService: Ember.inject.service('request'),
    intlService: Ember.inject.service('intl'),
    createContactFilterFields: function createContactFilterFields() {
      var intlService = this.get('intlService');
      var fieldsArray = Ember.A();
      var nameField = Ember.Object.create({
        name: 'name',
        type: 'dynamic',
        key: 'name',
        predicates: ['in', 'not_in'],
        actionOnOpen: this.fetchResults,
        onSearch: this.fetchResults,
        isLoading: this.fetchResults.isRunning,
        allowMultipleValues: true
      });
      var emailField = Ember.Object.create({
        name: 'email',
        type: 'dynamic',
        key: 'email',
        predicates: ['in', 'not_in'],
        actionOnOpen: this.fetchResults,
        onSearch: this.fetchResults,
        isLoading: this.fetchResults.isRunning,
        allowMultipleValues: true
      });
      var contactPhoneNumberField = Ember.Object.create({
        name: intlService.findTranslationByKey('contact-phone-number'),
        type: 'dynamic',
        key: 'contact_phone_number',
        predicates: ['in', 'not_in'],
        allowMultipleValues: true
      });
      var contactCustomFieldsField = Ember.Object.create({
        name: intlService.findTranslationByKey('contact-custom-fields'),
        type: 'dynamic',
        key: 'custom_fields',
        lookUpUrl: 'entity-autocomplete/happyfox/contact-custom-fields/'
      });
      fieldsArray.addObjects([nameField, emailField, contactPhoneNumberField, contactCustomFieldsField]);
      return fieldsArray;
    }
  });
});