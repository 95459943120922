define("workflows-web/mixins/validation", ["exports", "workflows-web/utilities/email-validation"], function (_exports, _emailValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    intlService: Ember.inject.service('intl'),
    checkIfMandatory: function checkIfMandatory() {
      if (Ember.isBlank(this.get('value')) && this.get('isMandatory')) {
        this.addError(this.get('intlService').findTranslationByKey('validation.mandatory-error-message'));
      }
    },
    checkPhoneNumber: function checkPhoneNumber() {
      var value = this.get('value');
      if (!isValidNumber(value)) {
        // Calls phoneformat.js function to check, which is based on Google's libphonenumber - https://github.com/albeebe/phoneformat.js
        this.addError(this.get('intlService').findTranslationByKey('validation.invalid-phone-message'));
        return false;
      }
      return true;
    },
    checkForCharacterLimit: function checkForCharacterLimit() {
      var characterLimit = this.get('characterLimit');
      var value = this.get('value');
      if (!Ember.isBlank(value)) {
        if (value.length > characterLimit) {
          this.addError(this.get('intlService').findTranslationByKeyWithParameters('validation.text-{max}-characters-limit', {
            max: characterLimit
          }));
        }
      }
    },
    checkForNumber: function checkForNumber() {
      var $input = this.$('input');
      var inputVal = $input.val();
      if (!this.$('input')[0].checkValidity()) {
        this.addError(this.get('intlService').findTranslationByKey('validation.invalid-number-message'));
      } else if (inputVal) {
        var splittedInputValue = inputVal.toString().split('.');
        var decimalLength = splittedInputValue[1] ? splittedInputValue[1].length : 0;
        if (decimalLength > 2) {
          this.addError(this.get('intlService').findTranslationByKey('validation.invalid-decimal-limit'));
        }
      }
    },
    checkForEmail: function checkForEmail() {
      var value = this.get('value');
      if (!Ember.isBlank(value)) {
        if (!(0, _emailValidation.validateEmail)(value)) {
          this.addError(this.get('intlService').findTranslationByKey('validation.invalid-email-message'));
        }
      }
    },
    checkForDate: function checkForDate() {
      var $input = this.$('input');
      if ($input) {
        var $inputVal = $input.val();
        // https://github.com/moment/moment/issues/1407
        if (!Ember.isBlank($inputVal) && !moment($inputVal).isValid()) {
          this.addError(this.get('intlService').findTranslationByKey('validation.invalid-date-message'));
        }
      }
    }
  });
});