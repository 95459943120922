define("workflows-web/components/code-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hFj7Y93V",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"options\",\"value\",\"valueUpdated\"],[[30,[36,4],null,[[\"lineNumbers\",\"lineWrapping\",\"mode\",\"readOnly\",\"smartIndent\",\"styleActiveLine\"],[true,true,[35,3],[35,2],true,true]]],[35,1],[30,[36,0],[[32,0],\"onCodeChange\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"code\",\"readOnly\",\"mode\",\"hash\",\"ivy-codemirror\"]}",
    "meta": {
      "moduleName": "workflows-web/components/code-editor/template.hbs"
    }
  });
});