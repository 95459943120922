define("workflows-web/workflows/apps/details/happyfox-chatbot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "c2Hop1sK",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[32,0,[\"isInstalled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"hf-manage-app-form_heading\"],[12],[1,[30,[36,1],[\"apps.connected-hf-chatbot-account\"],null]],[2,\": \"],[1,[35,2,[\"settings\",\"companyName\"]]],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[30,[36,3],[\"hf-app-manage-section_box hf-u-vertically-centered-container\"],null]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-app-logged-in-user-details\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"hf-app-logged-in-user-details_picture\"],[14,\"alt\",\"user picture\"],[14,\"src\",\"/assets/images/happyfox.png\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"hf-app-logged-in-user-details_right\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"hf-app-logged-in-user-details_right_name\"],[12],[2,\"\\n          \"],[1,[32,0,[\"app\",\"userInfo\",\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"hf-app-logged-in-user-details_right_email\"],[12],[1,[32,0,[\"app\",\"userInfo\",\"email\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-u-push-right\"],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"app\",\"isActive\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"a\"],[4,[38,0],[\"click\",[32,0,[\"toggleChangingHFChatBotForm\"]]],null],[12],[1,[30,[36,1],[\"change\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[19,\"workflows/apps/details/partials/base-form\",[]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"on\",\"t\",\"app\",\"concat-strings\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/apps/details/happyfox-chatbot/template.hbs"
    }
  });
});