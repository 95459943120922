define("workflows-web/form-field-models/color-field", ["exports", "workflows-web/form-field-models/base-field"], function (_exports, _baseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseField.default.extend({
    isColor: true,
    validate: function validate() {
      var _this = this;
      this._super().then(function () {
        _this.set('_cleanValue', _this.get('value'));
      });
    }
  });
});