define("workflows-web/components/form-field-input/time/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Bk9tgnq0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-form-field hf-mod-time\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"hf-u-flex-container \",[30,[36,1],[[35,0],\"hf-mod-error\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"field\",\"class\",\"onChoiceChange\",\"onClose\",\"testIdPrefix\"],[[35,2,[\"hourField\"]],\"hf-mod-time-component\",[30,[36,4],[[32,0],\"setDefaultMinute\"],null],[30,[36,4],[[32,0],\"validate\"],null],[30,[36,3],[[35,2,[\"key\"]],\"-\"],null]]]]],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"field\",\"class\",\"onClose\",\"testIdPrefix\"],[[35,2,[\"minuteField\"]],\"hf-mod-time-component\",[30,[36,4],[[32,0],\"validate\"],null],[30,[36,3],[[35,2,[\"key\"]],\"-\"],null]]]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-form-field-time_meridiem\"],[12],[2,\"\\n      \"],[11,\"div\"],[16,0,[31,[\"hf-form-field-time_meridiem_item hf-mod-am \",[30,[36,1],[[35,2,[\"isAM\"]],\"hf-mod-active\"],null]]]],[16,\"data-test-id\",[30,[36,3],[\"form-field-\",[35,2,[\"key\"]],\"-am\"],null]],[4,[38,4],[[32,0],\"setMeridiem\",\"AM\"],null],[12],[2,\"\\n        \"],[1,[30,[36,6],[\"am\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[11,\"div\"],[16,0,[31,[\"hf-form-field-time_meridiem_item hf-mod-pm \",[30,[36,1],[[35,2,[\"isPM\"]],\"hf-mod-active\"],null]]]],[16,\"data-test-id\",[30,[36,3],[\"form-field-\",[35,2,[\"key\"]],\"-pm\"],null]],[4,[38,4],[[32,0],\"setMeridiem\",\"PM\"],null],[12],[2,\"\\n        \"],[1,[30,[36,6],[\"pm\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[19,\"components/form-field-input/partials/error-message\",[]]],\"hasEval\":true,\"upvars\":[\"hasErrors\",\"if\",\"field\",\"concat\",\"action\",\"form-field\",\"t\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/time/template.hbs"
    }
  });
});