define("workflows-web/utilities/time", ["exports", "workflows-web/constants", "workflows-web/utilities/date", "workflows-web/utilities/string"], function (_exports, _constants, _date, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convert12HourTo24HourFormat = convert12HourTo24HourFormat;
  _exports.convert24HourTo12HourFormat = convert24HourTo12HourFormat;
  _exports.convertDateToMoment = convertDateToMoment;
  _exports.convertMomentToDateTime = convertMomentToDateTime;
  _exports.formatTime = formatTime;
  _exports.getAPIFormatTime = getAPIFormatTime;
  _exports.getAPIFormatTimeWithUtc = getAPIFormatTimeWithUtc;
  _exports.getCurrentDateTime = getCurrentDateTime;
  _exports.getCustomRelativeTime = getCustomRelativeTime;
  _exports.getEndOfDay = getEndOfDay;
  _exports.getRelativeTime = getRelativeTime;
  _exports.getStartOfDay = getStartOfDay;
  _exports.getTimeInHrs = getTimeInHrs;
  _exports.getTimeInHrsAndMins = getTimeInHrsAndMins;
  _exports.getTimeZoneOffset = getTimeZoneOffset;
  _exports.isAfterToday = isAfterToday;
  _exports.isBeforeToday = isBeforeToday;
  _exports.isToday = isToday;
  // Replacement new Date() if you need the current date time
  // You can use new Date() if you provide an input to it
  // Input: Nothing
  // Output: JS DateTime having the corrected system time
  function getCurrentDateTime() {
    var timeDeviation = localStorage.getItem(_constants.TIME_DEVIATION_LOCAL_STORAGE_KEY);
    if (timeDeviation) {
      timeDeviation = Number(timeDeviation);
      var now = new Date() - timeDeviation;
      return new Date(now);
    } else {
      return new Date();
    }
  }

  // Input: '2018-11-27' or JS DateTime
  // Output: String specifying the relative time - 'in a day', '5 months ago'
  function getRelativeTime(time, intlService) {
    var now = moment(getCurrentDateTime());
    var momentTime = moment(time);
    if (typeof time === 'string') {
      // We assume that pure dates are in the form of strings
      if (momentTime.diff(now) > 0) {
        // If the date has not yet passed, it is considered to pass only at the last instant of the day
        momentTime.hours(23);
        momentTime.minutes(59);
        momentTime.seconds(59);
        momentTime.milliseconds(999);
      }
      if (now.format(_constants.API_DATE_FORMAT) === momentTime.format(_constants.API_DATE_FORMAT)) {
        return intlService.findTranslationByKey('today');
      }
    }
    return momentTime.from(now);
  }
  function getTimeInHrs(minutes) {
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    var time = '';
    time = hours ? hours.toString().concat(': ') : '';
    time = time.concat(minutes).concat(': 00');
    return time;
  }
  function getTimeInHrsAndMins(timeInMinutes) {
    var minutes = timeInMinutes % 60;
    var hours = (timeInMinutes - minutes) / 60;
    if (hours === 0) {
      return "".concat(minutes, "m");
    } else {
      return "".concat(hours, "h ").concat(Math.abs(minutes), "m");
    }
  }

  // Output: Integer specifying the current staff's timezone offset from GMT
  function getTimeZoneOffset() {
    return moment().utcOffset();
  }

  // WARNING: Use only to convert dates, not datetimes, as the function strips of the time component
  function convertDateToMoment(date) {
    return moment("".concat(date.getFullYear(), "-").concat(date.getMonth() + 1, "-").concat(date.getDate()), 'YYYY-M-D');
  }

  // Use this to format a JS datetime object without applying timezone
  // Input: JS DateTime
  // Output: String specifying the absolute time contained in the input agnostic of timezone
  function getAPIFormatTime(date) {
    if (date) {
      var m = convertDateToMoment(date);
      m.hours(date.getHours());
      m.minutes(date.getMinutes());
      m.seconds(date.getSeconds());
      m.milliseconds(date.getMilliseconds());
      return m.format(_constants.API_TIMESTAMP_FORMAT);
    }
  }

  // Input: JS DateTime
  // Output: String specifying the absolute time of the input in UTC
  function getAPIFormatTimeWithUtc(date) {
    if (date) {
      return moment(date).utc().format(_constants.API_TIMESTAMP_FORMAT);
    }
  }

  // Input: JS DateTime
  // Output: JS DateTime set to the beginning of the day
  function getStartOfDay() {
    var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (0, _date.getCurrentDate)();
    if (!(date instanceof Date)) {
      date = (0, _date.createDate)(date);
    }
    date.setHours(0, 0, 0, 0);
    return date;
  }

  // Input: JS DateTime
  // Output: JS DateTime set to the end of the day
  function getEndOfDay() {
    var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (0, _date.getCurrentDate)();
    if (!(date instanceof Date)) {
      date = (0, _date.createDate)(date);
    }
    var newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  }
  function isAfterToday(date) {
    if (date) {
      return getEndOfDay() < getEndOfDay(date);
    }
  }
  function isBeforeToday(date) {
    if (date) {
      return getEndOfDay() > getEndOfDay(date);
    }
  }

  // Input: JS DateTime
  // Output: Boolean - if the given date corresponds to today in staff timezone
  function isToday(date) {
    if (!(date instanceof Date)) {
      date = (0, _date.createDate)(date);
    }
    var currentDate = (0, _date.getCurrentDate)();
    return currentDate.getDate() === date.getDate() && currentDate.getMonth() === date.getMonth() && currentDate.getFullYear() === date.getFullYear();
  }
  function formatTime(minutes, intlService) {
    var noOfhours = Math.floor(minutes / 60);
    var noOfMinutes = minutes % 60;
    var hour = intlService.findTranslationByKey('hour');
    var minute = intlService.findTranslationByKey('minute');
    if (noOfhours > 0) {
      return (0, _string.getSafeHtml)("".concat(noOfhours, " ").concat(hour, "  ").concat(noOfMinutes, " ").concat(minute));
    } else {
      return (0, _string.getSafeHtml)("".concat(noOfMinutes, " ").concat(minute));
    }
  }

  // Input: moment
  // Output: JS DateTime set to the same date and time as in the moment object (staff timezone dependent)
  function convertMomentToDateTime(dateTime) {
    return new Date(dateTime.year(), dateTime.month(), dateTime.date(), dateTime.hour(), dateTime.minute(), dateTime.seconds());
  }
  function convert12HourTo24HourFormat(hour, meridiem) {
    if (meridiem === 'PM' && hour !== 12) {
      return hour + 12;
    } else if (meridiem === 'AM' && hour === 12) {
      return 0;
    } else {
      return hour;
    }
  }
  function convert24HourTo12HourFormat(hour) {
    if (hour > 12) {
      return {
        hour: hour % 12,
        meridiem: 'PM'
      };
    } else if (hour === 0) {
      return {
        hour: 12,
        meridiem: 'AM'
      };
    } else if (hour === 12) {
      return {
        hour: 12,
        meridiem: 'PM'
      };
    } else {
      return {
        hour: hour,
        meridiem: 'AM'
      };
    }
  }
  function getCustomRelativeTime(time, intlService) {
    var pastTime = moment(time);
    var currentTime = moment(getCurrentDateTime());
    var duration = moment.duration(currentTime.diff(pastTime));
    if (duration.get('years') > 0) {
      return intlService.findTranslationByKeyWithParameters('relative-time-unit.years-ago', {
        value: duration.get('years')
      });
    } else if (duration.get('days') > 0 || duration.get('months') > 0) {
      return pastTime.format(_constants.RELATIVE_TIME_DATE_FORMAT);
    } else if (duration.get('hours') > 0) {
      return intlService.findTranslationByKeyWithParameters('relative-time-unit.hours-minutes-ago', {
        hrs: duration.get('hours'),
        mins: duration.get('minutes')
      });
    } else if (duration.get('minutes') > 0) {
      return intlService.findTranslationByKeyWithParameters('relative-time-unit.minutes-ago', {
        value: duration.get('minutes')
      });
    } else if (duration.get('seconds') > 0) {
      return intlService.findTranslationByKeyWithParameters('relative-time-unit.seconds-ago', {
        value: duration.get('seconds')
      });
    } else {
      return intlService.findTranslationByKey('just-now');
    }
  }
});