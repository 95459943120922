define("workflows-web/components/async-button/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'button',
    classNameBindings: ['inProgress:hf-in-inProgress', 'isDisabled:hf-is-disabled'],
    // `isDisabled` Required when async-button used with tagName='a'
    attributeBindings: ['isDisabled:disabled', 'dataTestId:data-test-id'],
    inProgress: false,
    disabled: false,
    isDisabled: Ember.computed.or('inProgress', 'disabled'),
    text: '',
    inProgressText: '',
    closePopOversOnFocus: false,
    svg: null,
    dataTestId: null,
    focusIn: function focusIn() {
      if (this.get('closePopOversOnFocus')) {
        (0, _jquery.default)(document).click(); // Close all open popovers
      }
    },
    click: function click() {
      if (this.get('onClick')) {
        this.get('onClick')();
      }
      return false;
    }
  });
});