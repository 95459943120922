define("workflows-web/forms/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fBjsstMU",
    "block": "{\"symbols\":[\"errorMessage\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@close\",\"@containerClass\",\"@translucentOverlay\"],[[32,0,[\"toggleCreateFormModal\"]],\"hf-mod-create-form-modal\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"span\"],[24,0,\"modal_close\"],[4,[38,0],[\"click\",[32,0,[\"toggleCreateFormModal\"]]],null],[12],[1,[30,[36,1],[\"modal-close\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal_header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal_header_title\"],[12],[2,\"Create New Form\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"form\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal_body\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"hf-form-field_label hf-is-compulsory\"],[12],[2,\"Name\"],[13],[2,\"\\n      \"],[8,\"input\",[[24,0,\"hf-form-field-input\"]],[[\"@value\"],[[32,0,[\"name\"]]]],null],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"hf-validation-error-message hf-validation-error-message-font\"],[12],[2,\"\\n          \"],[1,[32,1]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal_footer hf-u-vertically-centered-container\"],[12],[2,\"\\n      \"],[8,\"async-button\",[[24,0,\"hf-primary-action\"],[16,\"disabled\",[30,[36,7],[[32,0,[\"name\"]],\"\"],null]]],[[\"@inProgress\",\"@text\",\"@inProgressText\",\"@onClick\"],[[30,[36,6],[[35,5],[35,5,[\"isSaving\"]]],null],\"Create Form\",\"Creating...\",[32,0,[\"createForm\"]]]],null],[2,\"\\n      \"],[11,\"a\"],[24,0,\"modal_footer_secondary-action hf-u-push-right\"],[4,[38,0],[\"click\",[32,0,[\"toggleCreateFormModal\"]]],null],[12],[2,\"Cancel\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"inline-svg\",\"errors\",\"-track-array\",\"each\",\"newForm\",\"and\",\"eq\"]}",
    "meta": {
      "moduleName": "workflows-web/forms/new/template.hbs"
    }
  });
});