define("workflows-web/components/form-field-input/phone/component", ["exports", "workflows-web/components/form-field-input/base", "workflows-web/components/form-field-input/mixins/autofocus"], function (_exports, _base, _autofocus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend(_autofocus.default, {
    classNames: ['hf-u-flex-container', 'hf-u-flex-max-width'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('field.autoFocus')) {
        this.autoFocus();
      }
    },
    actions: {
      onFocusOut: function onFocusOut() {
        this.get('field').validate();
      }
    }
  });
});