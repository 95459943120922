define("workflows-web/components/form-field-input/code-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CSSUQabJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,\"data-test-id\",[34,0]],[12],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"mode\",\"readOnly\",\"code\",\"onCodeChange\"],[[35,2,[\"mode\"]],[35,2,[\"readOnly\"]],[35,2,[\"value\"]],[30,[36,1],[[32,0],\"onCodeChange\"],null]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fieldDataTestId\",\"action\",\"field\",\"code-editor\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/code-editor/template.hbs"
    }
  });
});