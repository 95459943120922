define("workflows-web/workflows/apps/details/zendesk/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6yw+DMYg",
    "block": "{\"symbols\":[],\"statements\":[[19,\"workflows/apps/details/partials/base-form\",[]],[2,\"\\n\"],[6,[37,7],[[35,4,[\"settings\",\"accountUrl\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"section\"],[14,0,\"hf-app-manage-section_container\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"hf-manage-app-form_heading\"],[12],[1,[30,[36,2],[\"apps.zendesk.workflow-app-setup\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"hf-manage-app-form_sub-heading\"],[12],[1,[30,[36,2],[\"apps.zendesk.workflow-app-setup-info\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-app-manage-section_box\"],[12],[2,\"\\n\"],[6,[37,7],[[35,4,[\"settings\",\"authToken\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,5],null,[[\"value\",\"disabled\",\"class\"],[[35,4,[\"settings\",\"authToken\"]],true,\"hf-form-field-input hf-text-input\"]]]],[2,\"\\n\"],[6,[37,6],null,[[\"class\",\"tagName\",\"value\"],[\"hf-primary-action hf-u-margin-top-10 hf-tooltipped hf-tooltipped-right\",\"button\",[35,4,[\"settings\",\"authToken\"]]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,2],[\"copy\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,3],null,[[\"text\",\"class\",\"inProgressText\",\"inProgress\",\"onClick\"],[[30,[36,2],[\"apps.generate-token\"],null],\"hf-primary-action\",[30,[36,2],[\"apps.generating-token\"],null],[35,1],[30,[36,0],[[32,0],\"generateToken\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"action\",\"generateTokenInProgress\",\"t\",\"async-button\",\"app\",\"input\",\"copy-to-clipboard\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/apps/details/zendesk/template.hbs"
    }
  });
});