define("workflows-web/initializers/modify-basic-dropdown", ["exports", "ember-basic-dropdown/components/basic-dropdown"], function (_exports, _basicDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'modify-basic-dropdown',
    initialize: function initialize() {
      _basicDropdown.default.reopen({
        // In a form when pressing enter, submit button should be activated and the currently focussed power select should not open.
        handleKeydown: function handleKeydown(e) {
          if (e.keyCode === 13) {
            // Prevent opening dropdown on Enter
            return;
          }
          this._super(e);
        }
      });
    }
  };
});