define("workflows-web/services/account-info", ["exports", "workflows-web/config/environment", "workflows-web/utilities/object", "workflows-web/constants"], function (_exports, _environment, _object, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    accountInfo: null,
    user: Ember.computed.reads('accountInfo.user'),
    permissions: Ember.computed.reads('user.permissions'),
    planFeatures: Ember.computed.reads('accountInfo.planFeatures'),
    activeWorkflowsLimit: Ember.computed.reads('planFeatures.activeWorkflowsLimit'),
    workflowActionsLimit: Ember.computed.reads('planFeatures.workflowActionsLimit'),
    activeWorkflowsCount: Ember.computed.reads('accountInfo.activeWorkflowsCount'),
    isApprovalManagementEnabled: Ember.computed.reads('planFeatures.approvalManagement'),
    isSalesforceEnabled: Ember.computed.reads('planFeatures.salesforce'),
    isZendeskEnabled: Ember.computed.reads('planFeatures.zendesk'),
    isJiraEnabled: Ember.computed.reads('planFeatures.jira'),
    isPipedriveEnabled: Ember.computed.reads('planFeatures.pipedrive'),
    isShopifyEnabled: Ember.computed.reads('planFeatures.shopify'),
    isOktaEnabled: Ember.computed.reads('planFeatures.okta'),
    isJamfProEnabled: Ember.computed.reads('planFeatures.jamfPro'),
    isGoogleSheetsEnabled: Ember.computed.reads('planFeatures.googleSheets'),
    isHappyfoxChatbotEnabled: Ember.computed.reads('planFeatures.happyfoxChatbot'),
    isSlackEnabled: Ember.computed.reads('planFeatures.slack'),
    isAzureDevopsEnabled: Ember.computed.reads('planFeatures.azureDevops'),
    isAzureActiveDirectoryEnabled: Ember.computed.reads('planFeatures.azureActiveDirectory'),
    isMSTeamsEnabled: Ember.computed.reads('planFeatures.msTeams'),
    costPerHour: Ember.computed.reads('accountInfo.costPerHour'),
    isBambooHREnabled: Ember.computed.reads('planFeatures.bamboohr'),
    isManageFormsEnabled: Ember.computed.reads('planFeatures.manageForms'),
    appsWithTriggersEnabled: Ember.computed.reads('accountInfo.appsWithTriggersEnabled'),
    isAsanaEnabled: Ember.computed.reads('planFeatures.asana'),
    isHubspotEnabled: Ember.computed.reads('planFeatures.hubspot'),
    isServiceDeskEnabled: Ember.computed.reads('planFeatures.happyfoxServiceDesk'),
    applicationMetaInfo: null,
    integrationMetaInfo: null,
    isUserSelfServed: Ember.computed.reads('accountInfo.isUserSelfServed'),
    accountState: Ember.computed.reads('accountInfo.state'),
    extraIntegrations: Ember.computed.reads('accountInfo.planFeatures.extraIntegrations'),
    hasSelfServedAccountExpired: Ember.computed('accountState', 'isUserSelfServed', {
      get: function get() {
        return this.accountState === _constants.USER_PLAN_STATES.TRIAL_EXPIRED && this.isUserSelfServed;
      }
    }),
    isUserSelfServedStateUnknown: Ember.computed('accountState', 'isUserSelfServed', {
      get: function get() {
        return this.accountState === _constants.USER_PLAN_STATES.UNKNOWN && this.isUserSelfServed;
      }
    }),
    isWebhookEnabled: Ember.computed('isUserSelfServed', 'planFeatures.webhooks', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.webhooks || !this.isUserSelfServed;
      }
    }),
    isTriggersEnabled: Ember.computed('isUserSelfServed', 'planFeatures.triggers', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.triggers || !this.isUserSelfServed;
      }
    }),
    isCheckConditionFieldsEnabled: Ember.computed('isUserSelfServed', 'planFeatures.checkConditions', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.checkConditions || !this.isUserSelfServed;
      }
    }),
    isCloneWorkflowEnabled: Ember.computed('isUserSelfServed', 'planFeatures.cloneWorkflow', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.cloneWorkflow || !this.isUserSelfServed;
      }
    }),
    isReorderWorklflowStepsEnabled: Ember.computed('isUserSelfServed', 'planFeatures.reorderWorkflowSteps', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.reorderWorkflowSteps || !this.isUserSelfServed;
      }
    }),
    isExtractValuesFromTextEnabled: Ember.computed('isUserSelfServed', 'planFeatures.extractValuesFromText', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.extractValuesFromText || !this.isUserSelfServed;
      }
    }),
    isWorflowLibraryEnabled: Ember.computed('isUserSelfServed', 'planFeatures.worflowLibrary', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.worflowLibrary || !this.isUserSelfServed;
      }
    }),
    isTicketFilteringEnabled: Ember.computed('isUserSelfServed', 'planFeatures.searchTickets', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.searchTickets || !this.isUserSelfServed;
      }
    }),
    isRenamingWorkflowEnabled: Ember.computed('isUserSelfServed', 'planFeatures.renameWorkflow', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.renameWorkflow || !this.isUserSelfServed;
      }
    }),
    isRenamingWorkflowStepEnabled: Ember.computed('isUserSelfServed', 'planFeatures.renameWorkflowStep', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.renameWorkflowStep || !this.isUserSelfServed;
      }
    }),
    isWorkflowExecutionDashboardEnabled: Ember.computed('isUserSelfServed', 'planFeatures.workflowExecutionDashboard', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.workflowExecutionDashboard || !this.isUserSelfServed;
      }
    }),
    isAiWorkflowBuilderEnabled: Ember.computed('isUserSelfServed', 'planFeatures.{aiWorkflowBuilder,aiAutomate}', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.aiWorkflowBuilder || !this.isUserSelfServed && this.planFeatures.aiAutomate;
      }
    }),
    isAiWorkflowDescriptionEnabled: Ember.computed('isUserSelfServed', 'planFeatures.aiWorkflowDescription', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.aiWorkflowDescription || !this.isUserSelfServed;
      }
    }),
    // TODO: AiActions feature flag is used for old users and AiAutomate feature flag is use for new users, once BE migration is done we can remove AiActions feature flag
    isAiActionsEnabled: Ember.computed('isUserSelfServed', 'planFeatures.{aiActions,aiAutomate}', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.aiAutomate || !this.isUserSelfServed && this.planFeatures.aiActions;
      }
    }),
    isInlineLookupTableEnabled: Ember.computed('isUserSelfServed', 'planFeatures.inlineLookupTable', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.inlineLookupTable || !this.isUserSelfServed;
      }
    }),
    isNoCodeWorkflowBuilderEnabled: Ember.computed('isUserSelfServed', 'planFeatures.noCodeWorkflowBuilder', {
      get: function get() {
        return this.isUserSelfServed && this.planFeatures.noCodeWorkflowBuilder || !this.isUserSelfServed;
      }
    }),
    tempFeatureFlags: Ember.computed('planFeatures.[]', {
      get: function get() {
        // format to access feature flag from account service
        // tempUseEnhancedMergeFieldsFormat: this.isTempFeatureEnabled(this.planFeatures, TEMP_FEATURE_FLAGS.TEMP_USE_ENHANCED_MERGE_FIELDS_FORMAT)
        return {
          tempEnableManagePermissions: this.isTempFeatureEnabled(this.planFeatures, _constants.TEMP_FEATURE_FLAGS.TEMP_ENABLE_MANAGE_PERMISSIONS),
          tempUseReactUpdateTicketForm: this.isTempFeatureEnabled(this.planFeatures, _constants.TEMP_FEATURE_FLAGS.TEMP_USE_REACT_UPDATE_TICKET_FORM),
          tempUseReactAiActions: this.isTempFeatureEnabled(this.planFeatures, _constants.TEMP_FEATURE_FLAGS.TEMP_USE_REACT_AI_ACTIONS)
        };
      }
    }),
    // Manage permissions is accessible only when the tempEnableManagePermissions flag is activated for self-served users and if the user possesses any permissions.
    enableManagePermissions: Ember.computed('tempFeatureFlags.tempEnableManagePermissions', 'isUserSelfServed', 'permissions', {
      get: function get() {
        var _this$permissions;
        return this.tempFeatureFlags.tempEnableManagePermissions && this.isUserSelfServed && ((_this$permissions = this.permissions) === null || _this$permissions === void 0 ? void 0 : _this$permissions.length) > 0;
      }
    }),
    hasBillingPermission: Ember.computed('isUserSelfServed', 'permissions', {
      get: function get() {
        var _this$permissions2;
        return this.isUserSelfServed && ((_this$permissions2 = this.permissions) === null || _this$permissions2 === void 0 ? void 0 : _this$permissions2.length) > 0 && this.permissions.includes(_constants.BILLING_PERMISSIONS);
      }
    }),
    isTempFeatureEnabled: function isTempFeatureEnabled(planFeatures, featureName) {
      var _config$tempFeatureFl;
      var experimentalFlagEnabled = (planFeatures === null || planFeatures === void 0 ? void 0 : planFeatures[featureName]) || ((_config$tempFeatureFl = _environment.default.tempFeatureFlags) === null || _config$tempFeatureFl === void 0 ? void 0 : _config$tempFeatureFl[featureName]) === 'true';
      return experimentalFlagEnabled;
    },
    tempUseReactUpdateTicketForm: Ember.computed.reads('tempFeatureFlags.tempUseReactUpdateTicketForm'),
    tempUseReactAiActions: Ember.computed.reads('tempFeatureFlags.tempUseReactAiActions'),
    getAccountInfo: function getAccountInfo() {
      return this.get('accountInfo');
    },
    setAccountInfo: function setAccountInfo(info) {
      this.set('accountInfo', (0, _object.camelizeObject)(info));
    },
    setApplicationMetaInfo: function setApplicationMetaInfo(info) {
      this.set('applicationMetaInfo', (0, _object.camelizeObject)(info));
    },
    setIntegrationMetaInfo: function setIntegrationMetaInfo(info) {
      this.set('integrationMetaInfo', (0, _object.camelizeObject)(info));
    }
  });
});