define("workflows-web/components/auto-complete/remote/single/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wM0n/S3j",
    "block": "{\"symbols\":[\"result\",\"&default\"],\"statements\":[[6,[37,14],null,[[\"options\",\"allowClear\",\"placeholder\",\"searchPlaceholder\",\"selected\",\"autofocus\",\"onchange\",\"onkeydown\",\"search\",\"triggerClass\",\"dropdownClass\",\"noMatchesMessage\",\"initiallyOpened\",\"renderInPlace\",\"isAddNew\",\"onopen\",\"onclose\"],[[35,13],[35,12],[35,11],[35,10],[35,9],[35,8],[30,[36,1],[[32,0],\"onChange\"],null],[30,[36,1],[[32,0],\"createCustomChoice\"],null],[30,[36,1],[[32,0],\"search\"],null],[35,7],[35,6],[30,[36,0],[[35,4],[30,[36,5],[[35,4]],null]],null],[35,3],true,[35,2],[30,[36,1],[[32,0],\"onOpen\"],null],[30,[36,1],[[32,0],\"onClose\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"isAddNew\",\"initiallyOpened\",\"noMatchesMessage\",\"t\",\"dropdownClass\",\"triggerClass\",\"autofocus\",\"selected\",\"searchPlaceholder\",\"placeholder\",\"allowClear\",\"options\",\"power-select\"]}",
    "meta": {
      "moduleName": "workflows-web/components/auto-complete/remote/single/template.hbs"
    }
  });
});