define("workflows-web/components/list-pagination/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['hf-pagination'],
    classNameBindings: ['isBottomPagination:hf-mod-bottom'],
    pageMeta: null,
    pageInfo: null,
    isLoading: true,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('pageInfo', {});
    },
    onPageMeta: Ember.observer('pageMeta', function () {
      var pageMeta = this.get('pageMeta');
      if (pageMeta) {
        this.set('pageInfo', pageMeta);
        this.set('isLoading', false);
      }
    }).on('init'),
    createPageOption: function createPageOption(startId, limit, count) {
      // endId should not be greater than total count of tickets
      var endId = startId + limit - 1;
      endId = endId > count - 1 ? count - 1 : endId;
      var pageOption = Ember.Object.create({
        label: "".concat(startId + 1, " - ").concat(endId + 1),
        value: startId
      });
      return {
        pageOption: pageOption,
        endId: endId
      };
    },
    currentPage: Ember.computed('pageInfo.{limit,offset,count}', 'adjacentPagesList', 'cataloguePagesList', {
      get: function get() {
        var _this = this;
        // meta is coming as plain object
        var pageInfo = this.get('pageInfo');
        var offset = pageInfo.offset,
          limit = pageInfo.limit,
          count = pageInfo.count;
        // HACK: power select compares equality with object reference hence the hack to return the object from options
        return this.get('adjacentPagesList').slice().addObjects(this.get('cataloguePagesList')).find(function (pageOption) {
          return _.isEqual(pageOption, _this.createPageOption(offset, limit, count).pageOption);
        });
      },
      set: function set(key, value) {
        return value;
      }
    }),
    currentOffset: Ember.computed('currentPage.value', {
      get: function get() {
        return this.get('currentPage.value');
      }
    }),
    // function return page list with 3 pages before and after the selected page
    getAdjacentPages: function getAdjacentPages(pageInfo) {
      var NUMBER_OF_ADJACENT_PAGES = 3;
      var pageList = Ember.A();
      // pageInfo(meta) is coming as plain object
      var offset = pageInfo.offset,
        limit = pageInfo.limit,
        count = pageInfo.count;

      // show 3 pages before and after selected page
      for (var i = -NUMBER_OF_ADJACENT_PAGES; i <= NUMBER_OF_ADJACENT_PAGES; i++) {
        var startId = offset + i * limit;
        if (startId < 0) {
          // skip if start index is less than 0
          continue;
        }
        var pageCreated = this.createPageOption(startId, limit, count);
        var pageOption = pageCreated.pageOption;
        pageList.pushObject(pageOption);
        if (pageCreated.endId >= count - 1) {
          // stop if last index is reached
          break;
        }
      }
      return pageList;
    },
    // function returns list of catalogue pages
    getCataloguePages: function getCataloguePages(pageInfo, adjacentPagesList) {
      var PAGES_TO_JUMP = 10;
      var NUMBER_OF_PAGES_REQUIRED = 4;
      var pageList = Ember.A();
      // pageInfo(meta) is coming as plain object
      var limit = pageInfo.limit,
        count = pageInfo.count;

      // Offset is set to 0
      var offset = 0;
      // create page list with separation of 10 pages but not more than set of 4
      var terminalOffset = limit * PAGES_TO_JUMP * NUMBER_OF_PAGES_REQUIRED;
      while (offset < count && offset <= terminalOffset) {
        if (!adjacentPagesList.findBy('value', offset)) {
          var _this$createPageOptio = this.createPageOption(offset, limit, count),
            pageOption = _this$createPageOptio.pageOption;
          pageList.pushObject(pageOption);
        }
        offset += limit * PAGES_TO_JUMP;
      }

      // create last page if not present.
      // remaining tickets in last page to create lastID.
      var lastPageLimit = count % limit;
      // if remainder is zero, there are limit number of tickets in last page. Set endId accordingly
      var lastPageOffset = lastPageLimit === 0 ? count - limit : count - lastPageLimit;
      if (!pageList.findBy('value', lastPageOffset) && !adjacentPagesList.findBy('value', lastPageOffset)) {
        var _this$createPageOptio2 = this.createPageOption(lastPageOffset, limit, count),
          _pageOption = _this$createPageOptio2.pageOption;
        pageList.pushObject(_pageOption);
      }
      return pageList;
    },
    adjacentPagesList: Ember.computed('pageInfo.{limit,offset,count}', {
      get: function get() {
        return this.getAdjacentPages(this.get('pageInfo'));
      }
    }),
    cataloguePagesList: Ember.computed('pageInfo.{limit,offset,count}', {
      get: function get() {
        var adjacentPagesList = this.get('adjacentPagesList');
        return this.getCataloguePages(this.get('pageInfo'), adjacentPagesList);
      }
    }),
    hasNoPreviousPage: Ember.computed('pageInfo.previous', {
      get: function get() {
        return Ember.isNone(this.get('pageInfo.previous'));
      }
    }),
    hasNoNextPage: Ember.computed('pageInfo.next', {
      get: function get() {
        return Ember.isNone(this.get('pageInfo.next'));
      }
    }),
    getParameterByName: function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    actions: {
      goToPreviousPage: function goToPreviousPage() {
        if (this.get('pageInfo')) {
          var offset = this.getParameterByName('offset', this.get('pageInfo.previous'));
          this.changePage(offset);
        }
      },
      goToNextPage: function goToNextPage() {
        if (this.get('pageInfo')) {
          var offset = this.getParameterByName('offset', this.get('pageInfo.next'));
          this.changePage(offset);
        }
      },
      changePage: function changePage(pageDetails) {
        this.set('currentPage', pageDetails);
        this.changePage(pageDetails.get('value'));
      }
    }
  });
});