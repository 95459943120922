define("workflows-web/components/dynamic-field/dropdown-component/options/component", ["exports", "ember-power-select/components/power-select/options", "workflows-web/components/dynamic-field/dropdown-component/options/template"], function (_exports, _options, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _options.default.extend({
    layout: _template.default
  });
});