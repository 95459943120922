define("workflows-web/logout/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    router: Ember.inject.service(),
    requestService: Ember.inject.service('request'),
    accountInfoService: Ember.inject.service('account-info'),
    beforeModel: function beforeModel() {
      var _this = this;
      this.get('requestService').logout().then(function () {
        var accountInfo = _this.accountInfoService.accountInfo;
        accountInfo.set('user', null);
        _this.router.transitionTo('login');
      });
    }
  });
});