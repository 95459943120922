define("workflows-web/workflows/apps/details/manage-app-form/component", ["exports", "workflows-web/workflows/apps/details/form-model", "workflows-web/workflows/apps/constants", "workflows-web/utilities/form-models", "workflows-web/utilities/object"], function (_exports, _formModel, _constants, _formModels, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["status"];
  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
  var _default = _exports.default = Ember.Component.extend({
    intlService: Ember.inject.service('intl'),
    requestService: Ember.inject.service('request'),
    accountInfoService: Ember.inject.service('account-info'),
    saveInProgress: false,
    isSalesforce: Ember.computed.equal('app.application', 'salesforce'),
    isJira: Ember.computed.equal('app.application', 'jira'),
    defaultSalesforceObjects: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('defaultSalesforceObjects', _constants.DEFAULT_SALESFORCE_OBJECTS);
      this.renderForm(this.getRawFieldsInfo());
    },
    disableButton: Ember.computed('form.{isDirty,alwaysEnableButton}', {
      get: function get() {
        var form = this.form;
        return form.alwaysEnableButton ? false : !form.isDirty;
      }
    }),
    renderForm: function renderForm(rawFields) {
      var copyFromDataSource = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var app = this.get('app');
      var basicFieldKeys = rawFields.mapBy('key');
      var alwaysEnableButton = ['azure-devops', 'happyfox'].includes(app.application);
      var dataSource = app.get('settings');
      this.set('form', (0, _formModels.createObjectInContainer)(this, _formModel.default, {
        app: app,
        rawFields: rawFields,
        dataSource: dataSource,
        basicFieldKeys: basicFieldKeys,
        copyFromDataSource: copyFromDataSource,
        alwaysEnableButton: alwaysEnableButton
      }));
    },
    actions: {
      resetForm: function resetForm() {
        this.get('form').reset();
      },
      saveSettings: function saveSettings() {
        var _this = this;
        var form = this.get('form');
        var application = this.get('app.application');
        form.validate().then(function () {
          var data = {};
          form.get('fields').forEach(function (field) {
            if (field.get('isDirty') || field.alwaysIncludeInPayload) {
              data[field.get('key')] = field.getCleanValue();
            }
          });
          _this.set('saveInProgress', true);
          _this.requestService.saveAppSettings({
            application: application,
            data: data
          }).then(function (response) {
            _this.set('nonFieldErrors', []);
            if (response.redirectUrl) {
              window.location = response.redirectUrl;
            } else {
              _this.toastMessageService.showMessage({
                type: 'success',
                text: 'apps.save-settings-success-message'
              });
              _this.requestService.fetchAccountInfo().then(function (accountInfo) {
                _this.accountInfoService.setAccountInfo(accountInfo);
              });
              if (_this.afterSave) {
                _this.afterSave((0, _object.camelizeObject)(response));
              }
            }
          }).catch(function (errors) {
            var _form$fields;
            var nonFieldErrors;
            if ('nonFieldErrors' in errors) {
              nonFieldErrors = errors['nonFieldErrors'];
            }
            if (nonFieldErrors) {
              _this.set('nonFieldErrors', nonFieldErrors);
            }
            var status = errors.status,
              error = _objectWithoutProperties(errors, _excluded);
            if (error) {
              form._populateErrors({
                status: status,
                errors: (0, _object.camelizeObject)(error)
              });
            }
            var isFieldError = form === null || form === void 0 || (_form$fields = form.fields) === null || _form$fields === void 0 ? void 0 : _form$fields.some(function (field) {
              var _field$errors;
              return (_field$errors = field.errors) === null || _field$errors === void 0 ? void 0 : _field$errors.length;
            });
            if (!nonFieldErrors && !isFieldError) {
              _this.set('nonFieldErrors', [_this.intlService.t('apps.save-settings-error-message')]);
            }
          }).finally(function () {
            _this.set('saveInProgress', false);
          });
        });
      }
    }
  });
});