define("workflows-web/components/dynamic-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KgHPgajD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,8],null,[[\"input\",\"dropdown\",\"isOpen\",\"openDropdown\",\"closeDropdown\",\"toggleDropdown\"],[[30,[36,7],[\"dynamic-field/input-component\"],[[\"field\",\"value\",\"choices\",\"isOpen\",\"openDropdown\",\"toggleDropdown\",\"setInputComponentReference\",\"onValueChange\",\"onFocusOut\"],[[35,4],[35,4,[\"value\"]],[35,4,[\"choices\"]],[35,1],[30,[36,0],[[32,0],\"openDropdown\"],null],[30,[36,0],[[32,0],\"toggleDropdown\"],null],[30,[36,0],[[32,0],\"setInputComponentReference\"],null],[30,[36,0],[[32,0],\"onValueChange\"],null],[30,[36,0],[[32,0],\"onFocusOut\"],null]]]],[30,[36,7],[\"dynamic-field/dropdown-component\"],[[\"field\",\"isOpen\",\"isLoading\",\"onSearch\",\"loadMore\",\"choices\",\"selectedChoice\",\"class\",\"dropdownClass\",\"closeDropdown\",\"customFieldData\"],[[35,4],[35,1],[30,[36,5],[[35,4,[\"actionOnOpen\",\"isRunning\"]],[35,6,[\"isRunning\"]]],null],[30,[36,5],[[32,0,[\"onSearch\"]],[35,4,[\"onSearch\"]]],null],[35,4,[\"loadMore\"]],[35,4,[\"choices\"]],[35,3],\"hf-mod-dropdown\",\"hf-mod-push-dropdown-up\",[30,[36,0],[[32,0],\"closeDropdown\"],null],[35,2]]]],[35,1],[30,[36,0],[[32,0],\"openDropdown\"],null],[30,[36,0],[[32,0],\"closeDropdown\"],null],[30,[36,0],[[32,0],\"toggleDropdown\"],null]]]]]],[2,\"\\n\"],[1,[30,[36,9],null,[[\"field\"],[[35,4]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"isOpen\",\"customFieldData\",\"selectedChoice\",\"field\",\"or\",\"actionOnOpen\",\"component\",\"hash\",\"field-preview\"]}",
    "meta": {
      "moduleName": "workflows-web/components/dynamic-field/template.hbs"
    }
  });
});