define("workflows-web/workflows/details/edit/actions/action/group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XkOv7w1T",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"li\"],[14,0,\"ember-power-select-group\"],[15,\"data-test-id\",[34,1,[\"dataTestId\"]]],[15,\"aria-disabled\",[30,[36,4],[[35,1,[\"disabled\"]],\"true\"],null]],[14,\"role\",\"option\"],[12],[2,\"\\n  \"],[11,\"span\"],[24,0,\"ember-power-select-group-name\"],[4,[38,5],[[32,0],\"toggleCanShowGroupOptions\"],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-u-vertically-centered-container\"],[12],[2,\"\\n\"],[6,[37,4],[[35,1,[\"logoSmall\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[15,\"src\",[34,1,[\"logoSmall\"]]],[15,\"alt\",[34,1,[\"groupName\"]]],[14,0,\"hf-application_group-name hf-u-icon-right-margin\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[[30,[36,2],[[35,1,[\"groupName\"]],\"-small\"],null]],[[\"class\"],[\"hf-u-icon-right-margin\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,1,[\"applicationDisplayName\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[1,[35,1,[\"applicationDisplayName\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[1,[30,[36,3],[[30,[36,2],[[35,1,[\"groupName\"]],\".title\"],null]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[6,[37,7],[[35,6,[\"lastSearchedText\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"hf-u-push-right\"],[12],[1,[30,[36,0],[\"dropdown-dark-bg-arrow-down\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,9],[[35,8],[35,6,[\"lastSearchedText\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"group\",\"concat\",\"t\",\"if\",\"action\",\"select\",\"unless\",\"canShowGroupOptions\",\"or\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/action/group/template.hbs"
    }
  });
});