define("workflows-web/components/date-picker/component", ["exports", "jquery", "ember-pikaday/components/pikaday-input", "workflows-web/constants"], function (_exports, _jquery, _pikadayInput, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _pikadayInput.default.extend({
    intlService: Ember.inject.service('intl'),
    // FIXME: For date-input integration tests to pass!
    format: _constants.DEFAULT_DATE_FORMAT,
    useUTC: false,
    bound: true,
    renderInPlace: false,
    hideInput: false,
    isAbsolutelyPositioned: false,
    firstDay: 0,
    keyboardInput: true,
    isArrowKeyPressed: false,
    allowClear: false,
    // To allow clearing the date input field on emptying the rendered input field.
    // Need to revisit this behaviour, we are losing the functionality to remove a date from the calendar
    keyPress: function keyPress(e) {
      // Using keyPress instead of keyDown since keyDown is not reliable for non-character keyCodes: https://stackoverflow.com/a/5829387
      // Disabling the keyPress event in dueDate to avoid manual user input.
      if (Ember.isPresent(String.fromCharCode(e.keyCode))) {
        e.preventDefault();
        return false;
      }
    },
    keyDown: function keyDown(e) {
      var _this = this;
      // since keyPress can't capture Tab
      if (e.which === 9 && this.get('bound')) {
        // Tab
        this.$().parent().find('button').attr('tabindex', '-1');
      }
      switch (e.which) {
        case 13:
          // enter
          this.onPikadaySelect();
          break;
        case 37: // left
        case 38: // up
        case 39: // right
        case 40:
          // down
          this.set('isArrowKeyPressed', true);
          Ember.run.next(function () {
            _this.set('isArrowKeyPressed', false);
          });
          break;
      }
    },
    // TODO: To remove this override once this PR is merged: https://github.com/edgycircle/ember-pikaday/pull/65
    didInsertElement: function didInsertElement() {
      var self = this;
      var firstDay = this.get('firstDay');
      var options = {
        field: this.$()[0],
        bound: this.get('bound'),
        onOpen: Ember.run.bind(this, this.onPikadayOpen),
        onClose: Ember.run.bind(this, this.onPikadayClose),
        onSelect: Ember.run.bind(this, this.onPikadaySelect),
        onDraw: Ember.run.bind(this, this.onPikadayRedraw),
        firstDay: typeof firstDay !== 'undefined' ? parseInt(firstDay, 10) : 1,
        format: this.get('format') || 'MMM D YYYY',
        yearRange: this.determineYearRange(),
        minDate: this.get('minDate') || null,
        maxDate: this.get('maxDate') || null,
        defaultDate: this.get('defaultDate') || null,
        theme: this.get('theme') || null,
        keyboardInput: this.get('keyboardInput'),
        toString: function toString(date) {
          return self.get('intlService').formatDate(date, {
            format: 'default'
          });
        }
      };
      if (this.get('renderInPlace')) {
        options.container = this.$().parent()[0];
      }
      if (this.get('hideInput')) {
        this.$().css('display', 'none');
      }
      if (this.get('i18n')) {
        options.i18n = this.get('i18n');
      }
      var pikaday = new Pikaday(options);
      this.set('pikaday', pikaday);
      this.setPikadayDate();
      this.addObserver('value', function () {
        self.setPikadayDate();
      });
      this.addObserver('minDate', function () {
        self.setMinDate();
      });
      this.addObserver('maxDate', function () {
        self.setMaxDate();
      });
    },
    onPikadaySelect: function onPikadaySelect() {
      this.set('value', this.get('pikaday').getDate());
      if (!this.get('isArrowKeyPressed')) {
        this.userSelectedDate();
        if (this.onSelectAction) {
          this.onSelectAction(this.get('value'));
        }
      }
    },
    // HACK: positions calendar depending on `isAbsolutelyPositioned` property
    onPikadayOpen: function onPikadayOpen() {
      var datePickerElement = this.get('pikaday.el');
      if (this.get('isAbsolutelyPositioned') && this.get('pikaday')) {
        datePickerElement.style.position = 'absolute';
        var windowHeight = (0, _jquery.default)(window).height();
        var datePickerHeight = datePickerElement.offsetHeight;
        var _datePickerElement$ge = datePickerElement.getBoundingClientRect(),
          topOffset = _datePickerElement$ge.top;
        if (datePickerHeight > windowHeight - topOffset) {
          datePickerElement.style.bottom = '100%';
        }
      }
    },
    /*
    We can use the onPikadayClose provided by date-picker once all the usecases in app are tested for clearing date fields
    Need to enable allowClear in each page seperately and test the usecases to avoid breakage
    */
    onPikadayClose: function onPikadayClose() {
      if (this.get('allowClear')) {
        this._super.apply(this, arguments);
      } else {
        this.setPikadayDate();
        if (this.onClose) {
          this.onClose();
        }
      }
    },
    setPikadayDate: function setPikadayDate() {
      var value = this.get('value');
      this.get('pikaday').setDate(value, true);
    }
  });
});