define("workflows-web/form-models/base-form", ["exports", "jquery", "workflows-web/utilities/object", "workflows-web/utilities/form-models", "workflows-web/utilities/request", "workflows-web/utilities/scroll"], function (_exports, _jquery, _object, _formModels, _request, _scroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    fields: null,
    errors: null,
    isHorizontalForm: false,
    dataSource: null,
    basicFieldKeys: null,
    intlService: Ember.inject.service('intl'),
    dynamicFieldsMetaInfo: null,
    isNew: false,
    scrollableContainerSelector: '',
    init: function init() {
      this._super.apply(this, arguments);
      this.set('errors', Ember.A());
    },
    model: Ember.computed('fields.[]', {
      get: function get() {
        var fieldModels = Ember.Object.create();
        this.get('fields').forEach(function (fieldItem) {
          fieldModels.set(fieldItem.get('key'), fieldItem);
        });
        return fieldModels;
      }
    }),
    dynamicFields: Ember.computed('fields.[]', {
      get: function get() {
        return this.get('fields').filterBy('isDynamic');
      }
    }),
    isDirty: Ember.computed('fields.@each.isDirty', {
      get: function get() {
        return this.get('fields').isAny('isDirty');
      }
    }),
    // rawFields is an array of fields data
    _createFieldModels: function _createFieldModels(rawFields) {
      var _this = this;
      var fieldModels = Ember.A();
      // check type of each field and create field model
      rawFields.forEach(function (rawField) {
        fieldModels.pushObject((0, _formModels.createField)(_this, rawField));
      });
      return fieldModels;
    },
    validate: function validate() {
      var _this2 = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var validationPromises = Ember.A();
        var fields = _this2.get('fields');
        fields.forEach(function (field) {
          validationPromises.pushObject(field.validate());
        });
        Ember.RSVP.allSettled(validationPromises).then(function (result) {
          var isValid = result.isEvery('state', 'fulfilled');
          _this2.set('isValid', isValid);
          if (isValid) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    _populateErrors: function _populateErrors(errors) {
      var _this3 = this;
      var fields = this.get('fields');
      if ((0, _request.isInvalid)(errors.status)) {
        var errorsPayload = errors.errors;
        (0, _object.getKeysFromObject)(errorsPayload).forEach(function (errorKey) {
          if (errorKey === 'nonFieldErrors') {
            _this3.set('errors', errorsPayload[errorKey]);
          } else {
            var field = fields.findBy('key', errorKey);
            if (Ember.isPresent(field)) {
              field.setErrors(errorsPayload[errorKey]);
            }
          }
        });
        this.scrollToError();
      }
    },
    scrollToError: function scrollToError() {
      var _this4 = this;
      Ember.run.next(this, function () {
        var scrollableContainerSelector = _this4.get('scrollableContainerSelector') || '.hf-entity-content';
        var errorFieldsSelector = '.hf-form-field .hf-is-error';
        var nonFieldErrorSelector = '.hf-js-form-content_non-field-errors';
        if ((0, _jquery.default)(scrollableContainerSelector).length) {
          if ((0, _jquery.default)(errorFieldsSelector).length) {
            (0, _scroll.scrollToElement)(scrollableContainerSelector, errorFieldsSelector);
          } else if ((0, _jquery.default)(nonFieldErrorSelector).length) {
            (0, _scroll.scrollToElement)(scrollableContainerSelector, nonFieldErrorSelector);
          } else {
            (0, _scroll.scrollToTop)(scrollableContainerSelector);
          }
        }
      });
    },
    // set match all or match any value for check conditions and triggers
    updateMatchAllOrMatchAny: function updateMatchAllOrMatchAny() {
      var _this$action$groups,
        _this5 = this;
      var basicFieldKeys = this.get('basicFieldKeys');
      var dataSourceTmp = Ember.Object.create({});
      var group = (_this$action$groups = this.action.groups) === null || _this$action$groups === void 0 ? void 0 : _this$action$groups.firstObject;
      if (group) {
        this.currentGroup = group;
      }
      basicFieldKeys.forEach(function (key) {
        var _this5$action$data, _this5$action$data2;
        if (key === 'matchAll' && group) {
          dataSourceTmp.set('matchAll', group.get('conditions.matchAll'));
        } else if (key === 'matchAny' && group) {
          dataSourceTmp.set('matchAny', group.get('conditions.matchAny'));
        } else if (key === 'matchAll' && (_this5$action$data = _this5.action.data) !== null && _this5$action$data !== void 0 && (_this5$action$data = _this5$action$data.conditions) !== null && _this5$action$data !== void 0 && _this5$action$data.matchAll) {
          dataSourceTmp.set('matchAll', _this5.action.data.conditions.matchAll);
        } else if (key === 'matchAny' && (_this5$action$data2 = _this5.action.data) !== null && _this5$action$data2 !== void 0 && (_this5$action$data2 = _this5$action$data2.conditions) !== null && _this5$action$data2 !== void 0 && _this5$action$data2.matchAny) {
          dataSourceTmp.set('matchAny', _this5.action.data.conditions.matchAny);
        } else if (group) {
          dataSourceTmp.set(key, group.get(key));
        }
      });
      if (group) {
        dataSourceTmp.set('id', group.id);
      }
      this.set('dataSource', dataSourceTmp);
    },
    _copyFromDataSource: function _copyFromDataSource() {
      var _this$action,
        _this$action2,
        _this6 = this;
      var source = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      if (((_this$action = this.action) === null || _this$action === void 0 ? void 0 : _this$action.stepType) === 'check_conditions' || ((_this$action2 = this.action) === null || _this$action2 === void 0 ? void 0 : _this$action2.eventType) === 'trigger') {
        this.updateMatchAllOrMatchAny();
      }
      var basicFieldKeys = this.get('basicFieldKeys');
      var dataSource = source || this.get('dataSource');
      if (basicFieldKeys) {
        basicFieldKeys.forEach(function (key) {
          var field = _this6.get('model').get(key);
          // To skip dynamic fields if not present
          if (field) {
            var value = dataSource[key];
            if (field.isChoice && field.choiceValueKey) {
              var choices = field.choices;
              var selectedChoice = choices.findBy(field.choiceValueKey, dataSource.get(field.get('key')));
              if (selectedChoice) {
                field.set('value', selectedChoice);
              }
            } else if (field.isMultiChoice) {
              if (field.apiValueKey) {
                var values = dataSource[key].map(function (value) {
                  return field.get('options').findBy(field.apiValueKey, value);
                });
                // avoid app breakage incase BE send values not present in options
                values = values.compact();
                field.set('value', values);
              } else {
                field.set('value', dataSource[key]);
              }
            } else if (field.isObject) {
              var objectSource = dataSource.get(field.key);
              field.get('childFields').forEach(function (childField) {
                if (objectSource) {
                  // objectSource will not be present in case of new
                  var _value = objectSource.get(childField.get('key'));
                  if (Ember.isPresent(_value) && childField.isChoice && childField.choiceValueKey) {
                    var _choices = childField.choices;
                    var _selectedChoice = _choices.findBy(childField.choiceValueKey, _value.toString());
                    childField.set('value', _selectedChoice);
                  } else if (Ember.isPresent(_value)) {
                    childField.set('value', objectSource.get(childField.get('key')));
                  }
                }
                if (!_this6.get('isClone')) {
                  childField.updateInitialValue();
                }
                _this6.setDefaultChoiceValue(childField);
              });
            } else if (!Ember.isNone(value)) {
              field.set('value', dataSource[key]);
            } else {
              // In edit, initialValue shouldn't be defaultValue
              if (_this6.get('isEdit')) {
                field.clear();
              }
            }
            if (!_this6.get('isClone')) {
              field.updateInitialValue();
            }
            _this6.setDefaultChoiceValue(field);
          }
        });
      }
    },
    setDefaultChoiceValue: function setDefaultChoiceValue(field) {
      if (field.isChoice && (field.isMandatory || field.makeEmptyChoiceAsDefault) && Ember.isBlank(field.get('value'))) {
        field.setDefaultChoiceValue();
      }
    },
    _copyToDataSource: function _copyToDataSource() {
      var _this7 = this;
      var dataSource = _.isEmpty(this.get('dataSource')) ? Ember.Object.create() : this.get('dataSource');
      var basicFieldKeys = this.get('basicFieldKeys');
      if (basicFieldKeys) {
        basicFieldKeys.forEach(function (key) {
          var field = _this7.get('model').get(key);
          // Dynamic field might not be present for this key
          if (field) {
            if (_this7.get('isEdit') && !field.get('isDirty')) {
              return; // because we will be sending a PATCH request during edit.
            }
            dataSource.set(key, field.getCleanValue());
          }
        });
      }
    },
    submit: function submit() {
      var _this8 = this;
      var dataSource = this.get('dataSource');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this8.validate().then(function () {
          _this8._copyToDataSource();
          dataSource.save().then(function (data) {
            resolve(data);
          }).catch(function (errors) {
            _this8._populateErrors(errors);
            if (!_this8.get('isNew')) {
              dataSource.rollbackAttributes();
              dataSource.rollbackRelationships();
            }
            _this8.scrollToError();
            reject(errors);
          });
        }).catch(function () {
          _this8.scrollToError();
          reject();
        });
      });
    },
    reset: function reset() {
      this.set('errors', Ember.A());
      this.get('fields').forEach(function (field) {
        field.reset();
      });
    },
    reinitialize: function reinitialize() {
      this.get('fields').forEach(function (field) {
        field.updateInitialValue();
      });
    }
  });
});