define("workflows-web/workflows/details/edit/actions/details/check-conditions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    intlService: Ember.inject.service('intl'),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      createNewGroup: function createNewGroup() {
        this.toggleProperty('canShowGroupForm');
      },
      editGroup: function editGroup(group) {
        this.toggleProperty('canShowGroupForm');
        this.set('currentGroup', group);
        var form = this.form;
        var dataSource = Ember.Object.create({});
        form.basicFieldKeys.forEach(function (key) {
          if (key === 'matchAll') {
            dataSource.set('matchAll', group.get('conditions.matchAll'));
          } else {
            dataSource.set(key, group.get(key));
          }
        });
        dataSource.set('id', group.id);
        form.set('dataSource', dataSource);
        form._copyFromDataSource();
      }
    }
  });
});