define("workflows-web/workflows/details/edit/actions/form-model", ["exports", "workflows-web/form-models/base-form", "workflows-web/utilities/request"], function (_exports, _baseForm, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseForm.default.extend({
    requestService: Ember.inject.service('request'),
    scrollableContainerSelector: '.hf-workflows_edit-form',
    init: function init() {
      var _this$action,
        _this = this;
      this._super.apply(this, arguments);
      var rawFields = this.get('rawFields');
      var fields = this._createFieldModels(rawFields);
      this.set('fields', fields);
      this.set('errors', Ember.A());
      var missingFields = (_this$action = this.action) === null || _this$action === void 0 || (_this$action = _this$action.errors) === null || _this$action === void 0 ? void 0 : _this$action.fields;
      fields.forEach(function (field) {
        if (field.isDynamicField || field.isEditor) {
          _this.setActionDetailsChoices(field);
        }
        if (field.isPrimary) {
          _this.set('primaryFieldKey', field.key);
        }
        var isMissingFieldPresent = missingFields === null || missingFields === void 0 ? void 0 : missingFields.find(function (missingField) {
          return missingField.name.camelize() === field.key;
        });
        if (isMissingFieldPresent) {
          field.set('isMissingFieldPresent', true);
        } else {
          field.set('isMissingFieldPresent', false);
        }
        if (field.isMergeFieldsConversionComplete) {
          field.set('isMergeFieldsConversionComplete', false);
        }
        field.set('prefix', _this.prefix);
      });
      if (!_.isEmpty(this.get('dataSource')) && !['create_issue', 'update_issue'].includes(this.action.stepType)) {
        this._copyFromDataSource();
      }
      if (fields.filterBy('isPrimary').length > 1) {
        this.set('isMultiplePrimaryKeysPresent', true);
        this.set('primaryFieldKeys', fields.filterBy('isPrimary').mapBy('key'));
      }
    },
    setActionDetailsChoices: function setActionDetailsChoices(field) {
      var actionDetailsChoices = this.get('actionDetailsChoices');
      var recommendedActionDetailsChoices = this.recommendedActionDetailsChoices;
      var choices = Ember.A();
      if (recommendedActionDetailsChoices !== null && recommendedActionDetailsChoices !== void 0 && recommendedActionDetailsChoices.hasOwnProperty(field.key)) {
        choices.addObject(recommendedActionDetailsChoices[field.key]);
      }
      choices.pushObjects(_.clone(actionDetailsChoices));
      field.set('choices', choices);
    },
    _copyFromDataSource: function _copyFromDataSource() {
      var _this2 = this;
      this._super.apply(this, arguments);
      var fields = this.get('fields');
      if (fields.isAny('isArray') && !['create_work_item'].includes(this.action.stepType)) {
        fields.filterBy('isArray').forEach(function (field) {
          if (field.transformDataSource) {
            var transformedValue = field.transformDataSource(field);
            field.set('value', transformedValue);
          }
          _this2.get("model.".concat(field.key)).createChildFields();
          if (Ember.isPresent(field.childFields)) {
            field.childFields.forEach(function (field) {
              if (field.model) {
                Object.keys(field.model).forEach(function (key) {
                  if (field.model[key].isDynamicField || field.model[key].isEditor) {
                    _this2.setActionDetailsChoices(field.model[key]);
                  }
                });
              }
            });
          }
        });
      }
      if (fields.isAny('requiresToBeFetchedBeforeFormLoad')) {
        fields.filterBy('requiresToBeFetchedBeforeFormLoad').forEach(function (field) {
          if (Ember.isPresent(_this2.dataSource[field.key])) {
            _this2.set('isLoading', true);
            field.actionOnOpen.perform(field).then(function () {
              _this2.set('isLoading', false);
              if (!isNaN(field.value)) {
                var value = field.value;
                var option = field.choices.findBy('groupName', field.groupName).options.findBy('id', Number(value));
                if (Ember.isPresent(option)) {
                  field.set('value', option.name);
                }
              }
            });
          }
        });
      }
      fields.forEach(function (field) {
        if (field.transformDataSource) {
          var transformedValue = field.transformDataSource(field);
          field.set('value', transformedValue);
        }
      });
    },
    _populateErrors: function _populateErrors(errorResponse) {
      var fields = this.get('fields');
      if (errorResponse && (0, _request.isInvalid)(errorResponse.status)) {
        var nonFieldErrorMessage = [];
        errorResponse.errors.forEach(function (error) {
          if (error.field && error.errors) {
            var field = fields.findBy('key', error.field.camelize());
            if (Ember.isPresent(field)) {
              var fieldErrorMessage = [];
              error.errors.forEach(function (err) {
                if (err.field && err.message && field.isArray) {
                  // custom fields - childField value is {id: {id: 1, name: '...'}, value: '....'} where id is primaryObjectKey and value is secondaryObjectKey
                  if (err.value) {
                    var _field$childFieldMeta, _field$childFields;
                    var primaryObjectKey = (_field$childFieldMeta = field.childFieldMetaInfo) === null || _field$childFieldMeta === void 0 ? void 0 : _field$childFieldMeta.primaryObjectKey;
                    var errorArrayField = (_field$childFields = field.childFields) === null || _field$childFields === void 0 ? void 0 : _field$childFields.find(function (childField) {
                      var _childField$value;
                      return ((_childField$value = childField.value) === null || _childField$value === void 0 || (_childField$value = _childField$value[primaryObjectKey]) === null || _childField$value === void 0 ? void 0 : _childField$value.id) === err.value;
                    });
                    if (errorArrayField) {
                      var errorFieldName = field.childFieldMetaInfo.secondaryObjectKey;
                      var errorField = errorArrayField.childFields.find(function (childField) {
                        return childField.key === errorFieldName;
                      });
                      if (errorField) {
                        errorField.setErrors([err.message]);
                      }
                    }
                  } else if (Ember.isPresent(err.index)) {
                    var _errorArrayField$chil;
                    var _errorArrayField = field.childFields[err.index];
                    var _errorFieldName = err.field;
                    var _errorField = _errorArrayField === null || _errorArrayField === void 0 || (_errorArrayField$chil = _errorArrayField.childFields) === null || _errorArrayField$chil === void 0 ? void 0 : _errorArrayField$chil.find(function (childField) {
                      return childField.key === _errorFieldName;
                    });
                    if (_errorField) {
                      _errorField.setErrors([err.message]);
                    }
                  }
                } else if (err.message) {
                  fieldErrorMessage.push(err.message);
                }
              });
              field.setErrors(fieldErrorMessage);
            }
          } else if (error.errors) {
            error.errors.forEach(function (err) {
              if (err.message) {
                nonFieldErrorMessage.push(err.message);
              }
            });
          }
        });
        if (nonFieldErrorMessage.length > 0) {
          this.set('nonFieldErrorMessage', nonFieldErrorMessage);
        } else {
          this.set('nonFieldErrorMessage', null);
        }
        this.scrollToError();
      }
    },
    submit: function submit() {
      var _this3 = this;
      var fields = this.get('fields');
      var dataSource = this.get('dataSource');
      this.set('errors', Ember.A());
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.validate().then(function () {
          _this3._copyToDataSource(fields, dataSource);
          resolve();
        }).catch(function () {
          _this3.scrollToError();
          reject();
        });
      });
    },
    generatePayload: function generatePayload() {
      var includeCleanFields = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var formModel = this.get('model');
      var basicFieldKeys = this.get('basicFieldKeys');
      var payload = Ember.Object.create();
      basicFieldKeys.forEach(function (key) {
        var field = formModel[key];
        var value = field.getCleanValue();
        if (!field.isArray && Ember.isBlank(value)) {
          value = field.value;
        }
        if (field.get('isDirty') || field.get('isMandatory') || Ember.isPresent(value) && includeCleanFields || Ember.isPresent(value) && field.alwaysSendInPayload) {
          if (Ember.isPresent(field.transformValue)) {
            var transformedValue = field.transformValue(field);
            if (Ember.isPresent(transformedValue)) {
              payload = Ember.assign(payload, transformedValue);
            }
          } else {
            payload.set(key, value);
          }
        }
      });
      return payload;
    }
  });
});