define("workflows-web/components/auto-complete/local/multiple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UIVFUP/q",
    "block": "{\"symbols\":[\"result\",\"select\",\"result\",\"select\",\"&default\"],\"statements\":[[6,[37,0],[[35,11]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,10],null,[[\"selectedItemComponent\",\"renderInPlace\",\"searchField\",\"options\",\"selected\",\"placeholder\",\"initiallyOpened\",\"closeOnSelect\",\"onchange\",\"onclose\",\"onfocus\",\"onkeydown\"],[[30,[36,9],[[35,8]],null],[35,7],[35,6],[35,5],[35,4],[35,2],true,[35,3],[30,[36,1],[[32,0],\"onChange\"],null],[30,[36,1],[[32,0],\"onClose\"],null],[30,[36,1],[[32,0],\"onFocus\"],null],[30,[36,1],[[32,0],\"onKeyDown\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,5]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,5,[[32,3],[32,4]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,3]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[3,4]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,10],null,[[\"selectedItemComponent\",\"renderInPlace\",\"searchField\",\"options\",\"selected\",\"closeOnSelect\",\"placeholder\",\"onchange\",\"onclose\",\"onfocus\",\"onkeydown\"],[[30,[36,9],[[35,8]],null],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[30,[36,1],[[32,0],\"onChange\"],null],[30,[36,1],[[32,0],\"onClose\"],null],[30,[36,1],[[32,0],\"onFocus\"],null],[30,[36,1],[[32,0],\"onKeyDown\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,5]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,5,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"placeholder\",\"closeOnSelect\",\"selected\",\"options\",\"searchField\",\"renderInPlace\",\"selectedItemComponent\",\"component\",\"power-select-multiple\",\"autofocus\"]}",
    "meta": {
      "moduleName": "workflows-web/components/auto-complete/local/multiple/template.hbs"
    }
  });
});