define("workflows-web/components/field-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OXucAhIE",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"hf-field-preview\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[1,[30,[36,1],[\"preview\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[15,0,[30,[36,6],[\"hf-field-preview_text\",[30,[36,3],[[30,[36,5],[[35,2],[35,4,[\"isDirty\"]]],null],\"hf-mod-full-text\"],null]],null]],[14,\"data-test-id\",\"hf-field-preview-text\"],[12],[2,\"\\n      \"],[1,[30,[36,8],[[35,7]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,0,\"hf-field-preview_toggle\"],[4,[38,0],[[32,0],\"toggleDescriptionText\"],null],[12],[2,\"\\n        \"],[1,[30,[36,3],[[35,2],[30,[36,1],[\"view-less\"],null],[30,[36,1],[\"view-more\"],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"isShowingFullText\",\"if\",\"field\",\"or\",\"concat-strings\",\"preview\",\"html-safe\",\"canTruncateText\"]}",
    "meta": {
      "moduleName": "workflows-web/components/field-preview/template.hbs"
    }
  });
});