define("workflows-web/components/form-input/multi-choice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2xIC4Z+r",
    "block": "{\"symbols\":[\"field\",\"isSelected\"],\"statements\":[[6,[37,12],null,[[\"options\",\"labelProperty\",\"valueProperty\",\"selection\",\"onchange\",\"data-test-id\",\"class\"],[[35,11],[35,3],[35,10],[35,9],[30,[36,8],[[32,0],\"updateSelection\"],null],[35,7],[30,[36,1],[[30,[36,6],[[35,5],\"hf-is-error\"],null],\" \"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t \"],[10,\"li\"],[15,0,[34,0]],[12],[2,\"\\n\\t   \"],[10,\"label\"],[15,\"data-test-id\",[31,[\"field-\",[32,1,[\"value\"]]]]],[12],[2,\"\\n\\t     \"],[1,[30,[36,2],null,[[\"type\",\"checked\",\"data-test-id\"],[\"checkbox\",[32,2],[30,[36,1],[\"field-\",[32,1,[\"value\"]],\"-checkbox\"],null]]]]],[2,\"\\n\\t     \"],[1,[30,[36,4],[[32,1],[35,3]],null]],[2,\"\\n\\t   \"],[13],[2,\"\\n\\t \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[19,\"components/form-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"optionClass\",\"concat\",\"input\",\"labelProperty\",\"get\",\"hasErrors\",\"if\",\"inputDataTestId\",\"action\",\"selection\",\"valueProperty\",\"options\",\"multiselect-checkboxes\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-input/multi-choice/template.hbs"
    }
  });
});