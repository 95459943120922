define("workflows-web/helpers/format-date", ["exports", "ember-intl/helpers/format-date"], function (_exports, _formatDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _formatDate.default.extend({
    intlService: Ember.inject.service('intl'),
    compute: function compute(value, hash) {
      return this.get('intlService').formatDate(value[0], hash);
    }
  });
});