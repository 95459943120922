define("workflows-web/helpers/concat-strings", ["exports", "workflows-web/utilities/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    var nonEmptyStrings = params.filter(function (param) {
      return !Ember.isBlank(param); // removes "", null, undefined
    });
    return (0, _string.getSafeHtml)(nonEmptyStrings.join(' '));
  });
});