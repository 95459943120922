define("workflows-web/form-field-models/date-field", ["exports", "workflows-web/form-field-models/base-field", "workflows-web/utilities/date"], function (_exports, _baseField, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseField.default.extend({
    isDate: true,
    minDate: null,
    maxDate: null,
    validate: function validate() {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          // date check
          if (!_this.get('isDynamicDate')) {
            // Dynamic date has its own validation that is affected by the validation here
            var value = _this.get('value');
            // https://github.com/moment/moment/issues/1407
            if (!Ember.isBlank(value) && !moment(value).isValid()) {
              _this.addError(_this.get('intlService').findTranslationByKey('validation.invalid-date-message'));
              reject();
            }
            _this.set('_cleanValue', value ? (0, _date.convertDateToApiFormat)(value) : null);
          }
          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });
});