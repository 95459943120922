define("workflows-web/components/slide-in/component", ["exports", "ember-wormhole/components/ember-wormhole", "workflows-web/utilities/run-loop"], function (_exports, _emberWormhole, _runLoop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberWormhole.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('to', 'body');
    },
    isOpen: false,
    canHide: true,
    isHidden: false,
    hideOverlay: false,
    onClose: null,
    modifierClass: '',
    actions: {
      toggleVisibility: function toggleVisibility() {
        this.toggleProperty('isHidden');
        this.toggleProperty('hideOverlay');
      },
      close: function close() {
        var _this = this;
        this.set('isHidden', true);
        (0, _runLoop.runLater)(this, function () {
          if (!_this.get('isDestroyed')) {
            _this.set('isOpen', false);
            if (_this.afterClose) {
              _this.afterClose();
            }
          }
        }, 200);
        if (this.onClose) {
          this.onClose();
        }
      }
    }
  });
});