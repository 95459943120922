define("workflows-web/utilities/run-loop", ["exports", "workflows-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.runLater = runLater;
  function runLater(context, method, waitTime) {
    if (_environment.default.environment === 'test') {
      Ember.run.bind(context, method)(); // binds the context and runs immediately.
    } else {
      Ember.run.later(context, method, waitTime);
    }
  }
});