define("workflows-web/router", ["exports", "workflows-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    init: function init() {
      this._super();
      this.setPageTitle();
    },
    setPageTitle: function setPageTitle() {
      this.on('routeDidChange', function (transition) {
        if (transition.to) {
          var toRouteName = transition.to.name;
          var pageTitles = {
            'workflows.list': function workflowsList() {
              return 'View Workflows';
            },
            'workflows.add': function workflowsAdd() {
              return 'Create Workflow';
            },
            'workflows.details.index': function workflowsDetailsIndex() {
              return "".concat(transition.pageTitleInfo.name, " webhook setup");
            },
            'workflows.details.edit': function workflowsDetailsEdit() {
              return transition.pageTitleInfo.name ? "".concat(transition.pageTitleInfo.name) : "Invalid Workflow";
            },
            'workflows.history.list': function workflowsHistoryList() {
              return 'Workflows Execution History';
            },
            'workflows.history.details': function workflowsHistoryDetails() {
              return "".concat(transition.pageTitleInfo.name, " - History");
            },
            'workflows.apps.details': function workflowsAppsDetails() {
              return "Apps - ".concat(transition.pageTitleInfo.name.capitalize(), " - Setup");
            },
            'forms.list': function formsList() {
              return 'Forms';
            },
            'forms.details.edit': function formsDetailsEdit() {
              return "Forms - ".concat(transition.pageTitleInfo.name);
            },
            'manage.index': function manageIndex() {
              return 'Manage';
            },
            'manage.agents.list': function manageAgentsList() {
              return 'Manage Agents';
            }
          };
          var titleSegments = [];
          var titleSetter = pageTitles[toRouteName];
          if (titleSetter) {
            titleSegments.push(titleSetter());
          }
          titleSegments.push('HappyFox Workflows');
          document.title = titleSegments.join(' - ');
        }
      });
    }
  });
  Router.map(function () {
    this.route('workflows', {
      path: '/'
    }, function () {
      this.route('list', {
        path: '/workflows/'
      });
      this.route('details', {
        path: 'workflow/:id/'
      }, function () {
        this.route('edit');
      });
      this.route('add');
      this.route('history', {
        path: 'workflows/history/'
      }, function () {
        this.route('list', {
          path: '/'
        });
        this.route('details', {
          path: '/:id/'
        });
      });
      this.route('apps', {
        path: 'workflows/apps/'
      }, function () {
        this.route('details', {
          path: '/:name/'
        });
      });
      this.route('library', {
        path: 'workflows/library/'
      }, function () {
        this.route('list', {
          path: '/'
        });
        this.route('details', {
          path: '/:id/'
        });
      });
      this.route('dashboard');
    });
    this.route('forms', function () {
      this.route('list', {
        path: '/'
      });
      this.route('details', {
        path: '/:id/'
      }, function () {
        this.route('edit', {
          path: '/edit/'
        });
      });
      this.route('new');
    });
    this.route('manage', function () {
      this.route('agents', function () {
        this.route('list', {
          path: '/'
        }, function () {
          this.route('new');
          this.route('edit', {
            path: '/:id/edit/'
          });
        });
      });
    });
    this.route('login');
    this.route('register');
    this.route('email-verification');
    this.route('reset-password');
    this.route('my-settings');
    this.route('logout');
    this.route('access-link', {
      path: 'apps/:name/login/'
    });
    this.route('signup');
    this.route('signup-verification');
  });
  var _default = _exports.default = Router;
});