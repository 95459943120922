define("workflows-web/components/form-field-input/choice/component", ["exports", "workflows-web/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    selectClass: '',
    dropdownClass: '',
    allowClear: Ember.computed.not('field.isMandatory'),
    jsTestClass: Ember.computed('fieldDataTestId', {
      get: function get() {
        var fieldDataTestId = this.get('fieldDataTestId');
        if (fieldDataTestId) {
          return "hf-js-test-".concat(fieldDataTestId);
        }
        return '';
      }
    }),
    actions: {
      valueChanged: function valueChanged(newChoice) {
        var oldChoice = this.get('field.value');
        this.set('field.value', newChoice);
        // TODO: How will this work with refactoring?
        if (this.onChange) {
          this.onChange(newChoice, oldChoice);
        }
      },
      onClose: function onClose() {
        this.get('field').validate();
        this.set('field.isHighlighted', false);
        if (this.onClose) {
          this.onClose();
        }
      },
      onOpen: function onOpen() {
        if (this.onOpen) {
          this.onOpen();
        }
      }
    }
  });
});