define("workflows-web/workflows/mixins/happyfox-chatbot/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    requestService: Ember.inject.service('request'),
    intlService: Ember.inject.service('intl'),
    getHappyFoxChatbotActions: function getHappyFoxChatbotActions() {
      var _this = this;
      var actionClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var workflowActionChoices = Ember.A();
      var sendMessageActionFields = actionClass.create({
        stepType: 'send_message',
        application: 'happyfox-chatbot',
        color: '#4c29e7',
        rawFields: [Ember.Object.create({
          label: 'Embed Token',
          key: 'embedToken',
          isDynamicField: true,
          isMandatory: true
        }), Ember.Object.create({
          label: 'Conversation ID',
          key: 'conversationId',
          isDynamicField: true,
          isMandatory: true
        }), Ember.Object.create({
          label: 'Message',
          key: 'text',
          isDynamicField: true,
          isMandatory: true
        })]
      });
      var quickReplyActionFields = actionClass.create({
        stepType: 'quick_reply',
        application: 'happyfox-chatbot',
        color: '#44516c',
        component: 'workflows/details/edit/actions/details/quick-reply-choices',
        rawFields: [Ember.Object.create({
          label: 'Embed Token',
          key: 'embedToken',
          isDynamicField: true,
          isMandatory: true
        }), Ember.Object.create({
          label: 'Conversation ID',
          key: 'conversationId',
          isDynamicField: true,
          isMandatory: true
        }), Ember.Object.create({
          label: 'Choices',
          key: 'choices',
          isArray: true,
          childFieldMetaInfo: Ember.Object.create({
            key: 'choice',
            isObject: true,
            primaryObjectKey: 'type',
            secondaryObjectKey: 'value',
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              label: 'Name of the option shown to user',
              key: 'text',
              isDynamicField: true,
              isMandatory: true
            }), Ember.Object.create({
              label: 'What happens after user selects the option?',
              key: 'type',
              isChoice: true,
              choiceLabelKey: 'label',
              choiceValueKey: 'value',
              isMandatory: true,
              choices: Ember.A([{
                value: 'goToStep',
                label: 'goToStep'
              }])
            }), Ember.Object.create({
              label: 'What is the next step?',
              key: 'value',
              isDynamicField: true,
              isMandatory: false
            })])
          })
        })]
      });
      var endConversationActionFields = actionClass.create({
        stepType: 'end_conversation',
        application: 'happyfox-chatbot',
        color: '#be2649',
        rawFields: [Ember.Object.create({
          label: 'Embed Token',
          key: 'embedToken',
          isDynamicField: true,
          isMandatory: true
        }), Ember.Object.create({
          label: 'Conversation ID',
          key: 'conversationId',
          isDynamicField: true,
          isMandatory: true
        })]
      });
      workflowActionChoices.addObjects([sendMessageActionFields, quickReplyActionFields, endConversationActionFields]);
      workflowActionChoices.forEach(function (action) {
        action.displayName = _this.intlService.findTranslationByKey(action.stepType);
        action.eventType = 'action';
      });
      return workflowActionChoices;
    }
  });
});