define("workflows-web/components/bread-crumbs", ["exports", "ember-crumbly/components/bread-crumbs"], function (_exports, _breadCrumbs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _breadCrumbs.default.extend({
    tagName: 'ul',
    linkable: true,
    crumbClass: 'breadcrumb_item'
  });
});