define("workflows-web/components/form-input/choice/component", ["exports", "workflows-web/components/form-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    choices: null,
    choiceValueProperty: 'id',
    selectClass: '',
    dropdownClass: '',
    isDisabled: false,
    isHighlighted: false,
    showHelpText: false,
    selectedChoice: Ember.computed('value', {
      get: function get() {
        if (!this.get('value')) {
          return null;
        } else {
          return this.get('choices').findBy(this.get('choiceValueProperty'), this.get('value'));
        }
      }
    }),
    allowClear: Ember.computed.not('isMandatory'),
    didInsertElement: function didInsertElement() {
      var _this = this;
      if (this.get('isInputDisabled')) {
        this.$('.ember-power-select-trigger-multiple-input').prop('disabled', true);
      } else if (this.get('autofocus')) {
        Ember.run.next(function () {
          _this.$('input').focus();
        });
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this2 = this;
      if (this.get('autofocus')) {
        Ember.run.next(function () {
          _this2.$('input').focus();
        });
      }
    },
    validate: function validate() {
      var value = this.get('value');
      var isValueEmpty = Ember.isEmpty(value);
      if (!isValueEmpty && !this.get('choices').isAny('id', value)) {
        this.set('value', null);
      }
      this.initializeErrors();
      if (isValueEmpty && this.get('isMandatory')) {
        this.addError(this.get('intlService').findTranslationByKey('validation.mandatory-error-message'));
      }
    },
    actions: {
      valueChanged: function valueChanged(newChoice) {
        // power-select sets the newChoice as `undefined` if we type some random text in search and hit enter.
        if (newChoice !== undefined) {
          var oldChoice = this.get('selectedChoice');
          this.set('selectedChoice', newChoice);
          if (this.onChange) {
            this.onChange(newChoice, oldChoice);
          }
        }
      },
      onClose: function onClose() {
        this.validate();
        this.set('isHighlighted', false);
        if (this.onClose) {
          this.onClose();
        }
      }
    }
  });
});