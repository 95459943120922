define("workflows-web/components/slide-in/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aHEbTQtQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,7],[[35,6]],null]],[2,\"\\n\"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"section\"],[15,0,[31,[\"hf-u-content-overlay \",[34,5],\" \",[30,[36,1],[[35,0],\"hf-overlay-fade-out\",\"hf-overlay-fade-in\"],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"aside\"],[15,0,[31,[\"hf-u-slide-in \",[34,5],\" \",[30,[36,1],[[35,0],\"hf-is-hidden\"],null],\" \",[30,[36,1],[[35,10],\"hf-mod-mini\"],null]]]],[12],[2,\"\\n\"],[6,[37,9],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,0,\"hf-form-header_close\"],[24,\"data-test-id\",\"slide-in-close\"],[4,[38,3],[[32,0],\"close\"],null],[12],[2,\"\\n        \"],[1,[30,[36,4],[\"close-details\"],[[\"class\"],[\"hf-mod-close-svg\"]]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[16,0,[31,[\"hf-slide-out-button \",[30,[36,1],[[35,0],\"hf-is-hidden hf-tooltipped hf-tooltipped-left\"],null]]]],[16,\"aria-label\",[30,[36,1],[[35,0],[35,2]],null]],[4,[38,3],[[32,0],\"toggleVisibility\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[18,1,[[30,[36,3],[[32,0],\"toggleVisibility\"],null],[30,[36,3],[[32,0],\"close\"],null]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,7],[[35,12]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isHidden\",\"if\",\"tooltipText\",\"action\",\"inline-svg\",\"modifierClass\",\"_wormholeHeadNode\",\"unbound\",\"hideOverlay\",\"unless\",\"isMini\",\"canHide\",\"_wormholeTailNode\"]}",
    "meta": {
      "moduleName": "workflows-web/components/slide-in/template.hbs"
    }
  });
});