define("workflows-web/workflows/details/edit/actions/action/trigger/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['hf-workflows-action-select_trigger'],
    init: function init() {
      this._super.apply(this, arguments);
      var _this$extra = this.extra,
        workflowAction = _this$extra.workflowAction,
        parentAction = _this$extra.parentAction;
      workflowAction.set('parentAction', parentAction);
    },
    displayOrder: Ember.computed('extra.workflowAction.prefix', {
      get: function get() {
        var workflowAction = this.extra.workflowAction;
        return workflowAction.prefix;
      }
    }),
    color: Ember.computed('extra.selectedAction', {
      get: function get() {
        var _this$extra2, _this$extra3, _this$extra4;
        if (((_this$extra2 = this.extra) === null || _this$extra2 === void 0 || (_this$extra2 = _this$extra2.selectedAction) === null || _this$extra2 === void 0 ? void 0 : _this$extra2.eventType) === 'trigger' || ((_this$extra3 = this.extra) === null || _this$extra3 === void 0 || (_this$extra3 = _this$extra3.workflowAction) === null || _this$extra3 === void 0 ? void 0 : _this$extra3.eventType) === 'trigger') {
          return "background: #C69000; color: #fff;";
        } else if ((_this$extra4 = this.extra) !== null && _this$extra4 !== void 0 && _this$extra4.parentAction) {
          return "background: #9BB1C6; color: #fff;";
        }
        return "background: #70A5D9; color: #fff;";
      }
    })
  });
});