define("workflows-web/workflows/mixins/asana/triggers", ["exports", "workflows-web/workflows/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    intlService: Ember.inject.service('intl'),
    accountInfoService: Ember.inject.service('account-info'),
    getAsanaTriggers: function getAsanaTriggers() {
      var _this = this;
      var triggerClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var asanaTriggerChoices = Ember.A();
      var taskUpdated = triggerClass.create({
        stepType: 'task_updated',
        eventType: 'trigger',
        application: 'asana',
        color: '#102aac',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS,
        isInstantTrigger: true
      });
      var taskCreated = triggerClass.create({
        stepType: 'task_created',
        eventType: 'trigger',
        application: 'asana',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS
      });
      var subTaskCreated = triggerClass.create({
        stepType: 'sub_task_created',
        eventType: 'trigger',
        application: 'asana',
        component: 'workflows/details/edit/triggers',
        rawFields: _constants.TRIGGER_RAW_FIELDS
      });
      asanaTriggerChoices.addObjects([taskUpdated, taskCreated, subTaskCreated]);
      asanaTriggerChoices.forEach(function (trigger) {
        trigger.displayName = _this.intlService.findTranslationByKey(trigger.stepType);
      });
      return asanaTriggerChoices;
    }
  });
});