define("workflows-web/components/form-input/number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DGWiUE6f",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,12],null,[[\"type\",\"value\",\"focus-out\",\"enter\",\"class\",\"data-test-id\",\"classNames\",\"placeholder\",\"min\",\"step\"],[\"number\",[35,11],[30,[36,10],[[32,0],\"onFocusOut\"],null],[30,[36,10],[[32,0],\"onEnter\"],null],[30,[36,9],[[30,[36,8],[\"hf-form-input hf-number-input\",[30,[36,6],[[35,7],\"hf-is-highlighted\"],null]],null],\" \",[30,[36,6],[[35,5],\"hf-is-error\"],null],\" \"],null],[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\\n\"],[19,\"components/form-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"step\",\"min\",\"placeholder\",\"inputClass\",\"inputDataTestId\",\"hasErrors\",\"if\",\"isHighlighted\",\"concat-strings\",\"concat\",\"action\",\"value\",\"input\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-input/number/template.hbs"
    }
  });
});