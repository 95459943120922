define("workflows-web/manage/agents/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PERMISSIONS = void 0;
  var PERMISSIONS = _exports.PERMISSIONS = [{
    code: 'manage_billing',
    name: 'Manage Billing'
  }];
});