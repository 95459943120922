define("workflows-web/components/form-field-input/date-time/component", ["exports", "workflows-web/components/form-field-input/base", "workflows-web/utilities/time"], function (_exports, _base, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    minDateTime: Ember.computed('field.minDateTime', {
      get: function get() {
        var minDateTime = this.get('field.minDateTime');
        if (minDateTime) {
          // to convert minDateTime to staff timezone.
          return (0, _time.convertMomentToDateTime)(moment(minDateTime));
        }
      }
    }),
    actions: {
      onDateSelect: function onDateSelect() {
        var field = this.get('field');
        if (field.get('selectDefaultTimeOnDateSelect')) {
          var hourField = field.get('hourField');
          var minuteField = field.get('minuteField');
          if (Ember.isBlank(hourField.get('value'))) {
            hourField.set('value', hourField.get('choices')[10]); // sets hour to 11
          }
          if (Ember.isBlank(minuteField.get('value'))) {
            minuteField.set('value', minuteField.get('choices')[59]); // sets minute to 59
          }
          if (Ember.isBlank(field.get('meridiem'))) {
            field.set('meridiem', 'PM');
          }
        }
      },
      validate: function validate() {
        this.get('field').validate();
      },
      setMeridiem: function setMeridiem(value) {
        var field = this.get('field');
        field.set('meridiem', value);
        field.validate();
      }
    }
  });
});