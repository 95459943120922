define("workflows-web/components/password-policy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xPH96yoV",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"section\"],[17,1],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"password-policy.heading\"],null]],[2,\":\"],[13],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"password-policy-list\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"password-policy-list_item\"],[12],[1,[30,[36,0],[\"password-policy.minimum-character\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"password-policy-list_item\"],[12],[1,[30,[36,0],[\"password-policy.maximum-character\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"password-policy-list_item\"],[12],[1,[30,[36,0],[\"password-policy.one-lowercase\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"password-policy-list_item\"],[12],[1,[30,[36,0],[\"password-policy.one-uppercase\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"password-policy-list_item\"],[12],[1,[30,[36,0],[\"password-policy.one-number\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"password-policy-list_item\"],[12],[1,[30,[36,0],[\"password-policy.one-special-character\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\" \"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "workflows-web/components/password-policy/template.hbs"
    }
  });
});