define("workflows-web/components/auto-complete/local/single", ["exports", "ember-power-select/components/power-select", "workflows-web/utilities/run-loop"], function (_exports, _powerSelect, _runLoop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _powerSelect.default.extend({
    initiallyOpened: true,
    renderInPlace: true,
    triggerClass: 'hf-u-hide',
    dropdownClass: 'hf-mod-auto-complete-dropdown',
    onSelectFunction: null,
    closeOnSelect: false,
    canClose: false,
    closePopOverOnSelect: true,
    tagName: 'div',
    didInsertElement: function didInsertElement() {
      var _this = this;
      (0, _runLoop.runLater)(this, function () {
        if (!_this.isDestroyed) {
          _this.$('input.ember-power-select-search-input').focus();
        }
      }, 200);
    },
    actions: {
      select: function select(dropdown, selected, e) {
        if (this.get('onSelectFunction')) {
          this.get('onSelectFunction')();
        }
        if (this.get('closePopOverOnSelect')) {
          var popOver = this.get('popOver');
          if (popOver) {
            popOver.closePopOver();
          }
        }
        this._super(dropdown, selected, e);
      },
      onClose: function onClose() {
        if (!this.get('canClose')) {
          return false;
        }
        return this._super.apply(this, arguments);
      },
      search: function search(term) {
        if (Ember.isBlank(term)) {
          this._resetSearch();
        } else {
          this._performFilter(term);
        }
        if (this.get('search')) {
          this.get('search')(term);
        }
      }
    }
  });
});