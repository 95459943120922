define("workflows-web/components/form-input/number/component", ["exports", "workflows-web/components/form-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    allowOnlyIntegerValues: false,
    allowOnlyPositiveIntegerValues: false,
    autoFocus: false,
    step: 'any',
    isHighlighted: false,
    didInsertElement: function didInsertElement() {
      if (this.get('autoFocus')) {
        this.$('input').focus();
      }
    },
    validate: function validate() {
      this.initializeErrors();
      this.checkIfMandatory();
      this.checkForNumber();
      if (Ember.isEmpty(this.get('value'))) {
        this.set('value', null);
      }
    },
    keyDown: function keyDown(e) {
      if (e.keyCode === 69) {
        // keycode 69 is letter e
        e.preventDefault();
      }
      if (this.get('allowOnlyIntegerValues')) {
        if (e.keyCode === 190) {
          // keycode 190 is '.'
          e.preventDefault();
        }
      }
      if (this.get('allowOnlyPositiveIntegerValues')) {
        if (e.keyCode === 189) {
          // keycode 189 is '-'
          e.preventDefault();
        }
      }
    },
    actions: {
      onFocusOut: function onFocusOut() {
        this.set('isHighlighted', false);
        this.validate();
      },
      onEnter: function onEnter() {
        this.validate();
        if (this.onEnter) {
          this.onEnter();
        }
      }
    }
  });
});