define("workflows-web/workflows/details/edit/actions/details/azure-active-directory/label-help-text/additional-filters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bzHv17d6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"hf-u-paragraph\"],[12],[2,\"Enter a \"],[10,\"a\"],[14,0,\"hf-u-link-color hf-u-cursor-pointer\"],[14,6,\"https://support.happyfox.com/kb/article/1318-using-azure-ad-integration-in-workflows-advanced-user-filtering/\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"search query parameter\"],[13],[2,\". For example:\"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\\"department:Dev Team\\\" OR \\\"jobTitle:developer\\\"\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/azure-active-directory/label-help-text/additional-filters/template.hbs"
    }
  });
});