define("workflows-web/workflows/details/edit/actions/details/update-task/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PcoqFII6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-vertical-flex-container hf-workflows_form-group\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,0],[\"step-{index}\"],[[\"index\"],[\"1\"]]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"field\",\"onValueChange\"],[[35,2,[\"model\",\"ticket\"]],[30,[36,1],[[32,0],\"onTicketIdChange\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[\"hf-u-vertical-flex-container hf-workflows_form-group hf-workflows_form-group-body  \",[30,[36,5],[[35,4],\"hf-u-disabled\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,0],[\"step-{index}\"],[[\"index\"],[\"2\"]]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"field\",\"actionOnOpen\",\"onSearch\"],[[35,2,[\"model\",\"tasks\"]],[35,6],[35,6]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"field\"],[[35,2,[\"model\",\"name\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"field\"],[[35,2,[\"model\",\"assignee\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"field\"],[[35,2,[\"model\",\"dueAt\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"field\"],[[35,2,[\"model\",\"isCompleted\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"form\",\"form-field\",\"isDisabled\",\"if\",\"fetchTaskNameAutocompleteResults\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/update-task/template.hbs"
    }
  });
});