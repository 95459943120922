define("workflows-web/components/form-field-input/choice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cumxSKct",
    "block": "{\"symbols\":[\"choice\",\"&default\"],\"statements\":[[6,[37,11],null,[[\"tagName\",\"options\",\"selected\",\"class\",\"placeholder\",\"onchange\",\"onclose\",\"onopen\",\"renderInPlace\",\"disabled\",\"searchField\",\"allowClear\",\"data-test-id\",\"dropdownClass\",\"initiallyOpened\"],[\"div\",[35,0,[\"choices\"]],[35,0,[\"value\"]],[30,[36,10],[[35,9],[35,8],[30,[36,2],[[35,7],\"hf-is-error\"],null],[30,[36,2],[[35,0,[\"isHighlighted\"]],\"hf-is-highlighted\"],null],[30,[36,2],[[35,0,[\"isDisabled\"]],\"hf-is-disabled\"],null]],null],[35,0,[\"placeholder\"]],[30,[36,6],[[32,0],\"valueChanged\"],null],[30,[36,6],[[32,0],\"onClose\"],null],[30,[36,6],[[32,0],\"onOpen\"],null],true,[35,0,[\"isDisabled\"]],[35,0,[\"choiceLabelKey\"]],[35,5],[35,4],[35,3],[35,0,[\"autoFocus\"]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0,[\"choiceLabelKey\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,1],[35,0,[\"choiceLabelKey\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"field\",\"get\",\"if\",\"dropdownClass\",\"fieldDataTestId\",\"allowClear\",\"action\",\"hasErrors\",\"jsTestClass\",\"selectClass\",\"concat-strings\",\"power-select\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/choice/template.hbs"
    }
  });
});