define("workflows-web/workflows/apps/details/salesforce/salesforce-objects/component", ["exports", "workflows-web/workflows/apps/constants", "workflows-web/utilities/form-models", "workflows-web/utilities/object"], function (_exports, _constants, _formModels, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    intlService: Ember.inject.service('intl'),
    requestService: Ember.inject.service('request'),
    saveInProgress: false,
    app: null,
    defaultSalesforceObjects: null,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('defaultSalesforceObjects', _constants.DEFAULT_SALESFORCE_OBJECTS);
      this.set('isFetchingInstanceObjects', true);
      this.get('requestService').get('integrations/salesforce/instance-objects/').then(function (instanceObjects) {
        instanceObjects = (0, _object.camelizeObject)(instanceObjects);
        var additionalObjects = _this.get('app.settings.additionalObjects') || [];
        _this.set('additionalObjects', additionalObjects);
        var choices = instanceObjects.filter(function (object) {
          return !_constants.DEFAULT_SALESFORCE_OBJECTS.includes(object.get('name')) && !additionalObjects.findBy('name', object.get('name'));
        });
        var addObjectsField = (0, _formModels.createField)(_this, Ember.Object.create({
          key: 'role',
          isChoice: true,
          choices: choices,
          choiceLabelKey: 'labelPlural',
          autoFocus: true,
          placeholder: _this.get('intlService').findTranslationByKey('apps.salesforce.add-additional-objects')
        }));
        _this.set('addObjectsField', addObjectsField);
      }).catch(function (_ref) {
        var errors = _ref.errors;
        if (errors && errors.detail) {
          _this.get('toastMessageService').showMessage({
            type: 'failure',
            text: errors.detail
          });
        }
      }).finally(function () {
        _this.set('isFetchingInstanceObjects', false);
      });
    },
    actions: {
      toggleAddObjects: function toggleAddObjects() {
        this.toggleProperty('isShowingAddObjectsField');
      },
      addObject: function addObject(object) {
        this.get('additionalObjects').addObject(object);
        this.get('addObjectsField').get('choices').removeObject(object);
      },
      removeObject: function removeObject(object) {
        this.get('additionalObjects').removeObject(object);
        this.get('addObjectsField').get('choices').addObject(object);
      },
      saveObjectSettings: function saveObjectSettings() {
        var _this2 = this;
        var additionalObjects = this.get('additionalObjects');
        var data = {
          additionalObjects: additionalObjects
        };
        this.set('isSaving', true);
        this.get('requestService').patch("integrations/salesforce/", data).then(function () {
          _this2.get('toastMessageService').showMessage({
            type: 'success',
            text: 'apps.save-settings-success-message'
          });
        }).catch(function (_ref2) {
          var errors = _ref2.errors;
          if (errors.configuredObjects) {
            _this2.get('toastMessageService').showMessage({
              type: 'failure',
              text: errors.additionalObjects[0]
            });
          }
        }).finally(function () {
          _this2.set('isSaving', false);
        });
      }
    }
  });
});