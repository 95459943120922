define("workflows-web/initializers/injectors/toast-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.create({
    name: 'inject-toast-message-service',
    initialize: function initialize(application) {
      application.inject('controller', 'toastMessageService', 'service:toast-message');
      application.inject('component', 'toastMessageService', 'service:toast-message');
    }
  });
});