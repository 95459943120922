define("workflows-web/workflows/apps/details/shopify/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FgcdBkZ2",
    "block": "{\"symbols\":[],\"statements\":[[19,\"workflows/apps/details/partials/base-form\",[]],[2,\"\\n\"],[10,\"section\"],[14,0,\"hf-app-manage-section_container\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"hf-manage-app-form_heading\"],[12],[1,[30,[36,0],[\"apps.callback-url\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-app-manage-section_box\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"value\",\"class\"],[[35,1,[\"callbackUrl\"]],\"hf-form-field-input hf-text-input\"]]]],[2,\"\\n\"],[6,[37,3],null,[[\"class\",\"tagName\",\"value\"],[\"hf-primary-action hf-u-margin-top-10 hf-tooltipped hf-tooltipped-top\",\"button\",[35,1,[\"callbackUrl\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"apps.copy-url\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":true,\"upvars\":[\"t\",\"app\",\"input\",\"copy-to-clipboard\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/apps/details/shopify/template.hbs"
    }
  });
});