define("workflows-web/form-field-models/remote-multi-choice-dropdown-field", ["exports", "workflows-web/form-field-models/base-field"], function (_exports, _baseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseField.default.extend({
    choiceLabelKey: null,
    choiceValueKey: null,
    isRemoteMultiChoiceDropdown: true,
    isAddNew: false,
    lookupUrl: '',
    searchParam: '',
    autoFocus: false,
    characterLimit: null,
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('value')) {
        this.set('value', []);
      }
      this.updateInitialValue();
    },
    isDirty: Ember.computed('value.[]', '_initialValue.[]', {
      get: function get() {
        var choiceLabelKey = this.get('choiceLabelKey');
        var initialValue = this.get('_initialValue');
        // _.isEqual([1, 2, 3], [3, 2, 1]) returns false as ordering is different. So using sortBy
        if (choiceLabelKey) {
          return !_.isEqual(initialValue.sortBy(choiceLabelKey), this.get('value').sortBy(choiceLabelKey));
        } else {
          if (initialValue) {
            initialValue.sort();
          }
          return !_.isEqual(initialValue, this.get('value').slice().sort());
        }
      }
    }),
    updateInitialValue: function updateInitialValue() {
      var value = this.get('value');
      this.set('_initialValue', Ember.isArray(value) ? value.slice() : Ember.A());
    },
    reset: function reset() {
      this._super();
      var initialValue = this.get('_initialValue');
      this.set('value', Ember.isArray(initialValue) ? initialValue.slice() : Ember.A());
    },
    validate: function validate() {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          var value = _this.get('value');
          if (value) {
            var choiceValueKey = _this.get('choiceValueKey');
            _this.set('_cleanValue', choiceValueKey ? value.mapBy(choiceValueKey) : value);
          }
          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });
});