define("workflows-web/components/form-field-input/color/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/a4WaXJw",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[1,[30,[36,6],null,[[\"color\",\"onChange\",\"data-test-id\",\"class\"],[[35,5,[\"value\"]],[30,[36,4],[[32,0],\"onChange\"],null],[35,3],[30,[36,2],[[30,[36,1],[[35,0],\"hf-is-error\"],null],\" \"],null]]]]],[2,\"\\n\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"hasErrors\",\"if\",\"concat\",\"fieldDataTestId\",\"action\",\"field\",\"color-picker\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/color/template.hbs"
    }
  });
});