define("workflows-web/application/staff-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "luKN5fg/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ul\"],[14,0,\"hf-staff-menu_list\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"hf-staff-menu_item hf-mod-staff-name\"],[14,\"data-test-id\",\"staff-menu_name\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"user\",\"class\"],[[35,1],\"hf-u-push-right\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"hf-staff-menu_item\"]],[[\"@tagName\",\"@route\"],[\"li\",\"my-settings\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[10,\"a\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,3],[\"gear-black\"],[[\"class\"],[\"hf-u-vertical-bottom hf-staff-menu_item_icon\"]]]],[2,\"\\n    \\t\"],[10,\"span\"],[12],[1,[30,[36,4],[\"my-settings\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[11,\"li\"],[24,0,\"hf-staff-menu_item hf-mod-logout\"],[4,[38,5],[[32,0],\"logout\"],null],[12],[2,\"\\n    \"],[10,\"a\"],[12],[1,[30,[36,3],[\"logout\"],[[\"class\"],[\"hf-u-vertical-bottom hf-staff-menu_item_icon\"]]]],[2,\" \"],[1,[30,[36,4],[\"logout\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"userName\",\"user\",\"staff-thumbnail\",\"inline-svg\",\"t\",\"action\"]}",
    "meta": {
      "moduleName": "workflows-web/application/staff-menu/template.hbs"
    }
  });
});