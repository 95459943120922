define("workflows-web/helpers/join-strings", ["exports", "workflows-web/utilities/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    var nonEmptyStrings = params.filter(function (param) {
      return !Ember.isBlank(param); // removes "", null, undefined
    });
    if (nonEmptyStrings.length === 1 && Ember.isArray(nonEmptyStrings[0])) {
      return (0, _string.getSafeHtml)(nonEmptyStrings[0].join(', '));
    } else {
      return (0, _string.getSafeHtml)(nonEmptyStrings.join(', '));
    }
  });
});