define("workflows-web/form-field-models/object-field", ["exports", "workflows-web/form-field-models/base-field"], function (_exports, _baseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseField.default.extend({
    isObject: true,
    childFields: null,
    model: Ember.computed('childFields.[]', {
      get: function get() {
        var fieldModels = Ember.Object.create();
        this.get('childFields').forEach(function (fieldItem) {
          fieldModels.set(fieldItem.get('key'), fieldItem);
        });
        return fieldModels;
      }
    }),
    getValueCopy: function getValueCopy() {
      var valueObject = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      // FIXME: Will fail for ember model related objects
      valueObject = valueObject || this.get('value');
      if (valueObject) {
        valueObject = Ember.Object.create(valueObject); // Converting to ember object
      }
      return _.cloneDeep(valueObject);
    },
    isDirty: Ember.computed('childFields.@each.isDirty', {
      get: function get() {
        var isDirty = this.get('childFields').isAny('isDirty');
        return isDirty;
      }
    }),
    value: Ember.computed('childFields.@each.value', {
      get: function get() {
        var value = Ember.Object.create();
        this.get('childFields').forEach(function (child) {
          value.set(child.get('key'), child.get('value'));
        });
        return value;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    hasErrors: Ember.computed('childFields.@each.hasErrors', {
      get: function get() {
        return Ember.isPresent(this.get('errors')) || this.get('childFields').isAny('hasErrors');
      }
    }),
    hasValueIfMandatory: Ember.computed('childFields.@each.hasValueIfMandatory', {
      get: function get() {
        return this.get('childFields').isEvery('hasValueIfMandatory');
      }
    }),
    updateInitialValue: function updateInitialValue() {
      this.get('childFields').forEach(function (child) {
        child.updateInitialValue();
      });
      var value = Ember.Object.create();
      var intialValue = Ember.Object.create();
      this.get('childFields').forEach(function (child) {
        intialValue.set(child.get('key'), child.get('_initialValue'));
        value.set(child.get('key'), child.get('value'));
      });
      this.set('_initialValue', intialValue);
      this.set('value', value);
    },
    validate: function validate() {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._initializeErrors();
        var promises = Ember.Object.create();
        _this.get('childFields').forEach(function (child) {
          promises.set(child.get('key'), child.validate());
        });
        Ember.RSVP.hash(promises).then(function () {
          resolve();
        }).catch(function () {
          reject();
        });
      });
    },
    reset: function reset() {
      this.set('errors', Ember.A());
      this.get('childFields').forEach(function (child) {
        child.reset();
      });
    },
    getCleanValue: function getCleanValue() {
      var cleanValue = Ember.Object.create();
      this.get('childFields').forEach(function (child) {
        cleanValue.set(child.get('key'), child.getCleanValue());
      });
      return cleanValue;
    },
    setErrors: function setErrors(errors) {
      if (_.isArray(errors)) {
        this.set('errors', errors);
      } else {
        this.get('childFields').forEach(function (field) {
          var fieldErrors = errors[field.get('key')];
          if (fieldErrors) {
            field.setErrors(fieldErrors);
          }
        });
      }
    }
  });
});