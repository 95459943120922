define("workflows-web/components/form-field-input/code-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      onCodeChange: function onCodeChange(updatedCode) {
        this.set('field.value', updatedCode);
        if (this.get('onCodeChange')) {
          this.get('onCodeChange')(updatedCode);
        }
      }
    }
  });
});