define("workflows-web/workflows/selected-item-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OCi+jK0r",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[[35,1]],[[\"domPurifyConfig\"],[[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"domPurifyConfig\",\"modifiedOption\",\"html-safe\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/selected-item-component/template.hbs"
    }
  });
});