define("workflows-web/components/toast-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vzfoVsDf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,7],[\"hf-toast-message_text\",[30,[36,3],[[35,6,[\"isMultiLine\"]],\"hf-mod-multi-line\"],null]],null]],[14,\"data-test-id\",\"toast-message\"],[12],[2,\"\\n\"],[6,[37,3],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,5],[[35,4]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[11,\"span\"],[24,0,\"hf-toast-message_close\"],[24,\"data-test-id\",\"toast-message-close\"],[4,[38,8],[[32,0],\"close\"],null],[12],[2,\" \"],[1,[30,[36,9],[\"toast-message-dismiss\"],null]],[2,\" \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"formattedMessage\",\"t\",\"isHtml\",\"if\",\"messageComponent\",\"component\",\"toastMessageService\",\"concat-strings\",\"action\",\"inline-svg\"]}",
    "meta": {
      "moduleName": "workflows-web/components/toast-message/template.hbs"
    }
  });
});