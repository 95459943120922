define("workflows-web/components/insert-tags/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      closeDropdown: function closeDropdown(popOver, value, choiceValueProperty) {
        this.closeDropdown(value, choiceValueProperty);
        popOver.closePopOver();
      }
    }
  });
});