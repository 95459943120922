define("workflows-web/components/data-table-new/category-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ASGsJxso",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[6,[37,2],[[35,0,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[19,\"templates/partials/table-category-popover\",[1]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[35,0,[\"length\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"orderedCategories\",\"with\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/components/data-table-new/category-cell/template.hbs"
    }
  });
});