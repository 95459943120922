define("workflows-web/components/color-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['hf-color-picker'],
    containerClassName: '',
    replacerClassName: '',
    allowEmpty: false,
    ariaLabel: '',
    showInput: true,
    showAlpha: false,
    showInitial: false,
    showButtons: false,
    color: '#FF9900',
    disabled: false,
    preferredFormat: 'hex',
    onChange: null,
    colorPickerElement: null,
    didInsertElement: function didInsertElement() {
      var $element = this.$().find('.hf-js-color-picker');
      var options = {
        color: this.get('color'),
        containerClassName: this.get('containerClassName'),
        replacerClassName: this.get('replacerClassName'),
        allowEmpty: this.get('allowEmpty'),
        disabled: this.get('disabled'),
        showInput: this.get('showInput'),
        showAlpha: this.get('showAlpha'),
        showInitial: this.get('showInitial'),
        showButtons: this.get('showButtons'),
        preferredFormat: this.get('preferredFormat'),
        appendTo: this.$(),
        change: Ember.run.bind(this, this.updateColor),
        move: Ember.run.bind(this, this.updateColor)
      };
      $element.spectrum(options);
      this.$().find('.sp-input').prop('placeholder', this.get('intlService').lookup('color-picker-input-placeholder'));
      this.$().find('.sp-input').attr('aria-label', this.get('ariaLabel'));
      this.set('colorPickerElement', $element);
      if (!this.get('color')) {
        this.set('color', 'FF9900');
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.get('colorPickerElement').spectrum('set', this.get('color'));
    },
    updateColor: function updateColor(newColor) {
      if (!this.get('isDestroyed')) {
        var _newColor$toString$sp = newColor.toString('hex6').split('#'),
          _newColor$toString$sp2 = _slicedToArray(_newColor$toString$sp, 2),
          color = _newColor$toString$sp2[1];
        this.set('color', color);
        if (this.onChange) {
          this.onChange(color);
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.get('colorPickerElement').spectrum('destroy');
    }
  });
});