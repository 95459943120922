define("workflows-web/workflows/library/details/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6TZx2rT9",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"section\"],[14,0,\"hf-card\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"hf-card_header\"],[12],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"hf-card_header_title\"],[12],[1,[34,3]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-card_content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\" hf-card_content_items\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[14,0,\"hf-card_content_item\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"hf-card_content_item\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"items\",\"-track-array\",\"each\",\"title\",\"isItemsArray\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/library/details/card/template.hbs"
    }
  });
});