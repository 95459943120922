define("workflows-web/helpers/truncate-strings", ["exports", "workflows-web/utilities/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DISPLAY_LIMIT = 60;
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    var displayLimit = Ember.isPresent(params[1]) ? params[1] : DISPLAY_LIMIT;
    if (params[0].length > displayLimit) {
      return "".concat((0, _string.getSafeHtml)(params[0].substring(0, displayLimit)), "...");
    } else {
      return (0, _string.getSafeHtml)(params[0]);
    }
  });
});