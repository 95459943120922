define("workflows-web/signup-verification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9jLfDRL/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"react-components/signup-verification-wrapper\",[],[[\"@validateSignupToken\",\"@goToLogin\",\"@resendSignupToken\"],[[30,[36,0],[[32,0],\"validateSignupToken\"],null],[30,[36,0],[[32,0],\"goToLogin\"],null],[30,[36,0],[[32,0],\"resendSignupToken\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "workflows-web/signup-verification/template.hbs"
    }
  });
});