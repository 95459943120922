define("workflows-web/workflows/details/edit/actions/details/azure-active-directory/create-or-update-user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZTIf3uXA",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,4,[\"model\",\"tenant\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"hf-u-vertical-flex-container hf-workflows_form-group\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,3],[\"step-{index}\"],[[\"index\"],[\"1\"]]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"field\",\"actionOnOpen\",\"onSearch\",\"onValueChange\"],[[35,4,[\"model\",\"tenant\"]],[35,7],[35,7],[30,[36,6],[[32,0],\"onTenantFieldChange\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[35,4,[\"model\",\"tenant\",\"value\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[30,[36,2],[\"hf-u-vertical-flex-container hf-workflows_form-group hf-mod-last\",[30,[36,1],[[35,0],\"hf-u-disabled\"],null]],null]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,3],[\"step-{index}\"],[[\"index\"],[\"2\"]]]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"field\"],[[35,4,[\"model\",\"userPrincipalName\"]]]]]],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"field\"],[[35,4,[\"model\",\"mail\"]]]]]],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"field\"],[[35,4,[\"model\",\"displayName\"]]]]]],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"field\"],[[35,4,[\"model\",\"mailNickName\"]]]]]],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"field\"],[[35,4,[\"model\",\"accountEnabled\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isPrimaryFieldEmpty\",\"if\",\"concat-strings\",\"t\",\"form\",\"form-field\",\"action\",\"fetchAzureActiveDirectoryAutocompleteResults\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/azure-active-directory/create-or-update-user/template.hbs"
    }
  });
});