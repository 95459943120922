define("workflows-web/workflows/details/edit/actions/details/message-field/component", ["exports", "workflows-web/utilities/editor", "workflows-web/workflows/details/edit/utilities"], function (_exports, _editor, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      var field = this.field;
      var choices = field.choices;
      if (field.value && choices && !field.isMergeFieldsConversionComplete) {
        var dynamicSubstrings = field.value.match(/[^{\}]+(?=})/g);
        if (dynamicSubstrings) {
          dynamicSubstrings.uniq().forEach(function (substring) {
            var tag;
            var key = substring;
            if (choices) {
              var _key$split = key.split('__'),
                _key$split2 = _slicedToArray(_key$split, 1),
                stepId = _key$split2[0];
              var choice = choices.findBy('stepId', stepId);
              if (choice) {
                var selectedOption = choice.options.findBy('value', "{{".concat(key, "}}"));
                if (selectedOption) {
                  _this.set('selectedOption', selectedOption);
                  tag = (0, _utilities.createTag)(choice.prefix, selectedOption.label, "{{".concat(key, "}}"));
                }
              } else {
                tag = (0, _utilities.createTagForInvalidStep)(key, field);
              }
            } else {
              tag = (0, _utilities.createTagForInvalidStep)(key, field);
            }
            if (tag) {
              var stringToReplace = "{{".concat(key, "}}");
              // Match all occurrences of stringToReplace that are not part of a tag attribute
              var regex = new RegExp("(".concat(stringToReplace, ")(?![^<>]*>)"), 'g');
              field.set('value', field.value.replace(regex, tag.outerHTML));
            }
          });
          field.set('value', "".concat(field.value, "&nbsp"));
          field.set('isMergeFieldsConversionComplete', true);
        }
      }
    },
    field: Ember.computed('form.model', 'key', {
      get: function get() {
        return this.form.model.get(this.key);
      }
    }),
    fieldChoices: Ember.computed.reads('field.choices'),
    actions: {
      insertEmailMessageTags: function insertEmailMessageTags(value, choiceValueProperty) {
        var messageField = this.field;
        var choices = this.field.choices;
        if (choices) {
          var actualValue = value[choiceValueProperty];
          var keys = actualValue.match(/[^{{\}}]+(?=})/g);
          if (Ember.isPresent(keys)) {
            var _keys = _slicedToArray(keys, 1),
              key = _keys[0];
            var _key$split3 = key.split('__'),
              _key$split4 = _slicedToArray(_key$split3, 1),
              stepId = _key$split4[0];
            var choice = choices.findBy('stepId', stepId);
            if (choice) {
              var selectedOption = choice.options.findBy('value', actualValue);
              if (selectedOption) {
                this.set('selectedOption', selectedOption);
                var tag = (0, _utilities.createTag)(choice.prefix, selectedOption.label, actualValue);
                (0, _editor.insertHtmlAtCaretPosition)("".concat(tag.outerHTML, "&nbsp"), messageField.key);
              }
            }
          } else {
            (0, _editor.insertHtmlAtCaretPosition)(actualValue, messageField.key);
          }
        }
        // Editor two way binding of value happens after 200ms
        Ember.run.later(function () {
          messageField.validate();
        }, 300);
      }
    }
  });
});