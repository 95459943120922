define("workflows-web/initializers/modify-text-field-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'modify-input-component',
    initialize: function initialize() {
      Ember.TextField.reopen({
        init: function init() {
          this._super.apply(this, arguments);
          var attributeBindings = Ember.isArray(this.get('attributeBindings')) ? this.get('attributeBindings').slice() : [];
          attributeBindings.addObjects(['data-test-id']);
          this.set('attributeBindings', attributeBindings);
        }
      });
    }
  };
});