define("workflows-web/components/form-input/partials/help-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NYNrIZfo",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"hf-form-field-help-text\"],[15,\"data-test-id\",[34,0]],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"helpTextDataTestId\",\"helpText\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-input/partials/help-text/template.hbs"
    }
  });
});