define("workflows-web/workflows/apps/details/happyfox-service-desk/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wRvAsgoI",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"app\",\"error\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"ul\"],[14,0,\"hf-form-content_non-field-errors hf-u-inline-flex-container hf-js-form-content_non-field-errors\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,0],[\"happyfox-service-desk.error.\",[32,0,[\"app\",\"error\"]]],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[19,\"workflows/apps/details/partials/base-form\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"concat\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/apps/details/happyfox-service-desk/template.hbs"
    }
  });
});