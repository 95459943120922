define("workflows-web/components/staff-thumbnail/component", ["exports", "workflows-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'img',
    defaultImage: _constants.DEFAULT_USER_IMAGE,
    user: null,
    classNames: ['hf-avatar-image'],
    attributeBindings: ['src'],
    isPictureUrl: Ember.computed('user.{pictureSmall,picture}', {
      get: function get() {
        var picture = this.get('user.pictureSmall');
        return picture && typeof this.get('user.pictureSmall') === 'string';
      }
    }),
    src: Ember.computed('isPictureUrl', 'user.pictureSmall', {
      get: function get() {
        return this.get('isPictureUrl') ? this.get('user.pictureSmall') : this.get('defaultImage');
      }
    })
  });
});