define("workflows-web/components/form-input/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "W5prLuSE",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,11],null,[[\"type\",\"value\",\"focus-out\",\"class\",\"data-test-id\",\"classNames\",\"disabled\",\"placeholder\",\"enter\"],[\"text\",[35,10],[30,[36,0],[[32,0],\"onFocusOut\"],null],[30,[36,9],[[30,[36,8],[\"hf-form-input hf-text-input\",[30,[36,6],[[35,7],\"hf-is-highlighted\"],null]],null],\" \",[30,[36,6],[[35,5],\"hf-is-error\"],null],\" \"],null],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"onEnter\"],null]]]]],[2,\"\\n\"],[19,\"components/form-input/partials/error-message\",[]],[2,\"\\n\"],[19,\"components/form-input/partials/help-text\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"action\",\"placeholder\",\"isDisabled\",\"inputClass\",\"inputDataTestId\",\"hasErrors\",\"if\",\"isHighlighted\",\"concat-strings\",\"concat\",\"value\",\"input\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-input/text/template.hbs"
    }
  });
});