define("workflows-web/services/logger", ["exports", "workflows-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    storeLogs: _environment.default.APP.STORE_LOGS,
    logsLimit: _environment.default.APP.LOGS_LIMIT,
    init: function init() {
      this._super(arguments);
      if (this.get('storeLogs')) {
        window.logs = [];
      }
    },
    log: function log(message) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      this.addLog('log', message, args);
    },
    error: function error(message) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }
      this.addLog('error', message, args);
    },
    debug: function debug(message) {
      for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
        args[_key3 - 1] = arguments[_key3];
      }
      this.addLog('debug', message, args);
    },
    warn: function warn(message) {
      for (var _len4 = arguments.length, args = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
        args[_key4 - 1] = arguments[_key4];
      }
      this.addLog('warn', message, args);
    },
    info: function info(message) {
      for (var _len5 = arguments.length, args = new Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
        args[_key5 - 1] = arguments[_key5];
      }
      this.addLog('info', message, args);
    },
    assert: function assert(message) {
      for (var _len6 = arguments.length, args = new Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
        args[_key6 - 1] = arguments[_key6];
      }
      this.addLog('assert', message, args);
    },
    isBasicLog: function isBasicLog(type) {
      if (type === 'log' || type === 'error' || type === 'debug' || type === 'warn' || type === 'info' || type === 'assert') {
        return true;
      }
      return false;
    },
    /*
     * addLog takes type, message and arbitrary number of arguments which will be
     * stored as data array in log record
     */
    addLog: function addLog(type, message) {
      for (var _len7 = arguments.length, args = new Array(_len7 > 2 ? _len7 - 2 : 0), _key7 = 2; _key7 < _len7; _key7++) {
        args[_key7 - 2] = arguments[_key7];
      }
      var data = args;
      var isBasic = this.isBasicLog(type);
      if (isBasic) {
        data = args[0];
      }
      if (data.length === 0) {
        data = null;
      }
      var logRecord = {
        type: type,
        message: message,
        data: data
      };
      if (isBasic) {
        Ember.Logger[type](logRecord);
      } else {
        Ember.Logger.log('Log info', logRecord);
      }
      if (this.get('storeLogs')) {
        if (window.logs.length === this.get('logsLimit')) {
          window.logs.shift();
        }
        window.logs.push(logRecord);
      }
    }
  });
});