define("workflows-web/components/form-field-input/date/component", ["exports", "workflows-web/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    renderInPlace: true,
    inputClass: '',
    actions: {
      onClose: function onClose() {
        this.get('field').validate();
      }
    }
  });
});