define("workflows-web/utilities/email-validation", ["exports", "workflows-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateEmail = validateEmail;
  function validateEmail(email) {
    return _constants.EMAIL_REGEX.test(email);
  }
});