define("workflows-web/workflows/details/edit/utilities", ["exports", "workflows-web/utilities/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appendTags = appendTags;
  _exports.commaSeperatedStringsToListPayload = commaSeperatedStringsToListPayload;
  _exports.commaSeperatedStringsToListPayloadAlways = commaSeperatedStringsToListPayloadAlways;
  _exports.constructAttendeeFieldPayload = constructAttendeeFieldPayload;
  _exports.createTag = createTag;
  _exports.createTagForInvalidStep = createTagForInvalidStep;
  _exports.generateAlertToStaffPayload = generateAlertToStaffPayload;
  _exports.generateAssetsPayload = generateAssetsPayload;
  _exports.generateAttachmentsPayload = generateAttachmentsPayload;
  _exports.generateBccPayload = generateBccPayload;
  _exports.generateCannedActionPayload = generateCannedActionPayload;
  _exports.generateCcPayload = generateCcPayload;
  _exports.generateContactGroupPayload = generateContactGroupPayload;
  _exports.generateCustomFieldsPayload = generateCustomFieldsPayload;
  _exports.generateCustomFieldsPayloadWithType = generateCustomFieldsPayloadWithType;
  _exports.generateMacrosPayload = generateMacrosPayload;
  _exports.generateRelateTicketsPayload = generateRelateTicketsPayload;
  _exports.generateSubscribersPayload = generateSubscribersPayload;
  _exports.generateTagsPayload = generateTagsPayload;
  _exports.generateTaskTemplatePayload = generateTaskTemplatePayload;
  _exports.generateTicketTemplatePayload = generateTicketTemplatePayload;
  _exports.generateToPayload = generateToPayload;
  _exports.getActualTagPosition = getActualTagPosition;
  _exports.getFieldPreview = getFieldPreview;
  _exports.getPrefixColor = getPrefixColor;
  _exports.getTagPositions = getTagPositions;
  _exports.listToStrings = listToStrings;
  _exports.modifyTags = modifyTags;
  _exports.transformCustomFields = transformCustomFields;
  _exports.transformCustomFieldsWithType = transformCustomFieldsWithType;
  _exports.transformDynamicFieldValue = transformDynamicFieldValue;
  _exports.transformForwardTicketEmailPayload = transformForwardTicketEmailPayload;
  _exports.transformPipedriveAttendeeField = transformPipedriveAttendeeField;
  _exports.transformPipedriveExtraFields = transformPipedriveExtraFields;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function generateCustomFieldsPayload(field) {
    var payload = {};
    var customFields = {};
    field.value.forEach(function (field) {
      if (field.id.type === "multiple_option") {
        field.value = field.value.split(",").map(function (value) {
          return value.trim();
        });
      }
      customFields[Number("".concat(field.id.id))] = field.value;
    });
    payload.customFields = customFields;
    return payload;
  }
  function transformCustomFields(field) {
    var modifiedValue = field.value.map(function (val) {
      return {
        id: {
          name: val.name,
          id: val.id
        },
        value: val.value
      };
    });
    return modifiedValue;
  }
  function transformPipedriveExtraFields(field) {
    var modifiedValue = field.value.map(function (val) {
      return {
        key: {
          name: val.name,
          key: val.key
        },
        value: val.value
      };
    });
    return modifiedValue;
  }
  function transformForwardTicketEmailPayload(field) {
    if (field.value) {
      return field.value.join(", ");
    } else {
      return null;
    }
  }
  function generateTagsPayload(field) {
    var tags = field.getCleanValue();
    var payload = {};
    if (tags) {
      payload.tags = tags.split(",").map(function (tag) {
        return tag.trim();
      });
    }
    return payload;
  }
  function commaSeperatedStringsToListPayload(field) {
    var value = field.getCleanValue();
    var payload = {};
    if (value) {
      payload[field.key] = value.split(",").map(function (val) {
        return val.trim();
      });
    }
    return payload;
  }
  function commaSeperatedStringsToListPayloadAlways(field) {
    var value = field.getCleanValue();
    var payload = {};
    if (value) {
      payload[field.key] = value.split(',').map(function (val) {
        return val.trim();
      });
    } else if (field.isDirty) {
      payload[field.key] = [];
    }
    return payload;
  }
  function generateToPayload(field) {
    var to = field.getCleanValue();
    var payload = {};
    if (to) {
      payload[field.key] = to.split(",").map(function (tag) {
        return tag.trim();
      });
    }
    return payload;
  }
  function generateCcPayload(field) {
    var cc = field.getCleanValue();
    var payload = {};
    if (cc) {
      payload.cc = cc.split(",").map(function (tag) {
        return tag.trim();
      });
    }
    return payload;
  }
  function generateBccPayload(field) {
    var bcc = field.getCleanValue();
    var payload = {};
    if (bcc) {
      payload.bcc = bcc.split(",").map(function (tag) {
        return tag.trim();
      });
    }
    return payload;
  }
  function generateAssetsPayload(field) {
    var assets = field.getCleanValue();
    var payload = {};
    assets.forEach(function (field) {
      payload["asset_".concat(field.id)] = field.quantity;
    });
    return payload;
  }
  function generateSubscribersPayload(field) {
    var subscribers = field.getCleanValue();
    var payload = {};
    if (subscribers) {
      payload.subscribers = subscribers.split(",").map(function (subscriber) {
        return subscriber.trim();
      });
    }
    return payload;
  }
  function generateAlertToStaffPayload(field) {
    var agents = field.getCleanValue();
    var payload = {};
    if (agents) {
      payload.alertStaff = agents.split(",").map(function (subscriber) {
        return subscriber.trim();
      });
    }
    return payload;
  }
  function generateRelateTicketsPayload(field) {
    var tickets = field.getCleanValue();
    var payload = {};
    if (tickets) {
      payload.tickets = tickets.split(",").map(function (subscriber) {
        return subscriber.trim();
      });
    }
    return payload;
  }
  function modifyTags(field) {
    if (field.value) {
      return field.value.join();
    } else {
      return "";
    }
  }
  function listToStrings(field) {
    if (field.value) {
      return field.value.join();
    } else {
      return "";
    }
  }
  function generateCannedActionPayload(field) {
    var payload = {};
    var happyFoxCannedActionChoices = field.choices.findBy("groupName", field.groupName);
    if (happyFoxCannedActionChoices) {
      var selectedCannedAction = happyFoxCannedActionChoices.options.findBy("label", field.value);
      if (Ember.isPresent(selectedCannedAction)) {
        payload.cannedAction = selectedCannedAction.id;
      } else {
        payload.cannedAction = field.value;
      }
    } else {
      payload.cannedAction = field.value;
    }
    return payload;
  }
  function generateContactGroupPayload(field) {
    var payload = {
      groups: []
    };
    var happyFoxContactGroupChoices = field.choices.findBy("groupName", field.groupName);
    if (happyFoxContactGroupChoices) {
      var selectedContactGroups = field.value.split(",").map(function (group) {
        return group.trim();
      });
      selectedContactGroups.forEach(function (group) {
        var selectedContactGroup = happyFoxContactGroupChoices.options.findBy("label", group);
        if (Ember.isPresent(selectedContactGroup)) {
          payload.groups.addObject(selectedContactGroup.id);
        } else {
          payload.groups.addObject(group);
        }
      });
    } else {
      payload.groups = [field.value];
    }
    return payload;
  }
  function generateTaskTemplatePayload(field) {
    var payload = {};
    var happyFoxTaskTemplateChoices = field.choices.findBy("groupName", field.groupName);
    if (happyFoxTaskTemplateChoices) {
      var selectedTaskTemplate = happyFoxTaskTemplateChoices.options.findBy("label", field.value);
      if (Ember.isPresent(selectedTaskTemplate)) {
        payload.taskTemplate = selectedTaskTemplate.id;
      } else {
        payload.taskTemplate = field.value;
      }
    } else {
      payload.taskTemplate = field.value;
    }
    return payload;
  }
  function generateTicketTemplatePayload(field) {
    var payload = {};
    var happyFoxTicketTemplateChoices = field.choices.findBy("groupName", field.groupName);
    if (happyFoxTicketTemplateChoices) {
      var selectedTicketTemplate = happyFoxTicketTemplateChoices.options.findBy("label", field.value);
      if (Ember.isPresent(selectedTicketTemplate)) {
        payload.ticketTemplate = selectedTicketTemplate.id;
      } else {
        payload.ticketTemplate = field.value;
      }
    } else {
      payload.ticketTemplate = field.value;
    }
    return payload;
  }
  function generateAttachmentsPayload(field) {
    var attachments = field.getCleanValue();
    var payload = {};
    if (attachments) {
      payload.attachments = attachments.split(",").map(function (attachment) {
        return attachment.trim();
      });
    } else if (field.isDirty) {
      payload.attachments = [];
    }
    return payload;
  }
  function generateMacrosPayload(field) {
    var payload = {};
    var group = field.choices.findBy("groupName", field.groupName);
    if (field.value) {
      var selectedChoice = group.options.findBy("value", field.value);
      payload["macro"] = selectedChoice;
    }
    return payload;
  }
  function transformDynamicFieldValue(field) {
    if (field.value && field.value.label) {
      return field.value.label;
    }
    return field.value;
  }
  function constructAttendeeFieldPayload(field) {
    var attendees = field.getCleanValue();
    var payload = {};
    payload["attendees"] = attendees.map(function (attendee) {
      if (Ember.isBlank(attendee["personId"])) {
        delete attendee["personId"];
      }
      return attendee;
    });
    return payload;
  }
  function transformPipedriveAttendeeField(field) {
    var modifiedValue = field.value.map(function (attendee) {
      if (!("personId" in attendee)) {
        attendee.set("personId", " ");
      }
      return attendee;
    });
    return modifiedValue;
  }
  function recursivelyRemoveDynamicTag(element) {
    element.childNodes.forEach(function (node) {
      var nodeId = node.getAttribute && node.getAttribute('id');
      var regExp = /\{\{.+?\}\}/g;
      var dynamicStrings = nodeId && nodeId.match(regExp);
      if (Ember.isPresent(dynamicStrings)) {
        node.replaceWith(dynamicStrings[0]);
      }
      if (node.childNodes.length) {
        node = recursivelyRemoveDynamicTag(node);
      }
    });
    return element;
  }
  function getModifiedField(field) {
    var element = document.createElement('div');
    element.innerHTML = field.value;
    var modifiedElement = recursivelyRemoveDynamicTag(element);
    field.actualValue = modifiedElement.innerHTML;
    return field;
  }
  function stringifyObjectInArray(array) {
    return array.map(function (value) {
      if (_typeof(value) === 'object') {
        return JSON.stringify(value);
      } else {
        return value;
      }
    });
  }
  function convertToString(value) {
    var convertToStringValue;
    if (Array.isArray(value)) {
      convertToStringValue = stringifyObjectInArray(value).join(',');
    } else if (_typeof(value) === 'object' && value !== null) {
      convertToStringValue = JSON.stringify(value);
    } else if (typeof value === 'number' || typeof value === 'boolean') {
      convertToStringValue = value.toString();
    } else {
      convertToStringValue = value;
    }
    return convertToStringValue;
  }
  function getFieldPreview(field) {
    var _field = field,
      choices = _field.choices,
      value = _field.value;
    if (field.isEditor) {
      field = getModifiedField(field);
      value = field.actualValue;
    }
    var preview;
    if (value) {
      value = convertToString(value);
      var regExp = /\{\{.+?\}\}/g;
      var stringsToReplace = value.match(regExp);
      var isOnlyDynamicFields = (stringsToReplace === null || stringsToReplace === void 0 ? void 0 : stringsToReplace.join('').length) === value.length;
      var options = [];
      if (stringsToReplace) {
        if (choices) {
          options = options.concat.apply([], choices.mapBy('options'));
        }
        var stringsToBeReplacedWith = stringsToReplace.map(function (string) {
          var option = options.findBy("value", string);
          if (option) {
            return (0, _string.convertArrayToString)(options.findBy('value', string).fieldValue);
          } else if (isOnlyDynamicFields) {
            // do not show implementation in FE. If merge field is not available, show Not Available
            return 'Not Available';
          } else {
            return '';
          }
        });
        var mapping = {};
        stringsToReplace.forEach(function (e, i) {
          return mapping["".concat(e)] = stringsToBeReplacedWith[i];
        });
        preview = value.replace(regExp, function (n) {
          return mapping[n];
        });
      } else {
        preview = field.value;
      }
    }
    return preview;
  }
  function createTag(key, value, actualValue) {
    var outerSpan = document.createElement('span');
    outerSpan.setAttribute('contenteditable', false);
    outerSpan.setAttribute("style", "display: inline-flex; align-items: center;margin: 0 2px 4px;cursor: pointer;");
    outerSpan.setAttribute("id", actualValue);
    if (Ember.isBlank(key)) {
      outerSpan.innerHTML = "<span id=".concat(actualValue, "__prefix style=\"background-color: #EEF4FF; border: 1px solid #B4CFEA; color: #000; padding: 2px 7px;display: inline-block; border-radius: 4px\">").concat(value, "</span><span style=\"display:none\"  id=").concat(actualValue, "__value>").concat(actualValue, "</span>");
    } else {
      outerSpan.innerHTML = "<span id=".concat(actualValue, "__prefix style=\"display: inline-flex;align-items: center;margin-right: 0px;border: 1px solid #B4CFEA;border-radius: 4px 0 0 4px;padding: 2px 7px;white-space: pre-wrap;background-color: #A7CBEF;color: #fff;\">").concat(key, "</span><span id=").concat(actualValue, "__key style=\"background-color: #EEF4FF; border: 1px solid #B4CFEA; border-left: 0; color: #000; padding: 2px 7px;display: inline-block; border-radius: 0 4px 4px 0\">").concat(value, "</span><span style=\"display:none\"  id=").concat(actualValue, "__value>").concat(actualValue, "</span>");
    }
    return outerSpan;
  }
  function appendTags(tags) {
    var outerSpan = document.createElement('span');
    outerSpan.setAttribute('style', 'color: #000');
    tags.forEach(function (tag) {
      if (typeof tag === 'string') {
        outerSpan.append(tag);
      } else {
        outerSpan.appendChild(tag);
      }
    });
    return outerSpan;
  }

  // creating tags for fields with no test data/ prefix not available for the step
  function createTagForInvalidStep(mergeField, field) {
    var _field$prefix;
    var underscoreSeparatorIndex = mergeField.indexOf('__');
    var key = mergeField.substring(0, underscoreSeparatorIndex);
    var stepLabel = mergeField.substring(underscoreSeparatorIndex + 2);
    // get prefix/order of the step
    var stepOrder = (_field$prefix = field.prefix) === null || _field$prefix === void 0 || (_field$prefix = _field$prefix[key.camelize()]) === null || _field$prefix === void 0 ? void 0 : _field$prefix.prefix;
    var tag = createTag(stepOrder, stepLabel, "{{".concat(mergeField, "}}"));
    return tag;
  }
  function getTagPositions(text) {
    var lines = text.split('\n');
    var regExp = /\{\{.+?\}\}/g;
    var positions = [];
    var string;
    for (var i = 0; i < lines.length; i++) {
      while ((string = regExp.exec(lines[i])) !== null) {
        var tag = string[0].substring(2, string[0].length - 2);
        positions.push({
          line: i,
          start: string.index,
          stop: string.index + string[0].length,
          tag: tag
        });
      }
    }
    return positions;
  }
  function getActualTagPosition(text, cursorPosition) {
    var positions = this.getTagPositions(text);
    return _.find(positions, function (p) {
      return cursorPosition.line === p.line && cursorPosition.ch >= p.start && cursorPosition.ch <= p.stop;
    });
  }
  function transformCustomFieldsWithType(field) {
    var _field$value;
    var modifiedValue = (_field$value = field.value) === null || _field$value === void 0 ? void 0 : _field$value.map(function (val) {
      return {
        id: {
          name: val.name,
          id: val.id,
          type: val.type
        },
        value: val.value
      };
    });
    return modifiedValue;
  }
  function generateCustomFieldsPayloadWithType(fields) {
    var _fields$getCleanValue;
    var payload = {};
    var customFields = (_fields$getCleanValue = fields.getCleanValue()) === null || _fields$getCleanValue === void 0 ? void 0 : _fields$getCleanValue.map(function (field) {
      var customField = {
        id: field.id.id,
        type: field.id.type,
        name: field.id.name,
        value: field.value
      };
      return customField;
    });
    payload[fields.key] = customFields;
    return payload;
  }
  function getPrefixColor(prefix) {
    var num = parseFloat(prefix);
    var isNestedAction = !isNaN(num) && num !== Math.floor(num);
    if (prefix === '1') {
      return "background: #C69000;";
    } else if (isNestedAction) {
      return "background: #9BB1C6;";
    }
    return "background: #70A5D9;";
  }
});