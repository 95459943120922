define("workflows-web/form-models/login-credentials-form", ["exports", "workflows-web/form-models/base-form", "workflows-web/utilities/object"], function (_exports, _baseForm, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseForm.default.extend({
    intlService: Ember.inject.service('intl'),
    userAuthService: Ember.inject.service('user-auth'),
    init: function init() {
      var rawFields = Ember.A();
      var userIdField = Ember.Object.create({
        key: 'userId',
        isText: true,
        isMandatory: true,
        label: this.get('intlService').findTranslationByKey('login.username')
      });
      var passwordField = Ember.Object.create({
        key: 'password',
        isPassword: true,
        isMandatory: true,
        label: this.get('intlService').findTranslationByKey('login.password')
      });
      var rememberMeField = Ember.Object.create({
        key: 'rememberMe',
        isCheckBox: true,
        value: false,
        label: this.get('intlService').findTranslationByKey('login.remember-me')
      });
      rawFields.addObjects([userIdField, passwordField, rememberMeField]);
      this.set('fields', this._createFieldModels(rawFields));
    },
    submit: function submit() {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.set('errors', null);
        _this.validate().then(function () {
          var userId = _this.get('model.userId.value');
          var password = _this.get('model.password.value');
          var rememberMe = _this.get('model.rememberMe.value');
          var loginPromise = _this.get('userAuthService').validateCredentials(userId, password, rememberMe);
          loginPromise.then(function (data) {
            resolve((0, _object.camelizeObject)(data));
          }).catch(function (errors) {
            _this._populateErrors(errors);
            reject(errors);
          });
        }).catch(function () {
          reject();
        });
      });
    }
  });
});