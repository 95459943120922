define("workflows-web/form-field-models/choice-field", ["exports", "workflows-web/form-field-models/base-field", "workflows-web/constants"], function (_exports, _baseField, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var _default = _exports.default = _baseField.default.extend({
    // default is 'name'
    choiceLabelKey: 'name',
    choiceValueKey: null,
    isChoice: true,
    choices: null,
    hasGroupedChoices: false,
    canSetDefaultChoiceValue: true,
    canIncludeEmptyChoice: false,
    makeEmptyChoiceAsDefault: false,
    init: function init() {
      var _this = this;
      var selectedChoice = null;
      var fieldValue = this.get('value');
      var choices = this.get('choices');
      if (this.get('canIncludeEmptyChoice')) {
        choices = choices.toArray();
        var choiceLabelKey = this.get('choiceLabelKey');
        var choiceValueKey = this.get('choiceValueKey');
        var emptyOptionField = Ember.Object.create(_defineProperty(_defineProperty({}, choiceLabelKey, _constants.EMPTY_DROPDOWN_VALUE), choiceValueKey, null));
        if (this.get('makeEmptyChoiceAsDefault')) {
          selectedChoice = emptyOptionField;
        }
        choices.unshiftObject(emptyOptionField);
        this.set('choices', choices);
      }
      if (fieldValue) {
        if (this.get('hasGroupedChoices')) {
          var ungroupedChoices = choices.rejectBy('groupName');
          var choice = this.findChoice(ungroupedChoices, fieldValue);
          if (!choice) {
            var choiceGroups = choices.filterBy('groupName');
            choiceGroups.forEach(function (group) {
              var matchedChoice = _this.findChoice(group.options, fieldValue);
              if (matchedChoice) {
                choice = matchedChoice;
              }
            });
          }
          selectedChoice = choice;
        } else {
          selectedChoice = this.findChoice(choices, fieldValue);
        }
      }
      this.setProperties({
        _initialValue: selectedChoice,
        value: selectedChoice
      });
    },
    findChoice: function findChoice(choices, choiceOption) {
      var choice = null;
      choices.forEach(function (item) {
        if (_.isEqual(item, choiceOption)) {
          choice = item;
        }
      });
      return choice;
    },
    reset: function reset() {
      this.set('errors', Ember.A());
      var initialValue = this.get('_initialValue');
      this.set('value', initialValue);
      if (Ember.isBlank(initialValue) && this.get('isMandatory')) {
        this.setDefaultChoiceValue();
      }
    },
    setDefaultChoiceValue: function setDefaultChoiceValue() {
      if (this.get('canSetDefaultChoiceValue')) {
        var _this$get = this.get('choices'),
          _this$get2 = _slicedToArray(_this$get, 1),
          defaultChoice = _this$get2[0];
        defaultChoice = this.get('choices').findBy('isDefault') || defaultChoice;
        this.set('value', defaultChoice);
      }
    },
    validate: function validate() {
      var _this2 = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2._super().then(function () {
          var value = _this2.get('value');
          if (value) {
            var choiceValueKey = _this2.get('choiceValueKey');
            _this2.set('_cleanValue', choiceValueKey ? value[choiceValueKey] : value);
          } else {
            _this2.set('_cleanValue', null);
          }
          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });
});