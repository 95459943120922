define("workflows-web/components/data-table-new/cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "H59UwDcO",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,0,[\"cellComponent\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,10],[[35,0,[\"cellComponent\"]]],[[\"tableActions\",\"extra\",\"table\",\"column\",\"row\",\"value\",\"rawValue\"],[[35,9],[35,8],[35,7],[35,0],[35,6],[35,3],[35,5]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,4],[[35,3]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,\"title\",[31,[[34,3]]]],[12],[1,[34,3]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0,[\"emptyStateValue\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"lt-cell_empty-value\"],[12],[1,[30,[36,1],[[35,0,[\"emptyStateValue\"]]],null]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"column\",\"t\",\"if\",\"value\",\"is-present\",\"rawValue\",\"row\",\"table\",\"extra\",\"tableActions\",\"component\"]}",
    "meta": {
      "moduleName": "workflows-web/components/data-table-new/cell/template.hbs"
    }
  });
});