define("workflows-web/components/form-input/multi-choice/component", ["exports", "workflows-web/components/form-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    options: null,
    selection: null,
    labelProperty: 'label',
    valueProperty: 'value',
    validate: function validate() {
      this.initializeErrors();
      if (this.get('isMandatory') && this.get('selection').length === 0) {
        this.addError(this.get('intlService').findTranslationByKey('validation.mandatory-error-message'));
      }
    },
    actions: {
      updateSelection: function updateSelection(newSelection) {
        this.set('selection', newSelection);
        this.validate();
      }
    }
  });
});