define("workflows-web/workflows/details/edit/actions/details/shopify/process-refund/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      addChildField: function addChildField(field) {
        var _this = this;
        field.addNewChildField(null);
        field.childFieldMetaInfo.childFields.forEach(function (field) {
          if (field.isDynamicField) {
            _this.get('form').setActionDetailsChoices(field);
          }
        });
      },
      removeChildField: function removeChildField(field, childField) {
        field.removeChildField(childField);
      }
    }
  });
});