define("workflows-web/form-field-models/email-field", ["exports", "workflows-web/form-field-models/base-field", "workflows-web/utilities/email-validation"], function (_exports, _baseField, _emailValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseField.default.extend({
    isEmail: true,
    value: '',
    _initialValue: '',
    validate: function validate() {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          // email check
          var value = _this.get('value');
          if (!Ember.isBlank(value) && !(0, _emailValidation.validateEmail)(value)) {
            _this.addError(_this.get('intlService').findTranslationByKey('validation.invalid-email-message'));
            reject();
          } else {
            _this.set('_cleanValue', value);
            resolve();
          }
        }).catch(function () {
          reject();
        });
      });
    },
    clear: function clear() {
      this.setProperties({
        value: '',
        _initialValue: ''
      });
    }
  });
});