define("workflows-web/workflows/apps/details/jamf-pro/component", ["exports", "workflows-web/workflows/apps/details/manage-app-form/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    getRawFieldsInfo: function getRawFieldsInfo() {
      var intlService = this.get('intlService');
      return [Ember.Object.create({
        label: intlService.findTranslationByKey('apps.form-fields.account-url'),
        key: 'accountUrl',
        isText: true
      }), Ember.Object.create({
        label: intlService.findTranslationByKey('apps.form-fields.username'),
        key: 'username',
        isText: true
      }), Ember.Object.create({
        label: intlService.findTranslationByKey('apps.form-fields.password'),
        key: 'password',
        isPassword: true
      })];
    }
  });
});