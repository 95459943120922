define("workflows-web/form-field-models/textarea-field", ["exports", "workflows-web/form-field-models/text-field"], function (_exports, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_ROWS_COUNT = 5;
  var _default = _exports.default = _textField.default.extend({
    isText: false,
    isTextarea: true,
    rowsCount: DEFAULT_ROWS_COUNT
  });
});