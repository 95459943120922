define("workflows-web/workflows/details/edit/actions/details/remove-tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "iYW6crhV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-vertical-flex-container hf-workflows_form-group\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,0],[\"step-{index}\"],[[\"index\"],[\"1\"]]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[8,\"form-field\",[],[[\"@field\"],[[32,0,[\"form\",\"model\",\"ticketId\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[30,[36,3],[\"hf-u-vertical-flex-container hf-workflows_form-group hf-mod-last\",[30,[36,2],[[35,1],\"hf-u-disabled\"],null]],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,0],[\"step-{index}\"],[[\"index\"],[\"2\"]]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[8,\"form-field\",[],[[\"@field\"],[[32,0,[\"form\",\"model\",\"removeAllTags\"]]]],null],[2,\"\\n\"],[6,[37,4],[[32,0,[\"form\",\"model\",\"removeAllTags\",\"value\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"form-field\",[],[[\"@field\"],[[32,0,[\"form\",\"model\",\"tags\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"isPrimaryFieldEmpty\",\"if\",\"concat-strings\",\"unless\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/remove-tags/template.hbs"
    }
  });
});