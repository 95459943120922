define("workflows-web/login/controller", ["exports", "workflows-web/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    requestService: Ember.inject.service('request'),
    ssoError: false,
    demoUrl: 'https://www.happyfox.com/workflows-showcase/',
    isStateUnknown: Ember.computed('state', {
      get: function get() {
        return this.state === _constants.USER_PLAN_STATES.UNKNOWN;
      }
    }),
    actions: {
      goToResetPassword: function goToResetPassword() {
        this.transitionToRoute('reset-password');
      },
      displaySSOError: function displaySSOError(shouldDisplay) {
        this.set('ssoError', shouldDisplay);
      }
    }
  });
});