define("workflows-web/form-field-models/array-field", ["exports", "workflows-web/form-field-models/base-field", "workflows-web/utilities/form-models"], function (_exports, _baseField, _formModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = _baseField.default.extend({
    isArray: true,
    childFields: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('childFields', Ember.A());
    },
    // createChildFields gives ability for array field to create its children using the
    // childFieldMetaInfo and childClassDefinition provided to it while creating array field
    createChildFields: function createChildFields() {
      var _this = this;
      var childFieldsValues = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var isClone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var setInitialValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      childFieldsValues = childFieldsValues || this.get('value');
      var childFields = Ember.A();
      var initialValue = Ember.A(); // To store initial values from child. Child field might modify value passed to it
      var value = Ember.A(); // To store values from child. Child field might modify value passed to it
      if (childFieldsValues) {
        var childMetaInfo = this.get('childFieldMetaInfo');
        childFieldsValues.forEach(function (childValue) {
          childMetaInfo.value = childValue;
          var field = (0, _formModels.createField)(_this, childMetaInfo);
          childMetaInfo.set('value', null);
          field.updateInitialValue();
          childFields.addObject(field);
          value.addObject(field.get('value')); // Parent values should be the same of its children
          if (isClone) {
            field.clearInitialValue();
          } else if (setInitialValue) {
            initialValue.addObject(field.get('_initialValue')); // Parent initial values should be the same of its children
          }
        });
      }
      this.setProperties({
        value: value,
        _initialValue: initialValue,
        childFields: childFields
      });
    },
    value: Ember.computed('childFields.@each.value', {
      get: function get() {
        var childFields = this.get('childFields') ? this.get('childFields') : Ember.A();
        return childFields.map(function (childField) {
          return childField.get('value');
        });
      },
      set: function set(key, value) {
        return value;
      }
    }),
    isDirty: Ember.computed('childFields.@each.isDirty', '_initialValue.[]', 'value.[]', {
      get: function get() {
        // isDirty of children should be called to get isDirty of parent to get computed
        this.get('childFields').isAny('isDirty');
        var isDirty = !_.isEqual(this.get('_initialValue'), this.get('value'));
        return isDirty || this.get('childFields').isAny('isDirty');
      }
    }),
    hasErrors: Ember.computed('errors.[]', 'childFields.@each.hasErrors', {
      get: function get() {
        return Ember.isPresent(this.get('errors')) || this.get('childFields').isAny('hasErrors');
      }
    }),
    hasValueIfMandatory: Ember.computed('isMandatory', 'childFields.@each.hasValueIfMandatory', {
      get: function get() {
        // return true if  it is not mandatory or when value is not present or when all the child fields hasValueIfMandatory.
        if (!this.get('isMandatory')) {
          return true;
        } else {
          return Ember.isPresent(this.get('value')) && this.get('childFields').isEvery('hasValueIfMandatory');
        }
      }
    }),
    updateInitialValue: function updateInitialValue() {
      var initialValue = Ember.A();
      this.get('childFields').forEach(function (field) {
        field.updateInitialValue();
        initialValue.addObject(field.get('_initialValue'));
      });
      this.set('_initialValue', initialValue);
    },
    validate: function validate() {
      var _this2 = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2._initializeErrors();
        if (_this2.get('isMandatory') && Ember.isEmpty(_this2.get('value'))) {
          _this2.addError(_this2.get('intlService').findTranslationByKey('validation.mandatory-error-message'));
          reject();
        }
        var promises = Ember.A();
        _this2.get('childFields').forEach(function (child) {
          promises.pushObject(child.validate());
        });
        Ember.RSVP.all(promises).then(function () {
          resolve();
          _this2.set('_cleanValue', _this2.get('value'));
        }).catch(function () {
          reject();
        });
      });
    },
    reset: function reset() {
      this.set('errors', Ember.A());
      this.createChildFields(this.get('_initialValue'));
      // TODO: Should Create childFields recursively
    },
    addNewChildField: function addNewChildField(value) {
      var childFields = this.get('childFields');
      var childMetaInfo = this.get('childFieldMetaInfo');
      childMetaInfo.value = null;
      var field = (0, _formModels.createField)(this, childMetaInfo);
      var fieldValue = field.getValueCopy(value);
      field.set('value', fieldValue);
      childFields.addObject(field);
    },
    removeChildField: function removeChildField(childField) {
      this.get('childFields').removeObject(childField);
    },
    getCleanValue: function getCleanValue() {
      return this.get('childFields').reduce(function (accumulator, childField) {
        var cleanValue;
        if (childField.isDynamicExtraField) {
          cleanValue = {
            key: childField.key,
            value: childField.getCleanValue()
          };
          accumulator.push(cleanValue);
        } else {
          cleanValue = childField.getCleanValue();
          // If the child field is an object field, we need to check if the primaryObjectKey and secondaryObjectKey values are present
          if (_typeof(cleanValue) === 'object' && cleanValue !== null && childField.primaryObjectKey && childField.secondaryObjectKey) {
            if (cleanValue[childField.primaryObjectKey] && cleanValue[childField.secondaryObjectKey]) {
              accumulator.push(cleanValue);
            }
          } else {
            accumulator.push(cleanValue);
          }
        }
        return accumulator;
      }, []);
    },
    setErrors: function setErrors(errors) {
      var hasChildErrors = !errors.every(function (error) {
        return typeof error === 'string';
      });
      if (hasChildErrors) {
        this.get('childFields').forEach(function (field, index) {
          var fieldErrors = errors[index];
          if (fieldErrors) {
            field.setErrors(fieldErrors);
          }
        });
      } else {
        this.set('errors', errors);
      }
    }
  });
});