define("workflows-web/workflows/history/details/action-info/component", ["exports", "workflows-web/mixins/table", "workflows-web/utilities/object"], function (_exports, _table, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATA_IN_PAGE_COLUMNS = [{
    label: 'Field',
    valuePath: 'label',
    cellClassNames: 'hf-mod-can-wrap',
    width: '250px'
  }, {
    label: 'placeholder',
    valuePath: 'placeholder',
    cellClassNames: 'hf-mod-can-wrap',
    width: '250px'
  }, {
    label: 'Value',
    valuePath: 'value',
    cellClassNames: 'hf-mod-can-wrap',
    width: '400px'
  }, {
    label: 'Error',
    valuePath: 'errorMessage',
    cellClassNames: 'hf-mod-can-wrap',
    width: '300px'
  }];
  var HTTP_REQUEST = 'http_request';
  var MASKED_VALUE = 'XXXXXXXXXXXXXXXX';
  var DOM_PURIFY_CONFIG = {
    ADD_ATTR: ['class']
  };
  var _default = _exports.default = Ember.Component.extend(_table.default, {
    domPurifyConfig: DOM_PURIFY_CONFIG,
    router: Ember.inject.service(),
    classNames: ['hf-workflow-execution_action-info'],
    isActionExecutionSuccessful: Ember.computed.equal('action.status', 'completed'),
    isActionExecutionUnSuccessful: Ember.computed.equal('action.status', 'failed'),
    tableModelKey: Ember.computed('showDataIn', 'showDataOut', {
      get: function get() {
        if (this.showDataIn) {
          return 'filteredDataIn';
        } else {
          return 'filteredDataOut';
        }
      }
    }),
    tableId: 'workflow-execution-history-action-data',
    tableHeight: '30vh',
    showMaskedData: false,
    showMaskedDataContainer: Ember.computed('action.workflowStep.stepType', {
      get: function get() {
        var _this$action;
        var _ref = ((_this$action = this.action) === null || _this$action === void 0 ? void 0 : _this$action.workflowStep) || {},
          stepType = _ref.stepType;
        return stepType === HTTP_REQUEST;
      }
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var action = this.action;
      var dataIn = (0, _object.hashToArray)(action['event_data']);
      var flattenedActionData = (0, _object.flattenObject)(action.data);
      dataIn.forEach(function (datum) {
        var _this$action2;
        var _this$action$workflow = (_this$action2 = _this.action) === null || _this$action2 === void 0 ? void 0 : _this$action2.workflowStep,
          stepType = _this$action$workflow.stepType;
        if (stepType === HTTP_REQUEST && !_this.showMaskedData) {
          datum.value = MASKED_VALUE;
        }
        var placeholder = flattenedActionData["".concat(datum.label)];
        if (placeholder) {
          datum.placeholder = placeholder.toString().startsWith('{{') ? placeholder : '~';
        } else {
          datum.placeholder = '~';
        }
      });
      if (this.isActionExecutionUnSuccessful) {
        var actionErrors = this.actionErrors;
        dataIn.forEach(function (datum) {
          var error = actionErrors.findBy('field', datum.label);
          if (error) {
            datum.set('errorMessage', error.message);
          } else {
            datum.set('errorMessage', '~');
          }
        });
      }
      this.setProperties({
        dataIn: dataIn,
        filteredDataIn: dataIn
      });
      var dataOut = (0, _object.hashToArray)(action.result);
      dataOut.forEach(function (datum) {
        var _this$action3;
        var _this$action$workflow2 = (_this$action3 = _this.action) === null || _this$action3 === void 0 ? void 0 : _this$action3.workflowStep,
          stepType = _this$action$workflow2.stepType;
        if (stepType === HTTP_REQUEST && !_this.showMaskedData) {
          datum.value = MASKED_VALUE;
        }
      });
      this.setProperties({
        dataOut: dataOut,
        filteredDataOut: dataOut
      });
      this.createTableInstance();
    },
    displayName: Ember.computed('action.workflowStep.{displayName,name}', {
      get: function get() {
        var _this$action4;
        var _ref2 = ((_this$action4 = this.action) === null || _this$action4 === void 0 ? void 0 : _this$action4.workflowStep) || {},
          displayName = _ref2.displayName,
          name = _ref2.name;
        return name || displayName;
      }
    }),
    columns: Ember.computed('showDataIn', 'showDataOut', {
      get: function get() {
        var columns = DATA_IN_PAGE_COLUMNS.slice();
        if (this.showDataOut || this.isActionExecutionSuccessful) {
          columns.removeObject(columns.findBy('valuePath', 'errorMessage'));
        }
        if (this.showDataOut) {
          columns.removeObject(columns.findBy('valuePath', 'placeholder'));
        }
        return columns;
      }
    }),
    currentNavTabValue: Ember.computed('isActionExecutionSuccessful', {
      get: function get() {
        return this.isActionExecutionSuccessful ? 'dataOut' : 'dataIn';
      }
    }),
    showDataIn: Ember.computed.equal('currentNavTabValue', 'dataIn'),
    showDataOut: Ember.computed.equal('currentNavTabValue', 'dataOut'),
    searchText: '',
    actionErrors: Ember.computed('action', {
      get: function get() {
        var _this2 = this;
        var actionErrors = Ember.A();
        var result = this.action.result;
        if (result) {
          var errors = result.errors;
          if (errors) {
            errors.forEach(function (error) {
              var field;
              if (Ember.isPresent(error.field)) {
                field = error.field;
              } else {
                field = 'non_field_errors';
              }
              if (Ember.isArray(error.errors)) {
                error.errors.forEach(function (errorObj) {
                  if (errorObj.field) {
                    if (errorObj.name) {
                      var _this2$action$event_d;
                      // find the index in the array of objects which has the error value based on errorObj.field
                      var fieldIndex = (_this2$action$event_d = _this2.action['event_data']) === null || _this2$action$event_d === void 0 || (_this2$action$event_d = _this2$action$event_d[field]) === null || _this2$action$event_d === void 0 ? void 0 : _this2$action$event_d.findIndex(function (field) {
                        return (field === null || field === void 0 ? void 0 : field[errorObj.field]) === errorObj.value;
                      });
                      var displayField = field;
                      if (fieldIndex !== -1) {
                        displayField = "".concat(field, "__").concat(fieldIndex, "__").concat(errorObj.field);
                      }
                      var message = "".concat(errorObj.name, " - ").concat(errorObj.message);
                      _this2.setFieldErrors(displayField, message, actionErrors);
                    } else if (Ember.isPresent(errorObj.index)) {
                      // if index is present, display error for that respective index
                      var _displayField = "".concat(field, "__").concat(errorObj.index, "__").concat(errorObj.field);
                      _this2.setFieldErrors(_displayField, errorObj.message, actionErrors);
                    }
                  } else {
                    _this2.setFieldErrors(field, errorObj.message, actionErrors);
                  }
                });
              } else if (error.code === 'unknown_error') {
                _this2.setFieldErrors(field, error.message, actionErrors);
              }
            });
          }
        }
        return actionErrors;
      }
    }),
    textDiff: Ember.computed('actionErrors.[]', {
      get: function get() {
        var field = this.dataIn.findBy('label', 'field');
        var pattern = this.dataIn.findBy('label', 'pattern');
        var dynamicSubstrings = pattern.value.match(/[^{\}]+(?=})/g);
        dynamicSubstrings.forEach(function (substring) {
          var patternToMatch = "{".concat(substring, "}");
          pattern.value = pattern.value.replace(patternToMatch, field.value.slice(pattern.value.indexOf(patternToMatch)).trim().split(' ')[0]);
        });
        var diff = Diff.createTwoFilesPatch("Expected Value", "Actual Value", pattern.value, field.value);
        var textDiff = [];
        textDiff.unshiftObject(Diff2Html.html(diff, {
          inputFormat: "json",
          drawFileList: true,
          fileListToggle: false,
          fileListStartVisible: false,
          fileContentToggle: false,
          matching: "lines",
          synchronisedScroll: true,
          highlight: true,
          renderNothingWhenEmpty: false
        }));
        var alternatePatterns = this.dataIn.findBy('label', 'alternate_patterns');
        if (alternatePatterns) {
          alternatePatterns = JSON.parse(alternatePatterns.value);
          alternatePatterns.forEach(function (_ref3) {
            var pattern = _ref3.pattern;
            var dynamicSubstrings = pattern.match(/[^{\}]+(?=})/g);
            dynamicSubstrings.forEach(function (substring) {
              var patternToMatch = "{".concat(substring, "}");
              pattern = pattern.replace(patternToMatch, field.value.slice(pattern.indexOf(patternToMatch)).trim().split(' ')[0]);
            });
            var diff = Diff.createTwoFilesPatch("Expected Value", "Actual Value", pattern, field.value);
            textDiff.unshiftObject(Diff2Html.html(diff, {
              inputFormat: "json",
              drawFileList: true,
              fileListToggle: false,
              fileListStartVisible: false,
              fileContentToggle: false,
              matching: "lines",
              synchronisedScroll: true,
              highlight: true,
              renderNothingWhenEmpty: false
            }));
          });
        }
        return textDiff;
      }
    }),
    nonFieldErrors: Ember.computed('actionErrors.[]', {
      get: function get() {
        var actionErrors = this.get('actionErrors');
        return actionErrors.filterBy('field', 'non_field_errors').map(function (error) {
          return error.message;
        });
      }
    }),
    setFieldErrors: function setFieldErrors(field, message, actionErrors) {
      var errorField = actionErrors === null || actionErrors === void 0 ? void 0 : actionErrors.findBy('field', field);
      if (errorField) {
        var updatedMessage = errorField.message.concat(message);
        errorField.set('message', updatedMessage);
      } else {
        actionErrors.addObject(Ember.Object.create({
          field: field,
          message: message
        }));
      }
    },
    filterData: function filterData(data, searchText) {
      return data.filter(function (datum) {
        return datum.get('label').toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
      });
    },
    actions: {
      resetSearchText: function resetSearchText() {
        this.set('searchText', '');
        if (this.showDataIn) {
          this.set('filteredDataIn', this.filterData(this.dataIn, ''));
        } else {
          this.set('filteredDataOut', this.filterData(this.dataOut, ''));
        }
        this.createTableInstance();
      },
      onSearch: function onSearch(searchText) {
        if (this.showDataIn) {
          this.set('filteredDataIn', this.filterData(this.dataIn, searchText));
        } else {
          this.set('filteredDataOut', this.filterData(this.dataOut, searchText));
        }
        this.createTableInstance();
      },
      goToWorkflowAction: function goToWorkflowAction(action) {
        var stepId = action.workflowStep.id;
        this.router.transitionTo('workflows.details.edit', this.workflow.id, {
          queryParams: {
            stepId: stepId
          }
        });
      },
      handleShowMaskData: function handleShowMaskData(result) {
        this.showMaskedData = result;
        var action = this.action;
        var dataInObj = action['event_data'];
        var updateDataIn = function updateDataIn(dataCollection) {
          dataCollection.forEach(function (datum) {
            datum.value = result ? dataInObj[datum.label] : MASKED_VALUE;
          });
        };
        var dataOutObj = action.result;
        var updateDataOut = function updateDataOut(dataCollection) {
          dataCollection.forEach(function (datum) {
            datum.value = result ? dataOutObj[datum.label] : MASKED_VALUE;
          });
        };
        updateDataIn(this.dataIn);
        updateDataIn(this.filteredDataIn);
        updateDataOut(this.dataOut);
        updateDataOut(this.filteredDataOut);
        this.setProperties({
          dataIn: this.dataIn,
          filteredDataIn: this.filteredDataIn,
          dataOut: this.dataOut,
          filteredDataOut: this.filteredDataOut
        });
        this.createTableInstance();
      }
    }
  });
});