define("workflows-web/constants/editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SUPPORT_CENTER_EDITOR_CONFIG = _exports.STANDARD_EDITOR_CONFIG = _exports.QUICK_REPLY_EDITOR_CONFIG = _exports.LITE_EDITOR_CONFIG = _exports.DEFAULT_EDITOR_CONFIG_WITH_MARK_DOWN = _exports.DEFAULT_EDITOR_CONFIG = _exports.AGENT_SCRIPT_EDITOR_CONFIG = void 0;
  var DEFAULT_EDITOR_CONFIG = _exports.DEFAULT_EDITOR_CONFIG = {
    title: false,
    toolbar: [{
      name: 'basicstyles',
      items: ['Link', 'Unlink', 'Bold', 'Italic', 'Underline', 'Font', 'FontSize', 'TextColor', 'BGColor']
    }, {
      name: 'advancestyles',
      items: ['BulletedList', 'NumberedList', 'RemoveFormat', 'Maximize']
    }],
    removeButtons: 'JustifyCenter',
    extraPlugins: 'divarea,font,colorbutton,format,maximize,table,tabletools,image2,widget,lineutils,clipboard,uploadwidget,colordialog',
    removePlugins: 'magicline,contextmenu,tabletools,tableselection,scayt,image',
    disableNativeSpellChecker: false,
    pasteFromWordRemoveStyles: false,
    pasteFromWordRemoveFontStyles: false,
    startupFocus: false,
    /* eslint-disable camelcase */
    font_names: 'Andale Mono;Arial;Arial Black;Book Antiqua;Calibri;Comic Sans Ms;Courier New;Georgia;Helvetica;Impact;Symbol;Tahoma;Terminal;Times New Roman;Trebuchet MS;Verdana',
    /* eslint-enable camelcase */
    enterTag: 'BR'
  };
  var DEFAULT_EDITOR_CONFIG_WITH_MARK_DOWN = _exports.DEFAULT_EDITOR_CONFIG_WITH_MARK_DOWN = {
    title: false,
    toolbar: [{
      name: 'basicstyles',
      items: ['Link', 'Unlink', 'Bold', 'Italic', 'Underline', 'Font', 'FontSize', 'TextColor', 'BGColor']
    }, {
      name: 'advancestyles',
      items: ['BulletedList', 'NumberedList', 'RemoveFormat', 'Maximize', 'Blockquote', 'Markdown']
    }],
    removeButtons: 'JustifyCenter',
    extraPlugins: 'divarea,font,colorbutton,format,maximize,table,image2,widget,lineutils,clipboard,uploadwidget,markdown,colordialog',
    removePlugins: 'magicline,contextmenu,tabletools,tableselection,scayt,image',
    allowedContent: true,
    disableNativeSpellChecker: false,
    pasteFromWordRemoveStyles: false,
    pasteFromWordRemoveFontStyles: false,
    startupFocus: false,
    /* eslint-disable camelcase */
    font_names: 'Andale Mono;Arial;Arial Black;Book Antiqua;Calibri;Comic Sans Ms;Courier New;Georgia;Helvetica;Impact;Symbol;Tahoma;Terminal;Times New Roman;Trebuchet MS;Verdana',
    /* eslint-enable camelcase */
    enterTag: 'BR'
  };
  var QUICK_REPLY_EDITOR_CONFIG = _exports.QUICK_REPLY_EDITOR_CONFIG = {
    title: false,
    toolbar: [{
      name: 'basicstyles',
      items: ['Bold', 'Italic', 'Underline']
    }],
    extraPlugins: 'divarea,font,format,maximize,table,image2,widget,lineutils,clipboard,uploadwidget,colordialog',
    removePlugins: 'magicline,contextmenu,tabletools,tableselection,scayt,image',
    disableNativeSpellChecker: false,
    pasteFromWordRemoveStyles: false,
    pasteFromWordRemoveFontStyles: false,
    removeButtons: 'JustifyCenter',
    startupFocus: true,
    enterTag: 'BR'
  };
  var LITE_EDITOR_CONFIG = _exports.LITE_EDITOR_CONFIG = {
    title: false,
    toolbar: [{
      name: 'basicstyles',
      items: ['Link', 'Unlink', 'Bold', 'Italic', 'Underline', 'Font', 'FontSize', 'TextColor', 'BGColor']
    }, {
      name: 'advancestyles',
      items: ['BulletedList', 'NumberedList', 'RemoveFormat', 'Source', 'Maximize']
    }],
    extraPlugins: 'divarea,font,colorbutton,format,maximize,table,image2,widget,lineutils,sourcedialog,clipboard,uploadimage,uploadwidget,colordialog',
    removePlugins: 'magicline,contextmenu,tabletools,tableselection,scayt,image',
    disableNativeSpellChecker: false,
    pasteFromWordRemoveStyles: false,
    pasteFromWordRemoveFontStyles: false,
    removeButtons: 'JustifyCenter',
    /* eslint-disable camelcase */
    font_names: 'Andale Mono;Arial;Arial Black;Book Antiqua;Calibri;Comic Sans Ms;Courier New;Georgia;Helvetica;Impact;Symbol;Tahoma;Terminal;Times New Roman;Trebuchet MS;Verdana',
    /* eslint-enable camelcase */
    enterTag: 'BR'
  };
  var STANDARD_EDITOR_CONFIG = _exports.STANDARD_EDITOR_CONFIG = {
    title: false,
    toolbar: [{
      name: 'basicstyles',
      items: ['Link', 'Unlink', 'Anchor', '-', 'Bold', 'Italic', 'Underline', 'Strike', '-', 'Font', 'FontSize', '-', 'TextColor', 'BGColor', '-', 'Styles', 'Format', '-']
    }, {
      name: 'advancestyles',
      items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'Table', 'HorizontalRule', '-', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '-', 'Blockquote', 'Cut', 'Copy', 'PasteText', 'PasteFromWord', '-', 'RemoveFormat', 'CodeSnippet', 'Find', 'Embed', 'Markdown', 'Source', 'EmojiPanel', 'Maximize']
    }],
    extraPlugins: 'divarea,font,colorbutton,format,maximize,contextmenu,tabletools,table,image2,widget,lineutils,sourcedialog,clipboard,uploadimage,uploadwidget,codesnippet,autocomplete,emoji,horizontalrule,find,markdown,autoembed,embed,colordialog,justify,pastefromword',
    removePlugins: 'magicline,scayt,image',
    removeButtons: 'Subscript,Superscript',
    disableNativeSpellChecker: false,
    pasteFromWordRemoveStyles: false,
    pasteFromWordRemoveFontStyles: false,
    /* eslint-disable camelcase */
    font_names: 'Andale Mono;Arial;Arial Black;Book Antiqua;Calibri;Comic Sans Ms;Courier New;Georgia;Helvetica;Impact;Open Sans;Symbol;Tahoma;Terminal;Times New Roman;Trebuchet MS;Verdana',
    fontSize_defaultLabel: '16px',
    font_defaultLabel: 'Open Sans',
    /* eslint-enable camelcase */
    allowClasses: true // Not to strip class attributes from content
  };
  var AGENT_SCRIPT_EDITOR_CONFIG = _exports.AGENT_SCRIPT_EDITOR_CONFIG = {
    title: false,
    toolbar: [{
      name: 'basicstyles',
      items: ['Link', 'Unlink', 'Bold', 'Italic', 'Underline']
    }, {
      name: 'advancestyles',
      items: ['BulletedList', 'NumberedList', 'Embed']
    }],
    extraPlugins: 'divarea,font,format,maximize,table,image2,widget,lineutils,clipboard,uploadwidget,colordialog,autoembed,embed',
    removePlugins: 'magicline,contextmenu,tabletools,tableselection,scayt,image',
    disableNativeSpellChecker: false,
    pasteFromWordRemoveStyles: false,
    pasteFromWordRemoveFontStyles: false,
    removeButtons: 'JustifyCenter',
    startupFocus: true,
    enterTag: 'BR'
  };
  var SUPPORT_CENTER_EDITOR_CONFIG = _exports.SUPPORT_CENTER_EDITOR_CONFIG = {
    title: false,
    toolbar: [{
      name: 'basicStyles',
      items: ['Link', 'Unlink', 'Bold', 'Italic', 'Underline', 'Strike', 'FontSize']
    }, {
      name: 'advancestyles',
      items: ['BulletedList', 'NumberedList', 'Blockquote', 'Markdown', 'Source', 'EmojiPanel', 'Maximize']
    }],
    extraPlugins: 'divarea,font,format,maximize,table,image2,widget,lineutils,clipboard,uploadimage,uploadwidget,emoji,markdown',
    removePlugins: 'magicline,contextmenu,tabletools,tableselection,scayt,image',
    disableNativeSpellChecker: false,
    pasteFromWordRemoveStyles: false,
    pasteFromWordRemoveFontStyles: false,
    removeButtons: 'JustifyCenter',
    startupFocus: true,
    enterTag: 'BR'
  };
});