define("workflows-web/components/toast-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['hf-toast-message'],
    isError: false,
    classNameBindings: ['toastMessageService.hidePreviousMessage:hf-hide', 'isError:hf-mod-error'],
    formattedMessage: Ember.computed('toastMessageService.message', {
      get: function get() {
        var _this = this;
        var message = this.get('toastMessageService.message');
        var text = message.text;
        // Used when more than one message is grouped like add update validation messages
        if (Ember.isArray(text)) {
          var messageGroup = Ember.A();
          text.forEach(function (message) {
            if (Ember.typeOf(message) == 'string') {
              messageGroup.push(message);
            } else {
              messageGroup.push(_this.getFormattedMessage(message.text, message.params));
            }
          });
          return messageGroup.join(', ');
        } else {
          return this.getFormattedMessage(text, message.params);
        }
      }
    }),
    isHtml: Ember.computed.reads('toastMessageService.message.params.htmlSafe'),
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      this.set('isError', this.get('toastMessageService.messageType') === 'failure');
      if (this.get('toastMessageService.autoHide')) {
        Ember.run.later(function () {
          // if the component is destroyed or if the previous message is hiding before the next message comes close() need not be called
          if (!_this2.isDestroyed && !_this2.get('toastMessageService.hidePreviousMessage')) {
            _this2.send('close');
          }
        }, 5000);
      }
    },
    getFormattedMessage: function getFormattedMessage(message, params) {
      if (params && params.htmlSafe) {
        // if params has htmlSafe true, then message has to be direct html string. example: <strong>Hello world</strong>
        return this.get('intlService').formatMessage(message, params);
      }
      var translationText = this.get('intlService').findTranslationByKey(message);
      return this.get('intlService').formatMessage(translationText, params);
    },
    messageComponent: Ember.computed.reads('toastMessageService.messageComponent'),
    actions: {
      close: function close() {
        var _this3 = this;
        this.set('toastMessageService.hidePreviousMessage', true);
        Ember.run.later(function () {
          _this3.set('toastMessageService.hidePreviousMessage', false);
          _this3.get('toastMessageService').hideMessage();
        }, 500);
      }
    }
  });
});