define("workflows-web/workflows/details/edit/actions/details/http-request/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wLGUC00v",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-vertical-flex-container hf-workflows_form-group\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,0],[\"step-{index}\"],[[\"index\"],[\"1\"]]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"field\"],[[35,1,[\"model\",\"url\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[30,[36,5],[\"hf-u-vertical-flex-container hf-workflows_form-group hf-mod-last\",[30,[36,4],[[35,3],\"hf-u-disabled\"],null]],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,0],[\"step-{index}\"],[[\"index\"],[\"2\"]]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"field\"],[[35,1,[\"model\",\"method\"]]]]]],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"form\",\"key\"],[[35,1],\"headers\"]]]],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"form\",\"key\"],[[35,1],\"body\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"form\",\"form-field\",\"isPrimaryFieldEmpty\",\"if\",\"concat-strings\",\"workflows/details/edit/actions/details/code-editor-field\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/http-request/template.hbs"
    }
  });
});