define("workflows-web/components/form-field-input/condition/component", ["exports", "workflows-web/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    field: null,
    actions: {
      removeCondition: function removeCondition() {
        if (this.removeCondition) {
          this.removeCondition(this.get('field'));
        }
      },
      updateCondition: function updateCondition() {
        if (this.updateCondition) {
          this.updateCondition(this.get('field'));
        }
      }
    }
  });
});