define("workflows-web/components/data-table-new/active-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sUxi4YKj",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"hf-entity-box_footer_active \",[30,[36,1],[[35,0],\"hf-mod-inactive\"],null]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"unless\"]}",
    "meta": {
      "moduleName": "workflows-web/components/data-table-new/active-cell/template.hbs"
    }
  });
});