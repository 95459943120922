define("workflows-web/components/data-table-new/category-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    orderedCategories: Ember.computed('value.@each.name', {
      get: function get() {
        return this.get('value').toArray().sortBy('name');
      }
    })
  });
});