define("workflows-web/components/form-input/textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UJgliSXl",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,12],null,[[\"value\",\"focus-out\",\"class\",\"data-test-id\",\"classNames\",\"disabled\",\"placeholder\",\"enter\"],[[35,11],[30,[36,0],[[32,0],\"onFocusOut\"],null],[30,[36,10],[[30,[36,9],[\"hf-form-input hf-text-input hf-mod-textarea\",[30,[36,6],[[35,8],\"hf-is-highlighted\"],null],[30,[36,6],[[35,7],\"hf-is-resizable\"],null]],null],\" \",[30,[36,6],[[35,5],\"hf-is-error\"],null],\" \"],null],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"onEnter\"],null]]]]],[2,\"\\n\"],[19,\"components/form-input/partials/error-message\",[]],[2,\"\\n\"],[19,\"components/form-input/partials/help-text\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"action\",\"placeholder\",\"isDisabled\",\"inputClass\",\"inputDataTestId\",\"hasErrors\",\"if\",\"isResizable\",\"isHighlighted\",\"concat-strings\",\"concat\",\"value\",\"textarea\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-input/textarea/template.hbs"
    }
  });
});