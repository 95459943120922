define("workflows-web/manage/agents/list/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MWZssZG9",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"slide-in\",[],[[\"@isOpen\",\"@canHide\",\"@onClose\"],[true,false,[30,[36,3],[[32,0,[\"closeEditAgentForm\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"header\"],[14,0,\"hf-entity-header hf-u-vertically-centered-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-entity-header_title hf-u-vertically-centered-container\"],[12],[1,[32,1,[\"firstName\"]]],[2,\" \"],[1,[32,1,[\"lastName\"]]],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,4],[[32,0,[\"canShowEditForm\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"a\"],[24,0,\"hf-u-push-right\"],[4,[38,2],[\"click\",[32,0,[\"toggleEditForm\"]]],null],[12],[1,[30,[36,0],[\"edit\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"canShowToastMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"mini-toast-message\",[[24,0,\"new-agent-toast-message\"]],[[\"@message\",\"@type\",\"@canShow\",\"@removeToastMessage\"],[[30,[36,0],[\"manage.agent-update-success-message\"],null],[30,[36,1],[[32,0,[\"isSaveSuccess\"]],\"success\",\"failure\"],null],[32,0,[\"canShowToastMessage\"]],[32,0,[\"removeToastMessage\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"manage/agents/form\",[],[[\"@dataSource\",\"@canShowEditForm\",\"@onSave\"],[[32,1],[32,0,[\"canShowEditForm\"]],[30,[36,3],[[32,0,[\"onSave\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"on\",\"fn\",\"not\"]}",
    "meta": {
      "moduleName": "workflows-web/manage/agents/list/edit/template.hbs"
    }
  });
});