define("workflows-web/helpers/format-time", ["exports", "workflows-web/utilities/time"], function (_exports, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    intlService: Ember.inject.service('intl'),
    compute: function compute(params) {
      // params is time in minutes
      return (0, _time.formatTime)(params, this.get('intlService'));
    }
  });
});