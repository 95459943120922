define("workflows-web/workflows/dashboard/metrics/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['hf-workflows-dashboard_row'],
    costSaved: Ember.computed('metric.timeSaved', {
      get: function get() {
        var costSaved = 0;
        var costPerHour = this.costPerHour;
        if (Ember.isPresent(costPerHour)) {
          costSaved = this.costPerHour * this.metric.timeSaved;
        }
        return costSaved;
      }
    })
  });
});