define("workflows-web/reset-password/controller", ["exports", "workflows-web/utilities/email-validation"], function (_exports, _emailValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    requestService: Ember.inject.service('request'),
    token: null,
    isValidatingToken: null,
    isValidToken: false,
    email: null,
    password: null,
    confirmPassword: null,
    passwordsMismatched: false,
    isSubmitInProgress: false,
    isEmailSubmitInProgress: false,
    isEmailSubmitSuccess: false,
    isPasswordResetSuccess: false,
    isForgotPassword: Ember.computed.not('token'),
    // FIXME: Enable once BE supports forgot password email trigger
    error: null,
    hasErrors: Ember.computed.or('error', 'passwordsMismatched'),
    passwordErrors: null,
    showingPasswordPolicy: Ember.computed('isForgotPassword', 'isValidToken', 'isPasswordResetSuccess', {
      get: function get() {
        return !this.isForgotPassword && this.isValidToken && !this.isPasswordResetSuccess;
      }
    }),
    validateToken: function validateToken() {
      var _this = this;
      var token = this.get('token');
      this.setProperties({
        isValidToken: false,
        isValidatingToken: true,
        error: null
      });
      return this.get('requestService').validateForgotPasswordToken(token).then(function () {
        _this.setProperties({
          'isValidToken': true
        });
      }).catch(function (errors) {
        _this.setProperties({
          error: errors.error,
          isValidToken: false
        });
      }).finally(function () {
        _this.set('isValidatingToken', false);
      });
    },
    actions: {
      emailSubmit: function emailSubmit() {
        var _this2 = this;
        this.set('emailError', null);
        var email = this.get('email');
        if ((0, _emailValidation.validateEmail)(email.trim())) {
          this.setProperties({
            isEmailSubmitSuccess: false,
            isEmailSubmitInProgress: true
          });
          var payload = {
            email: email
          };
          this.get('requestService').sendEmailForForgotPassword(payload).then(function () {
            _this2.set('isEmailSubmitSuccess', true);
          }).catch(function (error) {
            _this2.set('error', error);
          }).finally(function () {
            _this2.set('isEmailSubmitInProgress', false);
          });
        } else {
          this.set('emailError', 'Invalid email');
        }
      },
      submitPassword: function submitPassword() {
        var _this3 = this;
        var password = this.get('password');
        var confirmPassword = this.get('confirmPassword');
        var passwordsMatched = password === confirmPassword;
        this.set('passwordsMismatched', !passwordsMatched);
        this.set('passwordErrors', null);
        if (passwordsMatched) {
          var token = this.get('token');
          this.setProperties({
            isPasswordResetSuccess: false,
            isSubmitInProgress: true
          });
          this.get('requestService').resetPassword({
            password: password,
            confirmPassword: confirmPassword,
            token: token
          }).then(function () {
            _this3.set('isPasswordResetSuccess', true);
          }).catch(function (errors) {
            _this3.set('error', errors.error);
            if (errors.password) {
              _this3.set('passwordErrors', errors.password);
            }
          }).finally(function () {
            _this3.set('isSubmitInProgress', false);
          });
        }
      },
      goToLogin: function goToLogin() {
        this.transitionToRoute('login');
      },
      removeToken: function removeToken() {
        this.set('token', null);
      }
    }
  });
});