define("workflows-web/components/form-field-input/remote-choice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PRMO6VL+",
    "block": "{\"symbols\":[\"value\",\"&default\"],\"statements\":[[6,[37,12],null,[[\"options\",\"allowClear\",\"class\",\"autoFocus\",\"placeholder\",\"searchPlaceholder\",\"renderInPlace\",\"selected\",\"noMatchesMessage\",\"url\",\"itemsToBeExcluded\",\"searchParam\",\"additionalParams\",\"isAddNew\",\"onChange\",\"onClose\",\"disabled\",\"data-test-id\",\"dropdownClass\"],[[35,0,[\"choices\"]],[35,0,[\"allowClear\"]],[30,[36,11],[[35,10],[35,9],[30,[36,2],[[35,8],\"hf-is-error\"],null],[30,[36,2],[[35,0,[\"isHighlighted\"]],\"hf-is-highlighted\"],null],[30,[36,2],[[35,0,[\"isDisabled\"]],\"hf-is-disabled\"],null]],null],[35,0,[\"autoFocus\"]],[35,0,[\"placeholder\"]],[35,0,[\"searchPlaceholder\"]],true,[35,0,[\"value\"]],\"no-results-found\",[35,0,[\"lookupUrl\"]],[35,7],[35,0,[\"searchParam\"]],[35,6],[35,0,[\"isAddNew\"]],[30,[36,5],[[32,0],\"valueChanged\"],null],[30,[36,5],[[32,0],\"onClose\"],null],[35,0,[\"isDisabled\"]],[35,4],[35,3]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-elseblock\"],[12],[13],[2,\"\\n    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0,[\"choiceLabelKey\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-elseblock2\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,1],[[32,1],[35,0,[\"choiceLabelKey\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-elseblock3\"],[12],[13],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"field\",\"get\",\"if\",\"dropdownClass\",\"fieldDataTestId\",\"action\",\"additionalParams\",\"itemsToBeExcluded\",\"hasErrors\",\"jsTestClass\",\"selectClass\",\"concat-strings\",\"auto-complete/remote/single\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/remote-choice/template.hbs"
    }
  });
});