define("workflows-web/workflows/details/edit/actions/details/message-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "95VpKlXw",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"class\",\"choices\",\"closeDropdown\"],[\"hf-u-pull-right\",[35,1],[30,[36,0],[[32,0],\"insertEmailMessageTags\"],null]]]]],[2,\"\\n\"],[1,[30,[36,4],null,[[\"field\"],[[35,3]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"fieldChoices\",\"components/insert-tags\",\"field\",\"form-field\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/message-field/template.hbs"
    }
  });
});