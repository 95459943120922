define("workflows-web/components/form-input/email/component", ["exports", "workflows-web/components/form-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    onFocusOut: null,
    validate: function validate() {
      this.initializeErrors();
      this.checkIfMandatory();
      this.checkForEmail();
    },
    actions: {
      onFocusOut: function onFocusOut() {
        this.validate();
        if (!this.get('errors.length') && this.onFocusOut) {
          this.onFocusOut();
        }
      }
    }
  });
});