define("workflows-web/initializers/intl", ["exports", "ember-intl/services/intl", "workflows-web/utilities/date"], function (_exports, _intl, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'modify-intl-service-translations',
    initialize: function initialize() {
      this.modifyIntlService(_intl.default);
    },
    modifyIntlService: function modifyIntlService(IntlService) {
      IntlService.reopen({
        findTranslationByKeyWithParameters: function findTranslationByKeyWithParameters(key, parameters) {
          if (this.exists(key)) {
            var translation = this.findTranslationByKey(key);
            var formattedMessage = this.formatMessage(translation, parameters);
            return formattedMessage;
          } else {
            return this.findTranslationByKey(key);
          }
        },
        findTranslationByKey: function findTranslationByKey(key, locales) {
          locales = locales || Ember.get(this, '_locale');
          var translation = this.t(key, Ember.makeArray(locales));
          if (typeof translation === 'undefined') {
            return key;
          }
          return translation;
        },
        formatDate: function formatDate(value, options) {
          if (value) {
            return (0, _date.getFormattedDate)(value, options);
          }
        }
      });
    }
  };
});