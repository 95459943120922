define("workflows-web/components/form-field-input/check-box/component", ["exports", "workflows-web/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    tagName: 'label',
    classNames: ['hf-u-cursor-pointer'],
    onValueChange: Ember.observer('field.value', function () {
      if (this.onChange) {
        this.onChange(this.get('field.value'));
      }
    })
  });
});